<div class="attachments-container">
    <div class="position">
        <div class="title">Attachments</div>

        <div [formGroup]="lunForm">
            <app-lun-attachment-sia
                formControlName="SupportingDocumentAttachments"
                [headerText]="supportingDocsHeader"
                [lunForm]="lunForm"
                [isDisabled]="isDisabled"
                [printMode]="printMode"
            >
            </app-lun-attachment-sia>
        </div>
    </div>
</div>
