import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
    selector: 'app-manual-email-dialog',
    templateUrl: './manual-email-popup.component.html',
    styleUrls: ['./manual-email-popup.component.scss'],
})
export class ManualEmailDialogComponent extends BaseComponent {
    mailUrls: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.mailUrls = data.mailUrls
    }
}