import { IValidators } from 'src/app/validators/validators';
import { Dictionary } from 'typescript-collections';
import { Validators, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LUNFormValidators implements IValidators {
    private validators: Dictionary<string, ValidatorFn[]>;

    constructor() {
        this.validators = new Dictionary<string, ValidatorFn[]>();
        this.configure();
    }

    getValidators(fieldName: string) {
        return this.validators.getValue(fieldName);
    }

    private configure(): void {
        this.validators.setValue('subsystems', [Validators.required]);
        this.validators.setValue('areaSCManager', [Validators.required]);
    }
}
