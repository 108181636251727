import { Pipe, PipeTransform } from '@angular/core';
import { UserWithRoleDetail, AppRole } from '../store/common.model';

@Pipe({
    name: 'userList',
    pure: false,
})
export class UserListPipe implements PipeTransform {
    transform(items: UserWithRoleDetail[], role: AppRole): any {
        if (!items || role === null) {
            return items;
        }
        return items.filter((item) => AppRole[item.role] === AppRole[role]);
    }
}
