import { Directive, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
    selector: 'mat-autocomplete[infiniteScroll]',
})
export class InfiniteScrollDirective implements OnDestroy {
    @Input() thresholdPercent = 0.8;
    @Output() infiniteScroll = new EventEmitter<void>();
    onDestroy = new Subject();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    setTimeout(() => {
                        this.removeScrollEventListener();
                        if (this.autoComplete.panel) {
                            this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
                        }
                    });
                }),
                takeUntil(this.onDestroy)
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                takeUntil(this.onDestroy)
            )
            .subscribe();
    }

    private removeScrollEventListener() {
        if (this.autoComplete.panel) {
            this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
        }
    }

    ngOnDestroy() {
        this.onDestroy.next(null);
        this.onDestroy.complete();

        this.removeScrollEventListener();
    }

    onScroll(event: any) {
        if (this.thresholdPercent === undefined) {
            this.infiniteScroll.next();
        } else {
            const threshold = (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
            const current = event.target.scrollTop + event.target.clientHeight;

            if (current > threshold) {
                this.infiniteScroll.next();
            }
        }
    }
}
