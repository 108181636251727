import {
    LUNRegisterState,
    LUNRegisterDataPagination,
    LUNRegisterFilter,
    ShowColumnSettings,
    LUNRegisterDTO,
} from './model';
import { LUNRegisterActions, LUNRegisterActionTypes } from './actions';

const initialState: LUNRegisterState = {
    dataPagination: new LUNRegisterDataPagination(),
    filter: new LUNRegisterFilter(),
    isLoading: false,
    showColumnSettings: new ShowColumnSettings(),
};

export function reducer(state = initialState, action: LUNRegisterActions) {
    switch (action.type) {
        case LUNRegisterActionTypes.LUNRegisterAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterAddCommentSuccess: {
            var itemsObject = Object.assign(new Array<LUNRegisterDTO>(), state.dataPagination.items);
            const item = Object.assign({}, itemsObject.find((i) => i.id === action.payload.id));
            const index = itemsObject.findIndex((i) => i.id === action.payload.id);
            item.latestComment = action.payload.description;
            itemsObject[index] = item;
            
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    items: itemsObject,
                },
            };
        }
        case LUNRegisterActionTypes.LUNRegisterUpdateCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case LUNRegisterActionTypes.LUNRegisterFilterReset: {
            const newFilter = new LUNRegisterFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case LUNRegisterActionTypes.LUNRegisterExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNRegisterActionTypes.LUNRegisterToggleColumnShowSetting: {
            return {
                ...state,
                showColumnSettings: {
                    ...state.showColumnSettings,
                    [action.columnName]: !state.showColumnSettings[action.columnName],
                },
            };
        }
        default:
            return state;
    }
}
