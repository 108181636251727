import { Action } from '@ngrx/store';
import { LUNRegisterDataPagination } from './model';

export enum LUNRegisterActionTypes {
    LUNRegisterFilterRequest = '[LUN Register] Filter Request',
    LUNRegisterFilterSuccess = '[LUN Register] Filter Success',
    LUNRegisterFilterError = '[LUN Register] Filter Error',
    LUNRegisterFilterPropertyUpdate = '[LUN Register] Filter Property Update',
    LUNRegisterFilterReset = '[LUN Register] Filter Reset',
    LUNRegisterExportToExcelRequest = '[LUNRegister] Export to Excel Request',
    LUNRegisterExportToExcelSuccess = '[LUNRegister] Export to Excel Success',
    LUNRegisterExportToExcelError = '[LUNRegister] Export to Excel Error',
    LUNRegisterToggleColumnShowSetting = '[LUNRegister] Toggle Column Show Setting',
    LUNRegisterAddCommentRequest = '[LUNRegister] Add Comment Request',
    LUNRegisterAddCommentSuccess = '[LUNRegister] Add Comment Request Success',
    LUNRegisterUpdateCommentError = '[LUNRegister] Add Comment Request Error'
}


export class LUNRegisterAddCommentRequest implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterAddCommentRequest;

    constructor(public payload: { id: number; description: string;}) {}
}

export class LUNRegisterAddCommentSuccess implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterAddCommentSuccess;

    constructor(public payload: { id: number; description: string;}) {}
}

export class LUNRegisterAddCommentError implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterUpdateCommentError;

    constructor(public payload: string) {}
}

export class LUNRegisterFilterRequest implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterFilterRequest;
}

export class LUNRegisterFilterSuccess implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterFilterSuccess;

    constructor(public payload: LUNRegisterDataPagination) {}
}

export class LUNRegisterFilterError implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterFilterError;

    constructor(public payload: string) {}
}

export class LUNRegisterFilterPropertyUpdate implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class LUNRegisterFilterReset implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterFilterReset;
}

export class LUNRegisterExportToExcelRequest implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterExportToExcelRequest;
}

export class LUNRegisterExportToExcelSuccess implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class LUNRegisterExportToExcelError implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterExportToExcelError;

    constructor(public payload: string) {}
}

export class LUNRegisterToggleColumnShowSetting implements Action {
    readonly type = LUNRegisterActionTypes.LUNRegisterToggleColumnShowSetting;

    constructor(public columnName: string) {}
}

export type LUNRegisterActions =
    | LUNRegisterFilterRequest
    | LUNRegisterFilterSuccess
    | LUNRegisterFilterError
    | LUNRegisterFilterPropertyUpdate
    | LUNRegisterFilterReset
    | LUNRegisterExportToExcelRequest
    | LUNRegisterExportToExcelSuccess
    | LUNRegisterExportToExcelError
    | LUNRegisterToggleColumnShowSetting
    | LUNRegisterAddCommentRequest
    | LUNRegisterAddCommentSuccess
    | LUNRegisterAddCommentError;
