import { OrderDirection, CheckListColumn, CalendarColumn } from 'src/app/store/common.model';
import * as moment from 'moment';
import { LUNComment } from '../lun/model';
import { LUNStatusType } from 'src/app/models/enums';

export class LUNRegisterFilter {
    page = 0;
    take = 10;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    subsystems: { id: string }[] = [];
    areas: string[] = [];
    disciplines: string[] = [];
    statuses: string[] = [];
    timezoneOffset = 0;
    number: string = '';
    originators: string[] = [];
    projectTeamNames: string[] = [];
    columnRaisedDate: CalendarColumn = null;
    columnlunDate: CalendarColumn = null;
    columnNumbers: CheckListColumn = null;
    columnAreas: CheckListColumn = null;
    columnDisciplines: CheckListColumn = null;
    columnSubsystems: CheckListColumn = null;
    columnOriginators: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnStatuses: CheckListColumn = null;
    columnRequiredSigners: CheckListColumn = null;
    columnRequiredIsolationAuthoritySigners: CheckListColumn = null;
}

export class AttachmentDTO {
    name: string;
    link: string;
}

export class LUNRegisterDTO {
    id: number;
    number: string;
    area: string;
    discipline: string;
    originator: string;
    subsystems: string[] = [];
    raisedDate: moment.Moment;
    lunDate: moment.Moment;
    attachments: AttachmentDTO[] = [];
    comments: LUNComment[] = [];
    latestComment: string = '';
    status: LUNStatusType;
    requiredSigners: string;
    requiredIsolationAuthoritySigners: string;
}

export interface LUNRegisterState {
    dataPagination: LUNRegisterDataPagination;
    filter: LUNRegisterFilter;
    isLoading: boolean;
    showColumnSettings: ShowColumnSettings;
}

export class ShowColumnSettings {
    discipline = true;
    originator = true;
    raiseDate = true;
    lunDate = true;
    requiredSigners = true;
    requiredIsolationAuthoritySigners = true;
}

export class LUNRegisterDataPagination {
    items: LUNRegisterDTO[] = [];
    totalCount = 0;
}
