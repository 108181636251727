import {
    NightShiftRegisterState,
    NightShiftRegisterDataPagination,
    NightShiftRegisterFilter,
    ShowColumnSettings,
    NightShiftRegisterDTO,
} from './model';
import { NightShiftRegisterActions, NightShiftRegisterActionTypes } from './actions';

const initialState: NightShiftRegisterState = {
    dataPagination: new NightShiftRegisterDataPagination(),
    filter: new NightShiftRegisterFilter(),
    isLoading: false,
    showColumnSettings: new ShowColumnSettings(),
};

export function reducer(state = initialState, action: NightShiftRegisterActions) {
    switch (action.type) {
        case NightShiftRegisterActionTypes.NightShiftRegisterAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterAddCommentSuccess: {
            var itemsObject = Object.assign(new Array<NightShiftRegisterDTO>(), state.dataPagination.items);
            const item = Object.assign({}, itemsObject.find((i) => i.id === action.payload.id));
            const index = itemsObject.findIndex((i) => i.id === action.payload.id);
            item.latestComment = action.payload.description;
            itemsObject[index] = item;
            
            return {
                ...state,
                isLoading: false,
                dataPagination: {
                    items: itemsObject,
                },
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterUpdateCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterFilterRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterFilterSuccess: {
            return {
                ...state,
                isLoading: false,
                dataPagination: action.payload,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterFilterError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterFilterPropertyUpdate: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                    },
                };
            } else {
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        [action.payload.key]: Array.isArray(state.filter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterFilterReset: {
            const newFilter = new NightShiftRegisterFilter();
            return {
                ...state,
                filter: { ...newFilter },
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftRegisterActionTypes.NightShiftRegisterToggleColumnShowSetting: {
            return {
                ...state,
                showColumnSettings: {
                    ...state.showColumnSettings,
                    [action.columnName]: !state.showColumnSettings[action.columnName],
                },
            };
        }
        default:
            return state;
    }
}
