import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, mergeMap, map, catchError, withLatestFrom, filter, tap } from 'rxjs/operators';
import { LUNActionTypes, LUNUpdateStatusRequestSuccess, GetLUNRequestSuccess, LUNUpdateProperty } from '../lun/actions';
import {
    ActionUsersActionTypes,
    AreaSCElectricalDisciplineLeadsRequest,
    AreaSCSMPDisciplineLeadsRequest,
    AreaSCIAndCDisciplineLeadsRequest,
    AreaSCManagersRequest,
    AreaSCElectricalDisciplineLeadsRequestSuccess,
    AreaSCElectricalDisciplineLeadsRequestError,
    AreaSCSMPDisciplineLeadsRequestSuccess,
    AreaSCSMPDisciplineLeadsRequestError,
    AreaSCIAndCDisciplineLeadsRequestSuccess,
    AreaSCIAndCDisciplineLeadsRequestError,
    AreaSCManagersRequestSuccess,
    AreaSCManagersRequestError,
    AddPowerUserToAreaSCElectricalDisciplineLeads,
    AddPowerUserToAreaSCSMPDisciplineLeads,
    AddPowerUserToAreaSCIAndCDisciplineLeads,
    AddPowerUserToAreaSCManagers,
    LeadIsolationAuthoritiesSMPRequest,
    LeadIsolationAuthoritiesSMPRequestSuccess,
    LeadIsolationAuthoritiesSMPRequestError,
    LeadIsolationAuthoritiesElectricalRequest,
    LeadIsolationAuthoritiesElectricalRequestSuccess,
    LeadIsolationAuthoritiesElectricalRequestError,
} from './actions';
import { of, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToastService } from '../../services/shared/toast.service';
import { UserDetail } from '../common.model';
import { ActionUsersService } from '../../services/action-users.service';
import { ApplicationState } from '../model';
import { Constants } from 'src/app/constants';
import { LUNStatusType } from 'src/app/models/enums';

@Injectable()
export class ActionUsersEffects {
    constructor(
        private actions$: Actions,
        private actionUsersService: ActionUsersService,
        private store: Store<ApplicationState>,
        private toastService: ToastService
    ) {}

    
    actionUserListRequest$ = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.GetLUNRequestSuccess),
        withLatestFrom(this.store.select((store) => store.lunState.form)),
        switchMap(([action, lun]) => [
            new AreaSCElectricalDisciplineLeadsRequest(),
            new AreaSCSMPDisciplineLeadsRequest(),
            new AreaSCIAndCDisciplineLeadsRequest(),
            new AreaSCManagersRequest(),
            new LeadIsolationAuthoritiesSMPRequest(),
            new LeadIsolationAuthoritiesElectricalRequest(),
        ])
    ));

    
    loadAreaSCElectricalDisciplineLeads$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequest),
        mergeMap((action: AreaSCElectricalDisciplineLeadsRequest) =>
            this.actionUsersService.getReviewers(Constants.applicableGroups.AreaSCElectricalDisciplineLead).pipe(
                map(
                    (representatives: UserDetail[]) =>
                        new AreaSCElectricalDisciplineLeadsRequestSuccess(representatives)
                ),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Area SC Electrical Discipline Leads. Please contact Program Administrator.'
                    );
                    return of(new AreaSCElectricalDisciplineLeadsRequestError(error));
                })
            )
        )
    ));

    
    loadAreaSCSMPDisciplineLeads$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequest),
        mergeMap((action: AreaSCSMPDisciplineLeadsRequest) =>
            this.actionUsersService.getReviewers(Constants.applicableGroups.AreaSCSMPDisciplineLead).pipe(
                map((representatives: UserDetail[]) => new AreaSCSMPDisciplineLeadsRequestSuccess(representatives)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Area SC SMP Discipline Leads. Please contact Program Administrator.'
                    );
                    return of(new AreaSCSMPDisciplineLeadsRequestError(error));
                })
            )
        )
    ));

    
    loadAreaSCIAndCDisciplineLeads$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequest),
        mergeMap((action: AreaSCIAndCDisciplineLeadsRequest) =>
            this.actionUsersService.getReviewers(Constants.applicableGroups.AreaSCIAndCDisciplineLead).pipe(
                map((representatives: UserDetail[]) => new AreaSCIAndCDisciplineLeadsRequestSuccess(representatives)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Area SC I&C Discipline Leads. Please contact Program Administrator.'
                    );
                    return of(new AreaSCIAndCDisciplineLeadsRequestError(error));
                })
            )
        )
    ));

    
    loadAreaSCManagers$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.AreaSCManagersRequest),
        mergeMap((action: AreaSCManagersRequest) =>
            this.actionUsersService.getReviewers(Constants.applicableGroups.AreaSCManager).pipe(
                map((representatives: UserDetail[]) => new AreaSCManagersRequestSuccess(representatives)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Area SC Managers. Please contact Program Administrator.'
                    );
                    return of(new AreaSCManagersRequestError(error));
                })
            )
        )
    ));

    
    loadLeadIsolationAuthorities$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequest),
        mergeMap((action: LeadIsolationAuthoritiesSMPRequest) =>
            this.actionUsersService.getReviewers(Constants.applicableGroups.IsolationSMPLead, Constants.disciplines.Mechanical).pipe(
                map((representatives: UserDetail[]) => new LeadIsolationAuthoritiesSMPRequestSuccess(representatives)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Lead Isolation Authorities. Please contact Program Administrator.'
                    );
                    return of(new LeadIsolationAuthoritiesSMPRequestError(error));
                })
            )
        )
    ));

    
    loadLeadIsolationAuthoritiesElectrical$ = createEffect(() => this.actions$.pipe(
        ofType(ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequest),
        mergeMap((action: LeadIsolationAuthoritiesElectricalRequest) =>
            this.actionUsersService.getReviewers(Constants.applicableGroups.IsolationElectricalLead, Constants.disciplines.Electrical).pipe(
                map((representatives: UserDetail[]) => new LeadIsolationAuthoritiesElectricalRequestSuccess(representatives)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while getting Lead Isolation Authorities. Please contact Program Administrator.'
                    );
                    return of(new LeadIsolationAuthoritiesElectricalRequestError(error));
                })
            )
        )
    ));

    
    clearAreaSCElectricalDisciplineLeads$ = createEffect(() => this.actions$.pipe(
        ofType<AreaSCElectricalDisciplineLeadsRequestSuccess>(
            ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequestSuccess
        ),
        withLatestFrom(this.store.select((store) => store.lunState.form)),
        tap(([action, lun]) => {
            if (
                action.payload &&
                lun.areaSCElectricalDisciplineLead &&
                !(lun.status === LUNStatusType.Approved) &&
                !lun.wasAreaSCElectricalDisciplineLeadAdmin &&
                action.payload.findIndex(
                    (u) =>
                        lun.areaSCElectricalDisciplineLead &&
                        u.email.toLowerCase() === lun.areaSCElectricalDisciplineLead.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new LUNUpdateProperty({ key: 'areaSCElectricalDisciplineLead', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    clearAreaSCSMPDisciplineLeads$ = createEffect(() => this.actions$.pipe(
        ofType<AreaSCSMPDisciplineLeadsRequestSuccess>(ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequestSuccess),
        withLatestFrom(this.store.select((store) => store.lunState.form)),
        tap(([action, lun]) => {
            if (
                action.payload &&
                lun.areaSCSMPDisciplineLead &&
                !(lun.status === LUNStatusType.Approved) &&
                !lun.wasAreaSCSMPDisciplineLeadAdmin &&
                action.payload.findIndex(
                    (u) =>
                        lun.areaSCSMPDisciplineLead &&
                        u.email.toLowerCase() === lun.areaSCSMPDisciplineLead.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new LUNUpdateProperty({ key: 'areaSCSMPDisciplineLead', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    clearAreaSCIAndCDisciplineLeads$ = createEffect(() => this.actions$.pipe(
        ofType<AreaSCIAndCDisciplineLeadsRequestSuccess>(
            ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequestSuccess
        ),
        withLatestFrom(this.store.select((store) => store.lunState.form)),
        tap(([action, lun]) => {
            if (
                action.payload &&
                lun.areaSCIAndCDisciplineLead &&
                !(lun.status === LUNStatusType.Approved) &&
                !lun.wasAreaSCIAndCDisciplineLeadAdmin &&
                action.payload.findIndex(
                    (u) =>
                        lun.areaSCIAndCDisciplineLead &&
                        u.email.toLowerCase() === lun.areaSCIAndCDisciplineLead.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new LUNUpdateProperty({ key: 'areaSCIAndCDisciplineLead', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    clearAreaSCManagers$ = createEffect(() => this.actions$.pipe(
        ofType<AreaSCManagersRequestSuccess>(ActionUsersActionTypes.AreaSCManagersRequestSuccess),
        withLatestFrom(this.store.select((store) => store.lunState.form)),
        tap(([action, lun]) => {
            if (
                action.payload &&
                lun.areaSCManager &&
                !(lun.status === LUNStatusType.Approved) &&
                !lun.wasAreaSCManagerAdmin &&
                action.payload.findIndex(
                    (u) => lun.areaSCManager && u.email.toLowerCase() === lun.areaSCManager.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new LUNUpdateProperty({ key: 'areaSCManager', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    clearLeadIsolationAuthority$ = createEffect(() => this.actions$.pipe(
        ofType<LeadIsolationAuthoritiesSMPRequestSuccess>(ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequestSuccess),
        withLatestFrom(this.store.select((store) => store.lunState.form)),
        tap(([action, lun]) => {
            if (
                action.payload &&
                lun.leadIsolationAuthoritySMP &&
                !(lun.status === LUNStatusType.Approved) &&
                !lun.wasAreaSCSMPDisciplineLeadAdmin &&
                action.payload.findIndex(
                    (u) =>
                        lun.leadIsolationAuthoritySMP &&
                        u.email.toLowerCase() === lun.leadIsolationAuthoritySMP.email.toLowerCase()
                ) === -1
            ) {
                this.store.dispatch(
                    new LUNUpdateProperty({ key: 'leadIsolationAuthoritySMP', value: null, updatedProperties: [] })
                );
            }
        })
    ), { dispatch: false });

    
    addPowerUserToAreaSCElectricalDisciplineLeadsOnStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<LUNUpdateStatusRequestSuccess>(LUNActionTypes.LUNUpdateStatusRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.areaSCElectricalDisciplineLeads)),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCElectricalDisciplineLeads = representatives.filter((r) => r !== null);
            return (
                payload.areaSCElectricalDisciplineLead &&
                filteredAreaSCElectricalDisciplineLeads.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCElectricalDisciplineLead.email.toLowerCase()
                ) === -1
            );
        }),
        map(
            ([{ payload }]) => new AddPowerUserToAreaSCElectricalDisciplineLeads(payload.areaSCElectricalDisciplineLead)
        )
    ));

    
    addPowerUserToAreaSCElectricalDisciplineLeadsOnGetLUNRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<GetLUNRequestSuccess>(LUNActionTypes.GetLUNRequestSuccess),
        (action) =>
            combineLatest([
                action,
                this.store.select((store) => store.actionUsersState.areaSCElectricalDisciplineLeads),
            ]),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCElectricalDisciplineLeads = representatives.filter((r) => r !== null);
            return (
                payload.areaSCElectricalDisciplineLead &&
                filteredAreaSCElectricalDisciplineLeads &&
                Array.isArray(filteredAreaSCElectricalDisciplineLeads) &&
                filteredAreaSCElectricalDisciplineLeads.length > 0 &&
                filteredAreaSCElectricalDisciplineLeads.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCElectricalDisciplineLead.email.toLowerCase()
                ) === -1
            );
        }),
        map(
            ([{ payload }]) => new AddPowerUserToAreaSCElectricalDisciplineLeads(payload.areaSCElectricalDisciplineLead)
        )
    ));

    
    addPowerUserToAreaSCSMPDisciplineLeadsOnStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<LUNUpdateStatusRequestSuccess>(LUNActionTypes.LUNUpdateStatusRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.areaSCSMPDisciplineLeads)),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCSMPDisciplineLeads = representatives.filter((r) => r !== null);
            return (
                payload.areaSCSMPDisciplineLead &&
                filteredAreaSCSMPDisciplineLeads.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCSMPDisciplineLead.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToAreaSCSMPDisciplineLeads(payload.areaSCSMPDisciplineLead))
    ));

    
    addPowerUserToAreaSCSMPDisciplineLeadsOnGetLUNRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<GetLUNRequestSuccess>(LUNActionTypes.GetLUNRequestSuccess),
        (action) =>
            combineLatest([action, this.store.select((store) => store.actionUsersState.areaSCSMPDisciplineLeads)]),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCSMPDisciplineLeads = representatives.filter((r) => r !== null);
            return (
                payload.areaSCSMPDisciplineLead &&
                filteredAreaSCSMPDisciplineLeads &&
                Array.isArray(filteredAreaSCSMPDisciplineLeads) &&
                filteredAreaSCSMPDisciplineLeads.length > 0 &&
                filteredAreaSCSMPDisciplineLeads.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCSMPDisciplineLead.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToAreaSCSMPDisciplineLeads(payload.areaSCSMPDisciplineLead))
    ));

    
    addPowerUserToAreaSCIAndCDisciplineLeadsOnStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<LUNUpdateStatusRequestSuccess>(LUNActionTypes.LUNUpdateStatusRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.areaSCIAndCDisciplineLeads)),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCIAndCDisciplineLeads = representatives.filter((r) => r !== null);
            return (
                payload.areaSCIAndCDisciplineLead &&
                filteredAreaSCIAndCDisciplineLeads.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCIAndCDisciplineLead.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToAreaSCIAndCDisciplineLeads(payload.areaSCIAndCDisciplineLead))
    ));

    
    addPowerUserToAreaSCIAndCDisciplineLeadsOnGetLUNRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<GetLUNRequestSuccess>(LUNActionTypes.GetLUNRequestSuccess),
        (action) =>
            combineLatest([action, this.store.select((store) => store.actionUsersState.areaSCIAndCDisciplineLeads)]),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCIAndCDisciplineLeads = representatives.filter((r) => r !== null);
            return (
                payload.areaSCIAndCDisciplineLead &&
                filteredAreaSCIAndCDisciplineLeads &&
                Array.isArray(filteredAreaSCIAndCDisciplineLeads) &&
                filteredAreaSCIAndCDisciplineLeads.length > 0 &&
                filteredAreaSCIAndCDisciplineLeads.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCIAndCDisciplineLead.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToAreaSCIAndCDisciplineLeads(payload.areaSCIAndCDisciplineLead))
    ));

    
    addPowerUserToAreaSCManagersOnStatusUpdate$ = createEffect(() => this.actions$.pipe(
        ofType<LUNUpdateStatusRequestSuccess>(LUNActionTypes.LUNUpdateStatusRequestSuccess),
        withLatestFrom(this.store.select((store) => store.actionUsersState.areaSCManagers)),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCManagers = representatives.filter((r) => r !== null);
            return (
                payload.areaSCManager &&
                filteredAreaSCManagers.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCManager.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToAreaSCManagers(payload.areaSCManager))
    ));

    
    addPowerUserToAreaSCManagersOnGetLUNRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType<GetLUNRequestSuccess>(LUNActionTypes.GetLUNRequestSuccess),
        (action) => combineLatest([action, this.store.select((store) => store.actionUsersState.areaSCManagers)]),
        filter(([{ payload }, representatives]) => {
            const filteredAreaSCManagers = representatives.filter((r) => r !== null);
            return (
                payload.areaSCManager &&
                filteredAreaSCManagers &&
                Array.isArray(filteredAreaSCManagers) &&
                filteredAreaSCManagers.length > 0 &&
                filteredAreaSCManagers.findIndex(
                    (r) => r.email.toLowerCase() === payload.areaSCManager.email.toLowerCase()
                ) === -1
            );
        }),
        map(([{ payload }]) => new AddPowerUserToAreaSCManagers(payload.areaSCManager))
    ));
}
