import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { BasePopupSettings } from './base-popup-settings';

export class ConfirmDialogPopupSettings extends BasePopupSettings {
    disableClose: boolean;
    autoFocus: boolean;

    constructor(data: any, disableClose = true, autoFocus = true) {
        super(ConfirmDialogComponent, { disableClose, autoFocus }, data);
        this.disableClose = disableClose;
        this.autoFocus = autoFocus;
    }
}
