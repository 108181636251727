import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
    LUNRegisterActionTypes,
    LUNRegisterFilterSuccess,
    LUNRegisterFilterError,
    LUNRegisterExportToExcelSuccess,
    LUNRegisterExportToExcelError,
    LUNRegisterAddCommentRequest,
    LUNRegisterAddCommentSuccess,
    LUNRegisterAddCommentError,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { LUNService } from '../../services/lun.service';
import { ToastService } from '../../services/shared/toast.service';
import { CommentService } from '../../services/comment.service';
import { of } from 'rxjs';
import moment from 'moment';
import { ApplicationState } from '../model';
import { saveAs } from 'file-saver';

@Injectable()
export class LUNRegisterEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private lunService: LUNService,
        private toastService: ToastService,
        private commentService: CommentService
    ) {}

    
    filterRequest$ = createEffect(() => this.actions$.pipe(
        ofType(LUNRegisterActionTypes.LUNRegisterFilterRequest),
        withLatestFrom(this.store.select((store) => store.lunRegisterState.filter)),
        mergeMap(([, filter]) => {
            return this.lunService.getLUNRegisterData(filter).pipe(
                map((dataPagination) => {
                    return new LUNRegisterFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering LUN register data. Please contact Program Administrator.'
                    );
                    return of(new LUNRegisterFilterError(error));
                })
            );
        })
    ));

    
    exportToExcel$ = createEffect(() => this.actions$.pipe(
        ofType(LUNRegisterActionTypes.LUNRegisterExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.lunRegisterState.filter)),
        mergeMap(([, LUNRegisterFilter]) => {
            let filter = Object.assign({}, LUNRegisterFilter);
            filter.timezoneOffset = new Date().getTimezoneOffset();
            return this.lunService.generateExcel(LUNRegisterFilter).pipe(
                map((excelData) => new LUNRegisterExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new LUNRegisterExportToExcelError(error));
                })
            );
        })
    ));

    
    saveExcelData = createEffect(() => this.actions$.pipe(
        ofType(LUNRegisterActionTypes.LUNRegisterExportToExcelSuccess),
        map((action: LUNRegisterExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `LUN_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    ), { dispatch: false });

    
    addComment$ = createEffect(() => this.actions$.pipe(
        ofType<LUNRegisterAddCommentRequest>(LUNRegisterActionTypes.LUNRegisterAddCommentRequest),
        withLatestFrom(this.store.select((state) => state.lunRegisterState.dataPagination.items)),
        mergeMap(([{ payload }]) =>
            this.commentService.addComment(payload.id, payload.description).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new LUNRegisterAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new LUNRegisterAddCommentError(error));
                })
            )
        )
    ));
}
