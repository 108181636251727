import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UserDetail } from 'src/app/store/common.model';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/components/base.component';
import { filter, takeWhile } from 'rxjs/operators';
import { ApplicationState } from 'src/app/store/model';
import _ from 'lodash';
import { MatSelectChange } from '@angular/material/select';
import { LUNStatusType } from 'src/app/models/enums';
import { Account } from 'msal';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    selector: 'app-lun-workflow',
    templateUrl: './lun-workflow.component.html',
    styleUrls: ['./lun-workflow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LunWorkflowComponent extends BaseComponent implements OnInit {
    @Input() lunForm: UntypedFormGroup;

    areaSCElectricalDisciplineLeads: UserDetail[] = [];
    areaSCSMPDisciplineLeads: UserDetail[] = [];
    areaSCIAndCDisciplineLeads: UserDetail[] = [];
    leadIsolationAuthoritiesSMP: UserDetail[] = [];
    leadIsolationAuthoritiesElectrical: UserDetail[] = [];
    areaSCManagers: UserDetail[] = [];

    areaSCElectricalDisciplineLeads$ = this.store.select(
        (state) => state.actionUsersState.areaSCElectricalDisciplineLeads
    );
    areaSCSMPDisciplineLeads$ = this.store.select((state) => state.actionUsersState.areaSCSMPDisciplineLeads);
    areaSCIAndCDisciplineLeads$ = this.store.select((state) => state.actionUsersState.areaSCIAndCDisciplineLeads);
    areaSCManagers$ = this.store.select((state) => state.actionUsersState.areaSCManagers);
    leadIsolationAuthoritiesSMP$ = this.store.select((state) => state.actionUsersState.leadIsolationAuthoritiesSMP);
    leadIsolationAuthoritiesElectrical$ = this.store.select(
        (state) => state.actionUsersState.leadIsolationAuthoritiesElectrical
    );

    areaSCElectricalDisciplineLead$ = this.store.select((state) => state.lunState.form.areaSCElectricalDisciplineLead);
    areaSCSMPDisciplineLead$ = this.store.select((state) => state.lunState.form.areaSCSMPDisciplineLead);
    areaSCIAndCDisciplineLead$ = this.store.select((state) => state.lunState.form.areaSCIAndCDisciplineLead);
    areaSCManager$ = this.store.select((state) => state.lunState.form.areaSCManager);
    leadIsolationAuthoritySMP$ = this.store.select((state) => state.lunState.form.leadIsolationAuthoritySMP);
    leadIsolationAuthorityElectrical$ = this.store.select(
        (state) => state.lunState.form.leadIsolationAuthorityElectrical
    );
    originator$ = this.store.select((state) => state.lunState.form.originator);

    lunForm$ = this.store.select((state) => state.lunState.form);
    discipline$ = this.store.select((state) => state.lunState.form.discipline);
    status$ = this.store.select((state) => state.lunState.form.status);
    isLocked$ = this.store.select((state) => state.lunState.isLocked);
    requiredSigners$ = this.store.select((state) => state.lunState.form.requiredSigners);
    requiredIsolationAuthoritySigners$ = this.store.select(
        (state) => state.lunState.form.requiredIsolationAuthoritySigners
    );
    lunId$ = this.store.select((state) => state.lunState.form.id);
    lunId: number;
    electricalSignOffVisible = true;
    smpSignOffVisible = true;
    iAndCSignOffVisible = true;
    isIsolationSMPVisible = false;
    isIsolationElectricalVisible = false;
    showApprovalCheckboxes = true;
    showIsolationAuthorityApprovalCheckboxes = false;
    isLocked = false;
    signers: string[] = [];
    isolationAuthoritySigners: string[] = [];
    originators: UserDetail[] = [];
    status: LUNStatusType;
    types = [Constants.leads.electricalLead, Constants.leads.smpLead, Constants.leads.iAndCLead];
    isolationAuthorityTypes = [Constants.leads.isolationLeadElectrical, Constants.leads.isolationLeadSMP];
    user: AccountInfo;
    isAdmin = false;
    isOriginator = false;
    isManager = false;
    isElectricalLead = false;
    isSMPLead = false;
    isIsolationAuthoritySMP = false;
    isIsolationAuthorityElectrical = false;
    isIAndCLead = false;
    discipline: string;

    constructor(private store: Store<ApplicationState>, private roleService: RoleService) {
        super();
    }

    ngOnInit() {
        combineLatest([
            this.areaSCElectricalDisciplineLeads$,
            this.areaSCSMPDisciplineLeads$,
            this.areaSCIAndCDisciplineLeads$,
            this.areaSCManagers$,
            this.leadIsolationAuthoritiesSMP$,
            this.leadIsolationAuthoritiesElectrical$,
            this.areaSCElectricalDisciplineLead$,
            this.areaSCSMPDisciplineLead$,
            this.areaSCIAndCDisciplineLead$,
            this.areaSCManager$,
            this.leadIsolationAuthoritySMP$,
            this.leadIsolationAuthorityElectrical$,
            this.originator$,
            this.status$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    areaSCElectricalDisciplineLeads,
                    areaSCSMPDisciplineLeads,
                    areaSCIAndCDisciplineLeads,
                    areaSCManagers,
                    leadIsolationAuthoritiesSMP,
                    leadIsolationAuthoritiesElectrical,
                    areaSCElectricalDisciplineLead,
                    areaSCSMPDisciplineLead,
                    areaSCIAndCDisciplineLead,
                    areaSCManager,
                    leadIsolationAuthoritySMP,
                    leadIsolationAuthorityElectrical,
                    originator,
                    status,
                ]) => {
                    this.user = this.roleService.getAccount();

                    this.areaSCElectricalDisciplineLeads = _.sortBy(
                        this.patchRepresentative(
                            areaSCElectricalDisciplineLeads as UserDetail[],
                            areaSCElectricalDisciplineLead as UserDetail
                        ),
                        'name'
                    );
                    this.areaSCSMPDisciplineLeads = _.sortBy(
                        this.patchRepresentative(
                            areaSCSMPDisciplineLeads as UserDetail[],
                            areaSCSMPDisciplineLead as UserDetail
                        ),
                        'name'
                    );
                    this.areaSCIAndCDisciplineLeads = _.sortBy(
                        this.patchRepresentative(
                            areaSCIAndCDisciplineLeads as UserDetail[],
                            areaSCIAndCDisciplineLead as UserDetail
                        ),
                        'name'
                    );
                    this.areaSCManagers = _.sortBy(
                        this.patchRepresentative(areaSCManagers as UserDetail[], areaSCManager as UserDetail),
                        'name'
                    );

                    this.leadIsolationAuthoritiesSMP = _.sortBy(
                        this.patchRepresentative(
                            leadIsolationAuthoritiesSMP as UserDetail[],
                            leadIsolationAuthoritySMP as UserDetail
                        )
                    );

                    this.leadIsolationAuthoritiesElectrical = _.sortBy(
                        this.patchRepresentative(
                            leadIsolationAuthoritiesElectrical as UserDetail[],
                            leadIsolationAuthorityElectrical as UserDetail
                        )
                    );

                    this.lunForm.patchValue({ areaSCElectricalDisciplineLead }, { emitEvent: false });
                    this.lunForm.patchValue({ areaSCSMPDisciplineLead }, { emitEvent: false });
                    this.lunForm.patchValue({ areaSCIAndCDisciplineLead }, { emitEvent: false });
                    this.lunForm.patchValue({ areaSCManager }, { emitEvent: false });
                    this.lunForm.patchValue(
                        { leadIsolationAuthoritySMP: leadIsolationAuthoritySMP },
                        { emitEvent: false }
                    );
                    this.lunForm.patchValue(
                        { leadIsolationAuthorityElectrical: leadIsolationAuthorityElectrical },
                        { emitEvent: false }
                    );

                    this.isOriginator = this.compareUsersWithAccount(this.user, originator as UserDetail);
                    this.isManager =
                        this.isAdmin || this.compareUsersWithAccount(this.user, areaSCManager as UserDetail);
                    this.isElectricalLead =
                        this.isAdmin ||
                        this.compareUsersWithAccount(this.user, areaSCElectricalDisciplineLead as UserDetail);
                    this.isSMPLead =
                        this.isAdmin || this.compareUsersWithAccount(this.user, areaSCSMPDisciplineLead as UserDetail);
                    this.isIAndCLead =
                        this.isAdmin ||
                        this.compareUsersWithAccount(this.user, areaSCIAndCDisciplineLead as UserDetail);
                    this.isIsolationAuthoritySMP =
                        this.isAdmin ||
                        this.compareUsersWithAccount(this.user, leadIsolationAuthoritySMP as UserDetail);
                    this.isIsolationAuthorityElectrical =
                        this.isAdmin ||
                        this.compareUsersWithAccount(this.user, leadIsolationAuthorityElectrical as UserDetail);

                    if (status === LUNStatusType.Draft) {
                        this.lunForm.controls.lunDate.setValidators([Validators.required]);
                        this.lunForm.controls.hazardDescription.setValidators([Validators.required]);
                        this.lunForm.controls.description.setValidators([Validators.required]);
                        if (this.isOriginator) {
                            this.lunForm.controls.originatorCAI.setValidators([Validators.required]);
                        }
                    }

                    if (status === LUNStatusType.Submitted) {
                        if (this.isElectricalLead && this.isSigner(Constants.leads.electricalLead)) {
                            this.lunForm.controls.areaSCElectricalDisciplineLeadCAI.setValidators([
                                Validators.required,
                            ]);
                        }
                        if (this.isSMPLead && this.isSigner(Constants.leads.smpLead)) {
                            this.lunForm.controls.areaSCSMPDisciplineLeadCAI.setValidators([Validators.required]);
                        }
                        if (this.isIAndCLead && this.isSigner(Constants.leads.iAndCLead)) {
                            this.lunForm.controls.areaSCIAndCDisciplineLeadCAI.setValidators([Validators.required]);
                        }
                    }

                    if (this.isIsolationAuthoritySMP && this.isSigner(Constants.leads.isolationLeadSMP)) {
                        this.lunForm.controls.leadIsolationAuthoritySMPCAI.setValidators([Validators.required]);
                    }
                    if (this.isIsolationAuthorityElectrical && this.isSigner(Constants.leads.isolationLeadElectrical)) {
                        this.lunForm.controls.leadIsolationAuthorityElectricalCAI.setValidators([Validators.required]);
                    }
                    if (status === LUNStatusType.Reviewed || (status === LUNStatusType.Submitted && this.isManager)) {
                        this.lunForm.controls.areaSCManagerCAI.setValidators([Validators.required]);
                    }
                }
            );

        this.lunForm$.pipe(takeWhile(() => this.isAlive)).subscribe((lun) => {
            this.lunForm.controls.areaSCElectricalDisciplineLeadReviewDate.patchValue(
                lun.areaSCElectricalDisciplineLeadReviewDate,
                { emitEvent: false }
            );
            this.lunForm.controls.areaSCSMPDisciplineLeadReviewDate.patchValue(lun.areaSCSMPDisciplineLeadReviewDate, {
                emitEvent: false,
            });
            this.lunForm.controls.areaSCIAndCDisciplineLeadReviewDate.patchValue(
                lun.areaSCIAndCDisciplineLeadReviewDate,
                {
                    emitEvent: false,
                }
            );
            this.lunForm.controls.areaSCManagerReviewDate.patchValue(lun.areaSCManagerReviewDate, {
                emitEvent: false,
            });
            this.lunForm.controls.leadIsolationAuthoritySMPReviewDate.patchValue(
                lun.leadIsolationAuthoritySMPReviewDate,
                {
                    emitEvent: false,
                }
            );
            this.lunForm.controls.leadIsolationAuthorityElectricalReviewDate.patchValue(
                lun.leadIsolationAuthorityElectricalReviewDate,
                {
                    emitEvent: false,
                }
            );

            this.lunForm.controls.routeDate.patchValue(lun.routeDate, { emitEvent: false });

            if (lun.originator.id && this.originators.findIndex((s) => s.email === lun.originator.email) === -1) {
                this.originators.push(lun.originator);
                let user = this.roleService.getAccount();
                let currentUser = new UserDetail();
                currentUser.email = user.username;
                currentUser.name = user.name;
                currentUser.id = 0;
                if (currentUser.email !== lun.originator.email) {
                    this.originators.push(currentUser);
                }
            }
        });

        this.requiredSigners$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((signers) => !!signers)
            )
            .subscribe((signers) => {
                this.signers = signers;
                this.setSignersVisible();
            });

        this.requiredIsolationAuthoritySigners$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((isolationAuthoritySigners) => !!isolationAuthoritySigners)
            )
            .subscribe((signers) => {
                this.isolationAuthoritySigners = signers;
                this.setSignersVisible();
            });

        combineLatest([this.discipline$, this.lunId$])
            .pipe(
                takeWhile(() => this.isAlive),
                filter((disc, lunId) => !!disc || lunId !== this.lunId)
            )
            .subscribe(([discipline, lunId]) => {
                if (lunId !== null && this.lunId !== lunId) {
                    this.clearValidations();
                }
                this.lunId = lunId;
                if (discipline !== '' && this.discipline !== discipline) {
                    this.setValidators(discipline);
                    this.setSignersVisible();
                }
                this.discipline = discipline;
            });

        this.status$.pipe(takeWhile(() => this.isAlive)).subscribe((status) => {
            this.status = status;
        });
    }

    patchRepresentative(representativeCollection: UserDetail[], representative: UserDetail): UserDetail[] {
        if (
            representative &&
            representative !== undefined &&
            representative.email !== '' &&
            !representativeCollection.some((c) => c.email === representative.email)
        ) {
            representativeCollection = [...representativeCollection, representative];
        }

        return representativeCollection;
    }

    selectionChange(listChange: MatSelectChange) {
        this.setSignersVisible();
    }

    onSelectedUserChanged(listChange: MatSelectChange) {
        if (listChange.source.ngControl.name === 'areaSCElectricalDisciplineLead') {
            this.lunForm.controls.areaSCElectricalDisciplineLeadCAI.setValue(listChange.value?.cai);
        }
        if (listChange.source.ngControl.name === 'areaSCIAndCDisciplineLead') {
            this.lunForm.controls.areaSCIAndCDisciplineLeadCAI.setValue(listChange.value?.cai);
        }
        if (listChange.source.ngControl.name === 'areaSCSMPDisciplineLead') {
            this.lunForm.controls.areaSCSMPDisciplineLeadCAI.setValue(listChange.value?.cai);
        }
        if (listChange.source.ngControl.name === 'originator') {
            this.lunForm.controls.originatorCAI.setValue(listChange.value?.cai);
        }
        if (listChange.source.ngControl.name === 'areaSCManager') {
            this.lunForm.controls.areaSCManagerCAI.setValue(listChange.value?.cai);
        }
        if (listChange.source.ngControl.name === 'leadIsolationAuthoritySMP') {
            this.lunForm.controls.leadIsolationAuthoritySMPCAI.setValue(listChange.value?.cai);
        }
        if (listChange.source.ngControl.name === 'leadIsolationAuthorityElectrical') {
            this.lunForm.controls.leadIsolationAuthorityElectricalCAI.setValue(listChange.value?.cai);
        }
    }

    setSignersVisible() {
        setTimeout(() => {
            this.electricalSignOffVisible = this.isSigner(Constants.leads.electricalLead);
            this.smpSignOffVisible = this.isSigner(Constants.leads.smpLead);
            this.iAndCSignOffVisible = this.isSigner(Constants.leads.iAndCLead);
            // this.isIsolationSMPVisible =
            //     (this.lunForm.controls.discipline.value === Constants.disciplines.AllDisciplines &&
            //         this.isIsolationAuthoritySigner(Constants.leads.isolationLeadSMP)) ||
            //     this.lunForm.controls.discipline.value === Constants.disciplines.Mechanical ||
            //     this.lunForm.controls.discipline.value === Constants.disciplines.Piping;
            // this.isIsolationElectricalVisible =
            //     (this.lunForm.controls.discipline.value === Constants.disciplines.AllDisciplines &&
            //         this.isIsolationAuthoritySigner(Constants.leads.isolationLeadElectrical)) ||
            //     this.lunForm.controls.discipline.value === Constants.disciplines.Electrical ||
            //     this.lunForm.controls.discipline.value === Constants.disciplines.Instrument ||
            //     this.lunForm.controls.discipline.value === Constants.disciplines.Telecom;
            this.showApprovalCheckboxes =
                !this.isLocked && this.lunForm.controls.discipline.value === Constants.disciplines.AllDisciplines;
            // this.showIsolationAuthorityApprovalCheckboxes =
            //     !this.isLocked && this.lunForm.controls.discipline.value === Constants.disciplines.AllDisciplines;

            this.clearApplicableValidations();
        }, 0);
    }

    setValidators(discipline: string) {
        setTimeout(() => {
            if (discipline === Constants.disciplines.Electrical) {
                this.lunForm.controls.areaSCElectricalDisciplineLead.setValidators([Validators.required]);
                this.lunForm.controls.areaSCElectricalDisciplineLead.updateValueAndValidity();
                this.lunForm.controls.areaSCIAndCDisciplineLead.setValue(null);
                this.lunForm.controls.areaSCSMPDisciplineLead.setValue(null);
                this.lunForm.controls.leadIsolationAuthorityElectrical.setValidators([Validators.required]);
                this.lunForm.controls.leadIsolationAuthorityElectrical.updateValueAndValidity();
                this.lunForm.controls.requiredSigners.setValue([Constants.leads.electricalLead]);
                this.lunForm.controls.requiredIsolationAuthoritySigners.setValue([
                    Constants.leads.isolationLeadElectrical,
                ]);
            } else if (
                discipline === Constants.disciplines.Instrument ||
                discipline === Constants.disciplines.Telecom
            ) {
                this.lunForm.controls.areaSCIAndCDisciplineLead.setValidators([Validators.required]);
                this.lunForm.controls.areaSCIAndCDisciplineLead.updateValueAndValidity();
                this.lunForm.controls.areaSCSMPDisciplineLead.setValue(null);
                this.lunForm.controls.areaSCElectricalDisciplineLead.setValue(null);
                this.lunForm.controls.leadIsolationAuthorityElectrical.setValidators([Validators.required]);
                this.lunForm.controls.leadIsolationAuthorityElectrical.updateValueAndValidity();
                this.lunForm.controls.requiredSigners.setValue([Constants.leads.iAndCLead]);
                this.lunForm.controls.requiredIsolationAuthoritySigners.setValue([
                    Constants.leads.isolationLeadElectrical,
                ]);
            } else if (discipline === Constants.disciplines.Mechanical || discipline === Constants.disciplines.Piping) {
                this.lunForm.controls.areaSCSMPDisciplineLead.setValidators([Validators.required]);
                this.lunForm.controls.areaSCSMPDisciplineLead.updateValueAndValidity();
                this.lunForm.controls.areaSCIAndCDisciplineLead.setValue(null);
                this.lunForm.controls.areaSCElectricalDisciplineLead.setValue(null);
                this.lunForm.controls.leadIsolationAuthoritySMP.setValidators([Validators.required]);
                this.lunForm.controls.leadIsolationAuthoritySMP.updateValueAndValidity();
                this.lunForm.controls.requiredSigners.setValue([Constants.leads.smpLead]);
                this.lunForm.controls.requiredIsolationAuthoritySigners.setValue([Constants.leads.isolationLeadSMP]);
            } else if (discipline === Constants.disciplines.AllDisciplines) {
                this.lunForm.controls.requiredSigners.setValue(this.lunForm.controls.requiredSigners.value || null);
                this.lunForm.controls.requiredSigners.setValidators([Validators.required]);
                this.lunForm.controls.requiredSigners.updateValueAndValidity();
                this.lunForm.controls.requiredIsolationAuthoritySigners.setValue(
                    this.lunForm.controls.requiredIsolationAuthoritySigners.value || null
                );
                this.lunForm.controls.requiredIsolationAuthoritySigners.setValidators([Validators.required]);
                this.lunForm.controls.requiredIsolationAuthoritySigners.updateValueAndValidity();
                this.lunForm.controls.leadIsolationAuthorityElectrical.setValidators([Validators.required]);
                this.lunForm.controls.leadIsolationAuthorityElectrical.updateValueAndValidity();
                this.lunForm.controls.leadIsolationAuthoritySMP.setValidators([Validators.required]);
                this.lunForm.controls.leadIsolationAuthoritySMP.updateValueAndValidity();
            }
        }, 0);
    }

    clearApplicableValidations() {
        if (!this.electricalSignOffVisible) {
            this.clearControl('areaSCElectricalDisciplineLead');
        }
        if (!this.smpSignOffVisible) {
            this.clearControl('areaSCSMPDisciplineLead');
        }
        if (!this.iAndCSignOffVisible) {
            this.clearControl('areaSCIAndCDisciplineLead');
        }
        if (!this.isIsolationSMPVisible) {
            this.clearControl('leadIsolationAuthoritySMP');
        }
        if (!this.isIsolationElectricalVisible) {
            this.clearControl('leadIsolationAuthorityElectrical');
        }

        if (!this.showApprovalCheckboxes) {
            this.lunForm.controls.requiredSigners.clearValidators();
            this.lunForm.controls.requiredSigners.updateValueAndValidity({ emitEvent: false });
        }

        if (!this.showIsolationAuthorityApprovalCheckboxes) {
            this.lunForm.controls.requiredIsolationAuthoritySigners.clearValidators();
            this.lunForm.controls.requiredIsolationAuthoritySigners.updateValueAndValidity({ emitEvent: false });
        }
    }

    private compareUsersWithAccount(account: AccountInfo, userDetail: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(account) &&
            this.isNotNullAndNotUndefined(userDetail) &&
            account.username.toLowerCase() === userDetail.email.toLowerCase()
        );
    }

    private isSigner(lead: string): boolean {
        return this.signers.indexOf(lead) > -1;
    }

    private isIsolationAuthoritySigner(lead: string): boolean {
        return this.isolationAuthoritySigners.indexOf(lead) > -1;
    }

    private clearControl(name: string) {
        this.lunForm.controls[name].setValue(null);
        this.lunForm.controls[name].clearValidators();
        this.lunForm.controls[name].updateValueAndValidity();
    }

    private clearValidations() {
        let controlNames = [
            'areaSCIAndCDisciplineLead',
            'areaSCIAndCDisciplineLeadCAI',
            'areaSCElectricalDisciplineLead',
            'areaSCElectricalDisciplineLeadCAI',
            'areaSCSMPDisciplineLead',
            'areaSCSMPDisciplineLeadCAI',
            'areaSCManagerCAI',
            'leadIsolationAuthoritySMP',
            'leadIsolationAuthoritySMPCAI',
            'leadIsolationAuthorityElectrical',
            'leadIsolationAuthorityElectricalCAI',
        ];
        controlNames.forEach((name) => {
            this.lunForm.controls[name].clearValidators();
            this.lunForm.controls[name].updateValueAndValidity();
        });
    }
}
