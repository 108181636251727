import { Component, OnInit } from '@angular/core';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { BaseComponent } from '../base.component';
import { ProjectTeamsService } from 'src/app/services/project-teams.service';

@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
})
export class TopHeaderComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    userName = '';
    emailAddress = '';
    roles: string[] = [];
    projectTeamName = '3GP/3GI';

    constructor(private roleService: RoleService, private projectTeamsService: ProjectTeamsService) {
        super();
    }

    ngOnInit() {
        let projectTeams = this.projectTeamsService.getTeams() || [];
        if (projectTeams.length > 0 && projectTeams.indexOf('3GI') > 0) {
            this.projectTeamName = '3GI';
        }
        if (projectTeams.length > 0 && projectTeams.indexOf('3GP') > 0) {
            this.projectTeamName = '3GP';
        }
        if (projectTeams.length === 2) {
            this.projectTeamName = '3GP/3GI';
        }
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        const account = this.roleService.getAccount();
        if (!!account) {
            this.userName = account.name;
            this.emailAddress = account.username;
            this.roles = this.roleService.userRoleNames;
        }
    }
}
