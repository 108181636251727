import { Injectable } from '@angular/core';
import { RoleService } from './shared/role.service';
import { Constants } from 'src/app/constants';

@Injectable({
    providedIn: 'root',
})
export class ProjectTeamsService {
    constructor(private roleService: RoleService) {}

    getTeams(readonly = true): string[] {
        const teams = new Array();

        if (
            this.roleService.isInRole(Constants.applicableGroups.Admin) ||
            (this.roleService.isReadOnly() && readonly) ||
            this.roleService.isInAnyRole([
                Constants.applicableGroups.AreaSCElectricalDisciplineLead,
                Constants.applicableGroups.AreaSCIAndCDisciplineLead,
                Constants.applicableGroups.AreaSCManager,
                Constants.applicableGroups.AreaSCSMPDisciplineLead,
            ])
        ) {
            return;
        } else {
            if (this.roleService.isInRole(Constants.applicableGroups.EditUser)) {
                teams.push('3GP');
            }
            if (this.roleService.isInRole(Constants.applicableGroups.GIEditUser)) {
                teams.push('3GI');
            }
        }
        return teams;
    }
}
