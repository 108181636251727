import { LUNState, LUN } from './model';
import { LUNActions, LUNActionTypes } from './actions';
import { Attachment, LUNAttachmentType } from '../lun-attachments/model';

const initialState: LUNState = {
    form: new LUN(),
    isLoading: false,
    isCreated: false,
    updatedProperties: [],
    isDescriptionLoading: true,
    isSubsystemMCStatusLoading: true,
    isEstimateDetailsLoading: true,
    isHistoryLoading: false,
    isLocked: false,
    isAutosaveInProgress: false,
    initiaFormVersion: 0
};

const attachmentTypes = LUNAttachmentType;

export function reducer(state = initialState, action: LUNActions) {
    switch (action.type) {
        case LUNActionTypes.GetLUNRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.GetLUNRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },

                isLoading: false,
            };
        }
        case LUNActionTypes.GetLUNRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNActionTypes.AddLUNRequest: {
            return {
                ...state,
                isCreated: false,
            };
        }
        case LUNActionTypes.AddLUNRequestSuccess: {
            return {
                ...state,
                isCreated: true,
            };
        }
        case LUNActionTypes.AddLUNRequestError: {
            return {
                ...state,
                isCreated: false,
            };
        }
        case LUNActionTypes.LUNUpdateRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.LUNUpdateRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
                isLoading: false,
                updatedProperties: [],
                initiaFormVersion: state.initiaFormVersion + 1
            };
        }
        case LUNActionTypes.LUNUpdateRequestError: {
            return {
                ...state,
                isLoading: false,
                isLocked: action.payload.unlockForm ? false : state.isLocked,
            };
        }

        case LUNActionTypes.LUNUpdateProperty: {
            return {
                ...state,
                updatedProperties: [...action.payload.updatedProperties],
                form: {
                    ...state.form,
                    [action.payload.key]: Array.isArray(action.payload.value)
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case LUNActionTypes.LUNClear: {
            const newLUN = new LUN();
            return {
                ...state,
                isLocked: false,
                form: {
                    ...newLUN,
                },
                updatedProperties: [],
            };
        }

        case LUNActionTypes.LUNDescriptionRequest: {
            return {
                ...state,
                isDescriptionLoading: true,
            };
        }
        case LUNActionTypes.LUNDescriptionRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    description: action.payload,
                },
                isDescriptionLoading: false,
            };
        }
        case LUNActionTypes.LUNDescriptionRequestError: {
            return {
                ...state,
                isDescriptionLoading: false,
            };
        }
        case LUNActionTypes.LUNRemoveCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.LUNRemoveCommentSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    comments: [...state.form.comments.filter((c) => c.id !== action.payload.comment.id)],
                },
                isLoading: false,
            };
        }
        case LUNActionTypes.LUNRemoveCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNActionTypes.LUNAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.LUNAddCommentSuccess: {
            const comment = Object.assign({}, action.payload.comment, { isLoading: false });
            return {
                ...state,
                isLoading: false,
                form: {
                    ...state.form,
                    comments: [comment, ...state.form.comments],
                },
            };
        }
        case LUNActionTypes.LUNAddCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case LUNActionTypes.LUNSubsysteMCStatusRequest: {
            return {
                ...state,
                isSubsystemMCStatusLoading: true,
            };
        }
        case LUNActionTypes.LUNSubsysteMCStatusRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    subsystemMCStatusDetails: action.payload,
                },
                isSubsystemMCStatusLoading: false,
            };
        }
        case LUNActionTypes.LUNSubsysteMCStatusRequestError: {
            return {
                ...state,
                isSubsystemMCStatusLoading: false,
            };
        }
        case LUNActionTypes.LUNSubsystemDetailsRequest: {
            return {
                ...state,
            };
        }
        case LUNActionTypes.LUNSubsystemDetailsRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    subsystemDetails: action.payload,
                },
            };
        }
        case LUNActionTypes.LUNSubsystemDetailsRequestError: {
            return {
                ...state,
            };
        }
        case LUNActionTypes.LUNHistoryRequest: {
            return {
                ...state,
                isHistoryLoading: true,
            };
        }
        case LUNActionTypes.LUNHistoryRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    history: [...action.payload],
                },
                isHistoryLoading: false,
            };
        }
        case LUNActionTypes.LUNHistoryRequestError: {
            return {
                ...state,
                isHistoryLoading: false,
            };
        }

        case LUNActionTypes.LUNAutosaveRequest: {
            return {
                ...state,
                isAutosaveInProgress: true,
            };
        }
        case LUNActionTypes.LUNAutosaveSuccess: {
            return {
                ...state,
                isAutosaveInProgress: false,
                form: action.payload,
                updatedProperties: [],
            };
        }
        case LUNActionTypes.LUNAutosaveError: {
            return {
                ...state,
                isAutosaveInProgress: false,
            };
        }

        case LUNActionTypes.LockLUNFormIfNoChanges: {
            let isLocked = false;
            if (state.updatedProperties.length === 0) {
                isLocked = true;
            }

            return {
                ...state,
                isLocked,
            };
        }

        case LUNActionTypes.LockLUNForm: {
            return {
                ...state,
                isLocked: true,
            };
        }

        case LUNActionTypes.UnLockLUNForm: {
            return {
                ...state,
                isLocked: false,
            };
        }
        case LUNActionTypes.LUNUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.LUNUpdateStatusRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                    SupportingDocumentAttachments: state.form.SupportingDocumentAttachments,
                    OldSupportingDocumentAttachments: state.form.SupportingDocumentAttachments,
                },
                isLoading: false,
                updatedProperties: [],
            };
        }
        case LUNActionTypes.LUNSaveAndUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.LUNUpdateStatusRequestError: {
            return {
                ...state,
                isLoading: false,
                isLocked: action.payload.unlockForm ? false : state.isLocked,
            };
        }
        case LUNActionTypes.LUNSaveAndUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LUNActionTypes.LUNAttachmentsSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                    [`Old${attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                },
            };
        }
        case LUNActionTypes.LUNDownloadAttachmentRequest: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.attachment.name
                                            ? true
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        case LUNActionTypes.LUNDownloadAttachmentSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.fileName
                                            ? false
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        case LUNActionTypes.LUNDownloadAttachmentError: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.attachment.name
                                            ? false
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        default: {
            return state;
        }
    }
}
