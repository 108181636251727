import { JoditDescriptionState } from './model';
import { JoditDescriptionActionTypes, JoditDescriptionActions } from './actions';

const initialState: JoditDescriptionState = {
    type: null,
    preserverBackButton: false,
};

export function reducer(state: JoditDescriptionState = initialState, action: JoditDescriptionActions) {
    switch (action.type) {
        case JoditDescriptionActionTypes.SetType: {
            return {
                ...state,
                type: action.payload,
            };
        }
        case JoditDescriptionActionTypes.PreserveBackButton: {
            return {
                ...state,
                preserverBackButton: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
