import { Action } from '@ngrx/store';
import { LUN, LUNInitialFields, LUNComment, LUNHistory, LUNSubsystemMCStatusDetails, SubsystemDetails } from './model';
import { HttpErrorResponse } from '@angular/common/http';
import { Attachment, LUNAttachments, LUNAttachmentType } from '../lun-attachments/model';
import { ActionUsers } from '../action-users/model';
import { LUNStatusChangeAction } from 'src/app/models/enums';


export enum LUNActionTypes {
    AddLUNRequest = '[LUN] Add LUN Request',
    AddLUNRequestSuccess = '[LUN] Add LUN Request Success',
    AddLUNRequestError = '[LUN] Add LUN Request Error',

    GetLUNRequest = '[LUN] Get LUN Request',
    GetLUNRequestSuccess = '[LUN] Get LUN Request Success',
    GetLUNRequestError = '[LUN] Get LUN Request Error',

    LUNUpdateRequest = '[LUN] LUN Update Request',
    LUNUpdateRequestSuccess = '[LUN] LUN Update Request Success',
    LUNUpdateRequestError = '[LUN] LUN Update Request Error',

    LUNSetButtonStatus = '[LUN] LUN Set Button Status',
    LUNUpdateProperty = '[LUN] LUN Update Property',
    LUNClear = '[LUN] LUN Clear',

    LUNDescriptionRequest = '[LUN] LUN description request',
    LUNDescriptionRequestSuccess = '[LUN] LUN description request success',
    LUNDescriptionRequestError = '[LUN] LUN description request error',

    LUNSubsysteMCStatusRequest = '[LUN] LUN subsystem mcstatus request',
    LUNSubsysteMCStatusRequestSuccess = '[LUN] LUN subsystem mcstatus request success',
    LUNSubsysteMCStatusRequestError = '[LUN] LUN subsystem mcstatus request error',

    LUNSubsystemDetailsRequest = '[LUN] LUN subsystem details request',
    LUNSubsystemDetailsRequestSuccess = '[LUN] LUN subsystem details request success',
    LUNSubsystemDetailsRequestError = '[LUN] LUN subsystem details request error',

    LUNRemoveCommentRequest = '[LUN] Remove Comment Request',
    LUNRemoveCommentSuccess = '[LUN] Remove Comment Success',
    LUNRemoveCommentError = '[LUN] Remove Comment Error',

    LUNAddCommentRequest = '[LUN] Add Comment Request',
    LUNAddCommentSuccess = '[LUN] Add Comment Success',
    LUNAddCommentError = '[LUN] Add Comment Error',

    LUNHistoryRequest = '[LUN] History Request',
    LUNHistoryRequestSuccess = '[LUN] History Success',
    LUNHistoryRequestError = '[LUN] History Error',

    LUNAutosaveRequest = '[LUN] Autosave Request',
    LUNAutosaveSuccess = '[LUN] Autosave Success',
    LUNAutosaveError = '[LUN] Autosave Error',

    LockLUNFormIfNoChanges = '[LUN] Lock LUN if no changes made',
    LockLUNForm = '[LUN] Lock LUN',
    UnLockLUNForm = '[LUN] UnLock LUN',

    LUNAttachmentsRequest = '[LUN] Attachments Request',
    LUNAttachmentsSuccess = '[LUN] Attachments Success',
    LUNAttachmentsError = '[LUN] Attachments Error',
    LUNUpdateInitialFormWithAttachments = '[LUN] Update Initial Form With Attachments',
    LUNUpdateAttachmentLinks = '[LUN] Update Attachment Links',
    LUNDownloadAttachmentRequest = '[LUN] Download Attachment Request',
    LUNDownloadAttachmentSuccess = '[LUN] Download Attachment Success',
    LUNDownloadAttachmentError = '[LUN] Download Attachment Error',
    
    LUNUpdateStatusRequest = '[LUN] Update Status Request',
    LUNUpdateStatusRequestSuccess = '[LUN] Update Status Request Success',
    LUNUpdateStatusRequestError = '[LUN] Update Status Request Error',

    LUNSaveAndUpdateStatusRequest = '[LUN] Save And Update Status Request',
}

export class LUNUpdateStatusRequest implements Action {
    readonly type = LUNActionTypes.LUNUpdateStatusRequest;

    constructor(public payload: { id: number; changeAction: LUNStatusChangeAction }) {}
}
export class LUNUpdateStatusRequestSuccess implements Action {
    readonly type = LUNActionTypes.LUNUpdateStatusRequestSuccess;

    constructor(public payload: LUN) {}
}

export class LUNUpdateStatusRequestError implements Action {
    readonly type = LUNActionTypes.LUNUpdateStatusRequestError;

    constructor(public payload: { unlockForm: boolean }) {}
}

export class LUNSaveAndUpdateStatusRequest implements Action {
    readonly type = LUNActionTypes.LUNSaveAndUpdateStatusRequest;

    constructor(public payload: { form: LUN; changeAction: LUNStatusChangeAction }) {}
}

export class AddLUNRequest implements Action {
    readonly type = LUNActionTypes.AddLUNRequest;

    constructor(public payload: { lun: LUNInitialFields }) {}
}
export class AddLUNRequestSuccess implements Action {
    readonly type = LUNActionTypes.AddLUNRequestSuccess;

    constructor(public payload: { lunId: number; lun?: LUNInitialFields }) {}
}
export class AddLUNRequestError implements Action {
    readonly type = LUNActionTypes.AddLUNRequestError;
    constructor(public payload: string) {}
}

export class GetLUNRequest implements Action {
    readonly type = LUNActionTypes.GetLUNRequest;

    constructor(public payload: number) {}
}
export class GetLUNRequestSuccess implements Action {
    readonly type = LUNActionTypes.GetLUNRequestSuccess;

    constructor(public payload: LUN) {}
}
export class GetLUNRequestError implements Action {
    readonly type = LUNActionTypes.GetLUNRequestError;
    constructor(public payload: string) {}
}

export class LUNUpdateRequest implements Action {
    readonly type = LUNActionTypes.LUNUpdateRequest;

    constructor(public payload: LUN) {}
}
export class LUNUpdateRequestSuccess implements Action {
    readonly type = LUNActionTypes.LUNUpdateRequestSuccess;

    constructor(public payload: LUN) {}
}
export class LUNUpdateRequestError implements Action {
    readonly type = LUNActionTypes.LUNUpdateRequestError;
    constructor(public payload: { error: string; unlockForm: boolean }) {}
}

export class LUNUpdateProperty implements Action {
    readonly type = LUNActionTypes.LUNUpdateProperty;
    constructor(public payload: { key: string; value: any; updatedProperties: string[] }) {}
}

export class LUNClear implements Action {
    readonly type = LUNActionTypes.LUNClear;
}

export class LUNDescriptionRequest implements Action {
    readonly type = LUNActionTypes.LUNDescriptionRequest;
    constructor(public payload: number) {}
}

export class LUNDescriptionRequestSuccess implements Action {
    readonly type = LUNActionTypes.LUNDescriptionRequestSuccess;
    constructor(public payload: string) {}
}

export class LUNDescriptionRequestError implements Action {
    readonly type = LUNActionTypes.LUNDescriptionRequestError;
    constructor(public payload: string) {}
}

export class LUNSubsysteMCStatusRequest implements Action {
    readonly type = LUNActionTypes.LUNSubsysteMCStatusRequest;
    constructor(public subsystems: string[]) {}
}

export class LUNSubsysteMCStatusRequestSuccess implements Action {
    readonly type = LUNActionTypes.LUNSubsysteMCStatusRequestSuccess;
    constructor(public payload: LUNSubsystemMCStatusDetails[]) {}
}

export class LUNSubsysteMCStatusRequestError implements Action {
    readonly type = LUNActionTypes.LUNSubsysteMCStatusRequestError;
    constructor(public payload: string) {}
}

export class LUNSubsystemDetailsRequest implements Action {
    readonly type = LUNActionTypes.LUNSubsystemDetailsRequest;
    constructor(public subsystem: string) {}
}

export class LUNSubsystemDetailsRequestSuccess implements Action {
    readonly type = LUNActionTypes.LUNSubsystemDetailsRequestSuccess;
    constructor(public payload: SubsystemDetails) {}
}

export class LUNSubsystemDetailsRequestError implements Action {
    readonly type = LUNActionTypes.LUNSubsystemDetailsRequestError;
    constructor(public payload: string) {}
}

export class LUNRemoveCommentRequest implements Action {
    readonly type = LUNActionTypes.LUNRemoveCommentRequest;

    constructor(public payload: { comment: LUNComment }) {}
}

export class LUNRemoveCommentSuccess implements Action {
    readonly type = LUNActionTypes.LUNRemoveCommentSuccess;

    constructor(public payload: { comment: LUNComment }) {}
}
export class LUNRemoveCommentError implements Action {
    readonly type = LUNActionTypes.LUNRemoveCommentError;

    constructor(public payload: HttpErrorResponse) {}
}

export class LUNAddCommentRequest implements Action {
    readonly type = LUNActionTypes.LUNAddCommentRequest;

    constructor(public payload: { comment: LUNComment }) {}
}

export class LUNAddCommentSuccess implements Action {
    readonly type = LUNActionTypes.LUNAddCommentSuccess;

    constructor(public payload: { comment: LUNComment }) {}
}
export class LUNAddCommentError implements Action {
    readonly type = LUNActionTypes.LUNAddCommentError;

    constructor(public payload: string) {}
}

export class LUNHistoryRequest implements Action {
    readonly type = LUNActionTypes.LUNHistoryRequest;

    constructor(public payload: number) {}
}
export class LUNHistoryRequestSuccess implements Action {
    readonly type = LUNActionTypes.LUNHistoryRequestSuccess;

    constructor(public payload: LUNHistory[]) {}
}
export class LUNHistoryRequestError implements Action {
    readonly type = LUNActionTypes.LUNHistoryRequestError;
    constructor(public payload: string) {}
}

export class LockLUNFormIfNoChanges implements Action {
    readonly type = LUNActionTypes.LockLUNFormIfNoChanges;

    constructor() {}
}
export class LockLUNForm implements Action {
    readonly type = LUNActionTypes.LockLUNForm;

    constructor() {}
}
export class UnLockLUNForm implements Action {
    readonly type = LUNActionTypes.UnLockLUNForm;

    constructor() {}
}

export class LUNAttachmentsRequest implements Action {
    readonly type = LUNActionTypes.LUNAttachmentsRequest;

    constructor(public payload: { id: number; type: LUNAttachmentType }) {}
}

export class LUNAttachmentsSuccess implements Action {
    readonly type = LUNActionTypes.LUNAttachmentsSuccess;

    constructor(public payload: { attachments: LUNAttachments; type: LUNAttachmentType }) {}
}

export class LUNAttachmentsError implements Action {
    readonly type = LUNActionTypes.LUNAttachmentsError;

    constructor(public payload: string) {}
}

export class LUNUpdateInitialFormWithAttachments implements Action {
    readonly type = LUNActionTypes.LUNUpdateInitialFormWithAttachments;

    constructor(public payload: { attachments: LUNAttachments; type: LUNAttachmentType }) {}
}

export class LUNUpdateAttachmentLinks implements Action {
    readonly type = LUNActionTypes.LUNUpdateAttachmentLinks;
}

export class LUNDownloadAttachmentRequest implements Action {
    readonly type = LUNActionTypes.LUNDownloadAttachmentRequest;

    constructor(public payload: { attachment: Attachment; type: LUNAttachmentType }) {}
}

export class LUNDownloadAttachmentSuccess implements Action {
    readonly type = LUNActionTypes.LUNDownloadAttachmentSuccess;

    constructor(public payload: { content: Blob; fileName: string; type: LUNAttachmentType }) {}
}

export class LUNDownloadAttachmentError implements Action {
    readonly type = LUNActionTypes.LUNDownloadAttachmentError;

    constructor(public payload: { attachment: Attachment; type: LUNAttachmentType }) {}
}

export class LUNAutosaveRequest implements Action {
    readonly type = LUNActionTypes.LUNAutosaveRequest;

    constructor(public payload: LUN) {}
}

export class LUNAutosaveSuccess implements Action {
    readonly type = LUNActionTypes.LUNAutosaveSuccess;

    constructor(public payload: LUN) {}
}

export class LUNAutosaveError implements Action {
    readonly type = LUNActionTypes.LUNAutosaveError;

    constructor(public payload: string) {}
}

export type LUNActions =
    | AddLUNRequest
    | AddLUNRequestSuccess
    | AddLUNRequestError
    | GetLUNRequest
    | GetLUNRequestSuccess
    | GetLUNRequestError
    | LUNUpdateRequest
    | LUNUpdateRequestSuccess
    | LUNUpdateRequestError
    | LUNUpdateProperty
    | LUNClear
    | LUNDescriptionRequest
    | LUNDescriptionRequestSuccess
    | LUNDescriptionRequestError
    | LUNRemoveCommentRequest
    | LUNRemoveCommentSuccess
    | LUNRemoveCommentError
    | LUNAddCommentRequest
    | LUNAddCommentSuccess
    | LUNAddCommentError
    | LUNHistoryRequest
    | LUNHistoryRequestError
    | LUNHistoryRequestSuccess
    | LockLUNFormIfNoChanges
    | LockLUNForm
    | UnLockLUNForm
    | LUNAttachmentsRequest
    | LUNAttachmentsSuccess
    | LUNAttachmentsError
    | LUNUpdateInitialFormWithAttachments
    | LUNUpdateAttachmentLinks
    | LUNDownloadAttachmentRequest
    | LUNDownloadAttachmentSuccess
    | LUNDownloadAttachmentError
    | LUNAutosaveRequest
    | LUNAutosaveSuccess
    | LUNAutosaveError
    | LUNSubsysteMCStatusRequest
    | LUNSubsysteMCStatusRequestSuccess
    | LUNSubsysteMCStatusRequestError
    | LUNSubsystemDetailsRequest
    | LUNSubsystemDetailsRequestSuccess
    | LUNSubsystemDetailsRequestError
    | LUNUpdateStatusRequest
    | LUNUpdateStatusRequestSuccess
    | LUNUpdateStatusRequestError
    | LUNSaveAndUpdateStatusRequest;

