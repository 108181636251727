<main class="main">
    <div class="import-container">
        <h4>Temporary Subsystem List import</h4>
        <div class="import-subtitle">
            See
            <b (click)="downloadTemplate('Temporary Subsystem List.xlsx')">Temporary Subsystem List.xlsx</b> structure.
        </div>
    </div>
    <div class="delta-container">
        <div class="section-header">
            <h4>Delta validation</h4>
            <span> - validate changes to temporary subsystem data in live database</span>
        </div>
        <span *ngIf="!deltaData.data.length">No delta validation data available.</span>
        <div *ngIf="deltaData.data.length" class="changebuttons-container">
            <button (click)="patchAllChanges(true)" mat-icon-button title="accept">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
            <span class="margin-right20">Accept all changes</span>
            <button (click)="patchAllChanges(false)" mat-icon-button title="reject">
                <mat-icon>highlight_off</mat-icon>
            </button>
            <span>Reject all changes</span>
        </div>
        <div *ngIf="deltaData.data.length" class="legend-scale">
            <ul class="legend-labels">
                <li><span style="background: rgba(0, 0, 0, 0.6);"></span>Existing</li>
                <li><span style="background: #0066b2;"></span>Update</li>
            </ul>
        </div>
        <table mat-table [dataSource]="deltaData" *ngIf="deltaData.data.length">
            <ng-container matColumnDef="projectTeam">
                <th mat-header-cell *matHeaderCellDef class="empty">Project Team</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.projectTeam }}
                </td>
            </ng-container>
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell *matHeaderCellDef class="empty">Subsystem</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.subsystem }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.typeLive }}</div>
                    <div class="import-value">{{ element.typeImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="module">
                <th mat-header-cell *matHeaderCellDef>Module</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.moduleLive }}</div>
                    <div class="import-value">{{ element.moduleImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="module2">
                <th mat-header-cell *matHeaderCellDef>Module 2</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.module2Live }}</div>
                    <div class="import-value">{{ element.module2Import }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="walkdownComplete">
                <th mat-header-cell *matHeaderCellDef>Walkdown Complete</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.walkdownCompleteLive | date: 'd/MMM/yy' }}</div>
                    <div class="import-value">{{ element.walkdownCompleteImport | date: 'd/MMM/yy' }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="punchlistComplete">
                <th mat-header-cell *matHeaderCellDef>Punchlist Complete</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.punchlistCompleteLive | date: 'd/MMM/yy' }}</div>
                    <div class="import-value">{{ element.punchlistCompleteImport | date: 'd/MMM/yy' }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="lunSigned">
                <th mat-header-cell *matHeaderCellDef>LUN Signed</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.lunSignedLive | date: 'd/MMM/yy' }}</div>
                    <div class="import-value">{{ element.lunSignedImport | date: 'd/MMM/yy' }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="energized">
                <th mat-header-cell *matHeaderCellDef>Energized</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.energizedLive | date: 'd/MMM/yy' }}</div>
                    <div class="import-value">{{ element.energizedImport | date: 'd/MMM/yy' }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="deEnergized">
                <th mat-header-cell *matHeaderCellDef>De-Energized</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.deEnergizedLive | date: 'd/MMM/yy' }}</div>
                    <div class="import-value">{{ element.deEnergizedImport | date: 'd/MMM/yy' }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-150"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <div *ngIf="!element.deltaState" (click)="acceptChange(element)">
                        <button mat-icon-button title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <span>Accept</span>
                    </div>
                    <div *ngIf="element.deltaState" (click)="rejectChange(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>highlight_off</mat-icon>
                        </button>
                        <span>Reject</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDeltaDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeltaDataColumns"></tr>
        </table>
        <mat-paginator
            *ngIf="deltaData.data.length"
            [length]="deltaDataResultsLength"
            [pageSize]="deltaDataPageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onDeltaDataPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeltaDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="uploadLogData">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="infoMessage">
                <th mat-header-cell *matHeaderCellDef>Info message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.infoMessage"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error message</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.errorMessage ? element.errorMessage : 'No errors' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.user }}
                </td>
            </ng-container>
            <ng-container matColumnDef="downloadResult">
                <th mat-header-cell *matHeaderCellDef>Download result</th>
                <td mat-cell *matCellDef="let element">
                    <svg
                        class="downloadIcon"
                        *ngIf="element.outputDataLink"
                        (click)="downloadData(element.outputDataLink)"
                        width="18px"
                        height="20px"
                        viewBox="0 0 16 17"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                        <title>{{ element.outputDataLink }}</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <g
                                id="New-FCNi-Copy"
                                transform="translate(-1259.000000, -1440.000000)"
                                stroke="#0066B2"
                                stroke-width="2"
                            >
                                <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                    <path
                                        d="M5.5,8.5 L16.5,8.5"
                                        id="Line-3-Copy"
                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                    ></path>
                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span *ngIf="!element.outputDataLink && element.status === 'Started'" class="in-progress"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUploadLogDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUploadLogDataColumns"></tr>
        </table>
        <div *ngIf="isUploadLogLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"> </app-loading-indicator>
</main>
