import * as moment from 'moment';

export class UpdateDataLog {
    id: number;
    type: string;
    status: string;
    startDate: moment.Moment = null;
    endDate: moment.Moment = null;
    infoMessage: string;
    errorMessage: string;
    outputDataLink: string;
    user: string;
}
