<div class="buttons-container">
    <!-- <button
        class="lun-button lun-button-light"
        [ngClass]="{
            'lun-button-red': isManager || isElectricalLead || isSMPLead || isIAndCLead
        }"
        *ngIf="isRevertButtonVisible"
        (click)="revertToDraft()"
    >
        {{ isManager || isElectricalLead || isSMPLead || isIAndCLead ? 'reject' : 'revert to draft' }}
    </button>
    <button
        class="lun-button lun-button-light"
        *ngIf="isSubmitButtonVisible"
        (click)="submit()"
        [disabled]="!lunForm.valid || isAutosaveInProgress"
    >
        submit
    </button>
    <button
        class="lun-button lun-button-green"
        *ngIf="isSignOffButtonVisible"
        (click)="signOff()"
        [disabled]="!isLocked && !lunForm.valid"
    >
        sign off
    </button>
    <button
        class="lun-button lun-button-green"
        *ngIf="isApproveButtonVisible"
        (click)="approve()"
        [disabled]="!isLocked && !lunForm.valid"
    >
        approved
    </button> -->
    <button class="lun-button lun-button-light" *ngIf="isEditButtonVisible" (click)="edit()">
        edit
    </button>
    <button
        class="lun-button lun-button-light"
        *ngIf="isSaveButtonVisible"
        (click)="save()"
        [disabled]="isSaveButtonDisabled || isAutosaveInProgress"
    >
        save
    </button>
    <button
        class="lun-button lun-button-light"
        *ngIf="!isEditButtonVisible && isSaveButtonVisible && status !== 1"
        (click)="cancelEdit()"
    >
        cancel edit
    </button>
</div>
<app-loading-indicator *ngIf="!loaded"></app-loading-indicator>
