import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { LUNHistoryRequest } from 'src/app/store/lun/actions';
import { LUNHistory } from 'src/app/store/lun/model';

@Component({
    selector: 'app-lun-history-popup',
    templateUrl: './lun-history-popup.component.html',
    styleUrls: ['./lun-history-popup.component.scss'],
})
export class LUNHistoryPopupComponent extends BaseComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    leftCompare = ``;
    rightCompare = ``;
    lunId: number = null;
    displayedColumns: string[] = ['status', 'date', 'user.name', 'action', 'diff'];
    resultsLength = 0;
    pageSize = 5;
    sortBy = 'date';
    direction = 'desc';
    dataSource = new MatTableDataSource<LUNHistory>();
    isLoading = false;
    history$ = this.store.select((store) => store.lunState.form.history);
    isLoading$ = this.store.select((store) => store.lunState.isHistoryLoading);
    showDiff = false;
    constructor(
        public dialogRef: MatDialogRef<LUNHistoryPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private store: Store<ApplicationState>
    ) {
        super();
        this.lunId = data.lunId;
    }

    ngOnInit() {
        this.store.dispatch(new LUNHistoryRequest(this.lunId));

        this.history$.pipe(takeWhile(() => this.isAlive)).subscribe((history) => {
            if (history && history !== undefined) {
                this.dataSource.data = history;
                this.resultsLength = history.length;
                this.dataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'user.name':
                            return item.user.name;
                        default:
                            return item[property];
                    }
                };
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        });

        this.isLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((loading) => (this.isLoading = loading));
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public showDifference(element: LUNHistory) {
        this.showDiff = !this.showDiff;
        this.leftCompare = element.prevModelJson;
        this.rightCompare = element.newModelJson;
    }
}
