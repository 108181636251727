import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor() {}

    public getMailToURI(recepient, opts) {
        let link = 'mailto:?bcc=';
        link += encodeURIComponent(recepient);
        link += ';';
        const params = [];
        for (const key in opts) {
            const value = opts[key];
            params.push(key.toLowerCase() + '=' + encodeURIComponent(value));
        }
        if (params.length > 0) {
            link += '&' + params.join('&');
        }
        return link;
    }
}
