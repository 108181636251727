import { TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay/index';

export class BasePopupSettings {
    content: ComponentType<any> | TemplateRef<any>;
    data: any;
    config: any;

    constructor(content: any, config: any = null, data: any = null) {
        this.content = content;
        this.data = data;
        this.config = Object.assign(config, { data });
    }
}
