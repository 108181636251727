import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserDetail } from 'src/app/store/common.model';
import { Store } from '@ngrx/store';
import { NightShiftUpdateRequest } from 'src/app/store/night-shift/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ApplicationState } from 'src/app/store/model';
import { InformationDialogComponent } from '../../information-dialog/information-dialog.component';
import { Account } from 'msal';
import { NightShift } from 'src/app/store/night-shift/model';
import { NightShiftStatusType } from 'src/app/models/enums';
import { AccountInfo} from '@azure/msal-browser';

@Component({
    selector: 'app-night-shift-buttons',
    templateUrl: './night-shift-buttons.component.html',
    styleUrls: ['./night-shift-buttons.component.scss'],
})
export class NightShiftButtonsComponent extends BaseComponent implements OnInit {
    @Input() nightShiftForm: UntypedFormGroup;
    @Output() disableFormEvent = new EventEmitter<void>();
    @Output() enableFormEvent = new EventEmitter<void>();
    @Output() disableReviewAndApproveSectionEvent = new EventEmitter<void>();

    isSaveButtonDisabled = false;
    isSaveButtonVisible = false;
    isEditButtonVisible = false;
    loaded = true;
    updatedProperties: string[] = [];
    isReadOnly = false;
    user: AccountInfo;
    originator: UserDetail;
    nightShiftId: number;
    nightShift: NightShift;
    canSendAutomatedEmails = false;
    isAdmin = false;
    isOriginator = false;
    isAutosaveInProgress = false;

    isLocked$ = this.store.select((state) => state.nightShiftState.isLocked);
    isAutosaveInProgress$ = this.store.select((state) => state.nightShiftState.isAutosaveInProgress);
    originator$ = this.store.select((state) => state.nightShiftState.form.originator);
    updatedProperties$ = this.store.select((state) => state.nightShiftState.updatedProperties);
    nightShift$ = this.store.select((state) => state.nightShiftState.form);
    isLocked = false;
    isRevertButtonVisible = false;
    isSubmitButtonVisible = false;
    isSignOffButtonVisible = false;
    isApproveButtonVisible = false;
    status$ = this.store.select((state) => state.nightShiftState.form.status);
    status: NightShiftStatusType;

    constructor(
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private popupService: PopupService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        this.nightShift$.pipe(takeWhile(() => this.isAlive)).subscribe((nightShift) => {
            this.nightShiftId = nightShift.id;
            this.nightShift = nightShift;
        });

        combineLatest(
            this.status$,
            this.originator$,
            this.updatedProperties$,
            this.isLocked$,
            this.isAutosaveInProgress$
        )
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    status,
                    originator,
                    updatedProperties,
                    isLocked,
                    isAutosaveInProgress,
                ]) => {
                    this.user = this.roleService.getAccount();
                    this.originator = originator;
                    this.updatedProperties = updatedProperties;
                    this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
                    this.isOriginator = this.compareUsersWithAccount(this.user, originator);
                    this.isAutosaveInProgress = isAutosaveInProgress;
                    this.status = status;
                    this.showOrHideButtons(updatedProperties);
                }
            );

        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isSaveButtonVisible = !isLocked;
            this.isLocked = isLocked;
        });
    }

    edit() {
        this.enableFormEvent.emit();
        if (this.status !== NightShiftStatusType.Draft) {
            this.showAutosaveDisabledPopup();
        }
    }

    // submit() {
    //     this.popupService
    //         .openPopup(
    //             new ConfirmDialogPopupSettings({ title: 'Confirm action', text: 'Do you want to submit draft?' })
    //         )
    //         .afterClosed()
    //         .pipe(takeWhile(() => this.isAlive === true))
    //         .subscribe((answer) => {
    //             if (answer === true) {
    //                 this.confirmSubmitAction(NightShiftStatusChangeAction.Submit);
    //             }
    //         });
    // }

    // confirmSubmitAction(action: NightShiftStatusChangeAction) {
    //     this.loaded = true;
    //     if (this.isNotNullAndNotUndefined(this.updatedProperties) && this.updatedProperties.length > 0) {
    //         this.saveAndupdateStatusAction(action);
    //     } else {
    //         this.updateStatusAction(action);
    //     }
    // }

    // signOff() {
    //     if (this.status === NightShiftStatusType.Submitted) {
    //         var action =
    //             this.isIAndCLead && !this.isIAndCLeadReviewCompleted && this.nightShiftForm.controls.requiredSigners.value.indexOf('Area SC I&C Discipline Lead') > -1
    //                 ? NightShiftStatusChangeAction.AreaSCIAndCDisciplineLeadReview
    //                 : this.isElectricalLead && !this.isElectricalReviewCompleted && this.nightShiftForm.controls.requiredSigners.value.indexOf('Area SC Electrical Discipline Lead') > -1
    //                 ? NightShiftStatusChangeAction.AreaSCElectricalDisciplineLeadReview
    //                 : this.isSMPLead && !this.isSMPLeadReviewCompleted && this.nightShiftForm.controls.requiredSigners.value.indexOf('Area SC SMP Discipline Lead') > -1
    //                 ? NightShiftStatusChangeAction.AreaSCSMPDisciplineLeadReview
    //                 : null;
    //         this.runUpdateStatusAction(action, 'Confirm action', 'Do you want to sign off?');
    //     }
    // }

    // approve() {
    //     if (this.status === NightShiftStatusType.Reviewed) {
    //         this.runUpdateStatusAction(
    //             NightShiftStatusChangeAction.AreaSCManagerApprove,
    //             'Confirm action',
    //             'Do you want to submit approval?'
    //         );
    //     }
    // }

    // private saveAndupdateStatusAction(changeAction: NightShiftStatusChangeAction) {
    //     this.store.dispatch(
    //         new NightShiftSaveAndUpdateStatusRequest({
    //             form: this.nightShiftForm.value,
    //             changeAction: changeAction,
    //         })
    //     );
    //     this.disableFormEvent.emit();
    // }

    // private updateStatusAction(changeAction: NightShiftStatusChangeAction) {
    //     this.store.dispatch(
    //         new NightShiftUpdateStatusRequest({
    //             id: this.nightShift.id,
    //             changeAction: changeAction,
    //         })
    //     );
    //     this.disableFormEvent.emit();
    // }

    // private updateStatusWithChangesCheck(
    //     updateStatusPopupSettings: { title: string; text: string },
    //     successAction: () => void,
    //     discardAction?: () => void
    // ) {
    //     if (
    //         this.status !== NightShiftStatusType.Draft &&
    //         this.isNotNullAndNotUndefined(this.updatedProperties) === true &&
    //         this.updatedProperties.length > 0
    //     ) {
    //         this.popupService
    //             .openPopup(
    //                 new ConfirmDialogPopupSettings({
    //                     title: 'Changes are not saved!',
    //                     text: "You haven't save your changes. Do you want to discard them?",
    //                     isDiscardChanges: true,
    //                 })
    //             )
    //             .afterClosed()
    //             .pipe(takeWhile(() => this.isAlive))
    //             .subscribe((answer) => {
    //                 if (answer === true) {
    //                     this.popupService
    //                         .openPopup(new ConfirmDialogPopupSettings(updateStatusPopupSettings))
    //                         .afterClosed()
    //                         .pipe(takeWhile(() => this.isAlive === true))
    //                         .subscribe((ans) => {
    //                             if (ans === true) {
    //                                 successAction();
    //                             } else if (discardAction) {
    //                                 discardAction();
    //                             }
    //                         });
    //                 }
    //             });
    //     } else {
    //         this.popupService
    //             .openPopup(new ConfirmDialogPopupSettings(updateStatusPopupSettings))
    //             .afterClosed()
    //             .pipe(takeWhile(() => this.isAlive))
    //             .subscribe((answer) => {
    //                 if (answer === true) {
    //                     successAction();
    //                 } else if (discardAction) {
    //                     discardAction();
    //                 }
    //             });
    //     }
    // }

    // private runUpdateStatusAction(changeAction: NightShiftStatusChangeAction, title: string, text: string) {
    //     this.updateStatusWithChangesCheck({ title, text }, () => this.updateStatusAction(changeAction));
    // }

    private showAutosaveDisabledPopup() {
        return this.popupService
            .openPopup(
                new PopupSettings(
                    InformationDialogComponent,
                    null,
                    null,
                    {
                        title: 'Autosave disabled',
                        text:
                            'Autosave function is not enabled in mode other than Draft. Please click on Save after making edits.',
                    },
                    1,
                    1
                )
            )
            .afterClosed();
    }

    // revertToDraft = () => {
    //     let text =
    //         this.isManager || this.isSMPLead || this.isElectricalLead || this.isIAndCLead
    //             ? `Are you sure you want to reject NightShift back to the Originator? \n Consider adding comments (see section at the bottom of page) to provide reason for rejection.`
    //             : 'Do you want to revert to draft?';
    //     this.updateStatusWithChangesCheck({ title: 'Confirm action', text }, () => {
    //         this.disableFormEvent.emit();
    //         this.store.dispatch(
    //             new NightShiftUpdateStatusRequest({
    //                 id: this.nightShiftForm.controls['id'].value,
    //                 changeAction: NightShiftStatusChangeAction.Draft,
    //             })
    //         );
    //     });
    // };

    save() {
        this.nightShiftForm.updateValueAndValidity();
        this.store.dispatch(new NightShiftUpdateRequest(this.nightShiftForm.value));
        if (
            (this.isNotNullAndNotUndefined(this.status) && this.status !== NightShiftStatusType.Draft) ||
            (this.status === NightShiftStatusType.Draft &&
                !this.isOriginator &&
                this.roleService.isInRole(Constants.applicableGroups.Admin))
        ) {
            this.disableFormEvent.emit();
        }
    }

    cancelEdit() {
        location.reload();
    }

    private showOrHideButtons(updatedProperties: string[]) {
        this.isEditButtonVisible =
            (this.isAdmin ||
                this.isOriginator) &&
            this.isLocked;
        this.isSaveButtonVisible = !this.isLocked;
        this.isSaveButtonDisabled = updatedProperties.length === 0;
    }

    private compareUsersWithAccount(account: AccountInfo, userDetail: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(account) &&
            this.isNotNullAndNotUndefined(userDetail) &&
            account.username.toLowerCase() === userDetail.email.toLowerCase()
        );
    }
}
