import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { takeWhile, take, scan, tap, filter } from 'rxjs/operators';

@Component({
    selector: 'app-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent implements OnInit, OnDestroy {
    modalTitle: string;
    modalText: string;
    interval$ = interval(1000);
    timerTicksLeft = 10;
    useTimer = false;
    action: () => void;
    isAlive: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<InformationDialogComponent>
    ) {
        this.modalTitle = data.title;
        this.modalText = data.text;
        this.useTimer = data.useTimer;
        this.action = data.action;
    }

    ngOnInit() {
        if (this.useTimer) {
            this.interval$
                .pipe(
                    takeWhile(() => this.isAlive),
                    take(10),
                    scan((acc) => acc - 1, this.timerTicksLeft),
                    tap((tick) => (this.timerTicksLeft = tick)),
                    filter((tick) => tick === 0)
                )
                .subscribe(() => {
                    this.action();
                    this.dialogRef.close();
                });
        }
    }

    ngOnDestroy() {
        if (this.action) {
            this.action();
        }
        this.isAlive = false;
    }
}
