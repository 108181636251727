import { Injectable } from '@angular/core';
import { NightShift, NightShiftInitialFields, NightShiftComment, NightShiftHistory } from '../store/night-shift/model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { appConfig } from '../app.config';
import { NightShiftStatusChangeAction } from '../models/enums';
import { Attachment, NightShiftAttachmentType } from '../store/night-shift/model';
import { NightShiftRegisterDataPagination, NightShiftRegisterFilter } from '../store/night-shift-register/model';

@Injectable({
    providedIn: 'root',
})
export class NightShiftService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly nightShiftEndpoint: string = '/nightshift';
    private readonly attachmentTypes = NightShiftAttachmentType;

    constructor(private http: HttpClient) {}

    createNightShift(newNightShift: NightShiftInitialFields) {
        return this.http.post<number>(`${this.rootUrl}${this.nightShiftEndpoint}`, newNightShift);
    }

    generatePdf(lunId: number) {
        return this.http.get<Blob>(`${this.rootUrl}${this.nightShiftEndpoint}/print/${lunId}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    getNightShiftById(id: number) {
        return this.http.get<NightShift>(`${this.rootUrl}${this.nightShiftEndpoint}/${id}`);
    }

    getDescription(lunId: number) {
        return this.http.get(`${this.rootUrl}${this.nightShiftEndpoint}/${lunId}/description`, {
            responseType: 'text',
        });
    }

    getEstimateDetailsDescription(lunId: number) {
        return this.http.get(`${this.rootUrl}${this.nightShiftEndpoint}/${lunId}/estimatedetails`, {
            responseType: 'text',
        });
    }

    updateNightShift(form: NightShift, updatedProperties: string[]) {
        return this.http.put<NightShift>(`${this.rootUrl}${this.nightShiftEndpoint}`, {
            nightShift: form,
            updatedProperties,
        });
    }

    getNightShiftRegisterData(filter: NightShiftRegisterFilter) {
        return this.http.post<NightShiftRegisterDataPagination>(
            `${this.rootUrl}${this.nightShiftEndpoint}/register`,
            filter
        );
    }

    generateExcel(filter: NightShiftRegisterFilter) {
        return this.http.post(`${this.rootUrl}${this.nightShiftEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    uploadNightShiftAttachmentsRequest(lunId: number, attachments: Attachment[]) {
        const formData = new FormData();
        formData.append('NightShiftId', lunId.toString());
        attachments.forEach((attachment) => {
            formData.append(this.attachmentTypes[attachment.attachmentType], attachment.file, attachment.file.name);
        });
        return this.http.post(`${this.rootUrl}${this.nightShiftEndpoint}/attachments`, formData);
    }

    getNightShiftAttachmentsRequest(payload: { id: number; type: NightShiftAttachmentType }) {
        return this.http.get(`${this.rootUrl}${this.nightShiftEndpoint}/${payload.id}/attachments/${payload.type}`);
    }

    getNightShiftPreviousRevisionsRequest(id: number) {
        return this.http.get(`${this.rootUrl}${this.nightShiftEndpoint}/${id}/revisions/`);
    }

    downloadNightShiftAttachmentRequest(attachment: Attachment) {
        return this.http.get<Blob>(
            `${this.rootUrl}${this.nightShiftEndpoint}/attachments?nightShiftId=${
                attachment.nightShiftId
            }&attachmentName=${attachment.name}&attachmentType=${this.attachmentTypes[attachment.attachmentType]}`,
            { responseType: 'arraybuffer' as 'json' }
        );
    }

    downloadAttachment(link: string) {
        return this.http.get<Blob>(`${this.rootUrl}${this.nightShiftEndpoint}/attachment?link=${link}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    deleteNightShiftAttachmentRequest(attachment: Attachment) {
        return this.http.delete(
            `${this.rootUrl}${this.nightShiftEndpoint}/attachments?nightShiftId=${attachment.nightShiftId}&attachmentName=${attachment.name}`
        );
    }

    removeComment(comment: NightShiftComment) {
        return this.http.request('delete', `${this.rootUrl}${this.nightShiftEndpoint}/comment`, { body: comment });
    }

    addComment(comment: NightShiftComment) {
        return this.http.post<NightShiftComment>(`${this.rootUrl}${this.nightShiftEndpoint}/comment`, comment);
    }

    loadHistory(lunId: number) {
        return this.http.get<NightShiftHistory[]>(`${this.rootUrl}${this.nightShiftEndpoint}/${lunId}/history`);
    }

    newNightShiftRevision(lunId: number) {
        return this.http.get(`${this.rootUrl}${this.nightShiftEndpoint}/newrevision/${lunId}`);
    }

    updateNightShiftStatus(id: number, statusChangeAction: NightShiftStatusChangeAction) {
        return this.http.patch<NightShift>(`${this.rootUrl}${this.nightShiftEndpoint}/${id}`, {
            statusChangeAction,
        });
    }
}
