import { Directive, ElementRef, AfterViewInit, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[app-col-resize]',
})
export class ColResizeDirective implements AfterViewInit {
    startX: number;
    startWidth: number;
    clientRect: DOMRect | ClientRect;

    minColWidth: number = 50;

    constructor(private elementRef: ElementRef<HTMLTableHeaderCellElement>, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.clientRect = this.elementRef.nativeElement.getBoundingClientRect();
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        this.startX = event.pageX;
        this.startWidth = this.clientRect.width;
        this.registerMouseMoveEvent();
    }

    @HostListener('mouseup')
    onMouseUp() {
        this.removeMouseMoveEvent();
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.removeMouseMoveEvent();
    }

    private onMouseMove = (event: MouseEvent) => {
        const dx = event.pageX - this.startX;
        if (this.elementRef.nativeElement.children.length) {
            this.renderer.addClass(this.elementRef.nativeElement.firstChild, 'resize-column');
        }
        const width = this.startWidth + dx;
        if (width < this.minColWidth) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'width', `58px`);
        } else {
            this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${width}px`);
        }
        event.preventDefault();
    };

    private registerMouseMoveEvent() {
        this.elementRef.nativeElement.addEventListener('mousemove', this.onMouseMove);
    }

    private removeMouseMoveEvent() {
        this.clientRect = this.elementRef.nativeElement.getBoundingClientRect();
        this.elementRef.nativeElement.removeEventListener('mousemove', this.onMouseMove);

        if (this.elementRef.nativeElement.children.length) {
            this.renderer.removeClass(this.elementRef.nativeElement.firstChild, 'resize-column');
        }
    }
}
