<div class="popup">
    <span class="close" (click)="onCancel()">&times;</span>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">create new Night Shift Plan</span>
    </p>
    <div>
        <form [formGroup]="nightShiftForm" class="filter-container">
            Create new Night Shift Plan?
            <div class="row">
                <button type="button" (click)="onCancel()" class="lun-button lun-button-white margin-right20">
                    cancel
                </button>
                <button
                    type="button"
                    (click)="onConfirm()"
                    class="lun-button lun-button-light"
                    [disabled]="!nightShiftForm.valid"
                >
                    submit
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
