<div class="className">
    <div *ngIf="!printMode">
        <div class="doc-title">
            <div
                [ngClass]="{
                    'mat-error':
                        lunForm.controls[attachmentType].invalid &&
                        lunForm.controls[attachmentType].errors.required
                }"
            >
                {{ headerText }}
            </div>
        </div>
        <div
            appDragAndDrop
            (filesAdded)="fileChangeEvent($event)"
            class="drag-and-drop-container"
            [ngClass]="{ 'attachments-disabled': isDisabled }"
        >
            <div class="DandD-img"></div>
            <div class="drag-and-drop">
                Drop files here or click to
                <button (click)="attachmentFiles.click($event)" [disabled]="isDisabled">
                    select files to upload
                </button>
                <div
                    class="tooltip-icon"
                    matTooltip="Maximum file upload limit is 100 MB at a time"
                    matTooltipClass="luntooltip"
                ></div>
                <input
                    multiple
                    #attachmentFiles
                    type="file"
                    (click)="attachmentFiles.value = null"
                    (change)="fileChangeEvent($event.target.files)"
                    [disabled]="isDisabled"
                />
            </div>
        </div>
        <div *ngIf="attachments !== undefined">
            <div *ngFor="let item of attachments; let i = index">
                <div class="documents-container">
                    <label>{{ item.name }}</label>

                    <div>
                        <button *ngIf="item.link" [disabled]="item.isBeingProcessed" (click)="downloadAttachment(item)">
                            <svg
                                width="18px"
                                height="20px"
                                viewBox="0 0 16 17"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <g
                                        transform="translate(-1259.000000, -1440.000000)"
                                        stroke="#0066B2"
                                        stroke-width="2"
                                    >
                                        <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                            <path d="M4,18 L18,18" id="Line-3"></path>
                                            <path
                                                d="M5.5,8.5 L16.5,8.5"
                                                id="Line-3-Copy"
                                                transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                            ></path>
                                            <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <button
                            [disabled]="item.isBeingProcessed || isDisabled"
                            (click)="removeAttachment(item.name)"
                        >
                            <svg
                                width="18px"
                                height="20px"
                                viewBox="0 0 18 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke-linejoin="round"
                                >
                                    <g
                                        transform="translate(-1306.000000, -1658.000000)"
                                        stroke="#0066B2"
                                        stroke-width="2"
                                    >
                                        <g
                                            id="Icons-/-Blue-/-Bin-Copy-2"
                                            transform="translate(1304.000000, 1657.000000)"
                                        >
                                            <path
                                                d="M4,5 L18,5 L18,18 C18,19.1045695 17.1045695,20 16,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,5 Z"
                                                id="Rectangle"
                                            ></path>
                                            <path d="M3,5 L19,5" id="Line" stroke-linecap="round"></path>
                                            <path
                                                d="M8,2 L14,2 L14,4 C14,4.55228475 13.5522847,5 13,5 L9,5 C8.44771525,5 8,4.55228475 8,4 L8,2 Z"
                                                id="Rectangle-Copy"
                                                transform="translate(11.000000, 3.500000) rotate(-180.000000) translate(-11.000000, -3.500000) "
                                            ></path>
                                            <path d="M8,8.5625 L8,16.596316" id="Line-5" stroke-linecap="round"></path>
                                            <path
                                                d="M11,8.5625 L11,16.596316"
                                                id="Line-5-Copy"
                                                stroke-linecap="round"
                                            ></path>
                                            <path
                                                d="M14,8.5625 L14,16.596316"
                                                id="Line-5-Copy-2"
                                                stroke-linecap="round"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <div class="lds-hourglass-small" [class.show-spinner]="item.isBeingProcessed"></div>
                    </div>
                </div>
            </div>
        </div>
        <mat-error
            class="error-attachments"
            *ngIf="
                lunForm.controls[attachmentType].invalid && lunForm.controls[attachmentType].errors.required
            "
        >
            Attachments are required.
        </mat-error>
        <div *ngIf="isLoading$ | async" class="loading-background">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div *ngIf="printMode && attachments !== undefined">
        <mat-list>
            <mat-list-item *ngFor="let attachment of attachments">
                <i class="bullet"></i>
                {{ attachment.name }}
            </mat-list-item>
        </mat-list>
    </div>
</div>
