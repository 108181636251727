import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
    NightShiftRegisterActionTypes,
    NightShiftRegisterFilterSuccess,
    NightShiftRegisterFilterError,
    NightShiftRegisterExportToExcelSuccess,
    NightShiftRegisterExportToExcelError,
    NightShiftRegisterAddCommentRequest,
    NightShiftRegisterAddCommentSuccess,
    NightShiftRegisterAddCommentError,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError } from 'rxjs/operators';
import { NightShiftService } from '../../services/night-shift.service';
import { ToastService } from '../../services/shared/toast.service';
import { of } from 'rxjs';
import moment from 'moment';
import { ApplicationState } from '../model';
import { saveAs } from 'file-saver';
import { NightShiftCommentService } from '../../services/night-shift-comment.service';

@Injectable()
export class NightShiftRegisterEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private nightShiftService: NightShiftService,
        private toastService: ToastService,
        private commentService: NightShiftCommentService
    ) {}

    
    filterRequest$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftRegisterActionTypes.NightShiftRegisterFilterRequest),
        withLatestFrom(this.store.select((store) => store.nightShiftRegisterState.filter)),
        mergeMap(([, filter]) => {
            return this.nightShiftService.getNightShiftRegisterData(filter).pipe(
                map((dataPagination) => {
                    return new NightShiftRegisterFilterSuccess(dataPagination);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while filtering NightShift register data. Please contact Program Administrator.'
                    );
                    return of(new NightShiftRegisterFilterError(error));
                })
            );
        })
    ));

    
    exportToExcel$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelRequest),
        withLatestFrom(this.store.select((store) => store.nightShiftRegisterState.filter)),
        mergeMap(([, NightShiftRegisterFilter]) => {
            let filter = Object.assign({}, NightShiftRegisterFilter);
            filter.timezoneOffset = new Date().getTimezoneOffset();
            return this.nightShiftService.generateExcel(NightShiftRegisterFilter).pipe(
                map((excelData) => new NightShiftRegisterExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new NightShiftRegisterExportToExcelError(error));
                })
            );
        })
    ));

    
    saveExcelData = createEffect(() => this.actions$.pipe(
        ofType(NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelSuccess),
        map((action: NightShiftRegisterExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `NightShift_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    ), { dispatch: false });

    
    addComment$ = createEffect(() => this.actions$.pipe(
        ofType<NightShiftRegisterAddCommentRequest>(NightShiftRegisterActionTypes.NightShiftRegisterAddCommentRequest),
        withLatestFrom(this.store.select((state) => state.nightShiftRegisterState.dataPagination.items)),
        mergeMap(([{ payload }]) =>
            this.commentService.addComment(payload.id, payload.description).pipe(
                map(() => {
                    this.toastService.Success('Comment successfully added.');
                    return new NightShiftRegisterAddCommentSuccess(payload);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while updating comment. Please contact Program Administrator.'
                    );
                    return of(new NightShiftRegisterAddCommentError(error));
                })
            )
        )
    ));
}
