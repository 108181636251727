<main class="main">
    <a class="navigator" [routerLink]="['/admin']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Go back
    </a>
    <div class="title-container">
        <div class="title">manual uploads</div>
        <div class="buttons-container flex">
            <button
                [disabled]="isValidateButtonDisabled"
                class="lun-button lun-button-light margin-right20 validate-button"
                (click)="validateDelta()"
            >
                validate & push
                <span>{{ activeNavLink && activeNavLink.label }}</span>
            </button>
            <button type="button" class="lun-button lun-button-light margin-right20" (click)="importFile.click($event)">
                upload
            </button>
            <input
                multiple
                #importFile
                type="file"
                (click)="importFile.value = null"
                (change)="fileChangeEvent($event.target.files)"
            />
        </div>
    </div>
    <nav mat-tab-nav-bar>
        <a
            [ngClass]="{ 'in-progress': inProgressObservables[link.index] | async }"
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>
    <router-outlet></router-outlet>
    <app-loading-indicator *ngIf="isLoading || (isUploading$ | async)"> </app-loading-indicator>
</main>
