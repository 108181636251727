export class SetInputEventArgs {
    input: string;
    preserveInputValue: boolean;
    itemList: any[];

    constructor(preserveInputValue: boolean, input: string = '', itemList: any[] = []) {
        this.preserveInputValue = preserveInputValue;
        this.input = input;
        this.itemList = itemList;
    }
}
