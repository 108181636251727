<div class="popup">
    <span class="close" (click)="onCancel()">&times;</span>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">create new LUN</span>
    </p>
    <div>
        <form [formGroup]="lunForm" class="filter-container">
            <div class="container-multiselector">
                <div class="container367">
                    <app-multiple-items-selector
                        class="container367"
                        formControlName="subsystems"
                        [searchFunc]="getSubsystems"
                        [setInput]="setSubsystemInput"
                        [isAsync]="true"
                        [oneItemMode]="false"
                        [filterForm]="lunForm"
                        [formSetter]="'subsystems'"
                        [placeholder]="'Subsystem'"
                    >
                    </app-multiple-items-selector>
                </div>
            </div>
            <mat-form-field class="margin-top20">
                <mat-label>Discipline</mat-label>
                <mat-select matNativeControl formControlName="discipline" required>
                    <mat-option [value]="discipline" *ngFor="let discipline of disciplines"
                        >{{ discipline }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="lunForm.controls['discipline'].invalid && lunForm.controls['discipline'].errors.required"
                >
                    Discipline is required.
                </mat-error>
            </mat-form-field>

            <div class="row">
                <button type="button" (click)="onCancel()" class="lun-button lun-button-white margin-right20">
                    cancel
                </button>
                <button
                    type="button"
                    (click)="onConfirm()"
                    class="lun-button lun-button-light"
                    [disabled]="!lunForm.valid"
                >
                    submit
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
