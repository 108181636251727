import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { takeWhile, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import {
    SubsystemListDeltaFilterPropertyUpdate,
    SubsystemListDeltaRequest,
    SubsystemListDownloadOutputDataRequest,
    SubsystemListPatchAllDeltaRequest,
    SubsystemListPatchDeltaRequest,
    SubsystemListTemplateFileRequest,
} from 'src/app/store/manual-upload/subsystem-list/actions';
import { PageEvent } from '@angular/material/paginator';
import { SubsystemListDeltaDTO } from 'src/app/store/manual-upload/subsystem-list/model';

@Component({
    selector: 'app-subsystem-list',
    templateUrl: './subsystem-list.component.html',
    styleUrls: ['./subsystem-list.component.scss'],
})
export class SubsystemListComponent extends BaseComponent implements OnInit {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deltaData: MatTableDataSource<SubsystemListDeltaDTO>;
    deltaDataResultsLength = 0;
    deltaDataPageSize = 10;
    displayedDeltaDataColumns = [
        'projectTeam',
        'subsystem',
        'module',
        'module2',
        'type',
        'walkdownComplete',
        'punchlistComplete',
        'lunSigned',
        'energized',
        'deEnergized',
        'actions',
    ];
    uploadLogData$ = this.store.select((store) => store.subsystemListState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.subsystemListState.isUploadLogLoading);
    deltaData$ = this.store.select((store) => store.subsystemListState.deltaData);
    isDeltaDataLoading$ = this.store.select((store) => store.subsystemListState.isDeltaDataLoading);
    isLoading$ = this.store.select((store) => store.subsystemListState.isLoading);
    showOrder = true;
    projectTeam = '';

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((params: ParamMap) => {
            this.projectTeam = params.get('type');

            this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
                this.store.dispatch(new ManualUploadSetActivatedRoute(`${urlSegments[0].path}/${this.projectTeam}`));
            });
    
            this.uploadLogData$
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));
    
            this.deltaData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
                this.deltaData = new MatTableDataSource(deltaData.items);
                this.deltaDataResultsLength = deltaData.totalCount;
            });

            this.store.dispatch(new SubsystemListDeltaRequest(`SubsystemList-${this.projectTeam}`));
        });
    }

    rejectChange(element: SubsystemListDeltaDTO) {
        this.store.dispatch(
            new SubsystemListPatchDeltaRequest({
                subsystem: element.subsystem,
                deltaState: false,
            })
        );
    }

    acceptChange(element: SubsystemListDeltaDTO) {
        this.store.dispatch(
            new SubsystemListPatchDeltaRequest({
                subsystem: element.subsystem,
                deltaState: true,
            })
        );
    }

    patchAllChanges(deltaState: boolean) {
        this.store.dispatch(
            new SubsystemListPatchAllDeltaRequest({
                deltaState: deltaState,
                type: `SubsystemList-${this.projectTeam}`
            })
        );
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new SubsystemListTemplateFileRequest(fileName));
    }

    onDeltaDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deltaDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new SubsystemListDeltaFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deltaDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new SubsystemListDeltaFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new SubsystemListDeltaRequest(`SubsystemList-${this.projectTeam}`));
    }

    downloadData(filePath: string) {
        this.store.dispatch(new SubsystemListDownloadOutputDataRequest(filePath));
    }
}
