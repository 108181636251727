import { ManualUploadState } from './model';
import { ManualUploadActions, ManualUploadActionTypes } from './actions';

const initialState: ManualUploadState = {
    activatedRoute: '',
    uploadInProgress: false,
};

export function reducer(state = initialState, action: ManualUploadActions) {
    switch (action.type) {
        case ManualUploadActionTypes.ManualUploadSetActivatedRoute: {
            return {
                ...state,
                activatedRoute: action.payload,
            };
        }
        case ManualUploadActionTypes.ManualUploadFilesUploadRequest: {
          
            return {
                ...state,
                uploadInProgress: true
            };
        }
        case ManualUploadActionTypes.ManualUploadFilesUploadSuccess: {
            return {
                ...state,
                uploadInProgress: false
            };
        }
        case ManualUploadActionTypes.ManualUploadFilesUploadError: {
            return {
                ...state,
                uploadInProgress: false,
            };
        }
        default:
            return state;
    }
}
