export enum LUNStatusType {
    Draft = 1,
    Submitted = 2,
    Reviewed = 3,
    Approved = 4,
    Cancelled = 5,
    Void = 6
}

export enum LUNStatusChangeAction {
    Draft = 1,
    Submit = 2,
    AreaSCElectricalDisciplineLeadReview = 3,
    AreaSCSMPDisciplineLeadReview = 4,
    AreaSCIAndCDisciplineLeadReview = 5,
    LeadIsolationAuthoritySMPReview = 6,
    LeadIsolationAuthorityElectricalReview = 7,
    AreaSCManagerApprove = 8,
    Cancel = 9,
    Void = 10,
}

export enum NightShiftStatusType {
    Draft = 1,
}

export enum NightShiftStatusChangeAction {}
