import { BaseComponent } from '../../base.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationState } from 'src/app/store/model';
import { Store, Action } from '@ngrx/store';
import { delay, startWith, takeWhile } from 'rxjs/operators';
import { combineLatest, interval, Subscription } from 'rxjs';
import { ManualUploadFilesUploadRequest } from 'src/app/store/manual-upload/actions';
import * as _ from 'lodash';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import {
    SubsystemListValidateDeltaRequest,
    SubsystemListFileUploaded,
    SubsystemListAddStartedLog,
    SubsystemListUploadLogRequest,
} from 'src/app/store/manual-upload/subsystem-list/actions';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';
import { MatTabNav } from '@angular/material/tabs';
import { UploadLinkDTO } from 'src/app/models/upload-link-dto';
import {
    MasterSubsystemListAddStartedLog,
    MasterSubsystemListFileUploaded,
    MasterSubsystemListUploadLogRequest,
    MasterSubsystemListValidateDeltaRequest,
} from 'src/app/store/manual-upload/master-subsystem-list/actions';

@Component({
    selector: 'app-manual-upload',
    templateUrl: './manual-upload.component.html',
    styleUrls: ['./manual-upload.component.scss'],
})
export class ManualUploadComponent extends BaseComponent implements OnInit {
    isAdmin = false;
    activatedRoute$ = this.store.select((store) => store.manualUploadState.activatedRoute);

    navLinks: UploadLinkDTO[] = [];
    activeNavLink: UploadLinkDTO;
    isValidateButtonDisabled = true;
    validateActions: Action[] = [
        new SubsystemListValidateDeltaRequest('SubsystemList-3GP'),
        new SubsystemListValidateDeltaRequest('SubsystemList-3GI'),
        new MasterSubsystemListValidateDeltaRequest(),
    ];
    inProgressObservables = [
        this.store.select((store) => store.subsystemListState.is3GPImportInProgress),
        this.store.select((store) => store.subsystemListState.is3GIImportInProgress),
        this.store.select((store) => store.masterSubsystemListState.isImportInProgress),
    ];
    isLoading: boolean;
    isUploading$ = this.store.select((store) => store.manualUploadState.uploadInProgress);
    intervalSub: Subscription = null;

    constructor(
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private popupService: PopupService
    ) {
        super();
        this.navLinks = [
            {
                label: '3GP temporary subsystem list',
                link: './subsystemlist/3GP',
                index: 0,
                type: 'SubsystemList-3GP',
                fileUploadedAction: new SubsystemListFileUploaded('SubsystemList-3GP'),
                addLogAction: new SubsystemListAddStartedLog('SubsystemList-3GP'),
            },
            {
                label: '3GI temporary subsystem list',
                link: './subsystemlist/3GI',
                index: 1,
                type: 'SubsystemList-3GI',
                fileUploadedAction: new SubsystemListFileUploaded('SubsystemList-3GI'),
                addLogAction: new SubsystemListAddStartedLog('SubsystemList-3GI'),
            },
            {
                label: '3GI master subsystem list',
                link: './mastersubsystemlist',
                index: 2,
                type: 'MSL',
                fileUploadedAction: new MasterSubsystemListFileUploaded(),
                addLogAction: new MasterSubsystemListAddStartedLog(),
            },
        ];
    }

    ngOnInit() {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);

        combineLatest([
            this.store.select((store) => store.subsystemListState.isValidateButtonEnabled),
            this.store.select((store) => store.subsystemListState.isValidateButtonEnabled),
            this.store.select((store) => store.masterSubsystemListState.isValidateButtonEnabled),
            this.activatedRoute$,
        ])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => {
                setTimeout(() => {
                    this.activeNavLink = this.navLinks.find((n) => n.link === `./${data[data.length - 1]}`);
                    this.isValidateButtonDisabled = this.activeNavLink ? !data[this.activeNavLink.index] : true;
                    this.getImportHistoryData();
                });
            });
    }

    private getImportHistoryData(withDelay: boolean = false) {
        if (this.intervalSub) {
            this.intervalSub.unsubscribe();
        }
        let inter = interval(Constants.logRefreshInterval).pipe(
            startWith(0),
            takeWhile(() => this.isAlive),
            delay(+withDelay * Constants.delayAfterUploadInterval)
        );

        this.intervalSub = inter.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
            if (this.activeNavLink.type === 'MSL') {
                this.store.dispatch(new MasterSubsystemListUploadLogRequest());
            } else {
                this.store.dispatch(new SubsystemListUploadLogRequest(this.activeNavLink?.type));
            }
        });
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        const formData = new FormData();
        filesWrapper.push(...files);
        const fileTypes: string[] = [];
        filesWrapper.forEach((file) => {
            const type = this.activeNavLink.type;
            fileTypes.push(type);
            formData.append(type, file, file.name);
        });

        _.uniq(fileTypes).forEach((t) => {
            this.store.dispatch(this.activeNavLink.fileUploadedAction);
            this.store.dispatch(this.activeNavLink.addLogAction);
        });

        this.store.dispatch(new ManualUploadFilesUploadRequest(formData));
        this.getImportHistoryData(true);
    }

    validateDelta() {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({
                    title: 'Confirm action',
                    text: `Are you sure you want to import data and update existing records?`,
                })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer) {
                    this.store.dispatch(this.validateActions[this.activeNavLink.index]);
                    this.isValidateButtonDisabled = true;
                }
            });
    }

    //Fix for bug 1939060 - mat-ink-bar not aligned to the tab
    @ViewChild(MatTabNav) public matTabNav: MatTabNav;
    public ngAfterViewChecked(): void {
        setTimeout(() => this.matTabNav._alignInkBarToSelectedTab(), 0);
    }
}
