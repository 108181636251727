import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from '../app.config';
import { EmailDistribution, Setting } from '../models/configuration';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly configurationEndpoint: string = '/configuration';

    constructor(private http: HttpClient) {}

    getConfigData() {
        return this.http.get<Setting[]>(`${this.rootUrl}${this.configurationEndpoint}`);
    }

    updateConfigData(configData: Setting[]) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}`, configData);
    }

    getEmailDistributionData(filter: any) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/emailDistribution`, { ...filter });
    }

    updateEmailDistributionData(config: EmailDistribution) {
        return this.http.patch(`${this.rootUrl}${this.configurationEndpoint}/emailDistribution/${config.id}`, {
            areaManagerSharedEmail: config.areaManagerSharedEmail,
        });
    }

    getNamesForEmailDistributionFilter(search: string, page: number, take: number, filter: {}) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/emailDistribution/names`, {
            search,
            page,
            take,
            ...filter,
        });
    }

    getSurnamesForEmailDistributionFilter(search: string, page: number, take: number, filter: {}) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/emailDistribution/surnames`, {
            search,
            page,
            take,
            ...filter,
        });
    }

    getEmailsForEmailDistributionFilter(search: string, page: number, take: number, filter: {}) {
        return this.http.post(`${this.rootUrl}${this.configurationEndpoint}/emailDistribution/emails`, {
            search,
            page,
            take,
            ...filter,
        });
    }
}
