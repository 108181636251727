import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { LUNComment } from '../store/lun/model';

@Injectable({
    providedIn: 'root',
})
export class CommentService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly commentEndpoint: string = '/lun';
    constructor(private http: HttpClient) {}

    addComment(id: number, description: string) {
        return this.http.post<LUNComment>(`${this.rootUrl}${this.commentEndpoint}/comment`, {
            LunId: id,
            Description: description
        });
    }

    getComments(id: number) {
        return this.http.get<LUNComment[]>(`${this.rootUrl}${this.commentEndpoint}/comments/${id}`);
    }
}
