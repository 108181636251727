import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { Attachment, NightShiftAttachmentType } from 'src/app/store/night-shift/model';
import { ApplicationState } from 'src/app/store/model';
import { RoleService } from 'src/app/services/shared/role.service';
import _ from 'lodash';
import { NightShiftAttachmentsRequest, NightShiftDownloadAttachmentRequest } from 'src/app/store/night-shift/actions';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    selector: 'app-night-shift-attachment-sia',
    templateUrl: './night-shift-attachment-sia.component.html',
    styleUrls: ['./night-shift-attachment-sia.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NightShiftAttachmentSupportingInformationAttachmentComponent),
            multi: true,
        },
    ],
})
export class NightShiftAttachmentSupportingInformationAttachmentComponent extends BaseComponent
    implements ControlValueAccessor, OnInit {
    @Input() headerText = '';
    @Input() nightShiftForm: UntypedFormGroup;
    @Input() type: NightShiftAttachmentType = NightShiftAttachmentType.SupportingDocument;
    @Input() displayNote = true;
    @Input() isDisabled = false;
    @Input() printMode = false;

    propagateChange = (_: any) => {};

    attachments: Attachment[] = [];
    nightShiftId: number;
    isReadOnly: boolean;
    user: AccountInfo = null;
    attachmentTypes = NightShiftAttachmentType;
    nightShiftId$ = this.store.select((state) => state.nightShiftState.form.id);
    NightShift$ = this.store.select((state) => state.nightShiftState.form);
    attachments$ = this.store.select<Attachment[]>(
        (state) => state.nightShiftState.form[`${this.attachmentTypes[this.type]}Attachments`]
    );
    attachmentType = this.attachmentTypes[this.type] + 'Attachments';
    isLoading$ = this.store.select((state) => state.nightShiftState.isLoading);

    constructor(
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private roleService: RoleService
    ) {
        super();
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isReadOnly = this.roleService.isReadOnly();
        this.nightShiftId$.pipe(takeWhile(() => this.isAlive)).subscribe((nightShiftId) => {
            this.nightShiftId = nightShiftId;
            if (this.nightShiftId !== null) {
                this.store.dispatch(new NightShiftAttachmentsRequest({ id: this.nightShiftId, type: this.type }));
            }
        });

        this.attachments$.pipe(takeWhile(() => this.isAlive)).subscribe((attachments) => {
            this.attachments = attachments;
            this.attachmentType = this.attachmentTypes[this.type] + 'Attachments';
        });
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        filesWrapper.push(...files);
        filesWrapper.forEach((file) => {
            if (file.size === 0) {
                this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
                return;
            }
            if (_.some(this.attachments, (attachment) => attachment.name === file.name)) {
                this.toastService.Info(
                    'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
                );
            } else {
                const attachment = new Attachment();
                attachment.file = file;
                attachment.name = file.name;
                attachment.isValid = true;
                attachment.attachmentType = this.type;
                this.attachments = [...this.attachments, attachment];
            }
        });
        this.propagateChange(this.attachments);
    }

    removeAttachment(name: string) {
        this.attachments = [..._.filter(this.attachments, (attachment) => attachment.name !== name)];
        this.propagateChange(this.attachments);
    }

    downloadAttachment(attachment: Attachment) {
        this.store.dispatch(new NightShiftDownloadAttachmentRequest({ attachment, type: this.type }));
    }

    writeValue(value: any) {}

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
}
