import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/constants';
import { RoleService } from '../services/shared/role.service';

@Injectable({
    providedIn: 'root',
})
export class IsAdminGuard implements CanActivateChild {
    constructor(private groupSvc: RoleService, private router: Router) {}

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.groupSvc.isInRole(Constants.applicableGroups.Admin)) {
            return true;
        }

        this.router.navigate(['/notauthorized']);
        return false;
    }
}
