<div class="sub-title">approval notification DL configuration</div>
<table
    mat-table
    [dataSource]="users"
    matSort
    [matSortActive]="sortBy"
    matSortDisableClear
    [matSortDirection]="direction"
>
    <ng-container matColumnDef="name">
        <th mat-header-cell disableClear *matHeaderCellDef>
            <div class="sort-container center">
                <span
                    class="hover-pointer"
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnNames)
                    }"
                    (click)="openHeaderCheckListFilter('name', getNames, 'columnNames', 'Name', columnNames)"
                    >Name</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'name'">
                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="surname">
        <th mat-header-cell disableClear *matHeaderCellDef>
            <div class="sort-container center">
                <span
                    class="hover-pointer"
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnSurnames)
                    }"
                    (click)="
                        openHeaderCheckListFilter('surname', getSurnames, 'columnSurnames', 'Surname', columnSurnames)
                    "
                    >Surname</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'surname'">
                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.surname }}
        </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell disableClear *matHeaderCellDef>
            <div class="sort-container center">
                <span
                    class="hover-pointer"
                    [ngClass]="{
                        'header-underline': showUnderlineForCheckListColumn(columnEmails)
                    }"
                    (click)="openHeaderCheckListFilter('email', getEmails, 'columnEmails', 'Email', columnEmails)"
                    >Email</span
                >
                <div class="sort-icon" *ngIf="sortBy === 'email'">
                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                </div>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.email }}
        </td>
    </ng-container>
    <ng-container matColumnDef="areaManagerSharedEmail">
        <th mat-header-cell disableClear *matHeaderCellDef>Approval Notification DL</th>
        <td mat-cell *matCellDef="let element">
            <div *ngIf="!element.isInEditMode">
                <mat-form-field style.fontSize="16px" appearance="outline" class="approval-dl" *ngIf="element.areaManagerSharedEmail">
                    <mat-label>Approval Notification DL</mat-label>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="7"
                        [value]="element.areaManagerSharedEmail"
                        disabled
                    ></textarea>
                </mat-form-field>
            </div>
            <div *ngIf="element.isInEditMode">
                <mat-form-field style.fontSize="16px" appearance="outline" class="approval-dl">
                    <mat-label>Approval Notification DL</mat-label>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="7"
                        [(ngModel)]="element.areaManagerSharedEmail"
                        [ngModelOptions]="{ standalone: true }"
                        name="areaManagerSharedEmail"
                        autocomplete="off"
                    ></textarea>
                </mat-form-field>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell disableClear *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
            <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                <mat-icon>cancel</mat-icon>
            </button>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div class="paginator page-counter">
    <mat-paginator
        [length]="resultsLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 25, 50]"
        (page)="onPaginatorChange($event)"
        showFirstLastButtons
    >
    </mat-paginator>
    <input type="number" min="1" [value]="this.paginator.pageIndex + 1" (change)="onPageChange($event.target.value)" />
</div>
<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
