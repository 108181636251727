import { UpdateDataLog } from 'src/app/models/update-data-log';
import * as moment from 'moment';

export interface SubsystemListState {
    uploadLogData: UpdateDataLog[];
    isUploadLogLoading: boolean;
    deltaData: SubsystemListDeltaPagination;
    isDeltaDataLoading: boolean;
    deltaFilter: SubsystemListDeltaFilter;
    uploadLogStatus: string;
    uploadLogStartDate: moment.Moment;
    is3GPImportInProgress: boolean;
    is3GIImportInProgress: boolean;
    isLoading: boolean;
    isValidateButtonEnabled: boolean;
}

export class SubsystemListDeltaDTO {
    subsystem: string;
    projectTeam: string;
    typeLive: string;
    typeImport: string;
    moduleLive: string;
    moduleImport: string;
    module2Live: string;
    module2Import: string;
    walkdownCompleteLive: string;
    walkdownCompleteImport: string;
    punchlistCompleteLive: string;
    punchlistCompleteImport: string;
    lunSignedLive: string;
    lunSignedImport: string;
    energizedLive: string;
    energizedImport: string;
    deEnergizedLive: string;
    deEnergizedImport: string;
    deltaState: boolean;
}

export class SubsystemListDeltaFilter {
    page = 0;
    take = 10;
}

export class SubsystemListDeltaPagination {
    items: SubsystemListDeltaDTO[] = [];
    totalCount = 0;
}
