import { Pipe, PipeTransform } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import moment from 'moment';

@Pipe({
    name: 'rangeDate',
})
export class RangeDatePipe implements PipeTransform {
    transform(rangeDates: DateRange<Date>): string {
        if (!rangeDates || !rangeDates.start || !rangeDates.end) return null;

        const start = moment(rangeDates.start).format('DD/MM/YYYY');
        const end = moment(rangeDates.end).format('DD/MM/YYYY');

        return moment(rangeDates.start).isSame(moment(rangeDates.end), 'days') ? start : `${start} - ${end}`;
    }
}
