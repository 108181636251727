<div class="comments-container">
    <div [formGroup]="lunForm">
        <div class="position">
            <div class="title">comments</div>

            <div class="comments-editor-container">
                <div class="doc-title">
                    <div [ngClass]="{
                            'mat-error':
                                lunForm.controls['description'].invalid &&
                                lunForm.controls['description'].errors.required
                        }">
                    </div>
                </div>
                <div class="separator-10px">
                    <div class="jodit-container" [ngClass]="{
                            'description-error':
                                lunForm.controls['description'].invalid &&
                                lunForm.controls['description'].errors.required,
                            'description-disabled': isLocked
                        }" (click)="openJoditPopup(lunForm.controls['description']?.value)">
                        <div *ngIf="!isDescriptionLoading"
                            [innerHTML]="lunForm.controls['description']?.value | keepHtml"></div>
                        <div *ngIf="isDescriptionLoading" class="jodit-loader">
                            <div class="lds-hourglass"></div>
                        </div>
                    </div>
                </div>
                <mat-error class="error-description" *ngIf="
                        lunForm.controls['description'].invalid &&
                        lunForm.controls['description'].errors.required
                    ">
                    Description is required.
                </mat-error>
            </div>
        </div>
    </div>
</div>