import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from '../../services/shared/toast.service';
import { MyPageService } from '../../services/my-page.service';
import {
    MyPendingActionsRequest,
    LUNMyPageActionTypes,
    MyPendingActionsRequestSuccess,
    MyPendingActionsRequestError,
    AllDraftsRequestSuccess,
    AllDraftsRequestError,
    AllDraftsRequest,
    SummaryRequest,
    SummaryRequestSuccess,
    SummaryRequestError,
    MyPageCountersRequestSuccess,
    MyPageCountersRequestError,
    MyPageCountersSetSummarySectionCheck,
    MyPageCountersSetSummarySection,
    DelegationsRequest,
    DelegationsRequestSuccess,
    DelegationsRequestError,
    DelegationsAssignmentRequest,
    DelegationsAssignmentRequestError,
    DelegationsAssignmentRequestSuccess,
} from './actions';
import { withLatestFrom, mergeMap, map, catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { LUNMyPageSections } from './models';

@Injectable()
export class LUNMyPageEffects {
    constructor(
        private store: Store<ApplicationState>,
        private actions$: Actions,
        private myPageService: MyPageService,
        private toastService: ToastService
    ) {}

    
    loadMyPendingActions$ = createEffect(() => this.actions$.pipe(
        ofType<MyPendingActionsRequest>(LUNMyPageActionTypes.MyPendingActionsRequest),
        withLatestFrom(this.store.select((state) => state.lunMyPageState.myPendingActionsFilter)),
        mergeMap(([, myPendingActionsFilter]) =>
            this.myPageService.filterLUNForSection(LUNMyPageSections.Pending, myPendingActionsFilter).pipe(
                map((myPendingActionsPagination) => new MyPendingActionsRequestSuccess(myPendingActionsPagination)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading my pending actions section. Please contact Program Administrator.'
                    );
                    return of(new MyPendingActionsRequestError(error));
                })
            )
        )
    ));

    
    loadAllDrafts$ = createEffect(() => this.actions$.pipe(
        ofType<AllDraftsRequest>(LUNMyPageActionTypes.AllDraftsRequest),
        withLatestFrom(this.store.select((state) => state.lunMyPageState.allDraftsFilter)),
        mergeMap(([, allDraftsFilter]) =>
            this.myPageService.getAllDrafts(allDraftsFilter).pipe(
                map((allDraftsPagination) => new AllDraftsRequestSuccess(allDraftsPagination)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading all drafts section. Please contact Program Administrator.'
                    );
                    return of(new AllDraftsRequestError(error));
                })
            )
        )
    ));

    
    loadDelegations$ = createEffect(() => this.actions$.pipe(
        ofType<DelegationsRequest>(LUNMyPageActionTypes.DelegationsRequest),
        withLatestFrom(this.store.select((state) => state.lunMyPageState.delegationsFilter)),
        mergeMap(([, delegationsFilter]) =>
            this.myPageService.getDelegations(delegationsFilter).pipe(
                map((delegationsPagination) => new DelegationsRequestSuccess(delegationsPagination)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading delegations section. Please contact Program Administrator.'
                    );
                    return of(new DelegationsRequestError(error));
                })
            )
        )
    ));

    
    loadSummarySection$ = createEffect(() => this.actions$.pipe(
        ofType<SummaryRequest>(LUNMyPageActionTypes.SummaryRequest),
        withLatestFrom(
            this.store.select((state) => state.lunMyPageState.selectedSummarySection),
            this.store.select((state) => state.lunMyPageState.summaryFilter)
        ),
        mergeMap(([, section, summaryFilter]) =>
            this.myPageService.filterLUNForSection(section, summaryFilter).pipe(
                map((summarySectionPagination) => new SummaryRequestSuccess(summarySectionPagination)),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while loading summary ${section} section. Please contact Program Administrator.`
                    );
                    return of(new SummaryRequestError(error));
                })
            )
        )
    ));

    
    loadCounters$ = createEffect(() => this.actions$.pipe(
        ofType(LUNMyPageActionTypes.MyPageCountersRequest),
        mergeMap(() =>
            this.myPageService.getMyPageCounters().pipe(
                map((counters) => new MyPageCountersRequestSuccess(counters)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading counters. Please contact Program Administrator.'
                    );
                    return of(new MyPageCountersRequestError(error));
                })
            )
        )
    ));
    
    setSummarySectionCheck$ = createEffect(() => this.actions$.pipe(
        ofType<MyPageCountersSetSummarySectionCheck>(LUNMyPageActionTypes.MyPageCountersSetSummarySectionCheck),
        withLatestFrom(this.store.select((state) => state.lunMyPageState.selectedSummarySection)),
        filter(
            ([{ payload }, currentSummarySection]) =>
                currentSummarySection !== payload && payload !== LUNMyPageSections.Pending
        ),
        map(([{ payload }]) => new MyPageCountersSetSummarySection(payload))
    ));

    
    refreshSummarySection$ = createEffect(() => this.actions$.pipe(
        ofType(LUNMyPageActionTypes.MyPageCountersSetSummarySection),
        map(() => new SummaryRequest())
    ));

    
    requestAssignment$ = createEffect(() => this.actions$.pipe(
        ofType<DelegationsAssignmentRequest>(LUNMyPageActionTypes.DelegationsAssignmentRequest),
        withLatestFrom(
            this.store.select((state) => state.lunMyPageState.delegationsFilter),
            this.store.select((state) => state.lunMyPageState.selectedItems)
        ),
        mergeMap(([{ payload }, delegationsFilter, selectedItems]) =>
            this.myPageService
                .delegationAssignment(
                    delegationsFilter.type,
                    payload.id,
                    selectedItems.map((s) => s.id)
                )
                .pipe(
                    map(() => {
                        this.toastService.Success(
                            'Selected LUNs have been delegated. Please liaise with delegated users to ensure they use "My Page" to view their action list and progress the LUNs.'
                        );
                        return new DelegationsAssignmentRequestSuccess();
                    }),
                    catchError((error) => {
                        this.toastService.Error(
                            'Error occurred while delegating lun. Please contact Program Administrator.'
                        );
                        return of(new DelegationsAssignmentRequestError(error));
                    })
                )
        )
    ));

    
    requestAssignmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(LUNMyPageActionTypes.DelegationsAssignmentRequestSuccess),
        map(() => new DelegationsRequest())
    ));
}
