<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">Night Shift - status change history</span>
    </p>
    <mat-dialog-content>
        <div [hidden]="showDiff">
            <table
                mat-table
                matSort
                [dataSource]="dataSource"
                [matSortActive]="sortBy"
                matSortDisableClear
                [matSortDirection]="direction"
            >
                <ng-container matColumnDef="status"
                    ><th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>status</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="align-status lun-status" [ngClass]="getClassName(element.status)">
                            {{ getStatusName(element.status) }}
                        </div>
                    </td></ng-container
                >
                <ng-container matColumnDef="date"
                    ><th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>date updated</th>
                    <td mat-cell *matCellDef="let element">{{ element.date | date: 'd-MMM-y' }}</td></ng-container
                ><ng-container matColumnDef="user.name"
                    ><th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>changed by</th>
                    <td mat-cell *matCellDef="let element">{{ element.user.name }}</td></ng-container
                ><ng-container matColumnDef="action"
                    ><th mat-header-cell *matHeaderCellDef>updated properties</th>
                    <td mat-cell *matCellDef="let element">{{ element.action }}</td></ng-container
                >
                <ng-container matColumnDef="diff"
                    ><th mat-header-cell *matHeaderCellDef>diff</th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="element.prevModelJson"
                            mat-icon-button
                            (click)="showDifference(element)"
                            title="diff"
                        >
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </td></ng-container
                >
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
        <div *ngIf="showDiff">
            <button mat-icon-button (click)="showDiff = !showDiff" title="back">
                <a class="navigator"><mat-icon>keyboard_arrow_left</mat-icon> Go Back </a>
            </button>
            <td-ngx-text-diff [left]="leftCompare" [right]="rightCompare" [compareRowsClass]="'customdiffwrap'"> </td-ngx-text-diff>
        </div>
    </mat-dialog-content>
    <mat-paginator
        [hidden]="showDiff"
        [length]="resultsLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 15]"
    ></mat-paginator>

    <div *ngIf="isLoading" class="loader"><div class="lds-hourglass"></div></div>
</div>
