<main class="main">
    <div class="import-container">
        <h4>Master Subsystem List import</h4>
        <div class="import-subtitle">
            <button
                mat-icon-button
                (click)="showOrder = !showOrder"
                title="{{
                    showOrder ? 'info - click to display file structure' : 'info - click to hide file structure'
                }}"
            >
                <mat-icon>info</mat-icon>
            </button>
            See <b (click)="downloadTemplate('Master Subsystem List.xlsx')">Master Subsystem List.xlsx</b> structure.
            Data only imported for matching Project Teams (3GI).
            Admin is given the opportunity to validate
            deletion of existing records and changes to existing records for selected attributes (SC Area Manager,
            System Owner, MC Engineer, Contractor, ACM, Waypoint, Milestone).
        </div>
        <div *ngIf="!showOrder" class="list-container">
            <div class="list-structure">
                <ol>
                    <li>Ready for Operation (RFO)</li>
                    <li>Area</li>
                    <li>Area Name</li>
                    <li>Turnover Group</li>
                    <li>Turnover Group Name</li>
                    <li>Turnover Group Name (RUS)</li>
                    <li>System Number</li>
                    <li>System Name</li>
                    <li>System Name (RUS)</li>
                    <li>AGREED Subsystem No.</li>
                    <li>Sub-System Description</li>
                    <li>Sub-System Description (RUS)</li>
                    <li>SC Area Manager</li>
                    <li>System Owner</li>
                    <li>MC Engineer</li>
                    <li>Contractor</li>
                    <li>Contractor Name</li>
                    <li>MC Milestone</li>
                    <li>Priority</li>
                    <li>Priority Name</li>
                    <li>Waypoint</li>
                    <li>Waypoint Name</li>
                    <li>Milestone</li>
                    <li>Milestone Name</li>
                    <li>Area Construction Manager</li>
                    <li>Project Team</li>
                    <li>Remarks</li>
                    <li>MC Sequence (3GP & 3GI USED ONLY)</li>
                    <li>GOOC # (3GP USED ONLY)</li>
                    <li>GOOC Name (3GP USED ONLY)</li>
                    <li>Contractor Number (3GP & 3GI USED ONLY)</li>
                    <li>Non Commissionable Subsystem</li>
                </ol>
            </div>
        </div>
    </div>
    <div class="delta-container">
        <div class="section-header">
            <h4>Delta validation</h4>
            <span> - validate changes to subsystem data in live database</span>
        </div>
        <span *ngIf="!deltaData.data.length">No delta validation data available.</span>
        <div *ngIf="deltaData.data.length" class="changebuttons-container">
            <button (click)="patchAllChanges(true)" mat-icon-button title="accept">
                <mat-icon>check_circle_outline</mat-icon>
            </button>
            <span class="margin-right20">Accept all changes</span>
            <button (click)="patchAllChanges(false)" mat-icon-button title="reject">
                <mat-icon>highlight_off</mat-icon>
            </button>
            <span>Reject all changes</span>
        </div>
        <div *ngIf="deltaData.data.length" class="legend-scale">
            <ul class="legend-labels">
                <li><span style="background: rgba(0, 0, 0, 0.6);"></span>Existing</li>
                <li><span style="background: #0066b2;"></span>Update</li>
            </ul>
        </div>
        <table mat-table [dataSource]="deltaData" *ngIf="deltaData.data.length">
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell *matHeaderCellDef class="empty">Subsystem</th>
                <td mat-cell *matCellDef="let element" class="empty">
                    {{ element.subsystem }}
                </td>
            </ng-container>
            <ng-container matColumnDef="scm">
                <th mat-header-cell *matHeaderCellDef>SC Area Manager</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.scManagerLive }}</div>
                    <div class="import-value">{{ element.scManagerImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="systemOwner">
                <th mat-header-cell *matHeaderCellDef>System Owner</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.systemOwnerLive }}</div>
                    <div class="import-value">{{ element.systemOwnerImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="mcEngineer">
                <th mat-header-cell *matHeaderCellDef>MC Engineer</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.mcEngineerLive }}</div>
                    <div class="import-value">{{ element.mcEngineerImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="contractor">
                <th mat-header-cell *matHeaderCellDef>Contractor</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.contractorLive }}</div>
                    <div class="import-value">{{ element.contractorImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="acm">
                <th mat-header-cell *matHeaderCellDef>ACM</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.acmLive }}</div>
                    <div class="import-value">{{ element.acmImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="waypoint">
                <th mat-header-cell *matHeaderCellDef>Waypoint</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.waypointLive }}</div>
                    <div class="import-value">{{ element.waypointImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="milestone">
                <th mat-header-cell *matHeaderCellDef>Milestone</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.milestoneLive }}</div>
                    <div class="import-value">{{ element.milestoneImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell *matHeaderCellDef>Project Team</th>
                <td mat-cell *matCellDef="let element">
                    <div class="live-value">{{ element.projectTeamNameLive }}</div>
                    <div class="import-value">{{ element.projectTeamNameImport }}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="empty cell-width-150"></th>
                <td mat-cell *matCellDef="let element" class="empty">
                    <div *ngIf="!element.deltaState" (click)="acceptChange(element)">
                        <button mat-icon-button title="accept">
                            <mat-icon>check_circle_outline</mat-icon>
                        </button>
                        <span>Accept</span>
                    </div>
                    <div *ngIf="element.deltaState" (click)="rejectChange(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>highlight_off</mat-icon>
                        </button>
                        <span>Reject</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDeltaDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeltaDataColumns"></tr>
        </table>
        <mat-paginator
            *ngIf="deltaData.data.length"
            [length]="deltaDataResultsLength"
            [pageSize]="deltaDataPageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onDeltaDataPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeltaDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="delete-container">
        <div class="section-header">
            <h4>Records marked for deletion</h4>
            <span>
                - subsystem not present in upload file; validate deletion of existing subsystem in live database</span
            >
        </div>
        <span *ngIf="!deleteData.data.length">No delete records available.</span>
        <div class="changebuttons-container">
            <button (click)="patchAllDeleteRecords(true)" *ngIf="deleteData.data.length" mat-icon-button title="accept">
                <mat-icon>delete</mat-icon>
            </button>
            <span *ngIf="deleteData.data.length" class="margin-right20">Delete all records</span>
            <button
                (click)="patchAllDeleteRecords(false)"
                *ngIf="deleteData.data.length"
                mat-icon-button
                title="reject"
            >
                <mat-icon>restore_from_trash</mat-icon>
            </button>
            <span *ngIf="deleteData.data.length">Keep all records</span>
        </div>
        <table mat-table [dataSource]="deleteData" *ngIf="deleteData.data.length">
            <ng-container matColumnDef="subsystem">
                <th mat-header-cell *matHeaderCellDef>Subsystem</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.subsystem }}
                </td>
            </ng-container>
            <ng-container matColumnDef="subsystemName">
                <th mat-header-cell *matHeaderCellDef>Subsystem Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.subsystemName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="projectTeamName">
                <th mat-header-cell *matHeaderCellDef>Project Team</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.projectTeamName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mcEngineer">
                <th mat-header-cell *matHeaderCellDef>MC Engineer</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.mcEngineer }}
                </td>
            </ng-container>
            <ng-container matColumnDef="systemOwner">
                <th mat-header-cell *matHeaderCellDef>System Owner</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.systemOwner }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="cell-width-150"></th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.deleteState" (click)="restoreSubsystem(element)">
                        <button mat-icon-button title="restore">
                            <mat-icon>restore_from_trash</mat-icon>
                        </button>
                        <span>Restore</span>
                    </div>
                    <div *ngIf="!element.deleteState" (click)="deleteSubsystem(element)">
                        <button mat-icon-button title="reject">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <span>Delete</span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedDeleteDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedDeleteDataColumns"></tr>
        </table>
        <mat-paginator
            *ngIf="deleteData.data.length"
            [length]="deleteDataResultsLength"
            [pageSize]="deleteDataPageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onDeleteDataPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <div *ngIf="isDeleteDataLoading$ | async" class="delta-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <div class="upload-log-container">
        <h4>Import history</h4>
        <table mat-table [dataSource]="uploadLogData">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="getImportClassName(element.status)">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.startDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.endDate | date: 'd/MMM/y HH:mm:ss' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="infoMessage">
                <th mat-header-cell *matHeaderCellDef>Info message</th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.infoMessage"></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error message</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.errorMessage ? element.errorMessage : 'No errors' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.user }}
                </td>
            </ng-container>
            <ng-container matColumnDef="downloadResult">
                <th mat-header-cell *matHeaderCellDef>Download result</th>
                <td mat-cell *matCellDef="let element">
                    <svg
                        class="downloadIcon"
                        *ngIf="element.outputDataLink"
                        (click)="downloadData(element.outputDataLink)"
                        width="18px"
                        height="20px"
                        viewBox="0 0 16 17"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <!-- Generator: sketchtool 55.1 (78136) - https://sketchapp.com -->
                        <title>{{ element.outputDataLink }}</title>
                        <desc>Created with sketchtool.</desc>
                        <g
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <g
                                id="New-FCNi-Copy"
                                transform="translate(-1259.000000, -1440.000000)"
                                stroke="#0066B2"
                                stroke-width="2"
                            >
                                <g id="Buttons/Primary-Copy" transform="translate(1256.000000, 1438.000000)">
                                    <path d="M4,18 L18,18" id="Line-3"></path>
                                    <path
                                        d="M5.5,8.5 L16.5,8.5"
                                        id="Line-3-Copy"
                                        transform="translate(11.000000, 8.500000) rotate(-90.000000) translate(-11.000000, -8.500000) "
                                    ></path>
                                    <polyline id="Line-7" points="5 9 11.0055329 15 17 9"></polyline>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span *ngIf="!element.outputDataLink && element.status === 'Started'" class="in-progress"></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUploadLogDataColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedUploadLogDataColumns"></tr>
        </table>
        <div *ngIf="isUploadLogLoading$ | async" class="upload-log-loader">
            <div class="lds-hourglass"></div>
        </div>
    </div>
    <app-loading-indicator *ngIf="isLoading$ | async"> </app-loading-indicator>
</main>
