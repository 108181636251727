import { OrderDirection, CheckListColumn, CalendarColumn } from 'src/app/store/common.model';
import * as moment from 'moment';
import { NightShiftComment } from '../night-shift/model';
import { NightShiftStatusType } from 'src/app/models/enums';

export class NightShiftRegisterFilter {
    page = 0;
    take = 10;
    sortBy = 'number';
    direction: OrderDirection = OrderDirection.Desc;
    areas: string[] = [];
    statuses: string[] = [];
    timezoneOffset = 0;
    number: string = '';
    originators: string[] = [];
    projectTeamNames: string[] = [];
    columnRaisedDate: CalendarColumn = null;
    columnStartDate: CalendarColumn = null;
    columnEndDate: CalendarColumn = null;
    columnNumbers: CheckListColumn = null;
    columnAreas: CheckListColumn = null;
    columnOriginators: CheckListColumn = null;
    columnComments: CheckListColumn = null;
    columnStatuses: CheckListColumn = null;
}

export class AttachmentDTO {
    name: string;
    link: string;
}

export class NightShiftRegisterDTO {
    id: number;
    number: string;
    area: string;
    originator: string;
    raisedDate: moment.Moment;
    startDate: moment.Moment;
    endDate: moment.Moment;
    attachments: AttachmentDTO[] = [];
    comments: NightShiftComment[] = [];
    latestComment: string = '';
    status: NightShiftStatusType;
}

export interface NightShiftRegisterState {
    dataPagination: NightShiftRegisterDataPagination;
    filter: NightShiftRegisterFilter;
    isLoading: boolean;
    showColumnSettings: ShowColumnSettings;
}

export class ShowColumnSettings {
    originator = true;
    raiseDate = true;
    startDate = true;
    endDate = true;
}

export class NightShiftRegisterDataPagination {
    items: NightShiftRegisterDTO[] = [];
    totalCount = 0;
}
