<div class="subsystem-status-container">
    <div *ngFor="let statusForm of subsystemMCSForms; trackBy: trackForm" [formGroup]="statusForm">
        <div class="subsystem-status-item-container filter-container">
            <span class="subsystem-label margin-right20">
                {{statusForm.controls['subsystem']?.value}}
            </span>
            <mat-form-field class="container150 margin-right20">
                <input matInput placeholder="MC Actual" readonly [value]="statusForm.controls['mcActual']?.value | date: 'd/MMM/yy'" />
            </mat-form-field>
            <mat-form-field class="container150 margin-right20">
                <input matInput placeholder="DAC Actual" readonly [value]="statusForm.controls['dacActual']?.value | date: 'd/MMM/yy'" />
            </mat-form-field>
            <mat-form-field class="container50 margin-right20">
                <input matInput placeholder="A-QVD" formControlName="aqvd" readonly
                    (click)="goToSCToolDetailedStatus('AQVD', statusForm.controls['subsystem']?.value, '', 'Not Started')" />
            </mat-form-field>
            <mat-form-field class="container50 margin-right20">
                <input matInput placeholder="A-PLI" formControlName="apli" readonly
                    (click)="goToSCToolDetailedStatus('PLI', statusForm.controls['subsystem']?.value, 'A', '')" />
            </mat-form-field>
            <mat-form-field class="container50 margin-right20">
                <input matInput placeholder="NPW" formControlName="npw" readonly
                    (click)="goToSCToolChangeManagement('NPWRedzone', statusForm.controls['subsystem']?.value)" />
            </mat-form-field>
            <mat-form-field class="container50 margin-right20">
                <input matInput placeholder="Exc" formControlName="ex" readonly
                    (click)="goToSCToolDetailedStatus('EX', statusForm.controls['subsystem']?.value, '', '')" />
            </mat-form-field>
        </div>
    </div>
</div>