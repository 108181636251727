import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeaderRangeFilter, HeaderRangeFilterResult } from 'src/app/models/filter-settings';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
    selector: 'app-header-range-filter',
    templateUrl: './header-range-filter.component.html',
    styleUrls: ['./header-range-filter.component.scss'],
})
export class HeaderRangeFilterComponent extends BaseComponent implements OnDestroy {
    isAscendingSort: boolean = false;
    isDescendingSort: boolean = false;
    ranges: number[];
    placeHolder: string;
    action: (data: HeaderRangeFilterResult) => void;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HeaderRangeFilter,
        private dialogRef: MatDialogRef<HeaderRangeFilterComponent>,
    ) {
        super();

        this.action = data.action;
        this.ranges = [...data.rangeColumn.ranges];
        this.placeHolder = data.placeHolder;
        this.isAscendingSort = data.isAscendingSort;
        this.isDescendingSort = data.isDescendingSort;

        dialogRef.afterClosed().subscribe((applyChanges: boolean) => {
            if (applyChanges) {
                this.action({
                    isAscendingSort: this.isAscendingSort,
                    isDescendingSort: this.isDescendingSort,
                    rangeColumn: {
                        ranges: this.ranges
                    },
                });
            }
        });
    }

    clear() {
        this.ranges = [];
    }

    close() {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.dialogRef.close(true);
    }
}
