<div class="status-container">
    <div class="title-container">
        <div class="title">current workflow status</div>
    </div>
    <span class="align-status lun-status-big lun-status" [ngClass]="getNightShiftClassName(nightShiftStatus)">
        {{ getNightShiftStatusName(nightShiftStatus) }}
    </span>
    <!-- <span class="material-icons email" title="share" (click)="shareEmail()">
        forward_to_inbox
    </span> -->
    <div class="status-buttons">
        <button [disabled]="!nightShiftForm.valid" *ngIf="!isReadOnly" class="lun-button lun-button-white" (click)="printNightShift()">
            print
        </button>
        <div *ngIf="commentsCount > 0" class="comments-number">{{ commentsCount }}</div>
        <button
            *ngIf="commentsCount > 0"
            class="white lun-button lun-button-white comments-icon"
            (click)="scroll('app-night-shift-comments-history')"
        >
            comments
        </button>
        <button class="white lun-button lun-button-white history-icon" (click)="openHistoryPopup()">
            status history
        </button>
    </div>
</div>
