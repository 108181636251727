import { Action } from '@ngrx/store';
import { UserDetail } from '../common.model';
import {
    MyPageCounters,
    MyPageCollection,
    MyPageLUNRow,
    LUNMyPageSections,
    DelegationsRow,
} from './models';

export enum LUNMyPageActionTypes {
    MyPageCountersRequest = '[LUN] My Page Counters Request',
    MyPageCountersRequestSuccess = '[LUN] My Page Counters Success',
    MyPageCountersRequestError = '[LUN] My Page Counters Error',
    MyPageCountersSetSummarySectionCheck = '[LUN] Set MyPage Summary Section Check',
    MyPageCountersSetSummarySection = '[LUN] Set MyPage Summary Section',

    MyPendingActionsUpdateFileterProperty = '[LUN] My Pending Actions Update Fileter Property',
    MyPendingActionsRequest = '[LUN] My Pending Actions Request',
    MyPendingActionsRequestSuccess = '[LUN] My Pending Actions Request Success',
    MyPendingActionsRequestError = '[LUN] My Pending Actions Request Error',

    SummaryRequest = '[LUN] Summary Request',
    SummaryRequestSuccess = '[LUN] Summary Request Success',
    SummaryRequestError = '[LUN] Summary Request Error',
    SummaryUpdateFilterProperty = '[LUN] Summary Update Fileter Property',

    AllDraftsRequest = '[LUN] All Drafts Request',
    AllDraftsRequestSuccess = '[LUN] All Drafts Request Success',
    AllDraftsRequestError = '[LUN] All Drafts Request Error',
    AllDraftsUpdateFilterProperty = '[LUN] All Drafts Update Filter Property',

    DelegationsRequest = '[LUN] Delegations Request',
    DelegationsRequestSuccess = '[LUN] Delegations Request Success',
    DelegationsRequestError = '[LUN] Delegations Request Error',
    DelegationsUpdateFilterProperty = '[LUN] Delegations Update Filter Property',
    DelegationsToggleSelected = '[LUN] Delegations Toggle Selected',
    DelegationsAssignmentRequest = '[LUN] Delegations Assignment Request',
    DelegationsAssignmentRequestSuccess = '[LUN] Delegations Assignment Request Success',
    DelegationsAssignmentRequestError = '[LUN] Delegations Assignment Request Error',
    DelegationsSelectedAll = '[LUN] Delegations Toggle Selected All',
}

export class MyPageCountersRequest implements Action {
    readonly type = LUNMyPageActionTypes.MyPageCountersRequest;
}

export class MyPageCountersRequestSuccess implements Action {
    readonly type = LUNMyPageActionTypes.MyPageCountersRequestSuccess;

    constructor(public payload: MyPageCounters) {}
}

export class MyPageCountersRequestError implements Action {
    readonly type = LUNMyPageActionTypes.MyPageCountersRequestError;

    constructor(public payload: string) {}
}

export class MyPageCountersSetSummarySectionCheck implements Action {
    readonly type = LUNMyPageActionTypes.MyPageCountersSetSummarySectionCheck;

    constructor(public payload: LUNMyPageSections) {}
}

export class MyPageCountersSetSummarySection implements Action {
    readonly type = LUNMyPageActionTypes.MyPageCountersSetSummarySection;

    constructor(public payload: LUNMyPageSections) {}
}
export class MyPendingActionsRequest implements Action {
    readonly type = LUNMyPageActionTypes.MyPendingActionsRequest;
}

export class MyPendingActionsRequestSuccess implements Action {
    readonly type = LUNMyPageActionTypes.MyPendingActionsRequestSuccess;

    constructor(public payload: MyPageCollection<MyPageLUNRow>) {}
}

export class MyPendingActionsRequestError implements Action {
    readonly type = LUNMyPageActionTypes.MyPendingActionsRequestError;

    constructor(public payload: string) {}
}

export class SummaryRequest implements Action {
    readonly type = LUNMyPageActionTypes.SummaryRequest;
}

export class SummaryRequestSuccess implements Action {
    readonly type = LUNMyPageActionTypes.SummaryRequestSuccess;

    constructor(public payload: MyPageCollection<MyPageLUNRow>) {}
}

export class SummaryRequestError implements Action {
    readonly type = LUNMyPageActionTypes.SummaryRequestError;

    constructor(public payload: string) {}
}

export class AllDraftsRequest implements Action {
    readonly type = LUNMyPageActionTypes.AllDraftsRequest;
}

export class AllDraftsRequestSuccess implements Action {
    readonly type = LUNMyPageActionTypes.AllDraftsRequestSuccess;

    constructor(public payload: MyPageCollection<MyPageLUNRow>) {}
}

export class AllDraftsRequestError implements Action {
    readonly type = LUNMyPageActionTypes.AllDraftsRequestError;

    constructor(public payload: string) {}
}

export class MyPendingActionsUpdateFileterProperty implements Action {
    readonly type = LUNMyPageActionTypes.MyPendingActionsUpdateFileterProperty;

    constructor(public payload: { key: string; value: any }) {}
}
export class SummaryUpdateFilterProperty implements Action {
    readonly type = LUNMyPageActionTypes.SummaryUpdateFilterProperty;

    constructor(public payload: { key: string; value: any }) {}
}
export class AllDraftsUpdateFilterProperty implements Action {
    readonly type = LUNMyPageActionTypes.AllDraftsUpdateFilterProperty;

    constructor(public payload: { key: string; value: any }) {}
}

export class DelegationsRequest implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsRequest;
}

export class DelegationsRequestSuccess implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsRequestSuccess;

    constructor(public payload: MyPageCollection<DelegationsRow>) {}
}

export class DelegationsRequestError implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsRequestError;

    constructor(public payload: string) {}
}

export class DelegationsUpdateFilterProperty implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsUpdateFilterProperty;

    constructor(public payload: { key: string; value: any }) {}
}

export class DelegationsToggleSelected implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsToggleSelected;

    constructor(public payload: { selected: boolean; item: DelegationsRow }) {}
}
export class DelegationsSelectedAll implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsSelectedAll;

    constructor(public payload: { selected: boolean }) {}
}
export class DelegationsAssignmentRequest implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsAssignmentRequest;

    constructor(public payload: UserDetail) {}
}

export class DelegationsAssignmentRequestSuccess implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsAssignmentRequestSuccess;
}

export class DelegationsAssignmentRequestError implements Action {
    readonly type = LUNMyPageActionTypes.DelegationsAssignmentRequestError;

    constructor(public payload: string) {}
}

export type LUNMyPageActions =
    | MyPageCountersRequest
    | MyPageCountersRequestSuccess
    | MyPageCountersRequestError
    | MyPageCountersSetSummarySection
    | MyPageCountersSetSummarySectionCheck
    | MyPendingActionsUpdateFileterProperty
    | SummaryUpdateFilterProperty
    | AllDraftsUpdateFilterProperty
    | MyPendingActionsRequest
    | MyPendingActionsRequestSuccess
    | MyPendingActionsRequestError
    | SummaryRequest
    | SummaryRequestSuccess
    | SummaryRequestError
    | AllDraftsRequest
    | AllDraftsRequestSuccess
    | AllDraftsRequestError
    | DelegationsRequest
    | DelegationsRequestSuccess
    | DelegationsRequestError
    | DelegationsUpdateFilterProperty
    | DelegationsToggleSelected
    | DelegationsAssignmentRequest
    | DelegationsAssignmentRequestSuccess
    | DelegationsAssignmentRequestError
    | DelegationsSelectedAll;
