import { Injectable } from '@angular/core';
import {
    LUN,
    LUNInitialFields,
    LUNComment,
    LUNHistory,
    ShareEmail,
    LUNSubsystemMCStatusDetails,
    SubsystemDetails,
} from '../store/lun/model';
import { LUNRegisterFilter, LUNRegisterDataPagination } from '../store/lun-register/model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Attachment, LUNAttachmentType } from '../store/lun-attachments/model';
import { appConfig } from '../app.config';
import { LUNStatusChangeAction } from '../models/enums';

@Injectable({
    providedIn: 'root',
})
export class LUNService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly lunEndpoint: string = '/lun';
    private readonly attachmentTypes = LUNAttachmentType;

    constructor(private http: HttpClient) {}

    createLUN(newLUN: LUNInitialFields) {
        return this.http.post<number>(`${this.rootUrl}${this.lunEndpoint}`, newLUN);
    }

    generatePdf(lunId: number) {
        return this.http.get<Blob>(`${this.rootUrl}${this.lunEndpoint}/print/${lunId}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    getLUNById(id: number) {
        return this.http.get<LUN>(`${this.rootUrl}${this.lunEndpoint}/${id}`);
    }

    getDescription(lunId: number) {
        return this.http.get(`${this.rootUrl}${this.lunEndpoint}/${lunId}/description`, {
            responseType: 'text',
        });
    }
    getSubsystemMCStatusDetails(subsystems: string[]) {
        return this.http.post<LUNSubsystemMCStatusDetails[]>(`${this.rootUrl}${this.lunEndpoint}/subsystemmcstatus`, {
            subsystems: subsystems,
        });
    }

    getSubsystemDetails(subsystem: string) {
        var formdata = new FormData();
        formdata.append('subsystem', subsystem);
        return this.http.post<SubsystemDetails>(`${this.rootUrl}${this.lunEndpoint}/subsystems/details`, formdata);
    }

    getEstimateDetailsDescription(lunId: number) {
        return this.http.get(`${this.rootUrl}${this.lunEndpoint}/${lunId}/estimatedetails`, {
            responseType: 'text',
        });
    }

    updateLUN(form: LUN, updatedProperties: string[]) {
        return this.http.put<LUN>(`${this.rootUrl}${this.lunEndpoint}`, {
            lun: form,
            updatedProperties,
        });
    }
    getLUNRegisterData(filter: LUNRegisterFilter) {
        return this.http.post<LUNRegisterDataPagination>(`${this.rootUrl}${this.lunEndpoint}/register`, filter);
    }

    shareEmail(shareDto: ShareEmail) {
        return this.http.post(`${this.rootUrl}${this.lunEndpoint}/share`, shareDto);
    }

    generateExcel(filter: LUNRegisterFilter) {
        return this.http.post(`${this.rootUrl}${this.lunEndpoint}/generateexcel`, filter, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Disposition',
            }),
            responseType: 'blob' as 'json',
        });
    }

    uploadLUNAttachmentsRequest(lunId: number, attachments: Attachment[]) {
        const formData = new FormData();
        formData.append('LUNId', lunId.toString());
        attachments.forEach((attachment) => {
            formData.append(this.attachmentTypes[attachment.lunAttachmentType], attachment.file, attachment.file.name);
        });
        return this.http.post(`${this.rootUrl}${this.lunEndpoint}/attachments`, formData);
    }

    getLUNAttachmentsRequest(payload: { id: number; type: LUNAttachmentType }) {
        return this.http.get(`${this.rootUrl}${this.lunEndpoint}/${payload.id}/attachments/${payload.type}`);
    }

    getLUNPreviousRevisionsRequest(id: number) {
        return this.http.get(`${this.rootUrl}${this.lunEndpoint}/${id}/revisions/`);
    }

    downloadLUNAttachmentRequest(attachment: Attachment) {
        return this.http.get<Blob>(
            `${this.rootUrl}${this.lunEndpoint}/attachments?lunId=${attachment.lunId}&attachmentName=${
                attachment.name
            }&attachmentType=${this.attachmentTypes[attachment.lunAttachmentType]}`,
            { responseType: 'arraybuffer' as 'json' }
        );
    }

    downloadAttachment(link: string) {
        return this.http.get<Blob>(`${this.rootUrl}${this.lunEndpoint}/attachment?link=${link}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    deleteLUNAttachmentRequest(attachment: Attachment) {
        return this.http.delete(
            `${this.rootUrl}${this.lunEndpoint}/attachments?lunId=${attachment.lunId}&attachmentName=${attachment.name}`
        );
    }

    removeComment(comment: LUNComment) {
        return this.http.request('delete', `${this.rootUrl}${this.lunEndpoint}/comment`, { body: comment });
    }

    addComment(comment: LUNComment) {
        return this.http.post<LUNComment>(`${this.rootUrl}${this.lunEndpoint}/comment`, comment);
    }

    loadHistory(lunId: number) {
        return this.http.get<LUNHistory[]>(`${this.rootUrl}${this.lunEndpoint}/${lunId}/history`);
    }

    newLUNRevision(lunId: number) {
        return this.http.get(`${this.rootUrl}${this.lunEndpoint}/newrevision/${lunId}`);
    }

    updateLUNStatus(id: number, statusChangeAction: LUNStatusChangeAction) {
        return this.http.patch<LUN>(`${this.rootUrl}${this.lunEndpoint}/${id}`, {
            statusChangeAction,
        });
    }
}
