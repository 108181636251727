<div class="lun-form-workflow-container">
    <div [formGroup]="lunForm">
        <div class="col-workflow-container filter-container">
            <div class="title">review and approve</div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Originator</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="originator" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option
                            *ngFor="let originator of originators"
                            [value]="originator"
                        >
                            {{ originator !== null ? originator.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['originator'].invalid &&
                            lunForm.controls['originator'].errors.required
                        "
                    >
                        Originator is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="originatorCAI"
                        [maxlength]="4"
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['originatorCAI'].invalid &&
                        lunForm.controls['originatorCAI'].errors.required
                    "
                >
                    Originator CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="lunForm.controls['originator'].value && lunForm.controls['originator'].value.position"
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['routeDate'].value &&
                            lunForm.controls['originator'].value &&
                            'Approved Electronically' +
                                ( lunForm.controls['originatorCAI'].value 
                                    ? ' (' + lunForm.controls['originatorCAI'].value + ')'
                                    : lunForm.controls['originator'].value.cai
                                        ? ' (' + lunForm.controls['originator'].value.cai + ')'
                                        : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['routeDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            <div *ngIf="showApprovalCheckboxes">
                <mat-form-field class="doNotPrint container300 margin-right20">
                    <mat-label>Choose required sign off group</mat-label>
                    <mat-select
                        formControlName="requiredSigners"
                        required
                        multiple
                        (selectionChange)="selectionChange($event)"
                    >
                        <mat-option *ngFor="let type of types" [value]="type">
                            {{ type }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="showIsolationAuthorityApprovalCheckboxes">
                <mat-form-field class="doNotPrint container420 margin-right20">
                    <mat-label>Choose required Isolation Authority sign off group</mat-label>
                    <mat-select
                        formControlName="requiredIsolationAuthoritySigners"
                        required
                        multiple
                        (selectionChange)="selectionChange($event)"
                    >
                        <mat-option *ngFor="let type of isolationAuthorityTypes" [value]="type">
                            {{ type }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="electricalSignOffVisible">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Area SC Electrical Discipline Lead</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="areaSCElectricalDisciplineLead" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option
                            *ngFor="let areaSCElectricalDisciplineLead of areaSCElectricalDisciplineLeads"
                            [value]="areaSCElectricalDisciplineLead"
                        >
                            {{ areaSCElectricalDisciplineLead !== null ? areaSCElectricalDisciplineLead.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['areaSCElectricalDisciplineLead'].invalid &&
                            lunForm.controls['areaSCElectricalDisciplineLead'].errors.required
                        "
                    >
                        Area SC Electrical Discipline Lead is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="areaSCElectricalDisciplineLeadCAI"   
                        [maxlength]="4"  
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['areaSCElectricalDisciplineLeadCAI'].invalid &&
                        lunForm.controls['areaSCElectricalDisciplineLeadCAI'].errors.required
                    "
                >
                    Area SC Electrical Discipline Lead CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            (lunForm.controls['areaSCElectricalDisciplineLead'] &&
                                lunForm.controls['areaSCElectricalDisciplineLead'].value &&
                                lunForm.controls['areaSCElectricalDisciplineLead'].value.position) ||
                            ''
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['areaSCElectricalDisciplineLeadReviewDate'].value &&
                            lunForm.controls['areaSCElectricalDisciplineLead'] &&
                            lunForm.controls['areaSCElectricalDisciplineLead'].value &&
                            'Approved Electronically' +
                                (lunForm.controls['areaSCElectricalDisciplineLeadCAI'].value 
                                    ? ' (' + lunForm.controls['areaSCElectricalDisciplineLeadCAI'].value + ')'
                                    : lunForm.controls['areaSCElectricalDisciplineLead'].value.cai
                                        ? ' (' + lunForm.controls['areaSCElectricalDisciplineLead'].value.cai + ')'
                                        : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['areaSCElectricalDisciplineLeadReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            <div *ngIf="smpSignOffVisible">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Area SC SMP Discipline Lead</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="areaSCSMPDisciplineLead" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option
                            *ngFor="let areaSCSMPDisciplineLead of areaSCSMPDisciplineLeads"
                            [value]="areaSCSMPDisciplineLead"
                        >
                            {{ areaSCSMPDisciplineLead !== null ? areaSCSMPDisciplineLead.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['areaSCSMPDisciplineLead'].invalid &&
                            lunForm.controls['areaSCSMPDisciplineLead'].errors.required
                        "
                    >
                        Area SC SMP Discipline Lead is required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="areaSCSMPDisciplineLeadCAI"
                        [maxlength]="4"
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['areaSCSMPDisciplineLeadCAI'].invalid &&
                        lunForm.controls['areaSCSMPDisciplineLeadCAI'].errors.required
                    "
                >
                    Area SC SMP Discipline Lead CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            (lunForm.controls['areaSCSMPDisciplineLead'] &&
                                lunForm.controls['areaSCSMPDisciplineLead'].value &&
                                lunForm.controls['areaSCSMPDisciplineLead'].value.position) ||
                            ''
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['areaSCSMPDisciplineLeadReviewDate'].value &&
                            lunForm.controls['areaSCSMPDisciplineLead'] &&
                            lunForm.controls['areaSCSMPDisciplineLead'].value &&
                            'Approved Electronically' +
                                ( lunForm.controls['areaSCSMPDisciplineLeadCAI'].value 
                                    ? ' (' + lunForm.controls['areaSCSMPDisciplineLeadCAI'].value + ')' 
                                    : lunForm.controls['areaSCSMPDisciplineLead'].value.cai
                                        ? ' (' + lunForm.controls['areaSCSMPDisciplineLead'].value.cai + ')'
                                        : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['areaSCSMPDisciplineLeadReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            <div *ngIf="iAndCSignOffVisible">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Area SC I&C Discipline Lead</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="areaSCIAndCDisciplineLead" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option
                            *ngFor="let areaSCIAndCDisciplineLead of areaSCIAndCDisciplineLeads"
                            [value]="areaSCIAndCDisciplineLead"
                        >
                            {{ areaSCIAndCDisciplineLead !== null ? areaSCIAndCDisciplineLead.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['areaSCIAndCDisciplineLead'].invalid &&
                            lunForm.controls['areaSCIAndCDisciplineLead'].errors.required
                        "
                    >
                        Area SC I&C Discipline Lead is requried.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="areaSCIAndCDisciplineLeadCAI"  
                        [maxlength]="4"
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['areaSCIAndCDisciplineLeadCAI'].invalid &&
                        lunForm.controls['areaSCIAndCDisciplineLeadCAI'].errors.required
                    "
                >
                    Area SC I&C Discipline Lead CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            lunForm.controls['areaSCIAndCDisciplineLead'] &&
                            lunForm.controls['areaSCIAndCDisciplineLead'].value &&
                            lunForm.controls['areaSCIAndCDisciplineLead'].value.position
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['areaSCIAndCDisciplineLeadReviewDate'].value &&
                            lunForm.controls['areaSCIAndCDisciplineLead'] &&
                            lunForm.controls['areaSCIAndCDisciplineLead'].value &&
                            'Approved Electronically' +
                                ( lunForm.controls['areaSCIAndCDisciplineLeadCAI'].value 
                                ? ' (' + lunForm.controls['areaSCIAndCDisciplineLeadCAI'].value + ')'
                                : lunForm.controls['areaSCIAndCDisciplineLead'].value.cai
                                    ? ' (' + lunForm.controls['areaSCIAndCDisciplineLead'].value.cai + ')'
                                    : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['areaSCIAndCDisciplineLeadReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>

            <div *ngIf="isIsolationSMPVisible">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Lead Isolation Authority SMP</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="leadIsolationAuthoritySMP" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option *ngFor="let authority of leadIsolationAuthoritiesSMP" [value]="authority">
                            {{ authority !== null ? authority.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['leadIsolationAuthoritySMP'].invalid &&
                            lunForm.controls['leadIsolationAuthoritySMP'].errors.required
                        "
                    >
                        Lead Isolation Authority SMP is requried.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="leadIsolationAuthoritySMPCAI"
                        [maxlength]="4"
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['leadIsolationAuthoritySMPCAI'].invalid &&
                        lunForm.controls['leadIsolationAuthoritySMPCAI'].errors.required
                    "
                >
                Lead Isolation Authority SMP CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            lunForm.controls['leadIsolationAuthoritySMP'] &&
                            lunForm.controls['leadIsolationAuthoritySMP'].value &&
                            lunForm.controls['leadIsolationAuthoritySMP'].value.position
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['leadIsolationAuthoritySMPReviewDate'].value &&
                            lunForm.controls['leadIsolationAuthoritySMP'] &&
                            lunForm.controls['leadIsolationAuthoritySMP'].value &&
                            'Approved Electronically' +
                                ( lunForm.controls['leadIsolationAuthoritySMPCAI'].value 
                                    ? ' (' + lunForm.controls['leadIsolationAuthoritySMPCAI'].value + ')'
                                    : lunForm.controls['leadIsolationAuthoritySMP'].value.cai
                                        ? ' (' + lunForm.controls['leadIsolationAuthoritySMP'].value.cai + ')'
                                        : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['leadIsolationAuthoritySMPReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
            
            <div *ngIf="isIsolationElectricalVisible">
                <mat-form-field class="container300 margin-right20">
                    <mat-label>Lead Isolation Authority Electrical</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="leadIsolationAuthorityElectrical" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option *ngFor="let authority of leadIsolationAuthoritiesElectrical" [value]="authority">
                            {{ authority !== null ? authority.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['leadIsolationAuthorityElectrical'].invalid &&
                            lunForm.controls['leadIsolationAuthorityElectrical'].errors.required
                        "
                    >
                        Lead Isolation Authority Electrical is requried.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="leadIsolationAuthorityElectricalCAI"
                        [maxlength]="4"
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['leadIsolationAuthorityElectricalCAI'].invalid &&
                        lunForm.controls['leadIsolationAuthorityElectricalCAI'].errors.required
                    "
                >
                Lead Isolation Authority Electrical CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            lunForm.controls['leadIsolationAuthorityElectrical'] &&
                            lunForm.controls['leadIsolationAuthorityElectrical'].value &&
                            lunForm.controls['leadIsolationAuthorityElectrical'].value.position
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['leadIsolationAuthorityElectricalReviewDate'].value &&
                            lunForm.controls['leadIsolationAuthorityElectrical'] &&
                            lunForm.controls['leadIsolationAuthorityElectrical'].value &&
                            'Approved Electronically' +
                                ( lunForm.controls['leadIsolationAuthorityElectricalCAI'].value 
                                    ? ' (' + lunForm.controls['leadIsolationAuthorityElectricalCAI'].value + ')'
                                    : lunForm.controls['leadIsolationAuthorityElectrical'].value.cai
                                        ? ' (' + lunForm.controls['leadIsolationAuthorityElectrical'].value.cai + ')'
                                        : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['leadIsolationAuthorityElectricalReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="container300 margin-right20">
                  <mat-label>SC Discipline Execution Manager</mat-label>
                    <mat-select [compareWith]="compareUsers" formControlName="areaSCManager" (selectionChange)="onSelectedUserChanged($event)" required>
                        <mat-option *ngFor="let areaSCManager of areaSCManagers" [value]="areaSCManager">
                            {{ areaSCManager !== null ? areaSCManager.name : '' }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            lunForm.controls['areaSCManager'].invalid &&
                            lunForm.controls['areaSCManager'].errors.required
                        "
                    >
                        Area SC Manager is requried.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="container100 margin-right20">
                    <input
                        matInput
                        placeholder="CAI"
                        formControlName="areaSCManagerCAI"
                        [maxlength]="4"
                    />
                    <mat-error
                    *ngIf="
                        lunForm.controls['areaSCManagerCAI'].invalid &&
                        lunForm.controls['areaSCManagerCAI'].errors.required
                    "
                >
                    Area SC Manager CAI is required.
                </mat-error>
                </mat-form-field>
                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Position"
                        [value]="
                            lunForm.controls['areaSCManager'] &&
                            lunForm.controls['areaSCManager'].value &&
                            lunForm.controls['areaSCManager'].value.position
                        "
                        readonly
                    />
                </mat-form-field>

                <mat-form-field class="container300 margin-right20">
                    <input
                        matInput
                        placeholder="Signature"
                        [value]="
                            lunForm.controls['areaSCManagerReviewDate'].value &&
                            lunForm.controls['areaSCManager'] &&
                            lunForm.controls['areaSCManager'].value &&
                            'Approved Electronically' +
                                ( lunForm.controls['areaSCManagerCAI'].value 
                                    ? ' (' + lunForm.controls['areaSCManagerCAI'].value + ')'
                                    : lunForm.controls['areaSCManager'].value.cai
                                        ? ' (' + lunForm.controls['areaSCManager'].value.cai + ')'
                                        : '')
                        "
                        readonly
                    />
                </mat-form-field>
                <mat-form-field class="container100">
                    <input
                        matInput
                        placeholder="Date"
                        [value]="lunForm.controls['areaSCManagerReviewDate'].value | date: 'd/MMM/yy'"
                        readonly
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
