<main class="main">
    <form [formGroup]="filterForm">
        <div class="title-container">
            <div class="title">
                Night Shift Register
            </div>
        </div>
        <div class="filter-container">
            <div class="mixedapp-container">
                <mat-form-field class="container620 margin-right20">
                    <mat-label>Number</mat-label>
                    <input matInput formControlName="number" />
                </mat-form-field>

                <!-- <mat-form-field class="container300 margin-right20">
                    <mat-label>Area</mat-label>
                    <mat-select formControlName="areas" multiple>
                        <mat-option *ngFor="let area of areas" [value]="area">{{ area }} </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <div class="container300-mh55 margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        formControlName="originators"
                        [filterForm]="filterForm"
                        [formSetter]="'originators'"
                        [searchFunc]="getOriginators"
                        [isAsync]="true"
                        propertyToShow="name"
                        placeholder="Originator"
                        [setInput]="setOriginatorInput"
                        [ngStyle]="{ display: isIE === true ? 'inline' : null }"
                    >
                    </app-multiple-items-selector>
                </div>
            </div>
            <!-- <mat-form-field class="container300">
                    <mat-label>Status</mat-label>
                    <mat-select [compareWith]="compareStatuses" formControlName="statuses" multiple>
                        <mat-option *ngFor="let s of statuses | enumKeyValuelist" [value]="s.key">
                            {{ s.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
        </div>
        <div class="buttons-container flex">
            <button
                *ngIf="!isReadOnly"
                type="button"
                class="lun-button lun-button-light margin-right20"
                (click)="exportToExcel()"
            >
                export to excel
            </button>
            <button type="button" (click)="resetFilters()" class="lun-button lun-button-light margin-right20">
                reset filters
            </button>
            <button type="button" (click)="search()" class="lun-button lun-button-light">search</button>
        </div>
        <div class="results-container">
            <div class="results-title">
                <div class="title-date">{{ todayDate | date: 'MMM d yyyy' }}</div>
                <div class="table-checkboxes">
                    <mat-checkbox [checked]="showColumnSettings?.originator" (change)="toggleShowSetting('originator')"
                        >Originator</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.raiseDate" (change)="toggleShowSetting('raiseDate')"
                        >Raise Date</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.startDate" (change)="toggleShowSetting('startDate')"
                        >Start Date</mat-checkbox
                    >
                    <mat-checkbox [checked]="showColumnSettings?.endDate" (change)="toggleShowSetting('endDate')"
                        >End Date</mat-checkbox
                    >
                </div>
            </div>
            <div class="scroll">
                <table
                    mat-table
                    matSort
                    [dataSource]="data"
                    [matSortActive]="sortBy"
                    matSortDisableClear
                    [matSortDirection]="direction"
                >
                    <ng-container matColumnDef="number" sticky>
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="sort-header">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnNumbers)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'number',
                                            getNumbers,
                                            'columnNumbers',
                                            'Number',
                                            columnNumbers
                                        )
                                    "
                                    >Number</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'number'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="openDetailsPage(element.id)">{{ element.number }}</a>
                        </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="area">
                        <th app-col-resize mat-header-cell disableClear *matHeaderCellDef class="sort-header">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnAreas)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter('area', getAreas, 'columnAreas', 'Area', columnAreas)
                                    "
                                    >Area</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'area'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.area }}
                        </td>
                    </ng-container> -->
                    <ng-container matColumnDef="originator">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            [ngClass]="{
                                hidecells: !showColumnSettings?.originator
                            }"
                        >
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnOriginators)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'originator',
                                            searchOriginators,
                                            'columnOriginators',
                                            'Originator',
                                            columnOriginators,
                                            true
                                        )
                                    "
                                    >Originator</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'originator'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.originator
                            }"
                        >
                            {{ element.originator }}
                        </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="status">
                        <th mat-header-cell disableClear *matHeaderCellDef class="center">
                            <div class="sort-container center">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnStatuses)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'status',
                                            getStatuses,
                                            'columnStatuses',
                                            'Status',
                                            columnStatuses,
                                            true,
                                            false
                                        )
                                    "
                                    >Status</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'status'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="lun-status" [ngClass]="getClassName(element.status)">
                                {{ getStatusName(element.status) }}
                            </div>
                        </td>
                    </ng-container> -->
                    <ng-container matColumnDef="raisedDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.raiseDate
                            }"
                            (click)="
                                openHeaderDateFilter('raisedDate', 'columnRaisedDate', 'Raise Date', columnRaisedDate)
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnRaisedDate)
                                    }"
                                    >Raise Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'raisedDate'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.raiseDate
                            }"
                        >
                            {{ element.raisedDate | date: 'd/MMM/y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.startDate
                            }"
                            (click)="
                                openHeaderDateFilter('startDate', 'columnStartDate', 'Start Date', columnStartDate)
                            "
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnStartDate)
                                    }"
                                    >Start Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'startDate'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.startDate
                            }"
                        >
                            {{ element.startDate | date: 'd/MMM/y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="endDate">
                        <th
                            mat-header-cell
                            disableClear
                            *matHeaderCellDef
                            class="hover-pointer"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.endDate
                            }"
                            (click)="openHeaderDateFilter('endDate', 'columnEndDate', 'End Date', columnEndDate)"
                        >
                            <div class="sort-container center">
                                <span
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCalendarColumn(columnEndDate)
                                    }"
                                    >End Date</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'endDate'">
                                    <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [ngClass]="{
                                hidecells: !showColumnSettings?.endDate
                            }"
                        >
                            {{ element.endDate | date: 'd/MMM/y' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="latestComment">
                        <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                            <div class="sort-container">
                                <span
                                    class="hover-pointer"
                                    [ngClass]="{
                                        'header-underline': showUnderlineForCheckListColumn(columnComments)
                                    }"
                                    (click)="
                                        openHeaderCheckListFilter(
                                            'latestComment',
                                            getComments,
                                            'columnComments',
                                            'Comment',
                                            columnComments
                                        )
                                    "
                                    >Comment</span
                                >
                                <div class="sort-icon" *ngIf="sortBy === 'latestComment'">
                                    <mat-icon *ngIf="direction == 'asc'">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="direction == 'desc'">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" (click)="openCommentPopup(element, $event)">
                            <div *ngIf="element.latestComment && !isReadOnly; else notShow">
                                <a><span [innerHTML]="element.latestComment"></span></a>
                            </div>
                            <div *ngIf="isReadOnly">
                                <span [innerHTML]="element.latestComment"></span>
                            </div>
                            <ng-template #notShow>
                                <button type="button" *ngIf="!isReadOnly" mat-stroked-button class="add-comment">
                                    Add
                                </button>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="commenthist">
                        <th mat-header-cell disableClear *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <div [ngClass]="{ notactive: !element.latestComment, active: element.latestComment }">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    (click)="openHistoryPopup(element)"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path
                                        d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"
                                    />
                                </svg>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="attachments">
                        <th mat-header-cell disableClear *matHeaderCellDef class="center">Attachments</th>
                        <td mat-cell *matCellDef="let element" class="attachments">
                            <div class="uploaded-attachment-buttons">
                                <button
                                    *ngIf="element.attachments.length == 1"
                                    [title]="element.attachments[0].name"
                                    type="button"
                                    class="download-uploaded-attachment"
                                    (click)="download(element.attachments[0], $event)"
                                ></button>

                                <button
                                    *ngIf="element.attachments.length > 1"
                                    title="multiple attachments"
                                    type="button"
                                    class="multiple-attachments"
                                    (click)="openAttachmentsPopup(element)"
                                ></button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <div class="paginator page-counter">
                <mat-paginator
                    [length]="resultsLength"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 15, 20]"
                    (page)="onPaginatorChange($event)"
                    showFirstLastButtons
                >
                </mat-paginator>
                <input
                    type="number"
                    min="1"
                    [value]="this.paginator.pageIndex + 1"
                    (change)="onPageChange($event.target.value)"
                />
            </div>
        </div>
        <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
    </form>
</main>
