import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import {
    MyPageCountersRequest,
    MyPendingActionsRequest,
    SummaryRequest,
    AllDraftsRequest,
    MyPageCountersSetSummarySectionCheck,
    DelegationsRequest,
} from '../../store/my-page/actions';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { LUNMyPageSections } from '../../store/my-page/models';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lun-mypage',
    templateUrl: './lun-mypage.component.html',
    styleUrls: ['./lun-mypage.component.scss'],
})
export class LUNMypageComponent implements OnInit {
    @ViewChild('pendingHeader', { static: true }) pendingHeaderElementRef: ElementRef<HTMLElement>;
    @ViewChild('summaryHeader', { static: true }) summaryHeaderElementRef: ElementRef<HTMLElement>;

    counters$ = this.store.select((store) => store.lunMyPageState.counters);
    isAdmin = false;
    allDraftsVisible = false;
    public sections = { ...LUNMyPageSections };

    constructor(private store: Store<ApplicationState>, private roleService: RoleService, private router: Router) {
        this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
        this.allDraftsVisible =
            this.isAdmin || this.roleService.isInRole(Constants.applicableGroups.EditUser);
        let isReadonly = this.roleService.isReadOnly();
        if (isReadonly) {
            this.router.navigate(['register']);
        }
    }

    ngOnInit() {
        this.store.dispatch(new MyPageCountersRequest());
        this.store.dispatch(new MyPendingActionsRequest());
        this.store.dispatch(new SummaryRequest());
        this.store.dispatch(new DelegationsRequest());
        if (this.allDraftsVisible) {
            this.store.dispatch(new AllDraftsRequest());
        }
    }

    goToSection(section: LUNMyPageSections) {
        this.store.dispatch(new MyPageCountersSetSummarySectionCheck(section));

        switch (section) {
            case LUNMyPageSections.Pending:
                this.pendingHeaderElementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;

            default:
                this.summaryHeaderElementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    }
}
