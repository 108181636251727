import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toastr: ToastrService) {
        toastr.toastrConfig.enableHtml = true;
    }

    Success(message: string) {
        this.toastr.success(message);
    }

    Error(message: string) {
        this.toastr.error(message);
    }

    Info(message: string) {
        this.toastr.info(message);
    }
}
