import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupService } from '../../../services/lookup.service';
import { distinctUntilChanged, filter, map, take, takeWhile } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { AreaDTO, Contractor } from 'src/app/store/common.model';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { FormService } from 'src/app/services/shared/form.service';
import { ProjectTeamsService } from 'src/app/services/project-teams.service';
import { LUNLookupService } from 'src/app/services/lun-lookup.service';
@Component({
    selector: 'app-night-shift-header',
    templateUrl: './night-shift-header.component.html',
    styleUrls: ['./night-shift-header.component.scss'],
})
export class NightShiftFormHeaderComponent extends BaseComponent implements OnInit {
    @Input() nightShiftForm: UntypedFormGroup;
    areas: AreaDTO[] = [];
    setContractorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    isLocked = false;
    projectTeamNames: string[] = [];
    isLocked$ = this.store.select((state) => state.nightShiftState.isLocked);
    mainForm$ = this.store.select((state) => state.nightShiftState.form);
    fromDate$ = this.store.select((state) => state.nightShiftState.form.proposedDurationFromDate);
    untilDate$ = this.store.select((state) => state.nightShiftState.form.proposedDurationUntilDate);
    contractorsAutocompleteDisplayedColumns = ['contractno', 'contractor'];
    calculatedDurationMonths: number = 0;
    calculatedDurationDays: number = 0;
    minDate: Date;
    maxDate: Date;
    constructor(
        private lookupService: LookupService,
        private nightShiftLookupService: LUNLookupService,
        private store: Store<ApplicationState>,
        private formService: FormService,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();
    }

    ngOnInit() {
        //this.nightShiftForm.controls.area.disable();
        this.projectTeamNames = this.projectTeamsService.getTeams();
        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;
        });
        this.nightShiftLookupService
            .getAreas()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areas) => {
                this.areas = areas;
            });

        this.store
            .select((state) => state.nightShiftState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.setContractorInput.emit(new SetInputEventArgs(false));
                }
            });
        if (!this.nightShiftForm.controls.proposedDurationFromDate.value) {
            this.resetFromDate();
        }
        if (!this.nightShiftForm.controls.proposedDurationUntilDate.value) {
            this.resetUntilDate();
        }
        combineLatest([this.fromDate$, this.untilDate$])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(() => {
                this.calculateDate();
            });
    }

    onContractorsClosed() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    resetFromDate() {
        this.nightShiftForm.controls.proposedDurationFromDate.setValue(new Date());
    }

    resetUntilDate() {
        this.nightShiftForm.controls.proposedDurationUntilDate.setValue(new Date());
    }

    clearFromDate() {
        this.nightShiftForm.controls.proposedDurationFromDate.setValue(null);
        this.minDate = null;
        this.calculatedDurationMonths = 0;
        this.calculatedDurationDays = 0;
    }

    clearUntilDate() {
        this.nightShiftForm.controls.proposedDurationUntilDate.setValue(null);
        this.maxDate = null;
        this.calculatedDurationMonths = 0;
        this.calculatedDurationDays = 0;
    }

    dateFromChange(event: MatDatepickerInputEvent<Date>) {
        var date = moment(event.value).format('YYYY-MM-DD');
        this.nightShiftForm.controls.proposedDurationFromDate.setValue(date);
        this.calculateDate();
    }

    dateUntilChange(event: MatDatepickerInputEvent<Date>) {
        var date = moment(event.value).format('YYYY-MM-DD');
        this.nightShiftForm.controls.proposedDurationUntilDate.setValue(date);
        this.calculateDate();
    }

    calculateDate() {
        setTimeout(() => {
            if (this.nightShiftForm.controls.proposedDurationFromDate.value) {
                this.minDate = new Date(
                    new Date(this.nightShiftForm.controls.proposedDurationFromDate.value).getTime() +
                        1000 * 60 * 60 * 24
                );
            } else {
                this.minDate = null;
            }
            if (this.nightShiftForm.controls.proposedDurationUntilDate.value) {
                this.maxDate = new Date(
                    new Date(this.nightShiftForm.controls.proposedDurationUntilDate.value).getTime() -
                        1000 * 60 * 60 * 24
                );
            } else {
                this.maxDate = null;
            }
            let difference =
                new Date(this.nightShiftForm.controls.proposedDurationUntilDate.value).getTime() -
                new Date(this.nightShiftForm.controls.proposedDurationFromDate.value).getTime();
            if (
                !this.nightShiftForm.controls.proposedDurationFromDate.value ||
                !this.nightShiftForm.controls.proposedDurationUntilDate.value ||
                Number.isNaN(difference)
            ) {
                this.calculatedDurationMonths = 0;
                this.calculatedDurationDays = 0;
                return;
            }
            this.calculatedDurationMonths = Math.floor(difference / (1000 * 3600 * 24 * 30));
            var dayDifference =
                new Date(this.nightShiftForm.controls.proposedDurationUntilDate.value).getDate() -
                new Date(this.nightShiftForm.controls.proposedDurationFromDate.value).getDate();
            this.calculatedDurationDays = dayDifference >= 0 ? dayDifference : 30 - Math.abs(dayDifference);
        }, 0);
    }

    ngAfterViewInit() {
        this.setContractorInput.emit(new SetInputEventArgs(true));
    }

    getContractors = (search = '', take = 10, page = 0) => {
        return this.lookupService
            .searchContractors(search, take, page, this.projectTeamNames)
            .pipe(map((contractors: Contractor[]) => contractors));
    };

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    clearProperty(propertyName: string) {
        if (Array.isArray(this.nightShiftForm.controls[propertyName].value)) {
            this.nightShiftForm.controls[propertyName].setValue([]);
        } else {
            this.nightShiftForm.controls[propertyName].setValue('');
        }
    }
}
