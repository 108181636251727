import { LookupState } from './model';
import { LookupActions, LookupActionTypes } from './actions';

const initialState: LookupState = {
    disciplines: null,
    contractors: null,
};

export function reducer(state: LookupState = initialState, action: LookupActions) {
    switch (action.type) {
        case LookupActionTypes.LookupSave: {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        }
        default: {
            return state;
        }
    }
}
