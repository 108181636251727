    <div class="sub-title">amend settings</div>
    <table
        mat-table
        matSort
        [dataSource]="settings"
        [matSortActive]="sortBy"
        matSortDisableClear
        [matSortDirection]="direction"
    >
        <ng-container matColumnDef="key">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Key</th>
            <td mat-cell *matCellDef="let element">
                <div class="infotooltip">
                    {{ element.key }}
                    <div class="tooltip-icon" matTooltip="{{ element.tooltip }}" matTooltipClass="luntooltip"></div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!element.isInEditMode && !checkIfBoolean(element.value)">{{ element.value }}</div>
                <mat-checkbox
                    disabled="true"
                    *ngIf="!element.isInEditMode && checkIfBoolean(element.value)"
                    [checked]="getBoolean(element.value)"
                ></mat-checkbox>
                <div *ngIf="element.isInEditMode" class="editmode">
                    <mat-form-field *ngIf="!checkIfBoolean(element.value)"
                        ><input
                            matInput
                            [(ngModel)]="element.value"
                            [ngModelOptions]="{ standalone: true }"
                            name="value"
                            autocomplete="off"
                        />
                    </mat-form-field>
                    <mat-checkbox
                        (change)="changeBooleanValue(element)"
                        *ngIf="checkIfBoolean(element.value)"
                        [checked]="getBoolean(element.value)"
                    ></mat-checkbox>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="edit">
            <th mat-header-cell disableClear *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!element.isInEditMode" mat-icon-button (click)="enterEditMode(element)" title="edit">
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="save(element)" title="accept">
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <button *ngIf="element.isInEditMode" mat-icon-button (click)="cancelEdit(element)" title="cancel">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

<app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
