import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    NightShiftActionTypes,
    GetNightShiftRequest,
    GetNightShiftRequestSuccess,
    GetNightShiftRequestError,
    AddNightShiftRequestSuccess,
    AddNightShiftRequest,
    AddNightShiftRequestError,
    NightShiftUpdateRequest,
    NightShiftUpdateRequestError,
    NightShiftUpdateRequestSuccess,
    NightShiftRemoveCommentRequest,
    NightShiftRemoveCommentSuccess,
    NightShiftRemoveCommentError,
    NightShiftAddCommentRequest,
    NightShiftAddCommentSuccess,
    NightShiftAddCommentError,
    NightShiftHistoryRequest,
    NightShiftHistoryRequestSuccess,
    NightShiftHistoryRequestError,
    NightShiftAttachmentsRequest,
    NightShiftAttachmentsSuccess,
    NightShiftAttachmentsError,
    NightShiftUpdateInitialFormWithAttachments,
    NightShiftDownloadAttachmentRequest,
    NightShiftDownloadAttachmentSuccess,
    NightShiftDownloadAttachmentError,
    NightShiftAutosaveError,
    NightShiftAutosaveRequest,
    NightShiftAutosaveSuccess,
    NightShiftUpdateStatusRequest,
    NightShiftUpdateStatusRequestSuccess,
    NightShiftUpdateStatusRequestError,
    NightShiftSaveAndUpdateStatusRequest,
    NightShiftExportToExcelSuccess,
    NightShiftExportToExcelRequest,
    NightShiftExportToExcelError,
} from './actions';
import { mergeMap, catchError, map, tap, withLatestFrom, switchMap, concatMap } from 'rxjs/operators';
import { NightShiftService } from '../../services/night-shift.service';
import { ToastService } from '../../services/shared/toast.service';
import { iif, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Attachment, NightShift, NightShiftAttachmentType } from './model';
import { EmailService } from '../../services/email.service';
import { ApplicationState } from '../model';
import { HttpErrorResponse } from '@angular/common/http';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { LookupService } from 'src/app/services/lookup.service';
import { NightShiftStatusChangeAction, NightShiftStatusType } from 'src/app/models/enums';
import moment from 'moment';

@Injectable()
export class NightShiftEffects {
    constructor(
        private actions$: Actions,
        private nightShiftService: NightShiftService,
        private toastService: ToastService,
        private router: Router,
        private store: Store<ApplicationState>,
        private emailService: EmailService,
        private lookupService: LookupService
    ) {}

    
    getNightShift$ = createEffect(() => this.actions$.pipe(
        ofType<GetNightShiftRequest>(NightShiftActionTypes.GetNightShiftRequest),
        mergeMap(({ payload }) =>
            this.nightShiftService.getNightShiftById(payload).pipe(
                map((nightShiftdata) => {
                    return new GetNightShiftRequestSuccess(nightShiftdata);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while getting NightShift with id:${payload}. Please contact Program Administrator.`
                    );
                    return of(new GetNightShiftRequestError(error));
                })
            )
        )
    ));

    
    addNightShift$ = createEffect(() => this.actions$.pipe(
        ofType<AddNightShiftRequest>(NightShiftActionTypes.AddNightShiftRequest),
        mergeMap(({ payload }) =>
            this.nightShiftService.createNightShift(payload.nightShift).pipe(
                map((nightShiftId) => {
                    return new AddNightShiftRequestSuccess({
                        nightShiftId: +nightShiftId,
                        nightShift: payload.nightShift,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding NightShift. Please contact Program Administrator.'
                    );
                    return of(new AddNightShiftRequestError(error));
                })
            )
        )
    ));

    
    addNightShiftSuccess = createEffect(() => this.actions$.pipe(
        ofType<AddNightShiftRequestSuccess>(NightShiftActionTypes.AddNightShiftRequestSuccess),
        tap(({ payload }) => {
            this.router.navigate(['nightshift/details', payload.nightShiftId]);
        })
    ), { dispatch: false });

    
    autosaveNightShift$ = createEffect(() => this.actions$.pipe(
        ofType<NightShiftAutosaveRequest>(NightShiftActionTypes.NightShiftAutosaveRequest),
        withLatestFrom(
            this.store.select((store) => store.nightShiftState.updatedProperties),
            this.store.select((store) => store.nightShiftState.form)
        ),
        mergeMap(([action, updatedProperties, nightShift]) => {
            this.toastService.Info('NightShift autosave in progress.');
            return this.uploadAttachments(nightShift).pipe(
                concatMap((uploadedAttachments: any) =>
                    this.nightShiftUpdate(nightShift, updatedProperties, uploadedAttachments?.attachments).pipe(
                        map((result) => {
                            return new NightShiftAutosaveSuccess(result.payload);
                        }),
                        catchError(([error]) => {
                            this.toastService.Error('NightShift autosave failed.');
                            return of(new NightShiftAutosaveError(error));
                        })
                    )
                )
            );
        })
    ));

    
    updateNightShift$ = createEffect(() => this.actions$.pipe(
        ofType<NightShiftUpdateRequest>(NightShiftActionTypes.NightShiftUpdateRequest),
        withLatestFrom(
            this.store.select((store) => store.nightShiftState.updatedProperties),
            this.store.select((store) => store.nightShiftState.form)
        ),
        mergeMap(([action, updatedProperties, nightShift]) => {
            return this.uploadAttachments(nightShift).pipe(
                concatMap((uploadedAttachments: any) =>
                    this.nightShiftUpdate(nightShift, updatedProperties, uploadedAttachments?.attachments).pipe(
                        catchError(([error]) => {
                            this.toastService.Error('NightShift update failed.');
                            return of(new NightShiftUpdateRequestError({ error: error[0], unlockForm: error[1] }));
                        })
                    )
                )
            );
        })
    ));

    
    removeNightShiftComment$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftRemoveCommentRequest),
        mergeMap((action: NightShiftRemoveCommentRequest) =>
            this.nightShiftService.removeComment(action.payload.comment).pipe(
                map(() => new NightShiftRemoveCommentSuccess(action.payload)),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 403) {
                        this.toastService.Error('A comment can be deleted only by the author.');
                    } else {
                        this.toastService.Error(
                            'Error occurred while removing NightShift comment. Please contact Program Administrator.'
                        );
                    }
                    return of(new NightShiftRemoveCommentError(error));
                })
            )
        )
    ));

    
    addNightShiftComment$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftAddCommentRequest),
        mergeMap((action: NightShiftAddCommentRequest) =>
            this.nightShiftService.addComment(action.payload.comment).pipe(
                map((comment) => new NightShiftAddCommentSuccess({ comment: comment })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding NightShift comment. Please contact Program Administrator.'
                    );
                    return of(new NightShiftAddCommentError(error));
                })
            )
        )
    ));

    
    loadHistory$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftHistoryRequest),
        mergeMap((action: NightShiftHistoryRequest) =>
            this.nightShiftService.loadHistory(action.payload).pipe(
                map((history) => {
                    return new NightShiftHistoryRequestSuccess(history);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading NightShift history. Please contact Program Administrator.'
                    );
                    return of(new NightShiftHistoryRequestError(error));
                })
            )
        )
    ));

    
    getAttachments$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftAttachmentsRequest),
        mergeMap((action: NightShiftAttachmentsRequest) =>
            this.nightShiftService.getNightShiftAttachmentsRequest(action.payload).pipe(
                switchMap((uploadedAttachments: any) => {
                    return [
                        new NightShiftUpdateInitialFormWithAttachments({
                            attachments: uploadedAttachments,
                            type: action.payload.type,
                        }),
                        new NightShiftAttachmentsSuccess({
                            attachments: uploadedAttachments,
                            type: action.payload.type,
                        }),
                    ];
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while displaying attachment. Please contact Program Administrator.'
                    );
                    return of(new NightShiftAttachmentsError(error));
                })
            )
        )
    ));

    
    downloadNightShiftAttachmentRequest = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftDownloadAttachmentRequest),
        mergeMap((action: NightShiftDownloadAttachmentRequest) =>
            this.nightShiftService.downloadNightShiftAttachmentRequest(action.payload.attachment).pipe(
                map(
                    (attachmentBinaries) =>
                        new NightShiftDownloadAttachmentSuccess({
                            content: attachmentBinaries,
                            fileName: action.payload.attachment.name,
                            type: action.payload.type,
                        })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while downloading attachment ${action.payload.attachment.name}. Please contact Program Administrator.`
                    );
                    return of(
                        new NightShiftDownloadAttachmentError({
                            attachment: action.payload.attachment,
                            type: action.payload.type,
                        })
                    );
                })
            )
        )
    ));

    
    saveAndUpdateStatus$ = createEffect(() => this.actions$.pipe(
        ofType<NightShiftSaveAndUpdateStatusRequest>(NightShiftActionTypes.NightShiftSaveAndUpdateStatusRequest),
        withLatestFrom(
            this.store.select((store) => store.nightShiftState.updatedProperties),
            this.store.select((store) => store.nightShiftState.form)
        ),
        mergeMap(([action, updatedProperties, nightShift]) => {
            return this.uploadAttachments(nightShift).pipe(
                concatMap((uploadedAttachments: any) =>
                    this.nightShiftUpdate(nightShift, updatedProperties, uploadedAttachments?.attachments).pipe(
                        ofType<NightShiftUpdateRequestSuccess>(NightShiftActionTypes.NightShiftUpdateRequestSuccess),
                        switchMap((successAction: NightShiftUpdateRequestSuccess) => {
                            return [
                                successAction,
                                new NightShiftUpdateStatusRequest({
                                    id: action.payload.form.id,
                                    changeAction: action.payload.changeAction,
                                }),
                            ];
                        }),
                        catchError(([error]) => {
                            return of(new NightShiftUpdateRequestError({ error: error[0], unlockForm: error[1] }));
                        })
                    )
                )
            );
        })
    ));

    
    updateStatus$ = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftUpdateStatusRequest),
        mergeMap((action: NightShiftUpdateStatusRequest) => {
            return this.nightShiftService.updateNightShiftStatus(action.payload.id, action.payload.changeAction).pipe(
                map((nightShift) => {
                    this.lookupService.canSendAutomatedEmails().subscribe((canSend) => {
                        var signers = [];
                        // if (nightShift.areaSCElectricalDisciplineLead !== null) {
                        //     signers.push(nightShift.areaSCElectricalDisciplineLead.email);
                        // }
                        // if (nightShift.areaSCSMPDisciplineLead !== null) {
                        //     signers.push(nightShift.areaSCSMPDisciplineLead.email);
                        // }
                        // if (nightShift.areaSCIAndCDisciplineLead !== null) {
                        //     signers.push(nightShift.areaSCIAndCDisciplineLead.email);
                        // }

                        // if (
                        //     !canSend &&
                        //     nightShift.status === NightShiftStatusType.Submitted &&
                        //     nightShift.discipline === 'Electrical' &&
                        //     action.payload.changeAction === NightShiftStatusChangeAction.Submit
                        // ) {
                        //     this.sendEmail(nightShift, [nightShift.areaSCElectricalDisciplineLead.email]);
                        // } else if (
                        //     !canSend &&
                        //     nightShift.status === NightShiftStatusType.Submitted &&
                        //     (nightShift.discipline === 'Instrument' || nightShift.discipline === 'Telecom') &&
                        //     action.payload.changeAction === NightShiftStatusChangeAction.Submit
                        // ) {
                        //     this.sendEmail(nightShift, [nightShift.areaSCIAndCDisciplineLead.email]);
                        // } else if (
                        //     !canSend &&
                        //     nightShift.status === NightShiftStatusType.Submitted &&
                        //     (nightShift.discipline === 'Mechanical' || nightShift.discipline === 'Piping') &&
                        //     action.payload.changeAction === NightShiftStatusChangeAction.Submit
                        // ) {
                        //     this.sendEmail(nightShift, [nightShift.areaSCSMPDisciplineLead.email]);
                        // } else if (
                        //     !canSend &&
                        //     nightShift.status === NightShiftStatusType.Submitted &&
                        //     nightShift.discipline === 'All Disciplines' &&
                        //     action.payload.changeAction === NightShiftStatusChangeAction.Submit
                        // ) {
                        //     this.sendEmail(nightShift, signers);
                        // } else if (!canSend && nightShift.status === NightShiftStatusType.Reviewed) {
                        //     this.sendEmail(nightShift, [nightShift.areaSCManager.email]);
                        // } else if (
                        //     !canSend &&
                        //     nightShift.status === NightShiftStatusType.Approved &&
                        //     action.payload.changeAction === NightShiftStatusChangeAction.AreaSCManagerApprove
                        // ) {
                        //     signers.push(nightShift.originator.email);
                        //     this.sendEmail(nightShift, signers, false);
                        // }
                    });
                    // this.toastService.Success(`NightShift was successfully updated to status: ${NightShiftStatusType[nightShift.status]}`);
                    return new NightShiftUpdateStatusRequestSuccess(nightShift);
                }),
                catchError((response: HttpErrorResponse) => {
                    if (response.status === 400) {
                        this.toastService.Error(response.error);
                        return of(new NightShiftUpdateStatusRequestError({ unlockForm: true }));
                    } else {
                        this.toastService.Error(
                            'Error occurred while updating NightShift status. Please save NightShift manually, refresh page and retry updating status.'
                        );
                        return of(new NightShiftUpdateStatusRequestError({ unlockForm: false }));
                    }
                })
            );
        })
    ));

    
    downloadNightShiftAttachmentRequestSuccess = createEffect(() => this.actions$.pipe(
        ofType<NightShiftDownloadAttachmentSuccess>(NightShiftActionTypes.NightShiftDownloadAttachmentSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });

            saveAs(blob, action.payload.fileName);
        })
    ), { dispatch: false });

    
    exportToExcelNightShift$ = createEffect(() => this.actions$.pipe(
        ofType<NightShiftExportToExcelRequest>(NightShiftActionTypes.NightShiftExportToExcelRequest),
        mergeMap((action) => {
            return this.nightShiftService.generatePdf(action.payload).pipe(
                map((excelData) => new NightShiftExportToExcelSuccess(excelData)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while exporting data to Excel. Please contact Program Administrator.'
                    );
                    return of(new NightShiftExportToExcelError(error));
                })
            );
        })
    ));

    
    saveExcelData = createEffect(() => this.actions$.pipe(
        ofType(NightShiftActionTypes.NightShiftExportToExcelSuccess),
        map((action: NightShiftExportToExcelSuccess) => {
            this.toastService.Success('Data successfully exported to Excel.');

            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });

            saveAs(blob, `NightShift_${moment().format('YYYYMMDD_HHmmss')}` + '.xlsx');
        })
    ), { dispatch: false });

    private uploadAttachments(nightShift: NightShift) {
        return iif(
            () => nightShift.SupportingDocumentAttachments.some((attachment) => !!attachment.file),
            this.nightShiftService.uploadNightShiftAttachmentsRequest(
                nightShift.id,
                _.concat(nightShift.SupportingDocumentAttachments.filter((attachment) => !!attachment.file))
            ),
            of(null)
        );
    }

    private nightShiftUpdate(form: NightShift, updatedProperties: string[], uploadedAttachments: Attachment[] = []) {
        return this.nightShiftService.updateNightShift(form, updatedProperties).pipe(
            mergeMap((result: NightShift) => {
                const payload = {
                    ...result,
                    SupportingDocumentAttachments: [
                        ...form.SupportingDocumentAttachments.map((supportingAttachment) => {
                            const attachment = uploadedAttachments.find(
                                (uploadedAttachment) =>
                                    uploadedAttachment.attachmentType === NightShiftAttachmentType.SupportingDocument &&
                                    uploadedAttachment.name === supportingAttachment.name
                            );
                            return {
                                ...supportingAttachment,
                                link: attachment ? attachment.link : supportingAttachment.link,
                                nightShiftId: attachment ? attachment.nightShiftId : supportingAttachment.nightShiftId,
                                file: null,
                            };
                        }),
                    ],
                };
                payload.OldSupportingDocumentAttachments = payload.SupportingDocumentAttachments;
                this.toastService.Success('NightShift successfully saved.');
                return of(new NightShiftUpdateRequestSuccess(payload));
            }),
            catchError((response: HttpErrorResponse) => {
                if (response.status === 400) {
                    this.toastService.Error(response.error);
                } else {
                    this.toastService.Error(
                        'Error occurred while updating NightShift. Please save NightShift manually and retry updating fields.'
                    );
                }
                return throwError([response.error, response.status === 400]);
            })
        );
    }

    private sendEmail(nightShift: NightShift, recipients: string[], isSubmittedForApproval = true) {
        const originatorMail = this.isNotNullAndNotUndefined(nightShift.originator) ? nightShift.originator.email : '';
        const recipient = recipients.filter((r) => !!r).join(';');
        var options = null;
        if (isSubmittedForApproval) {
            options = {
                cc: originatorMail,
                subject: nightShift.area,
                body:
                    'The following NightShift is submitted for approval, please follow the link and to review and provide your approval \n' +
                    window.location.href,
            };
        } else {
            options = {
                subject: nightShift.area,
                body:
                    'The following NightShift is completed, please follow the link to review\n' + window.location.href,
            };
        }

        const mailto = document.createElement('a');
        document.body.appendChild(mailto);
        mailto.setAttribute('style', 'display: none');
        const url = this.emailService.getMailToURI(recipient, options);
        mailto.href = url;
        mailto.click();
        mailto.remove();
    }

    private isNotNullAndNotUndefined(item: any): boolean {
        return item !== null && item !== undefined;
    }
}
