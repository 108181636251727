<h2 mat-dialog-title class="title">{{ modalTitle }}</h2>
<mat-dialog-content>{{ modalText }}</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="isDiscardChanges">
        <button class="lun-button mcp-button-white" [mat-dialog-close]="true" [disabled]="isYesDisabled">Yes</button>
        <button class="lun-button lun-button-light margin-left20" [mat-dialog-close]="false">No</button>
    </ng-container>
    <ng-container *ngIf="!isDiscardChanges">
        <button class="lun-button lun-button-white" [mat-dialog-close]="false">No</button>
        <button class="lun-button lun-button-light margin-left20" [mat-dialog-close]="true" [disabled]="isYesDisabled">
            Yes
        </button>
    </ng-container>
</mat-dialog-actions>
