<main class="main">
    <div class="mypage-container">
        <div class="flex title-container">
            <div class="title">
                my page
            </div>
        </div>
        <div class="counter-container">
            <div class="pending">
                <div class="pending-count pointer" (click)="goToSection(sections.Pending)">
                    {{ (counters$ | async).pendingActionCount }}
                </div>
                <div class="separate">|</div>
                <div (click)="goToSection(sections.Pending)" class="description pointer">
                    pending my action:
                    <div>
                        {{ (counters$ | async).pendingActionCount }}
                    </div>
                </div>
            </div>
            <div class="total">
                <div class="total-count pointer" (click)="goToSection(sections.Total)">
                    {{ (counters$ | async).totalCount }}
                </div>
                <div class="separate">|</div>
                <div class="flex flex-column">
                    <div (click)="goToSection(sections.Total)" class="description pointer">
                        total:
                        <div>
                            {{ (counters$ | async).totalCount }}
                        </div>
                    </div>
                    <div class="flex">
                        <div (click)="goToSection(sections.MyDraft)" class="description pointer">
                            draft:
                            <div>
                                {{ (counters$ | async).draftCount }}
                            </div>
                        </div>
                        <div class="description-separate">|</div>
                        <div (click)="goToSection(sections.Submitted)" class="description pointer">
                            submitted:
                            <div>
                                {{ (counters$ | async).submittedCount }}
                            </div>
                        </div>
                        <div class="description-separate">|</div>
                        <div (click)="goToSection(sections.Reviewed)" class="description pointer">
                            endorsed:
                            <div>
                                {{ (counters$ | async).reviewedCount }}
                            </div>
                        </div>
                        <div class="description-separate">|</div>
                        <div (click)="goToSection(sections.Approved)" class="description pointer">
                            approved:
                            <div>
                                {{ (counters$ | async).approvedCount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div #pendingHeader class="pending-actions-section-container">
        <app-lun-my-pending-actions></app-lun-my-pending-actions>
    </div>
    <div #summaryHeader class="summary-section-container">
        <app-lun-summary></app-lun-summary>
    </div>
    <div *ngIf="allDraftsVisible" class="all-drafts-section-container">
        <app-lun-all-drafts></app-lun-all-drafts>
    </div>
    <div class="delegations-section-container">
        <app-lun-delegations></app-lun-delegations>
    </div>
</main>
