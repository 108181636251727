import { Action } from '@ngrx/store';
import {
    NightShift,
    NightShiftInitialFields,
    NightShiftComment,
    NightShiftHistory,
    NightShiftAttachments,
    NightShiftAttachmentType,
    Attachment,
} from './model';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionUsers } from '../action-users/model';
import { NightShiftStatusChangeAction } from 'src/app/models/enums';

export enum NightShiftActionTypes {
    AddNightShiftRequest = '[NightShift] Add NightShift Request',
    AddNightShiftRequestSuccess = '[NightShift] Add NightShift Request Success',
    AddNightShiftRequestError = '[NightShift] Add NightShift Request Error',

    GetNightShiftRequest = '[NightShift] Get NightShift Request',
    GetNightShiftRequestSuccess = '[NightShift] Get NightShift Request Success',
    GetNightShiftRequestError = '[NightShift] Get NightShift Request Error',

    NightShiftUpdateRequest = '[NightShift] NightShift Update Request',
    NightShiftUpdateRequestSuccess = '[NightShift] NightShift Update Request Success',
    NightShiftUpdateRequestError = '[NightShift] NightShift Update Request Error',

    NightShiftSetButtonStatus = '[NightShift] NightShift Set Button Status',
    NightShiftUpdateProperty = '[NightShift] NightShift Update Property',
    NightShiftClear = '[NightShift] NightShift Clear',

    NightShiftRemoveCommentRequest = '[NightShift] Remove Comment Request',
    NightShiftRemoveCommentSuccess = '[NightShift] Remove Comment Success',
    NightShiftRemoveCommentError = '[NightShift] Remove Comment Error',

    NightShiftAddCommentRequest = '[NightShift] Add Comment Request',
    NightShiftAddCommentSuccess = '[NightShift] Add Comment Success',
    NightShiftAddCommentError = '[NightShift] Add Comment Error',

    NightShiftHistoryRequest = '[NightShift] History Request',
    NightShiftHistoryRequestSuccess = '[NightShift] History Success',
    NightShiftHistoryRequestError = '[NightShift] History Error',

    NightShiftAutosaveRequest = '[NightShift] Autosave Request',
    NightShiftAutosaveSuccess = '[NightShift] Autosave Success',
    NightShiftAutosaveError = '[NightShift] Autosave Error',

    LockNightShiftFormIfNoChanges = '[NightShift] Lock NightShift if no changes made',
    LockNightShiftForm = '[NightShift] Lock NightShift',
    UnLockNightShiftForm = '[NightShift] UnLock NightShift',

    NightShiftAttachmentsRequest = '[NightShift] Attachments Request',
    NightShiftAttachmentsSuccess = '[NightShift] Attachments Success',
    NightShiftAttachmentsError = '[NightShift] Attachments Error',
    NightShiftUpdateInitialFormWithAttachments = '[NightShift] Update Initial Form With Attachments',
    NightShiftUpdateAttachmentLinks = '[NightShift] Update Attachment Links',
    NightShiftDownloadAttachmentRequest = '[NightShift] Download Attachment Request',
    NightShiftDownloadAttachmentSuccess = '[NightShift] Download Attachment Success',
    NightShiftDownloadAttachmentError = '[NightShift] Download Attachment Error',

    NightShiftUpdateStatusRequest = '[NightShift] Update Status Request',
    NightShiftUpdateStatusRequestSuccess = '[NightShift] Update Status Request Success',
    NightShiftUpdateStatusRequestError = '[NightShift] Update Status Request Error',

    NightShiftSaveAndUpdateStatusRequest = '[NightShift] Save And Update Status Request',

    NightShiftExportToExcelRequest = '[NightShift] Export to Excel Request',
    NightShiftExportToExcelSuccess = '[NightShift] Export to Excel Success',
    NightShiftExportToExcelError = '[NightShift] Export to Excel Error',
}

export class NightShiftExportToExcelRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftExportToExcelRequest;

    constructor(public payload: number) {}
}

export class NightShiftExportToExcelSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class NightShiftExportToExcelError implements Action {
    readonly type = NightShiftActionTypes.NightShiftExportToExcelError;

    constructor(public payload: string) {}
}

export class NightShiftUpdateStatusRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateStatusRequest;

    constructor(public payload: { id: number; changeAction: NightShiftStatusChangeAction }) {}
}
export class NightShiftUpdateStatusRequestSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateStatusRequestSuccess;

    constructor(public payload: NightShift) {}
}

export class NightShiftUpdateStatusRequestError implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateStatusRequestError;

    constructor(public payload: { unlockForm: boolean }) {}
}

export class NightShiftSaveAndUpdateStatusRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftSaveAndUpdateStatusRequest;

    constructor(public payload: { form: NightShift; changeAction: NightShiftStatusChangeAction }) {}
}

export class AddNightShiftRequest implements Action {
    readonly type = NightShiftActionTypes.AddNightShiftRequest;

    constructor(public payload: { nightShift: NightShiftInitialFields }) {}
}
export class AddNightShiftRequestSuccess implements Action {
    readonly type = NightShiftActionTypes.AddNightShiftRequestSuccess;

    constructor(public payload: { nightShiftId: number; nightShift?: NightShiftInitialFields }) {}
}
export class AddNightShiftRequestError implements Action {
    readonly type = NightShiftActionTypes.AddNightShiftRequestError;
    constructor(public payload: string) {}
}

export class GetNightShiftRequest implements Action {
    readonly type = NightShiftActionTypes.GetNightShiftRequest;

    constructor(public payload: number) {}
}
export class GetNightShiftRequestSuccess implements Action {
    readonly type = NightShiftActionTypes.GetNightShiftRequestSuccess;

    constructor(public payload: NightShift) {}
}
export class GetNightShiftRequestError implements Action {
    readonly type = NightShiftActionTypes.GetNightShiftRequestError;
    constructor(public payload: string) {}
}

export class NightShiftUpdateRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateRequest;

    constructor(public payload: NightShift) {}
}
export class NightShiftUpdateRequestSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateRequestSuccess;

    constructor(public payload: NightShift) {}
}
export class NightShiftUpdateRequestError implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateRequestError;
    constructor(public payload: { error: string; unlockForm: boolean }) {}
}

export class NightShiftUpdateProperty implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateProperty;
    constructor(public payload: { key: string; value: any; updatedProperties: string[] }) {}
}

export class NightShiftClear implements Action {
    readonly type = NightShiftActionTypes.NightShiftClear;
}

export class NightShiftRemoveCommentRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftRemoveCommentRequest;

    constructor(public payload: { comment: NightShiftComment }) {}
}

export class NightShiftRemoveCommentSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftRemoveCommentSuccess;

    constructor(public payload: { comment: NightShiftComment }) {}
}
export class NightShiftRemoveCommentError implements Action {
    readonly type = NightShiftActionTypes.NightShiftRemoveCommentError;

    constructor(public payload: HttpErrorResponse) {}
}

export class NightShiftAddCommentRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftAddCommentRequest;

    constructor(public payload: { comment: NightShiftComment }) {}
}

export class NightShiftAddCommentSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftAddCommentSuccess;

    constructor(public payload: { comment: NightShiftComment }) {}
}
export class NightShiftAddCommentError implements Action {
    readonly type = NightShiftActionTypes.NightShiftAddCommentError;

    constructor(public payload: string) {}
}

export class NightShiftHistoryRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftHistoryRequest;

    constructor(public payload: number) {}
}
export class NightShiftHistoryRequestSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftHistoryRequestSuccess;

    constructor(public payload: NightShiftHistory[]) {}
}
export class NightShiftHistoryRequestError implements Action {
    readonly type = NightShiftActionTypes.NightShiftHistoryRequestError;
    constructor(public payload: string) {}
}

export class LockNightShiftFormIfNoChanges implements Action {
    readonly type = NightShiftActionTypes.LockNightShiftFormIfNoChanges;

    constructor() {}
}
export class LockNightShiftForm implements Action {
    readonly type = NightShiftActionTypes.LockNightShiftForm;

    constructor() {}
}
export class UnLockNightShiftForm implements Action {
    readonly type = NightShiftActionTypes.UnLockNightShiftForm;

    constructor() {}
}

export class NightShiftAttachmentsRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftAttachmentsRequest;

    constructor(public payload: { id: number; type: NightShiftAttachmentType }) {}
}

export class NightShiftAttachmentsSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftAttachmentsSuccess;

    constructor(public payload: { attachments: NightShiftAttachments; type: NightShiftAttachmentType }) {}
}

export class NightShiftAttachmentsError implements Action {
    readonly type = NightShiftActionTypes.NightShiftAttachmentsError;

    constructor(public payload: string) {}
}

export class NightShiftUpdateInitialFormWithAttachments implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateInitialFormWithAttachments;

    constructor(public payload: { attachments: NightShiftAttachments; type: NightShiftAttachmentType }) {}
}

export class NightShiftUpdateAttachmentLinks implements Action {
    readonly type = NightShiftActionTypes.NightShiftUpdateAttachmentLinks;
}

export class NightShiftDownloadAttachmentRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftDownloadAttachmentRequest;

    constructor(public payload: { attachment: Attachment; type: NightShiftAttachmentType }) {}
}

export class NightShiftDownloadAttachmentSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftDownloadAttachmentSuccess;

    constructor(public payload: { content: Blob; fileName: string; type: NightShiftAttachmentType }) {}
}

export class NightShiftDownloadAttachmentError implements Action {
    readonly type = NightShiftActionTypes.NightShiftDownloadAttachmentError;

    constructor(public payload: { attachment: Attachment; type: NightShiftAttachmentType }) {}
}

export class NightShiftAutosaveRequest implements Action {
    readonly type = NightShiftActionTypes.NightShiftAutosaveRequest;

    constructor(public payload: NightShift) {}
}

export class NightShiftAutosaveSuccess implements Action {
    readonly type = NightShiftActionTypes.NightShiftAutosaveSuccess;

    constructor(public payload: NightShift) {}
}

export class NightShiftAutosaveError implements Action {
    readonly type = NightShiftActionTypes.NightShiftAutosaveError;

    constructor(public payload: string) {}
}

export type NightShiftActions =
    | AddNightShiftRequest
    | AddNightShiftRequestSuccess
    | AddNightShiftRequestError
    | GetNightShiftRequest
    | GetNightShiftRequestSuccess
    | GetNightShiftRequestError
    | NightShiftUpdateRequest
    | NightShiftUpdateRequestSuccess
    | NightShiftUpdateRequestError
    | NightShiftUpdateProperty
    | NightShiftClear
    | NightShiftRemoveCommentRequest
    | NightShiftRemoveCommentSuccess
    | NightShiftRemoveCommentError
    | NightShiftAddCommentRequest
    | NightShiftAddCommentSuccess
    | NightShiftAddCommentError
    | NightShiftHistoryRequest
    | NightShiftHistoryRequestError
    | NightShiftHistoryRequestSuccess
    | LockNightShiftFormIfNoChanges
    | LockNightShiftForm
    | UnLockNightShiftForm
    | NightShiftAttachmentsRequest
    | NightShiftAttachmentsSuccess
    | NightShiftAttachmentsError
    | NightShiftUpdateInitialFormWithAttachments
    | NightShiftUpdateAttachmentLinks
    | NightShiftDownloadAttachmentRequest
    | NightShiftDownloadAttachmentSuccess
    | NightShiftDownloadAttachmentError
    | NightShiftAutosaveRequest
    | NightShiftAutosaveSuccess
    | NightShiftAutosaveError
    | NightShiftUpdateStatusRequest
    | NightShiftUpdateStatusRequestSuccess
    | NightShiftUpdateStatusRequestError
    | NightShiftSaveAndUpdateStatusRequest
    | NightShiftExportToExcelRequest
    | NightShiftExportToExcelSuccess
    | NightShiftExportToExcelError;
