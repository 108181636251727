<div class="mypage-subcontainer">
    <div class="sub-title">
        Delegations
        <div class="filter-container">
            <mat-form-field class="delegation-type">
                <mat-label>Delegation Type</mat-label>
                <mat-select
                    [compareWith]="compareDelegationTypes"
                    [value]="type"
                    (selectionChange)="onDelegationClosed($event)"
                >
                    <mat-option *ngFor="let c of types" [value]="c.value">
                        {{ c.key }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button
                class="lun-button lun-button-light"
                [disabled]="(numberOfItemsSelected$ | async) === 0"
                (click)="openDelegationsPopup()"
            >
                Delegate
            </button>
        </div>
    </div>
    <div class="scroll">
        <table
            mat-table
            matSort
            [dataSource]="data.items"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container sticky matColumnDef="selected">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        [checked]="isSelectAll()"
                        (change)="selectAll($event)"
                        class="checkbox-margin-left-12"
                    ></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox
                        [checked]="isSelected(element)"
                        (change)="selectChange($event, element)"
                        class="checkbox-margin-left-12"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container sticky matColumnDef="lunNumber">
                <th mat-header-cell disableClear *matHeaderCellDef app-col-resize>
                    <div class="sort-container center">
                        <span
                            class="hover-pointer"
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnNumber)
                            }"
                            (click)="
                                openHeaderCheckListFilter(
                                    'lunNumber',
                                    getTableFilterValues,
                                    'columnNumber',
                                    'Number',
                                    columnNumber,
                                    false,
                                    true,
                                    false
                                )
                            "
                            >Number</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'lunNumber'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <a (click)="openDetailsPage(element.id)">{{ element.lunNumber }}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="revision">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'revision',
                            getTableFilterValues,
                            'columnRevision',
                            'Revision',
                            columnRevision,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnRevision)
                            }"
                            >Rev</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'revision'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.revision }}
                </td>
            </ng-container>
            <ng-container matColumnDef="discipline">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'discipline',
                            getTableFilterValues,
                            'columnDiscipline',
                            'Discipline',
                            columnDiscipline,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnDiscipline)
                            }"
                            >Discipline</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'discipline'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.discipline }}</td>
            </ng-container>
            <ng-container matColumnDef="subject">
                <th mat-header-cell app-col-resize disableClear *matHeaderCellDef>
                    <div class="sort-container center">
                        <span
                            class="hover-pointer center"
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnSubject)
                            }"
                            (click)="
                                openHeaderCheckListFilter(
                                    'subject',
                                    getTableFilterValues,
                                    'columnSubject',
                                    'Subject',
                                    columnSubject,
                                    true,
                                    true,
                                    false
                                )
                            "
                            >Subject</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'subject'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.subject }}
                </td>
            </ng-container>
            <ng-container matColumnDef="originator">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'originator',
                            getTableFilterValues,
                            'columnOriginator',
                            'Originator',
                            columnOriginator,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnOriginator)
                            }"
                            >Originator</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'originator'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.originator }}
                </td>
            </ng-container>
            <ng-container matColumnDef="approver">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'approver',
                            getTableFilterValues,
                            'columnapprover',
                            'approver',
                            columnapprover,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnapprover)
                            }"
                            >Company Representative</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'approver'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.approver }}
                </td>
            </ng-container>
            <ng-container matColumnDef="costEngineer">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'costEngineer',
                            getTableFilterValues,
                            'columncostEngineer',
                            'costEngineer',
                            columncostEngineer,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columncostEngineer)
                            }"
                            >Contractor Representative</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'costEngineer'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.costEngineer }}
                </td>
            </ng-container>
            <ng-container matColumnDef="contractsEngineer">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'contractsEngineer',
                            getTableFilterValues,
                            'columncontractsEngineer',
                            'contractsEngineer',
                            columncontractsEngineer,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columncontractsEngineer)
                            }"
                            >Contracts Team Reviewer</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'contractsEngineer'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.contractsEngineer }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'status',
                            getTableFilterValues,
                            'columnStatus',
                            'Status',
                            columnStatus,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnStatus)
                            }"
                            >Status</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'status'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="align-status lun-status" [ngClass]="getClassName(element.status)">
                        {{ getStatusName(element.status) }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="raisedDate">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer"
                    (click)="openHeaderDateFilter('raisedDate', 'columnRaisedDate', 'Raise Date', columnRaisedDate)"
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCalendarColumn(columnRaisedDate)
                            }"
                            >Raise Date</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'raisedDate'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">{{ element.raisedDate | date: 'd/MMM/yy' }}</td>
            </ng-container>
            <ng-container matColumnDef="contractNo">
                <th
                    mat-header-cell
                    disableClear
                    *matHeaderCellDef
                    class="hover-pointer center"
                    (click)="
                        openHeaderCheckListFilter(
                            'contractNo',
                            getTableFilterValues,
                            'columnContractNo',
                            'Contractor',
                            columnContractNo,
                            true,
                            true,
                            false
                        )
                    "
                >
                    <div class="sort-container center">
                        <span
                            [ngClass]="{
                                'header-underline': showUnderlineForCheckListColumn(columnContractNo)
                            }"
                            >Contractor</span
                        >
                        <div class="sort-icon" *ngIf="sortBy === 'contractNo'">
                            <mat-icon *ngIf="direction === 'asc'">keyboard_arrow_up</mat-icon>
                            <mat-icon *ngIf="direction === 'desc'">keyboard_arrow_down</mat-icon>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.contractNo || 'K-500-203-ON' }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
        </table>
    </div>
    <div class="paginator page-counter">
        <mat-paginator
            [length]="data.totalCount"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 15, 20]"
            (page)="onPaginatorChange($event)"
            showFirstLastButtons
        >
        </mat-paginator>
        <input
            type="number"
            min="1"
            [value]="this.paginator.pageIndex + 1"
            (change)="onPageChange($event.target.value)"
        />
    </div>
    <div *ngIf="isLoading$ | async" class="loader">
        <div class="lds-hourglass"></div>
    </div>
</div>
