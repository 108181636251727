import { Action } from '@ngrx/store';

export enum LookupActionTypes {
    LookupSave = '[Lookup] Save',
}

export class LookupSave implements Action {
    readonly type = LookupActionTypes.LookupSave;

    constructor(public payload: { key: string; value: any }) {}
}

export type LookupActions = LookupSave;
