import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from 'src/app/components/base.component';
import { DelegationFilter, DelegationsRow, DelegationType, MyPageCollection } from 'src/app/store/my-page/models';
import {
    DelegationsRequest,
    DelegationsSelectedAll,
    DelegationsToggleSelected,
    DelegationsUpdateFilterProperty,
} from 'src/app/store/my-page/actions';
import { take, takeWhile } from 'rxjs/operators';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from '../../filter/header-checklist-filter/header-checklist-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { MyPageService } from 'src/app/services/my-page.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderDateFilterComponent } from '../../filter/header-date-filter/header-date-filter.component';
import { Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DelegationsPopupComponent } from './delegations-popup/delegations-popup.component';
import { MatSelectChange } from '@angular/material/select';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-lun-delegations',
    templateUrl: './lun-delegations.component.html',
    styleUrls: ['./lun-delegations.component.scss'],
})
export class LUNDelegationsComponent extends BaseComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    pageSize = 10;
    sortBy = 'lunNumber';
    direction: OrderDirection = OrderDirection.Desc;

    columnNumber$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnNumber);
    columnNumber: CheckListColumn = null;
    columnRevision$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnRevision);
    columnRevision: CheckListColumn = null;
    columnDiscipline$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnDiscipline);
    columnDiscipline: CheckListColumn = null;
    columnSubject$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnSubject);
    columnSubject: CheckListColumn = null;
    columnStatus$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnStatus);
    columnStatus: CheckListColumn = null;
    columnOriginator$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnOriginator);
    columnOriginator: CheckListColumn = null;
    columnapprover$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnapprover);
    columnapprover: CheckListColumn = null;
    columncostEngineer$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columncostEngineer);
    columncostEngineer: CheckListColumn = null;
    columncontractsEngineer$ = this.store.select(
        (state) => state.lunMyPageState.delegationsFilter.columncontractsEngineer
    );
    columncontractsEngineer: CheckListColumn = null;
    columnRaisedDate$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnRaisedDate);
    columnRaisedDate: CalendarColumn = null;
    columnContractNo$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.columnContractNo);
    columnContractNo: CheckListColumn = null;
    isLoading$ = this.store.select((store) => store.lunMyPageState.isDelegationsSectionLoading);
    delegationsPagination$ = this.store.select((store) => store.lunMyPageState.delegationsPagination);
    filter$ = this.store.select((store) => store.lunMyPageState.delegationsFilter);
    numberOfItemsSelected$ = this.store.select((store) => store.lunMyPageState.selectedItems.length);
    type$ = this.store.select((store) => store.lunMyPageState.delegationsFilter.type);
    itemsSelected$ = this.store.select((store) => store.lunMyPageState.selectedItems);
    types = [
        { value: DelegationType.Originator, key: 'Originator' },
        { value: DelegationType.Approver, key: 'Approver' },
        { value: DelegationType.CostEngineer, key: 'Cost Engineer' },
        { value: DelegationType.ContractsEngineer, key: 'Contracts Advisor' },
    ];
    type: DelegationType;
    itemsSelected: DelegationsRow[];
    data: MyPageCollection<DelegationsRow>;

    constructor(
        private store: Store<ApplicationState>,
        private popupService: PopupService,
        private ngZone: NgZone,
        private router: Router,
        private myPageService: MyPageService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit() {
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.paginator.pageIndex = filter.page;
            this.paginator.pageSize = filter.take;
            this.pageSize = filter.take;
            this.direction = filter.direction;
            this.sortBy = filter.sortBy;
        });
        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new DelegationsUpdateFilterProperty({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new DelegationsRequest());
        });

        this.columnNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNumber = data));
        this.columnRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevision = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnSubject$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubject = data));
        this.columnStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatus = data));
        this.columnOriginator$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnOriginator = data));
        this.columnRaisedDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRaisedDate = data));
        this.columnContractNo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContractNo = data));
        this.type$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.type = data));
        this.itemsSelected$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.itemsSelected = data));
        this.delegationsPagination$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data;
        });
    }

    isSelected(element: DelegationsRow) {
        return this.itemsSelected.find((s) => s.id === element.id);
    }

    isSelectAll() {
        return !this.data.items.some((s) => this.itemsSelected.findIndex((f) => f.id === s.id) === -1);
    }

    getDisplayedColumns(): string[] {
        if (this.type === DelegationType.Originator) {
            return [
                'selected',
                'lunNumber',
                'revision',
                'discipline',
                'subject',
                'status',
                'originator',
                'raisedDate',
                'contractNo'
            ];
        } else if (this.type === DelegationType.Approver) {
            return [
                'selected',
                'lunNumber',
                'revision',
                'discipline',
                'subject',
                'status',
                'approver',
                'raisedDate',
                'contractNo',
            ];
        } else if (this.type === DelegationType.CostEngineer) {
            return [
                'selected',
                'lunNumber',
                'revision',
                'discipline',
                'subject',
                'status',
                'costEngineer',
                'raisedDate',
                'contractNo',
            ];
        } else if (this.type === DelegationType.ContractsEngineer) {
            return [
                'selected',
                'lunNumber',
                'revision',
                'discipline',
                'subject',
                'status',
                'contractsEngineer',
                'raisedDate',
                'contractNo',
            ];
        }
    }

    onDelegationClosed(change: MatSelectChange) {
        this.store.dispatch(
            new DelegationsUpdateFilterProperty({
                key: 'type',
                value: change.value,
            })
        );
        this.paginator.pageIndex = 0;
        this.store.dispatch(new DelegationsRequest());
    }

    selectChange(event: MatCheckboxChange, element: DelegationsRow) {
        this.store.dispatch(
            new DelegationsToggleSelected({
                selected: event.checked,
                item: element,
            })
        );
    }

    selectAll(event: MatCheckboxChange) {
        this.store.dispatch(
            new DelegationsSelectedAll({
                selected: event.checked,
            })
        );
    }

    openDelegationsPopup() {
        let areLUNsValid = true;
        let contract = this.itemsSelected[0].contractNo;
        if (this.type === DelegationType.Approver || this.type === DelegationType.CostEngineer) {
            this.itemsSelected.forEach((element) => {
                areLUNsValid =
                    contract === element.contractNo;
            });
        } else {
            this.itemsSelected.forEach((element) => {
                areLUNsValid = contract === element.contractNo;
            });
        }

        if (!areLUNsValid) {
            this.toastService.Error(
                'Only select LUNs on the same Contract and same Status to delegate to another user. For approver please also ensure that Reason For Instruction is the same as well.'
            );
            return;
        }

        this.popupService.openPopup(new PopupSettings(DelegationsPopupComponent, 0, 0, {}, 100, 300));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new DelegationsUpdateFilterProperty({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new DelegationsUpdateFilterProperty({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }

        this.store.dispatch(new DelegationsRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }

        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        displayBlanksRadio: boolean = false
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                displayBlanksRadio: displayBlanksRadio,
                onlyBlanks: selected ? selected.onlyBlanks : false,
                excludeBlanks: selected ? selected.excludeBlanks : false,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;

                    this.store.dispatch(
                        new DelegationsUpdateFilterProperty({
                            key: propertyName,
                            value: value,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new DelegationsUpdateFilterProperty({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openDetailsPage(id: number) {
        this.ngZone.run(() => this.router.navigate([`/details/${id}`]));
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, 345, 625, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                action: (data) => {
                    this.store.dispatch(
                        new DelegationsUpdateFilterProperty({
                            key: propertyName,
                            value: data.calendarColumn,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new DelegationsUpdateFilterProperty({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new DelegationsUpdateFilterProperty({
                    key: 'sortBy',
                    value: { active: 'lunNumber', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new DelegationsUpdateFilterProperty({
                key: 'page',
                value: 0,
            })
        );

        this.store.dispatch(new DelegationsRequest());
    }

    private getLatestFilterData(): DelegationFilter {
        let filter: DelegationFilter;
        this.store
            .pipe(select((x) => x.lunMyPageState, take(1)))
            .subscribe((data) => (filter = data.delegationsFilter));
        return filter;
    }

    getTableFilterValues = (search = '', take = 10, page = 0, column?: string) =>
        this.myPageService.getDelegationsFilterValues(column, search, take, page, this.getLatestFilterData());
}
