import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { Constants } from './models/constants.model';

@Component({
    selector: 'app-lun-attachments',
    templateUrl: './lun-attachments.component.html',
    styleUrls: ['./lun-attachments.component.scss'],
})
export class LUNAttachmentsComponent {
    @Input() lunForm: UntypedFormGroup;
    @Input() isDisabled = false;
    @Input() printMode = false;
    supportingDocsHeader = Constants.SupportingDocumentsHeader;
}
