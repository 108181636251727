import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from '../app.config';
@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly mcPlusEndpoint: string = '/user';

    constructor(private http: HttpClient) {}

    addUserDetails() {
        return this.http.post(`${this.rootUrl}${this.mcPlusEndpoint}`, {});
    }
}
