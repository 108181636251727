export enum LUNAttachmentType {
    SupportingDocument = 1,
    Estimate = 2,
}

export class LUNAttachments {
    lunId: number;
    attachments: Attachment[];
}

export class Attachment {
    id: number;
    name = '';
    link = '';
    documentNo = '';
    loading: boolean;
    lunAttachmentType: LUNAttachmentType = null;
    lunId: number;
    file: File;
    isValid: boolean;
    isBeingProcessed: boolean;
}
