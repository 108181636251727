import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { NightShiftHistoryPopupComponent } from '../night-shift-history-popup/night-shift-history-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { RoleService } from 'src/app/services/shared/role.service';
import { NightShiftStatusType } from 'src/app/models/enums';
import { NightShiftService } from 'src/app/services/night-shift.service';
import { NightShiftExportToExcelRequest } from 'src/app/store/night-shift/actions';

@Component({
    selector: 'app-night-shift-workflow-status',
    templateUrl: './night-shift-workflow-status.component.html',
    styleUrls: ['./night-shift-workflow-status.component.scss'],
})
export class NightShiftWorkflowStatusComponent extends BaseComponent implements OnInit {
    @Input() nightShiftForm: UntypedFormGroup;
    commentsCount = 0;
    comments$ = this.store.select((store) => store.nightShiftState.form.comments);
    nightShiftId$ = this.store.select((store) => store.nightShiftState.form.id);
    isReadOnly = false;
    nightShiftStatus$ = this.store.select((store) => store.nightShiftState.form.status);
    nightShiftStatus: NightShiftStatusType;

    constructor(
        private popupService: PopupService,
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private nightShiftService: NightShiftService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        combineLatest([this.nightShiftStatus$, this.comments$])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([status, comments]) => {
                this.nightShiftStatus = status;
                this.commentsCount = comments.length;
            });
    }
    scroll(tagName) {
        const el = document.getElementsByTagName(tagName);
        if (el) {
            el[0].scrollIntoView({ behavior: 'smooth' });
        }
    }
    openHistoryPopup() {
        this.popupService.openPopup(
            new PopupSettings(
                NightShiftHistoryPopupComponent,
                null,
                null,
                { nightShiftId: this.nightShiftForm.controls['id'].value },
                640,
                240,
                1040,
                600
            )
        );
    }

    printNightShift() {
        this.store.dispatch(new NightShiftExportToExcelRequest(this.nightShiftForm.controls['id'].value));
    }

    // shareEmail() {
    //     this.popupService.openPopup(
    //         new PopupSettings(
    //             NightShiftShareEmailPopupComponent,
    //             null,
    //             null,
    //             {
    //                 number: this.nightShiftForm.controls.number.value,
    //                 id: this.nightShiftForm.controls.id.value,
    //             },
    //             500,
    //             200,
    //             null,
    //             null,
    //             false,
    //             'email-dropdown'
    //         )
    //     );
    // }
}
