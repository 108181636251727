import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../model';
import { AdminPanelService } from 'src/app/services/admin-panel.service';
import { ManualUploadService } from 'src/app/services/manual-upload.service';
import {
    SubsystemListActionTypes,
    SubsystemListUploadLogSuccess,
    SubsystemListUploadLogError,
    SubsystemListSetStatusAndStartDate,
    SubsystemListDeltaRequest,
    SubsystemListDeltaSuccess,
    SubsystemListDeltaError,
    SubsystemListPatchDeltaRequest,
    SubsystemListPatchDeltaSuccess,
    SubsystemListPatchDeltaError,
    SubsystemListValidateDeltaSuccess,
    SubsystemListValidateDeltaError,
    SubsystemListClearInProgressSpinner,
    SubsystemListTemplateFileRequest,
    SubsystemListTemplateFileSuccess,
    SubsystemListTemplateFileError,
    SubsystemListValidateButtonStateSuccess,
    SubsystemListValidateButtonStateError,
    SubsystemListDownloadOutputDataRequest,
    SubsystemListDownloadOutputDataSuccess,
    SubsystemListDownloadOutputDataError,
    SubsystemListPatchAllDeltaRequest,
    SubsystemListPatchAllDeltaSuccess,
    SubsystemListPatchAllDeltaError,
    SubsystemListUploadLogRequest,
    SubsystemListValidateDeltaRequest,
} from './actions';
import { mergeMap, map, catchError, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ImportStatuses } from 'src/app/models/import-statuses';
import { ToastService } from 'src/app/services/shared/toast.service';

@Injectable()
export class SubsystemListEffects {
    constructor(
        private actions$: Actions,
        private store: Store<ApplicationState>,
        private adminPanelService: AdminPanelService,
        private manualUploadsService: ManualUploadService,
        private toastService: ToastService
    ) {}

    
    getUploadLog$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListUploadLogRequest>(SubsystemListActionTypes.SubsystemListUploadLogRequest),
        mergeMap((action) =>
            this.adminPanelService.getLogs([action.payload]).pipe(
                map((uploadLogData) => new SubsystemListUploadLogSuccess(uploadLogData, action.payload)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Upload Log. Please contact Program Administrator'
                    );
                    return of(new SubsystemListUploadLogError(error));
                })
            )
        )
    ));

    
    setStatusAndStartDate$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListUploadLogSuccess>(SubsystemListActionTypes.SubsystemListUploadLogSuccess),
        withLatestFrom(
            this.store.select((store) => store.subsystemListState.uploadLogStatus),
            this.store.select((store) => store.subsystemListState.uploadLogStartDate)
        ),
        filter(([{ payload }, status, startDate]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return logs[0] && (status !== logs[0].status || startDate !== logs[0].startDate);
        }),
        map(([{ payload, subType }]) => {
            const logs = payload.filter((l) => l.status !== ImportStatuses.Info);
            return new SubsystemListSetStatusAndStartDate({
                status: logs[0].status,
                startDate: logs[0].startDate,
                type: subType,
            });
        })
    ));

    
    invokeDeltaRequest$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListSetStatusAndStartDate>(SubsystemListActionTypes.SubsystemListSetStatusAndStartDate),
        withLatestFrom(this.store.select((store) => store.subsystemListState.uploadLogData)),
        filter(([, uploadLogs]) => {
            const logs = uploadLogs.filter((l) => l.status !== ImportStatuses.Info);
            return (logs[0] && logs[0].status === ImportStatuses.Finished) || logs[0].status === ImportStatuses.Error;
        }),
        switchMap(([action]) => [new SubsystemListDeltaRequest(action.payload.type)])
    ));

    
    getDelta$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListDeltaRequest>(SubsystemListActionTypes.SubsystemListDeltaRequest),
        withLatestFrom(this.store.select((store) => store.subsystemListState.deltaFilter)),
        mergeMap(([action, deltaFilter]) =>
            this.manualUploadsService.getSubsystemListDelta(deltaFilter.page, deltaFilter.take, action.payload).pipe(
                map((deltaData: any) => new SubsystemListDeltaSuccess(deltaData, action.payload)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while getting Delta records. Please contact Program Administrator'
                    );
                    return of(new SubsystemListDeltaError(error));
                })
            )
        )
    ));

    
    patchDelta$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListPatchDeltaRequest>(SubsystemListActionTypes.SubsystemListPatchDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchSubsystemListDelta(action.payload.subsystem, action.payload.deltaState).pipe(
                map(() => new SubsystemListPatchDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new SubsystemListPatchDeltaError(error));
                })
            )
        )
    ));

    
    patchAllDelta$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListPatchAllDeltaRequest>(SubsystemListActionTypes.SubsystemListPatchAllDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.patchSubsystemListAllDelta(action.payload.deltaState, action.payload.type).pipe(
                map(() => new SubsystemListPatchAllDeltaSuccess({ deltaState: action.payload.deltaState })),
                catchError((error) => {
                    this.toastService.Error(
                        `Error has occurred while ${
                            action.payload.deltaState ? 'accepting' : 'rejecting'
                        } the change. Please contact Program Administrator`
                    );
                    return of(new SubsystemListPatchAllDeltaError(error));
                })
            )
        )
    ));

    
    validateDelta$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListValidateDeltaRequest>(SubsystemListActionTypes.SubsystemListValidateDeltaRequest),
        mergeMap((action) =>
            this.manualUploadsService.validateSubsystemListDelta(action.payload).pipe(
                map(() => new SubsystemListValidateDeltaSuccess()),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while validating and pushing the data to live database. Please contact Program Administrator'
                    );
                    return of(new SubsystemListValidateDeltaError(error));
                })
            )
        )
    ));

    
    clearInProgressSpinner$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListDeltaRequest>(SubsystemListActionTypes.SubsystemListDeltaRequest),
        withLatestFrom(this.store.select((store) => store.subsystemListState.uploadLogData)),
        filter(([action, uploadLogs]) => {
            var logs = uploadLogs.filter((u) => u.status !== ImportStatuses.Info);
            if (!logs.length) return false;
            return logs[0].status === ImportStatuses.Finished || logs[0].status === ImportStatuses.Error;
        }),
        map(([action]) => new SubsystemListClearInProgressSpinner(action.payload))
    ));

    
    downloadTemplate$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListTemplateFileRequest>(SubsystemListActionTypes.SubsystemListTemplateFileRequest),
        mergeMap((action) =>
            this.manualUploadsService.downloadTemplate(action.payload).pipe(
                map((blob) => new SubsystemListTemplateFileSuccess(blob)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading Subsystem List structure file. Please contact Program Administrator'
                    );
                    return of(new SubsystemListTemplateFileError(error));
                })
            )
        )
    ));

    
    saveTemplate$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListTemplateFileSuccess>(SubsystemListActionTypes.SubsystemListTemplateFileSuccess),
        map((action) => {
            const blob = new Blob([action.payload], {
                type: 'application/octet-stream',
            });
            saveAs(blob, 'Temporary Subsystem List.xlsx');
        })
    ), { dispatch: false });

    
    setValidateButtonState$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListDeltaSuccess>(SubsystemListActionTypes.SubsystemListDeltaSuccess),
        mergeMap((action) =>
            this.manualUploadsService.getValidateButtonState(action.subType).pipe(
                map((response: any) => new SubsystemListValidateButtonStateSuccess(response)),
                catchError((error) => of(new SubsystemListValidateButtonStateError(error)))
            )
        )
    ));

    
    downloadOutputData$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListDownloadOutputDataRequest>(SubsystemListActionTypes.SubsystemListDownloadOutputDataRequest),
        mergeMap((action) =>
            this.adminPanelService.downloadOutputData(action.payload).pipe(
                map((blob) => new SubsystemListDownloadOutputDataSuccess({ content: blob, fileName: action.payload })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error has occurred while downloading output file. Please contact Program Administrator'
                    );
                    return of(new SubsystemListDownloadOutputDataError(error));
                })
            )
        )
    ));

    
    saveOutputData$ = createEffect(() => this.actions$.pipe(
        ofType<SubsystemListDownloadOutputDataSuccess>(SubsystemListActionTypes.SubsystemListDownloadOutputDataSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });
            const fileName = action.payload.fileName.replace(/^.*[\\\/]/, '');

            saveAs(blob, fileName);
        })
    ), { dispatch: false });
}
