import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { LUNHistoryPopupComponent } from '../lun-history-popup/lun-history-popup.component';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { LUNService } from 'src/app/services/lun.service';
import { LUNShareEmailPopupComponent } from '../lun-share-email-popup/lun-share-email-popup.component';
import { RoleService } from 'src/app/services/shared/role.service';
import { LUNStatusType } from 'src/app/models/enums';

@Component({
    selector: 'app-lun-workflow-status',
    templateUrl: './lun-workflow-status.component.html',
    styleUrls: ['./lun-workflow-status.component.scss'],
})
export class LUNWorkflowStatusComponent extends BaseComponent implements OnInit {
    @Input() lunForm: UntypedFormGroup;
    @Output() printEvent = new EventEmitter();
    commentsCount = 0;
    comments$ = this.store.select((store) => store.lunState.form.comments);
    lunId$ = this.store.select((store) => store.lunState.form.id);
    isReadOnly = false;
    lunStatus$ = this.store.select((store) => store.lunState.form.status);
    lunStatus: LUNStatusType;

    constructor(
        private popupService: PopupService,
        private store: Store<ApplicationState>,
        private roleService: RoleService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        combineLatest([this.lunStatus$, this.comments$])
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(([status, comments]) => {
                this.lunStatus = status;
                this.commentsCount = comments.length;
            });
    }
    scroll(tagName) {
        const el = document.getElementsByTagName(tagName);
        if (el) {
            el[0].scrollIntoView({ behavior: 'smooth' });
        }
    }
    openHistoryPopup() {
        this.popupService.openPopup(
            new PopupSettings(
                LUNHistoryPopupComponent,
                null,
                null,
                { lunId: this.lunForm.controls['id'].value },
                640,
                240,
                1240,
                600
            )
        );
    }

    printLUN() {
        this.printEvent.emit();
    }

    shareEmail() {
        this.popupService.openPopup(
            new PopupSettings(
                LUNShareEmailPopupComponent,
                null,
                null,
                {
                    number: this.lunForm.controls.lunNumber.value,
                    id: this.lunForm.controls.id.value,
                },
                500,
                200,
                null,
                null,
                false,
                'email-dropdown'
            )
        );
    }
}
