import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasePopupSettings } from 'src/app/models/base-popup-settings';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    constructor(private dialog: MatDialog) {}

    openPopup(popupItem: BasePopupSettings): MatDialogRef<any, any> {
        const dialogRef = this.dialog.open(popupItem.content, popupItem.config);
        return dialogRef;
    }
}
