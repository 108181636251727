import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { Attachment, LUNAttachmentType } from 'src/app/store/lun-attachments/model';
import { ApplicationState } from 'src/app/store/model';
import { RoleService } from 'src/app/services/shared/role.service';
import _ from 'lodash';
import { LUNAttachmentsRequest, LUNAutosaveRequest, LUNDownloadAttachmentRequest } from 'src/app/store/lun/actions';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    selector: 'app-lun-attachment-sia',
    templateUrl: './lun-attachment-sia.component.html',
    styleUrls: ['./lun-attachment-sia.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LUNAttachmentSupportingInformationAttachmentComponent),
            multi: true,
        },
    ],
})
export class LUNAttachmentSupportingInformationAttachmentComponent extends BaseComponent
    implements ControlValueAccessor, OnInit {
    @Input() headerText = '';
    @Input() lunForm: UntypedFormGroup;
    @Input() type: LUNAttachmentType = LUNAttachmentType.SupportingDocument;
    @Input() displayNote = true;
    @Input() isDisabled = false;
    @Input() printMode = false;

    propagateChange = (_: any) => {};

    attachments: Attachment[] = [];
    lunId: number;
    isReadOnly: boolean;
    user: AccountInfo = null;
    attachmentTypes = LUNAttachmentType;
    lunId$ = this.store.select((state) => state.lunState.form.id);
    LUN$ = this.store.select((state) => state.lunState.form);
    attachments$ = this.store.select<Attachment[]>(
        (state) => state.lunState.form[`${this.attachmentTypes[this.type]}Attachments`]
    );
    attachmentType = this.attachmentTypes[this.type] + 'Attachments';
    isLoading$ = this.store.select((state) => state.lunState.isLoading);

    constructor(
        private toastService: ToastService,
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private authService: MsalService
    ) {
        super();
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isReadOnly = this.roleService.isReadOnly();
        this.lunId$.pipe(takeWhile(() => this.isAlive)).subscribe((lunId) => {
            this.lunId = lunId;
            if (this.lunId !== null) {
                this.store.dispatch(new LUNAttachmentsRequest({ id: this.lunId, type: this.type }));
            }
        });

        this.attachments$.pipe(takeWhile(() => this.isAlive)).subscribe((attachments) => {
            this.attachments = attachments;
            this.attachmentType = this.attachmentTypes[this.type] + 'Attachments';
        });
    }

    fileChangeEvent(files: Array<File>) {
        const filesWrapper = new Array<File>();
        filesWrapper.push(...files);
        filesWrapper.forEach((file) => {
            if (file.size === 0) {
                this.toastService.Info(`File ${file.name} has 0B size. Please upload a valid file.`);
                return;
            }
            if (_.some(['&', '\\', '/', '?'], (c) => file.name.indexOf(c) > -1)) {
                this.toastService.Info(`File ${file.name} contains invalid character (&, \\, / or ?).`);
                return;
            }
            if (_.some(this.attachments, (attachment) => attachment.name === file.name)) {
                this.toastService.Info(
                    'File ' + file.name + ' already uploaded. Please delete existing file and re-upload.'
                );
            } else {
                const attachment = new Attachment();
                attachment.file = file;
                attachment.name = file.name;
                attachment.isValid = true;
                attachment.lunAttachmentType = this.type;
                this.attachments = [...this.attachments, attachment];
            }
        });
        this.propagateChange(this.attachments);
    }

    removeAttachment(name: string) {
        this.attachments = [..._.filter(this.attachments, (attachment) => attachment.name !== name)];
        this.propagateChange(this.attachments);
        this.store.dispatch(new LUNAutosaveRequest(this.lunForm.value));
    }

    downloadAttachment(attachment: Attachment) {
        this.store.dispatch(new LUNDownloadAttachmentRequest({ attachment, type: this.type }));
    }

    writeValue(value: any) {}

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
}
