import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupSettings } from 'src/app/models/popup-settings';
import { Constants } from 'src/app/constants';
import { DeleteConfirmationPopupComponent } from '../../delete-confirmation-popup/delete-confirmation-popup.component';
import { CommentPopupComponent } from '../../comment-popup/comment-popup.component';
import { NightShiftComment } from 'src/app/store/night-shift/model';
import { NightShiftRemoveCommentRequest, NightShiftAddCommentRequest } from 'src/app/store/night-shift/actions';
import moment from 'moment';
import { ApplicationState } from 'src/app/store/model';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo} from '@azure/msal-browser';

@Component({
    selector: 'app-night-shift-comments-history',
    templateUrl: './night-shift-comments-history.component.html',
    styleUrls: ['./night-shift-comments-history.component.scss'],
})
export class NightShiftCommentsHistoryComponent implements OnInit {
    @Input() comments: NightShiftComment[] = [];
    @Input() nightShiftId: number = 0;
    @Input() nightShiftNumber: string = '';
    @Input() printMode = false;
    displayedColumns: string[] = ['comment', 'user', 'date', 'delete'];
    user: AccountInfo;
    isAdmin = false;
    isReadOnly = false;
    status$ = this.store.select((state) => state.nightShiftState.form.status);

    constructor(
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private roleService: RoleService,
        private authService: MsalService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon(
            'del-icon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isAdmin = this.roleService.isInRole('Admin');
        this.isReadOnly = this.roleService.isReadOnly();
    }

    removeComment(element: NightShiftComment) {
        this.popupSvc.openPopup(
            new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                action: (value: boolean) => {
                    if (value) {
                        this.store.dispatch(new NightShiftRemoveCommentRequest({ comment: Object.assign({}, element, { isLoading: true }) }));
                    }
                },
            })
        );
    }

    openCommentPopup(event: any) {
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                action: (value: string) => {
                    let comment = new NightShiftComment();
                    comment.nightShiftId = this.nightShiftId;
                    comment.date = moment();
                    comment.description = value;
                    this.store.dispatch(
                        new NightShiftAddCommentRequest({
                            comment: comment,
                        })
                    );
                },
            })
        );

        event.stopPropagation();
    }
}
