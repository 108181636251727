<div class="comments-container">
    <div class="title" *ngIf="printMode">
        {{ lunNumber }}: Comments
    </div>
    <button
        *ngIf="(!isReadOnly && (status$ | async) !== 1) && !printMode"
        type="button"
        class="lun-button lun-button-light"
        (click)="openCommentPopup($event)"
    >
        Add comment
    </button>
    <table mat-table matSort [dataSource]="comments">
        <ng-container matColumnDef="comment">
            <th mat-header-cell disableClear *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell disableClear *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell disableClear *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">{{ element.date | date: 'MMMM  dd yyyy, h:mm:ss a' }}</td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell disableClear *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon
                    *ngIf="!element.isLoading && (this.user.username === element.user.email || isAdmin)"
                    svgIcon="del-icon"
                    (click)="removeComment(element)"
                ></mat-icon>
                <mat-spinner diameter="20" *ngIf="element.isLoading"></mat-spinner>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="printMode ? displayedColumns.slice(0, -1) : displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: printMode ? displayedColumns.slice(0, -1) : displayedColumns"></tr>
    </table>
</div>
