import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LUNFormComponent } from './components/lun-form/lun-form.component';
import { LUNRegisterComponent } from './components/lun-register/lun-register.component';
import { CanDeactivateLUNGuard } from './guards/can-deactivate-lun.guard';
import { UserSynchronizationComponent } from './components/user-synchronization/user-synchronization.component';
import { AdminComponent } from './components/admin-section/admin.component';
import { DashboardComponent } from './components/admin-section/dashboard/dashboard.component';
import { IsAdminGuard } from './guards/is-admin-guard';
import { IsInGroupGuard } from './guards/is-in-group-guard';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { NotAuthorizedComponent } from './components/error/not-authorized/not-authorized.component';
import { SubsystemListComponent } from './components/admin-section/manual-upload/subsystem-list/subsystem-list.component';
import { ManualUploadComponent } from './components/admin-section/manual-upload/manual-upload.component';
import { SettingsComponent } from './components/admin-section/configuration/settings/settings.component';
import { ConfigurationComponent } from './components/admin-section/configuration/configuration.component';
import { MasterSubsystemListComponent } from './components/admin-section/manual-upload/master-subsystem-list/master-subsystem-list.component';
import { NightShiftRegisterComponent } from './components/night-shift-register/night-shift-register.component';
import { NightShiftFormComponent } from './components/night-shift-form/night-shift-form.component';
import { EmailDistributionComponent } from './components/admin-section/configuration/email-distribution/email-distribution.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [MsalGuard],
        children: [
            { path: '', component: LUNRegisterComponent, canActivate: [IsInGroupGuard] },
            {
                path: 'details/:id',
                component: LUNFormComponent,
                canDeactivate: [CanDeactivateLUNGuard],
                canActivate: [IsInGroupGuard],
            },
            {
                path: 'nightshift/details/:id',
                component: NightShiftFormComponent,
                canDeactivate: [CanDeactivateLUNGuard],
                canActivate: [IsInGroupGuard],
            },
            { path: 'register', component: LUNRegisterComponent, canActivate: [IsInGroupGuard] },
            { path: 'night-shift-register', component: NightShiftRegisterComponent, canActivate: [IsInGroupGuard] },
            { path: 'synchronize', component: UserSynchronizationComponent, canActivate: [IsInGroupGuard] },
            {
                path: 'admin',
                component: AdminComponent,
                children: [
                    {
                        path: '',
                        canActivateChild: [IsAdminGuard],
                        children: [
                            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
                            { path: 'dashboard', component: DashboardComponent },
                            {
                                path: 'manualupload',
                                component: ManualUploadComponent,
                                children: [
                                    { path: '', redirectTo: 'subsystemlist/:type', pathMatch: 'full' },
                                    { path: 'subsystemlist/:type', component: SubsystemListComponent },
                                    { path: 'mastersubsystemlist', component: MasterSubsystemListComponent },
                                ],
                            },
                            {
                                path: 'configuration',
                                component: ConfigurationComponent,
                                children: [
                                    { path: '', redirectTo: 'settings', pathMatch: 'full' },
                                    { path: 'settings', component: SettingsComponent },
                                    { path: 'email-distribution', component: EmailDistributionComponent },
                                ],
                            },
                        ],
                    },
                ],
            },
            { path: 'notfound', component: NotFoundComponent, canActivate: [IsInGroupGuard] },
        ],
    },
    { path: 'notauthorized', component: NotAuthorizedComponent },
    { path: '**', component: NotFoundComponent, canActivate: [IsInGroupGuard] },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
