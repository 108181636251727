import { Action } from '@ngrx/store';
import { NightShiftRegisterDataPagination } from './model';

export enum NightShiftRegisterActionTypes {
    NightShiftRegisterFilterRequest = '[NightShift Register] Filter Request',
    NightShiftRegisterFilterSuccess = '[NightShift Register] Filter Success',
    NightShiftRegisterFilterError = '[NightShift Register] Filter Error',
    NightShiftRegisterFilterPropertyUpdate = '[NightShift Register] Filter Property Update',
    NightShiftRegisterFilterReset = '[NightShift Register] Filter Reset',
    NightShiftRegisterExportToExcelRequest = '[NightShiftRegister] Export to Excel Request',
    NightShiftRegisterExportToExcelSuccess = '[NightShiftRegister] Export to Excel Success',
    NightShiftRegisterExportToExcelError = '[NightShiftRegister] Export to Excel Error',
    NightShiftRegisterToggleColumnShowSetting = '[NightShiftRegister] Toggle Column Show Setting',
    NightShiftRegisterAddCommentRequest = '[NightShiftRegister] Add Comment Request',
    NightShiftRegisterAddCommentSuccess = '[NightShiftRegister] Add Comment Request Success',
    NightShiftRegisterUpdateCommentError = '[NightShiftRegister] Add Comment Request Error'
}


export class NightShiftRegisterAddCommentRequest implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterAddCommentRequest;

    constructor(public payload: { id: number; description: string;}) {}
}

export class NightShiftRegisterAddCommentSuccess implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterAddCommentSuccess;

    constructor(public payload: { id: number; description: string;}) {}
}

export class NightShiftRegisterAddCommentError implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterUpdateCommentError;

    constructor(public payload: string) {}
}

export class NightShiftRegisterFilterRequest implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterFilterRequest;
}

export class NightShiftRegisterFilterSuccess implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterFilterSuccess;

    constructor(public payload: NightShiftRegisterDataPagination) {}
}

export class NightShiftRegisterFilterError implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterFilterError;

    constructor(public payload: string) {}
}

export class NightShiftRegisterFilterPropertyUpdate implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class NightShiftRegisterFilterReset implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterFilterReset;
}

export class NightShiftRegisterExportToExcelRequest implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelRequest;
}

export class NightShiftRegisterExportToExcelSuccess implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelSuccess;

    constructor(public payload: any) {}
}

export class NightShiftRegisterExportToExcelError implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterExportToExcelError;

    constructor(public payload: string) {}
}

export class NightShiftRegisterToggleColumnShowSetting implements Action {
    readonly type = NightShiftRegisterActionTypes.NightShiftRegisterToggleColumnShowSetting;

    constructor(public columnName: string) {}
}

export type NightShiftRegisterActions =
    | NightShiftRegisterFilterRequest
    | NightShiftRegisterFilterSuccess
    | NightShiftRegisterFilterError
    | NightShiftRegisterFilterPropertyUpdate
    | NightShiftRegisterFilterReset
    | NightShiftRegisterExportToExcelRequest
    | NightShiftRegisterExportToExcelSuccess
    | NightShiftRegisterExportToExcelError
    | NightShiftRegisterToggleColumnShowSetting
    | NightShiftRegisterAddCommentRequest
    | NightShiftRegisterAddCommentSuccess
    | NightShiftRegisterAddCommentError;
