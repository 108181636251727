import {
    MasterSubsystemListState,
    MasterSubsystemListDeltaPagination,
    MasterSubsystemListDeltaFilter,
    MasterSubsystemListDeletePagination,
    MasterSubsystemListDeleteFilter,
    MasterSubsystemListDeltaDTO,
    MasterSubsystemListDeleteDTO,
} from './model';
import { MasterSubsystemListActions, MasterSubsystemListActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: MasterSubsystemListState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deltaData: new MasterSubsystemListDeltaPagination(),
    isDeltaDataLoading: false,
    deleteData: new MasterSubsystemListDeletePagination(),
    isDeleteDataLoading: false,
    deltaFilter: new MasterSubsystemListDeltaFilter(),
    deleteFilter: new MasterSubsystemListDeleteFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    isImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
};

export function reducer(state = initialState, action: MasterSubsystemListActions) {
    switch (action.type) {
        case MasterSubsystemListActionTypes.MasterSubsystemListUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaSuccess: {
            return {
                ...state,
                deltaData: action.payload,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeltaFilterPropertyUpdate: {
            state.deltaFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteFilterPropertyUpdate: {
            state.deleteFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaRequest: {
            let itemsObject = Object.assign(new Array<MasterSubsystemListDeltaDTO>(), state.deltaData.items);
            const item = Object.assign(
                {},
                itemsObject.find((d) => d.subsystem === action.payload.subsystem)
            );
            item.deltaState = action.payload.deltaState;
            const index = itemsObject.findIndex((i) => i.subsystem === action.payload.subsystem);
            itemsObject[index] = item;
            return {
                ...state,
                isDeltaDataLoading: true,
                deltaData: {
                    items: itemsObject,
                },
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaSuccess: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaSuccess: {
            let itemsObject = Object.assign(new Array<MasterSubsystemListDeltaDTO>(), state.deltaData.items);
            itemsObject.forEach((i) => {
                const item = Object.assign({}, i);
                item.deltaState = action.payload.deltaState;
                const index = itemsObject.findIndex((i) => i.subsystem === item.subsystem);
                itemsObject[index] = item;
            });
            return {
                ...state,
                isDeltaDataLoading: false,
                deltaData: {
                    items: itemsObject,
                },
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchAllDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsSuccess: {
            let itemsObject = Object.assign(new Array<MasterSubsystemListDeleteDTO>(), state.deleteData.items);
            itemsObject.forEach((i) => {
                const item = Object.assign({}, i);
                item.deleteState = action.payload.deleteState;
                const index = itemsObject.findIndex((i) => i.subsystem === item.subsystem);
                itemsObject[index] = item;
            });
            return {
                ...state,
                isDeleteDataLoading: false,
                deleteData: {
                    items: itemsObject,
                },
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteAllRecordsError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isDeleteDataLoading: true,
                isValidateButtonEnabled: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaSuccess: {
            return {
                ...state,
                deltaData: new MasterSubsystemListDeltaPagination(),
                deleteData: new MasterSubsystemListDeletePagination(),
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListFileUploaded: {
            return {
                ...state,
                isImportInProgress: true,
                deltaData: new MasterSubsystemListDeltaPagination(),
                deleteData: new MasterSubsystemListDeletePagination(),
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListClearInProgressSpinner: {
            return {
                ...state,
                isImportInProgress: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataRequest: {
            return {
                ...state,
                isDeleteDataLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataSuccess: {
            return {
                ...state,
                deleteData: action.payload,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDeleteDataError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordRequest: {
            let itemsObject = Object.assign(new Array<MasterSubsystemListDeleteDTO>(), state.deleteData.items);
            const item = Object.assign(
                {},
                itemsObject.find((d) => d.subsystem === action.payload.subsystem)
            );
            item.deleteState = action.payload.deleteState;
            const index = itemsObject.findIndex((i) => i.subsystem === action.payload.subsystem);
            itemsObject[index] = item;
            return {
                ...state,
                isDeleteDataLoading: true,
                deleteData: {
                    items: itemsObject,
                },
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordSuccess: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListPatchDeleteRecordError: {
            return {
                ...state,
                isDeleteDataLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case MasterSubsystemListActionTypes.MasterSubsystemListAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = 'MSL';
            updateLog.startDate = moment();
            var logs = [{ ...updateLog }, ...state.uploadLogData];
            return {
                ...state,
                uploadLogData: logs,
            };
        }
        default:
            return state;
    }
}
