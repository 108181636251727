import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { HeaderCheckListFilter, HeaderCheckListFilterResult } from 'src/app/models/filter-settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { Subject, Observable, timer } from 'rxjs';
import { startWith, exhaustMap, takeWhile, scan, tap, debounce, filter, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CdkVirtualScrollViewport, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { BaseComponent } from 'src/app/components/base.component';
import _ from 'lodash';

@Component({
    selector: 'app-header-checklist-filter',
    templateUrl: './header-checklist-filter.component.html',
    styleUrls: ['./header-checklist-filter.component.scss'],
})
export class HeaderChecklistFilterComponent extends BaseComponent implements OnInit {
    isAscendingSort: boolean;
    isDescendingSort: boolean;
    isSortingOff: boolean;
    placeholder: string;
    nextPage$ = new Subject();
    isLoading = false;
    noResultFound = false;
    elements: string[] = [];
    showCounts = false;
    selectedItems: string[] = [];
    itemCtrl = new UntypedFormControl('');
    itemsPerPage = 30;
    showInputSearch = true;
    excludeBlanks = false;
    onlyBlanks = false;
    displayBlanksRadio = false;
    searchFunc: (id?: string, take?: number, page?: number, column?: string, allElements?: any[]) => Observable<any[]>;
    action: (data: HeaderCheckListFilterResult) => void;
    resetColumnAction: () => void;
    column: string;
    allElements: any[];

    @ViewChild(CdkVirtualForOf, { static: true }) virtualFor: CdkVirtualForOf<any>;
    @ViewChild(MatSelectionList, { static: true }) matSelectionList: MatSelectionList;
    @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: HeaderCheckListFilter,
        private dialogRef: MatDialogRef<HeaderChecklistFilterComponent>,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        this.isSortingOff = data.isSortingOff;
        this.isAscendingSort = data.isAscendingSort;
        this.isDescendingSort = data.isDescendingSort;
        this.placeholder = data.placeHolder;
        this.searchFunc = data.searchFunc;
        this.resetColumnAction = data.resetColumnAction;
        this.action = data.action;
        this.column = data.column;
        this.allElements = data.allElements ? [...data.allElements] : [];
        this.showInputSearch = data.showInputSearch;
        this.selectedItems = data.selectedItems === null ? [] : data.selectedItems;
        this.showCounts = data.showCounts;
        this.displayBlanksRadio = data.displayBlanksRadio;
        this.excludeBlanks = data.excludeBlanks;
        this.onlyBlanks = data.onlyBlanks;
        dialogRef.afterClosed().subscribe((saveFilter: boolean) => {
            if (saveFilter) {
                this.action({
                    selectedItems: this.selectedItems,
                    isAscendingSort: this.isAscendingSort,
                    isDescendingSort: this.isDescendingSort,
                    excludeBlanks: this.excludeBlanks,
                    onlyBlanks: this.onlyBlanks,
                });
            }
        });
    }

    ngAfterViewInit() {
        this.matSelectionList.selectionChange
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((listChange) => this.selectionChange(listChange));
    }

    ngOnInit() {
        this.itemCtrl.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => {
                    this.isLoading = true;
                    this.changeDetectorRef.detectChanges();
                }),
                debounce(() => timer(800)),
                startWith(''),
                filter((value) => typeof value === 'string' && this.searchFunc !== undefined),
                switchMap((value) => this.applyInfiniteScroll(value))
            )
            .subscribe((data) => {
                this.elements = [];
                this.elements = data;
                this.isLoading = false;
                this.noResultFound = data ? data.length === 0 : true;

                if (this.isAlive) {
                    this.changeDetectorRef.detectChanges();
                    this.selectItemsOnList();
                }
            });
    }

    getElementValue(element: any) {
        return element.value !== undefined ? element.value : element;
    }

    applyInfiniteScroll(value: string) {
        let currentPage = 0;
        return this.nextPage$.pipe(
            startWith(currentPage),
            tap(() => (this.isLoading = true)),
            exhaustMap(() => this.searchFunc(value, this.itemsPerPage, currentPage, this.column, this.allElements)),
            tap(() => (this.isLoading = false)),
            takeWhile((newItems: any[]) => {
                if (newItems.length === 0 && currentPage === 0) {
                    this.noResultFound = true;
                    this.elements = [];
                }
                return newItems.length > 0;
            }),
            scan((allItems, newItems) => {
                if (currentPage === 0) {
                    allItems = [];
                }
                return allItems.concat(newItems);
            }, []),
            tap(() => currentPage++)
        );
    }

    nextBatch($event: any) {
        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();

        this.selectItemsOnList();

        if (end === total && this.elements.length >= this.itemsPerPage) {
            this.nextPage$.next(null);
        }
    }

    trackByFn(index: number, item: string) {
        return item;
    }

    selectionChange(listChange: MatSelectionListChange) {
        if (listChange.options[0].selected && !_.some(this.selectedItems, (i) => i === listChange.options[0].value)) {
            this.selectedItems.push(listChange.options[0].value);
        } else {
            this.selectedItems = this.selectedItems.filter((x) => x !== listChange.options[0].value);
        }
    }

    updateBlanks(radio: any) {
        switch (radio.value) {
            case 'all':
                this.excludeBlanks = false;
                this.onlyBlanks = false;
                break;
            case 'onlyBlanks':
                this.excludeBlanks = false;
                this.onlyBlanks = true;
                break;
            case 'excludeBlanks':
                this.excludeBlanks = true;
                this.onlyBlanks = false;
                break;
        }
    }

    resetFilters() {
        if (this.resetColumnAction) {
            this.resetColumnAction();
        }
        this.matSelectionList.deselectAll();
        this.itemCtrl.setValue('');
        this.selectedItems = [];
        this.viewport.scrollToOffset(0);
        this.excludeBlanks = false;
        this.onlyBlanks = false;
    }

    close() {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.dialogRef.close(true);
    }

    private selectItemsOnList() {
        this.matSelectionList.options
            .filter((x) => this.selectedItems.filter((i) => i === x.value).length > 0)
            .forEach((x) => (x.selected = true));
    }
}
