import { NightShiftState, NightShift, NightShiftAttachmentType, Attachment } from './model';
import { NightShiftActions, NightShiftActionTypes } from './actions';


const initialState: NightShiftState = {
    form: new NightShift(),
    isLoading: false,
    isCreated: false,
    updatedProperties: [],
    isDescriptionLoading: true,
    isHistoryLoading: false,
    isLocked: false,
    isAutosaveInProgress: false,
};

const attachmentTypes = NightShiftAttachmentType;

export function reducer(state = initialState, action: NightShiftActions) {
    switch (action.type) {
        case NightShiftActionTypes.GetNightShiftRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.GetNightShiftRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },

                isLoading: false,
            };
        }
        case NightShiftActionTypes.GetNightShiftRequestError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftActionTypes.NightShiftExportToExcelRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftExportToExcelSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftActionTypes.NightShiftExportToExcelError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftActionTypes.AddNightShiftRequest: {
            return {
                ...state,
                isCreated: false,
            };
        }
        case NightShiftActionTypes.AddNightShiftRequestSuccess: {
            return {
                ...state,
                isCreated: true,
            };
        }
        case NightShiftActionTypes.AddNightShiftRequestError: {
            return {
                ...state,
                isCreated: false,
            };
        }
        case NightShiftActionTypes.NightShiftUpdateRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftUpdateRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                },
                isLoading: false,
                updatedProperties: [],
            };
        }
        case NightShiftActionTypes.NightShiftUpdateRequestError: {
            return {
                ...state,
                isLoading: false,
                isLocked: action.payload.unlockForm ? false : state.isLocked,
            };
        }

        case NightShiftActionTypes.NightShiftUpdateProperty: {
            return {
                ...state,
                updatedProperties: action.payload.updatedProperties,
                form: {
                    ...state.form,
                    [action.payload.key]: Array.isArray(action.payload.value)
                        ? [...action.payload.value]
                        : action.payload.value,
                },
            };
        }
        case NightShiftActionTypes.NightShiftClear: {
            const newNightShift = new NightShift();
            return {
                ...state,
                isLocked: false,
                form: {
                    ...newNightShift,
                },
                updatedProperties: [],
            };
        }
        case NightShiftActionTypes.NightShiftRemoveCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftRemoveCommentSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    comments: [...state.form.comments.filter((c) => c.id !== action.payload.comment.id)],
                },
                isLoading: false,
            };
        }
        case NightShiftActionTypes.NightShiftRemoveCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftActionTypes.NightShiftAddCommentRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftAddCommentSuccess: {
            const comment = Object.assign({}, action.payload.comment, { isLoading: false });
            return {
                ...state,
                isLoading: false,
                form: {
                    ...state.form,
                    comments: [comment, ...state.form.comments],
                },
            };
        }
        case NightShiftActionTypes.NightShiftAddCommentError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case NightShiftActionTypes.NightShiftHistoryRequest: {
            return {
                ...state,
                isHistoryLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftHistoryRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    history: [...action.payload],
                },
                isHistoryLoading: false,
            };
        }
        case NightShiftActionTypes.NightShiftHistoryRequestError: {
            return {
                ...state,
                isHistoryLoading: false,
            };
        }

        case NightShiftActionTypes.NightShiftAutosaveRequest: {
            return {
                ...state,
                isAutosaveInProgress: true,
            };
        }
        case NightShiftActionTypes.NightShiftAutosaveSuccess: {
            return {
                ...state,
                isAutosaveInProgress: false,
                form: action.payload,
                updatedProperties: [],
            };
        }
        case NightShiftActionTypes.NightShiftAutosaveError: {
            return {
                ...state,
                isAutosaveInProgress: false,
            };
        }

        case NightShiftActionTypes.LockNightShiftFormIfNoChanges: {
            let isLocked = false;
            if (state.updatedProperties.length === 0) {
                isLocked = true;
            }

            return {
                ...state,
                isLocked,
            };
        }

        case NightShiftActionTypes.LockNightShiftForm: {
            return {
                ...state,
                isLocked: true,
            };
        }

        case NightShiftActionTypes.UnLockNightShiftForm: {
            return {
                ...state,
                isLocked: false,
            };
        }
        case NightShiftActionTypes.NightShiftUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftUpdateStatusRequestSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload,
                    SupportingDocumentAttachments: state.form.SupportingDocumentAttachments,
                    OldSupportingDocumentAttachments: state.form.SupportingDocumentAttachments,
                },
                isLoading: false,
                updatedProperties: [],
            };
        }
        case NightShiftActionTypes.NightShiftSaveAndUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftUpdateStatusRequestError: {
            return {
                ...state,
                isLoading: false,
                isLocked: action.payload.unlockForm ? false : state.isLocked,
            };
        }
        case NightShiftActionTypes.NightShiftSaveAndUpdateStatusRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case NightShiftActionTypes.NightShiftAttachmentsSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                    [`Old${attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                },
            };
        }
        case NightShiftActionTypes.NightShiftDownloadAttachmentRequest: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.attachment.name
                                            ? true
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        case NightShiftActionTypes.NightShiftDownloadAttachmentSuccess: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.fileName
                                            ? false
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        case NightShiftActionTypes.NightShiftDownloadAttachmentError: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [`${attachmentTypes[action.payload.type]}Attachments`]: [
                        ...state.form[`${attachmentTypes[action.payload.type]}Attachments`].map(
                            (attachment: Attachment) => {
                                return {
                                    ...attachment,
                                    isBeingProcessed:
                                        attachment.name === action.payload.attachment.name
                                            ? false
                                            : attachment.isBeingProcessed,
                                };
                            }
                        ),
                    ],
                },
            };
        }
        default: {
            return state;
        }
    }
}
