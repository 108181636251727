<div class="button-container flex">
    <button
        mat-icon-button
        class="sort-button"
        [ngClass]="{ 'sort-button-active': isAscendingSortToggle === true }"
        (click)="onAscendingSort()"
    >
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <!-- Generator: sketchtool 62 (101010) - https://sketch.com -->
            <title>Ascending</title>
            <desc>Created with sketchtool.</desc>
            <g
                id="Asset-Artboard-Page"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <g id="Icons-/-Blue-/-Sort-Asc-Copy" stroke="#979797" stroke-width="2">
                    <polyline
                        id="Line-7-Copy"
                        stroke-opacity="0.499726836"
                        transform="translate(11.127973, 10.457096) scale(1, -1) rotate(-180.000000) translate(-11.127973, -10.457096) "
                        points="9.16503595 5.72727273 9.08440434 15.1869187 13.1715412 11.0960097"
                    ></polyline>
                    <polyline
                        id="Line-7-Copy-2"
                        transform="translate(6.952659, 8.002550) scale(-1, 1) rotate(-180.000000) translate(-6.952659, -8.002550) "
                        points="4.98972252 3.27272727 4.90909091 12.7323732 8.99622774 8.64146424"
                    ></polyline>
                </g>
            </g>
        </svg>
        Ascending
    </button>
    <button
        mat-icon-button
        class="sort-button"
        [ngClass]="{ 'sort-button-active': isDescendingSortToggle === true }"
        (click)="onDescendingSort()"
    >
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <!-- Generator: sketchtool 62 (101010) - https://sketch.com -->
            <title>Descending</title>
            <desc>Created with sketchtool.</desc>
            <g
                id="Asset-Artboard-Page"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <g id="Icons-/-Blue-/-Sort-Asc" stroke="#979797" stroke-width="2">
                    <polyline
                        id="Line-7-Copy"
                        stroke-opacity="0.499726836"
                        transform="translate(11.127973, 10.457096) scale(1, -1) rotate(-180.000000) translate(-11.127973, -10.457096) "
                        points="9.16503595 5.72727273 9.08440434 15.1869187 13.1715412 11.0960097"
                    ></polyline>
                    <polyline
                        id="Line-7-Copy-2"
                        transform="translate(6.952659, 8.002550) scale(-1, 1) rotate(-180.000000) translate(-6.952659, -8.002550) "
                        points="4.98972252 3.27272727 4.90909091 12.7323732 8.99622774 8.64146424"
                    ></polyline>
                </g>
            </g>
        </svg>
        Descending
    </button>
</div>
