import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-lun-originator-section',
  templateUrl: './lun-originator-section.component.html',
  styleUrls: ['./lun-originator-section.component.scss']
})
export class LunOriginatorSectionComponent extends BaseComponent implements OnInit {
  @Input() lunForm: UntypedFormGroup;
  constructor() {
    super();
   }

  ngOnInit(): void {
  }

}
