import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { ActivatedRoute } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { ManualUploadSetActivatedRoute } from 'src/app/store/manual-upload/actions';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import {
    MasterSubsystemListDeltaDTO,
    MasterSubsystemListDeleteDTO,
} from 'src/app/store/manual-upload/master-subsystem-list/model';
import {
    MasterSubsystemListPatchDeltaRequest,
    MasterSubsystemListPatchDeleteRecordRequest,
    MasterSubsystemListTemplateFileRequest,
    MasterSubsystemListDeltaFilterPropertyUpdate,
    MasterSubsystemListDeltaRequest,
    MasterSubsystemListDeleteFilterPropertyUpdate,
    MasterSubsystemListDeleteDataRequest,
    MasterSubsystemListDownloadOutputDataRequest,
    MasterSubsystemListPatchAllDeltaRequest,
    MasterSubsystemListPatchDeleteAllRecordsRequest,
} from 'src/app/store/manual-upload/master-subsystem-list/actions';

@Component({
    selector: 'app-master-subsystem-list',
    templateUrl: './master-subsystem-list.component.html',
    styleUrls: ['./master-subsystem-list.component.scss'],
})
export class MasterSubsystemListComponent extends BaseComponent implements OnInit {
    uploadLogData: MatTableDataSource<UpdateDataLog>;
    displayedUploadLogDataColumns = [
        'type',
        'status',
        'startDate',
        'endDate',
        'infoMessage',
        'errorMessage',
        'user',
        'downloadResult',
    ];
    deltaData: MatTableDataSource<MasterSubsystemListDeltaDTO>;
    deltaDataResultsLength = 0;
    deltaDataPageSize = 10;
    displayedDeltaDataColumns = [
        'subsystem',
        'scm',
        'systemOwner',
        'mcEngineer',
        'contractor',
        'acm',
        'waypoint',
        'milestone',
        'projectTeamName',
        'actions',
    ];
    deleteData: MatTableDataSource<MasterSubsystemListDeleteDTO>;
    deleteDataResultsLength = 0;
    deleteDataPageSize = 10;
    displayedDeleteDataColumns = [
        'subsystem',
        'subsystemName',
        'projectTeamName',
        'mcEngineer',
        'systemOwner',
        'actions',
    ];

    uploadLogData$ = this.store.select((store) => store.masterSubsystemListState.uploadLogData);
    isUploadLogLoading$ = this.store.select((store) => store.masterSubsystemListState.isUploadLogLoading);
    deltaData$ = this.store.select((store) => store.masterSubsystemListState.deltaData);
    isDeltaDataLoading$ = this.store.select((store) => store.masterSubsystemListState.isDeltaDataLoading);
    deleteData$ = this.store.select((store) => store.masterSubsystemListState.deleteData);
    isDeleteDataLoading$ = this.store.select((store) => store.masterSubsystemListState.isDeleteDataLoading);
    isLoading$ = this.store.select((store) => store.masterSubsystemListState.isLoading);
    showOrder = true;

    constructor(private store: Store<ApplicationState>, private activatedRoute: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.activatedRoute.url.pipe(take(1)).subscribe((urlSegments) => {
            this.store.dispatch(new ManualUploadSetActivatedRoute(urlSegments[0].path));
        });

        this.uploadLogData$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((uploadLogData) => (this.uploadLogData = new MatTableDataSource(uploadLogData)));

        this.deltaData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
            this.deltaData = new MatTableDataSource(deltaData.items);
            this.deltaDataResultsLength = deltaData.totalCount;
        });

        this.deleteData$.pipe(takeWhile(() => this.isAlive)).subscribe((deltaData) => {
            this.deleteData = new MatTableDataSource(deltaData.items);
            this.deleteDataResultsLength = deltaData.totalCount;
        });

        this.store.dispatch(new MasterSubsystemListDeltaRequest());
    }

    rejectChange(element: MasterSubsystemListDeltaDTO) {
        this.store.dispatch(
            new MasterSubsystemListPatchDeltaRequest({
                subsystem: element.subsystem,
                deltaState: false,
            })
        );
    }

    acceptChange(element: MasterSubsystemListDeltaDTO) {
        this.store.dispatch(
            new MasterSubsystemListPatchDeltaRequest({
                subsystem: element.subsystem,
                deltaState: true,
            })
        );
    }

    patchAllChanges(deltaState: boolean) {
        this.store.dispatch(
            new MasterSubsystemListPatchAllDeltaRequest({
                deltaState: deltaState,
            })
        );
    }

    deleteSubsystem(element: MasterSubsystemListDeleteDTO) {
        this.store.dispatch(
            new MasterSubsystemListPatchDeleteRecordRequest({
                subsystem: element.subsystem,
                deleteState: true,
            })
        );
    }

    restoreSubsystem(element: MasterSubsystemListDeleteDTO) {
        this.store.dispatch(
            new MasterSubsystemListPatchDeleteRecordRequest({
                subsystem: element.subsystem,
                deleteState: false,
            })
        );
    }

    patchAllDeleteRecords(deleteState: boolean) {
        this.store.dispatch(
            new MasterSubsystemListPatchDeleteAllRecordsRequest({
                deleteState: deleteState,
            })
        );
    }

    downloadTemplate(fileName: string) {
        this.store.dispatch(new MasterSubsystemListTemplateFileRequest(fileName));
    }

    onDeltaDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deltaDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new MasterSubsystemListDeltaFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deltaDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new MasterSubsystemListDeltaFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new MasterSubsystemListDeltaRequest());
    }

    onDeleteDataPaginatorChange(pageEvent: PageEvent) {
        if (this.deleteDataPageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new MasterSubsystemListDeleteFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.deleteDataPageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new MasterSubsystemListDeleteFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new MasterSubsystemListDeleteDataRequest());
    }

    downloadData(filePath: string) {
        this.store.dispatch(new MasterSubsystemListDownloadOutputDataRequest(filePath));
    }
}
