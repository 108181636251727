import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    MsalModule,
    MsalInterceptor,
    MsalService,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuard,
    MsalBroadcastService,
    MsalRedirectComponent,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LUNFormComponent } from './components/lun-form/lun-form.component';
import { NewLUNComponent } from './components/new-lun/new-lun.component';
import { NewNightShiftComponent } from './components/new-night-shift/new-night-shift.component';
import { LUNRegisterComponent } from './components/lun-register/lun-register.component';
import { NightShiftRegisterComponent } from './components/night-shift-register/night-shift-register.component';
import { LUNFormHeaderComponent } from './components/lun-form/lun-header/lun-header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { LUNButtonsComponent } from './components/lun-form/lun-buttons/lun-buttons.component';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { LUNCommentsComponent } from './components/lun-form/lun-comments/lun-comments.component';
import { LUNCommentsHistoryComponent } from './components/lun-form/lun-comments-history/lun-comments-history.component';
import { LUNAttachmentsComponent } from './components/lun-form/lun-attachments/lun-attachments.component';
import { LUNAttachmentSupportingInformationAttachmentComponent } from './components/lun-form/lun-attachments/app-lun-attachment-sia/lun-attachment-sia.component';
import { LUNWorkflowStatusComponent } from './components/lun-form/lun-workflow-status/lun-workflow-status.component';
import { LUNHistoryPopupComponent } from './components/lun-form/lun-history-popup/lun-history-popup.component';
import { NightShiftFormComponent } from './components/night-shift-form/night-shift-form.component';
import { NightShiftCommentsHistoryComponent } from './components/night-shift-form/night-shift-comments-history/night-shift-comments-history.component';
import { NightShiftAttachmentsComponent } from './components/night-shift-form/night-shift-attachments/night-shift-attachments.component';
import { NightShiftAttachmentSupportingInformationAttachmentComponent } from './components/night-shift-form/night-shift-attachments/app-night-shift-attachment-sia/night-shift-attachment-sia.component';
import { NightShiftWorkflowStatusComponent } from './components/night-shift-form/night-shift-workflow-status/night-shift-workflow-status.component';
import { NightShiftHistoryPopupComponent } from './components/night-shift-form/night-shift-history-popup/night-shift-history-popup.component';
import { NightShiftFormHeaderComponent } from './components/night-shift-form/night-shift-header/night-shift-header.component';
import { NightShiftButtonsComponent } from './components/night-shift-form/night-shift-buttons/night-shift-buttons.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { LUNMypageComponent } from './components/lun-mypage/lun-mypage.component';
import { LUNMyPendingActionsComponent } from './components/lun-mypage/lun-my-pending-actions/lun-my-pending-actions.component';
import { LUNSummaryComponent } from './components/lun-mypage/lun-summary/lun-summary.component';
import { LUNAllDraftsComponent } from './components/lun-mypage/lun-all-drafts/lun-all-drafts.component';
import { NumericDirective } from './directives/numeric.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { StoreModule } from './store/store.module';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MultipleItemsSelectorComponent } from './components/multiple-items-selector/multiple-items-selector.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeleteConfirmationPopupComponent } from './components/delete-confirmation-popup/delete-confirmation-popup.component';
import { CommentPopupComponent } from './components/comment-popup/comment-popup.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnumKeyValueListPipe } from './pipes/enumlist.pipe';
import { UserListPipe } from './pipes/userlist.pipe';
import { RangeDatePipe } from './pipes/date-range.pipe';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { AdminComponent } from './components/admin-section/admin.component';
import { DashboardComponent } from './components/admin-section/dashboard/dashboard.component';
import { NotAuthorizedComponent } from './components/error/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/error/not-found/not-found.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PopupService } from './services/shared/popup.service';
import { RoleService } from './services/shared/role.service';
import { ProjectTeamsService } from './services/project-teams.service';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { CacheInterceptor } from './interceptors/cache-interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { ColResizeDirective } from './directives/col-resize.directive';
import { NgxCurrencyModule } from 'ngx-currency';
import { AscDescOrderComponent } from './components/filter/asc-desc-order/asc-desc-order.component';
import { HeaderChecklistFilterComponent } from './components/filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from './components/filter/header-date-filter/header-date-filter.component';
import { HeaderRangeFilterComponent } from './components/filter/header-range-filter/header-range-filter.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSliderModule } from '@angular/material/slider';
import { LUNDelegationsComponent } from './components/lun-mypage/lun-delegations/lun-delegations.component';
import { DelegationsPopupComponent } from './components/lun-mypage/lun-delegations/delegations-popup/delegations-popup.component';
import {
    appInsightsConfig,
    APP_INSIGHTS_CONFIG,
    MSALGuardConfigFactory,
    MSALInscanceFactory,
    MSALInterceptorConfigFactory,
} from './app.config';
import { LUNShareEmailPopupComponent } from 'src/app/components/lun-form/lun-share-email-popup/lun-share-email-popup.component';
import { MentionModule } from 'angular-mentions';
import { LunSubsystemStatusComponent } from './components/lun-form/lun-subsystem-status/lun-subsystem-status.component';
import { CommentsHistoryComponent } from './components/comments-history/comments-history.component';
import { BreakLinePipe } from './pipes/break-line.pipe';
import { SubsystemListComponent } from './components/admin-section/manual-upload/subsystem-list/subsystem-list.component';
import { ManualUploadComponent } from './components/admin-section/manual-upload/manual-upload.component';
import { LunOriginatorSectionComponent } from './components/lun-form/lun-originator-section/lun-originator-section.component';
import { LunCheckOptionsComponent } from './components/lun-form/lun-check-options/lun-check-options.component';
import { LunWorkflowComponent } from './components/lun-form/lun-workflow/lun-workflow.component';
import { SettingsComponent } from './components/admin-section/configuration/settings/settings.component';
import { ConfigurationComponent } from './components/admin-section/configuration/configuration.component';
import { MasterSubsystemListComponent } from './components/admin-section/manual-upload/master-subsystem-list/master-subsystem-list.component';
import { MultipleAttachmentsComponent } from './components/multiple-attachments/multiple-attachments.component';
import { EmailDistributionComponent } from './components/admin-section/configuration/email-distribution/email-distribution.component';
import { ManualEmailDialogComponent } from './components/admin-section/configuration/email-distribution/manual-email-popup/manual-email-popup.component';
import { NgxTextDiffModule } from 'ngx-text-diff';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
};
@NgModule({
    declarations: [
        AppComponent,
        TopHeaderComponent,
        FooterComponent,
        NavbarComponent,
        EnumKeyValueListPipe,
        UserListPipe,
        RangeDatePipe,
        LUNFormComponent,
        NightShiftFormComponent,
        NewLUNComponent,
        LUNRegisterComponent,
        NightShiftRegisterComponent,
        LUNFormHeaderComponent,
        LUNButtonsComponent,
        NightShiftFormHeaderComponent,
        NightShiftButtonsComponent,
        EscapeHtmlPipe,
        LUNCommentsComponent,
        LUNWorkflowStatusComponent,
        LUNHistoryPopupComponent,
        LUNCommentsHistoryComponent,
        LUNAttachmentsComponent,
        LUNAttachmentSupportingInformationAttachmentComponent,
        NightShiftWorkflowStatusComponent,
        NightShiftHistoryPopupComponent,
        NightShiftCommentsHistoryComponent,
        NightShiftAttachmentsComponent,
        NightShiftAttachmentSupportingInformationAttachmentComponent,
        DragAndDropDirective,
        LUNMypageComponent,
        LUNMyPendingActionsComponent,
        LUNSummaryComponent,
        LUNAllDraftsComponent,
        NumericDirective,
        InfiniteScrollDirective,
        MultipleItemsSelectorComponent,
        InformationDialogComponent,
        ConfirmDialogComponent,
        DeleteConfirmationPopupComponent,
        CommentPopupComponent,
        LoadingIndicatorComponent,
        AdminComponent,
        DashboardComponent,
        NotAuthorizedComponent,
        NotFoundComponent,
        ColResizeDirective,
        AscDescOrderComponent,
        HeaderChecklistFilterComponent,
        HeaderDateFilterComponent,
        HeaderRangeFilterComponent,
        LUNDelegationsComponent,
        DelegationsPopupComponent,
        LUNShareEmailPopupComponent,
        LunSubsystemStatusComponent,
        CommentsHistoryComponent,
        BreakLinePipe,
        SubsystemListComponent,
        ManualUploadComponent,
        LunOriginatorSectionComponent,
        LunCheckOptionsComponent,
        LunWorkflowComponent,
        SettingsComponent,
        ConfigurationComponent,
        MasterSubsystemListComponent,
        NewNightShiftComponent,
        MultipleAttachmentsComponent,
        EmailDistributionComponent,
        ManualEmailDialogComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatMenuModule,
        MatRadioModule,
        MatTabsModule,
        MatToolbarModule,
        MatListModule,
        StoreModule,
        NgxMatSelectSearchModule,
        MatSlideToggleModule,
        ScrollingModule,
        MatSliderModule,
        ToastrModule.forRoot(),
        MsalModule,
        NgxCurrencyModule,
        MentionModule,
        NgxTextDiffModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        PopupService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        RoleService,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInscanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: APP_INSIGHTS_CONFIG, useValue: appInsightsConfig },
        MsalService,
        ProjectTeamsService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
