<div class="originator-section-container">
    <div [formGroup]="lunForm">
        <div class="col-header-container filter-container">
            <div>
                <span>
                    All of the following items must be verified and checked off, any exception must be documented in the
                    comments section below
                </span>
            </div>

            <div>
                <mat-checkbox formControlName="mcCertificateSigned">
                    The MC-DAC2 / MCC2 Certificate for this Sub-System has been issued and signed by all necessary
                    parties
                </mat-checkbox>
            </div>

            <div>
                <mat-checkbox formControlName="qvDsCompleted">
                    All outstanding QVD's "A" Punch List Items, Non-Conformance Reports (NCR) / Non Progressible Work
                    (NPW), and Exceptions have been reviewed, accepted, and excluded from the scope of this LUN
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox formControlName="markedupDrawingsAttached">
                    Marked-up drawings of equipment and power source are attached
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>
