import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, FormControl } from '@angular/forms';
import { NightShiftConstants } from './models/constants.model';

@Component({
    selector: 'app-night-shift-attachments',
    templateUrl: './night-shift-attachments.component.html',
    styleUrls: ['./night-shift-attachments.component.scss'],
})
export class NightShiftAttachmentsComponent {
    @Input() nightShiftForm: UntypedFormGroup;
    @Input() isDisabled = false;
    @Input() printMode = false;
    supportingDocsHeader = NightShiftConstants.SupportingDocumentsHeader;
}
