<mat-form-field [ngClass]="{ 'multiple-items-error': invalid }">
    <div *ngIf="isValueLoading" class="value-loader">
        <div class="lds-hourglass"></div>
    </div>
    <mat-chip-list #chipList [disabled]="isDisabled">
        <mat-chip
            *ngFor="let item of itemsSelected"
            [selectable]="!isDisabled"
            [removable]="!isDisabled"
            (removed)="remove(item)"
        >
            {{ propertyToShow ? item[propertyToShow] : item }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
            (keydown)="addManual($event, chipInput)"
            matInput
            [placeholder]="placeholderText"
            #chipInput
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            matChipInputAddOnBlur="true"
            [formControl]="itemCtrl"
        />
        <mat-error *ngIf="invalid">
            {{ errorMessage }}
        </mat-error>
    </mat-chip-list>
    <button
        [disabled]="isDisabled"
        type="button"
        *ngIf="openSearchPopupCallback"
        (click)="openSearchPopupCallback(); $event.stopPropagation()"
        mat-icon-button
        title="Search"
    >
        <mat-icon>search</mat-icon>
    </button>
    <mat-autocomplete
        (infiniteScroll)="onScroll()"
        class="wide-autocomplete"
        #auto="matAutocomplete"
        (closed)="closed()"
    >
        <ng-container *ngIf="!isLoading">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="contractno">
                    <th mat-header-cell *matHeaderCellDef>Contract No</th>
                    <td mat-cell *matCellDef="let element" [ngClass]="contractorClass">
                        {{ propertyToShow ? element[propertyToShow] : element.id }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>Id</th>
                    <td mat-cell *matCellDef="let element">
                        {{ propertyToShow ? element[propertyToShow] : element.id }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="no">
                    <th mat-header-cell *matHeaderCellDef>No</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.no }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.description }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="contractor">
                    <th mat-header-cell *matHeaderCellDef>Contractor</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.contract }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="tagNo">
                    <th mat-header-cell *matHeaderCellDef>Tag Number</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.tagNo }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="tagType">
                    <th mat-header-cell *matHeaderCellDef>Tag Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.tagType }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.email }}
                    </td>
                </ng-container>
                <tr
                    [hidden]="displayedColumns.length === 1 || noResultFound"
                    mat-header-row
                    *matHeaderRowDef="displayedColumns"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    (click)="selected(row)"
                    [ngClass]="{ 'tr-selected': row.Selected }"
                ></tr>
            </table>
        </ng-container>
        <mat-option *ngIf="dataSource.length" hidden></mat-option>
        <mat-option *ngIf="isLoading || noResultFound" disabled>
            <div *ngIf="isLoading" class="lds-hourglass"></div>
            <h4 *ngIf="noResultFound && !isLoading">No results found</h4>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
