import { OnDestroy, Component } from '@angular/core';
import { UserDetail, CheckListColumn, CalendarColumn, AppRole, RangeColumn } from '../store/common.model';
import { DelegationType } from '../store/my-page/models';
import _ from 'lodash';
import { ImportStatuses } from '../models/import-statuses';
import { LUNStatusType, NightShiftStatusType } from '../models/enums';
import { AccountInfo} from '@azure/msal-browser';

@Component({
    template: '',
})
export class BaseComponent implements OnDestroy {
    isAlive = true;
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    ngOnDestroy() {
        this.isAlive = false;
    }

    isNotNullAndNotUndefined(item: any): boolean {
        return item !== null && item !== undefined;
    }

    areUsersEqual(user: AccountInfo, decisionUser: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(user) &&
            this.isNotNullAndNotUndefined(decisionUser) &&
            user.username.toLowerCase() === decisionUser.email.toLowerCase()
        );
    }

    compareRoles(r1: AppRole, r2: AppRole): boolean {
        return AppRole[r1] === AppRole[r2];
    }

    compareDelegationTypes(r1: DelegationType, r2: DelegationType): boolean {
        return DelegationType[r1] === DelegationType[r2];
    }

    showUnderlineForCalendarColumn(calendarColumn: CalendarColumn): boolean {
        return (
            calendarColumn !== null &&
            (calendarColumn.rangeDates.length > 0 ||
                calendarColumn.excludeBlanks !== false ||
                calendarColumn.onlyBlanks !== false)
        );
    }

    showUnderlineForRangeColumn(rangeColumn: RangeColumn): boolean {
        return rangeColumn !== null && rangeColumn.ranges.length > 0;
    }

    showUnderlineForCheckListColumn(column: CheckListColumn): boolean {
        return (
            column !== null &&
            (column.items.length > 0 || column.excludeBlanks !== false || column.onlyBlanks !== false)
        );
    }

    arraysEqual(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

        var arr1 = _arr1.concat().sort();
        var arr2 = _arr2.concat().sort();

        for (var i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    findRole(types: { key: string; value: number }[], appRole: AppRole) {
        return types.find((s) => s.value === appRole).key;
    }

    compareArraysOfObjectsByProperty(array1: {}[], array2: {}[], property: string) {
        if (!Array.isArray(array1) || !Array.isArray(array2) || array1.length !== array2.length) return false;

        const a1 = _.sortBy(array1, property);
        const a2 = _.sortBy(array2, property);

        const result = _.isEqualWith(a1, a2, (v1, v2) => v1[property] === v2[property]);
        return result;
    }

    compareUsers(e1: UserDetail, e2: UserDetail): boolean {
        return e1 && e2 ? e1.email.toLowerCase() === e2.email.toLowerCase() : e1 === e2;
    }

    getImportClassName(status: string) {
        switch (status) {
            case ImportStatuses.Started:
                return 'started-status';
            case ImportStatuses.Finished:
                return 'finished-status';
            case ImportStatuses.Error:
                return 'error-status';
            case ImportStatuses.Info:
                return 'info-status';
            case ImportStatuses.Warning:
                return 'warning-status';
        }
    }

    getStatusName(status: LUNStatusType): string {
        return LUNStatusType[status];
    }

    getClassName(status: LUNStatusType) {
        switch (status) {
            case LUNStatusType.Draft:
                return 'draft-status';
            case LUNStatusType.Submitted:
                return 'submitted-status';
            case LUNStatusType.Reviewed:
                return 'reviewed-status';
            case LUNStatusType.Approved:
                return 'approved-status';
            case LUNStatusType.Cancelled:
                return 'cancelled-status';
            case LUNStatusType.Void:
                return 'void-status';
            default:
                return '';
        }
    }

    compareStatuses(r1: LUNStatusType, r2: LUNStatusType): boolean {
        return LUNStatusType[r1] === LUNStatusType[r2];
    }

    getNightShiftStatusName(status: NightShiftStatusType): string {
        return NightShiftStatusType[status];
    }

    getNightShiftClassName(status: NightShiftStatusType) {
        switch (status) {
            case NightShiftStatusType.Draft:
                return 'draft-status';
            default:
                return '';
        }
    }

    compareNightShiftStatuses(r1: NightShiftStatusType, r2: NightShiftStatusType): boolean {
        return NightShiftStatusType[r1] === NightShiftStatusType[r2];
    }
}
