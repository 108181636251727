import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { HttpParams, HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class AdminPanelService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly adminPanelEndpoint: string = '/admin';
    downloadFileSubject$ = new Subject<boolean>();

    constructor(private http: HttpClient) {}

    getLogs(types: string[]): Observable<UpdateDataLog[]> {
        let params = new HttpParams();
        types.forEach((type) => {
            params = params.append('types', type);
        });
        return this.http.get<UpdateDataLog[]>(`${this.rootUrl}${this.adminPanelEndpoint}/logs`, { params });
    }

    getDataInfo(types: string[]) {
        let params = new HttpParams();
        types.forEach((type) => {
            params = params.append('types', type);
        });
        return this.http.get(`${this.rootUrl}${this.adminPanelEndpoint}/getdatainfo`, { params });
    }

    uploadFiles(formData: FormData) {
        return this.http.post(`${this.rootUrl}${this.adminPanelEndpoint}/importdata`, formData);
    }

    downloadOutputData(filePath: string) {
        return this.http.get<BlobPart>(`${this.rootUrl}${this.adminPanelEndpoint}/downloaddata?filePath=${filePath}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }
}
