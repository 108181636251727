import {
    LUNMyPageState,
    MyPageCounters,
    MyPageCollection,
    MyPageLUNRow,
    MyPageFilter,
    LUNMyPageSections,
    DelegationsRow,
    DelegationFilter,
} from './models';
import { LUNMyPageActionTypes, LUNMyPageActions } from './actions';
import { Constants } from '../../constants';

const initialState: LUNMyPageState = {
    counters: new MyPageCounters(),
    isCountersSectionLoading: false,

    myPendingActionsPagination: new MyPageCollection<MyPageLUNRow>(),
    myPendingActionsFilter: new MyPageFilter(Constants.LUNDefaults.defaultSortField),
    isMyPendingActionsSectonLoading: false,

    allDraftsPagination: new MyPageCollection<MyPageLUNRow>(),
    allDraftsFilter: new MyPageFilter(Constants.LUNDefaults.defaultSortField),
    isAllDraftsSectionLoading: false,

    summaryPagination: new MyPageCollection<MyPageLUNRow>(),
    summaryFilter: new MyPageFilter(Constants.LUNDefaults.defaultSortField),
    isSummarySectionLoading: false,
    selectedSummarySection: LUNMyPageSections.Total,

    delegationsPagination: new MyPageCollection<DelegationsRow>(),
    isDelegationsSectionLoading: false,
    delegationsFilter: new DelegationFilter(Constants.LUNDefaults.defaultSortField),
    selectedItems: [],
};

export function reducer(state: LUNMyPageState = initialState, action: LUNMyPageActions) {
    switch (action.type) {
        case LUNMyPageActionTypes.MyPageCountersRequest: {
            return {
                ...state,
                isCountersSectionLoading: true,
            };
        }
        case LUNMyPageActionTypes.MyPageCountersRequestSuccess: {
            return {
                ...state,
                counters: action.payload,
                isCountersSectionLoading: false,
            };
        }
        case LUNMyPageActionTypes.MyPageCountersRequestError: {
            return {
                ...state,
                isCountersSectionLoading: false,
            };
        }

        case LUNMyPageActionTypes.MyPendingActionsRequest: {
            return {
                ...state,
                isMyPendingActionsSectonLoading: true,
            };
        }
        case LUNMyPageActionTypes.MyPendingActionsRequestSuccess: {
            return {
                ...state,
                isMyPendingActionsSectonLoading: false,
                myPendingActionsPagination: action.payload,
            };
        }
        case LUNMyPageActionTypes.MyPendingActionsRequestError: {
            return {
                ...state,
                isMyPendingActionsSectonLoading: false,
            };
        }
        case LUNMyPageActionTypes.MyPendingActionsUpdateFileterProperty: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    myPendingActionsFilter: {
                        ...state.myPendingActionsFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                };
            } else {
                return {
                    ...state,
                    myPendingActionsFilter: {
                        ...state.myPendingActionsFilter,
                        [action.payload.key]: Array.isArray(state.myPendingActionsFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }

        case LUNMyPageActionTypes.SummaryRequest: {
            return {
                ...state,
                isSummarySectionLoading: true,
            };
        }
        case LUNMyPageActionTypes.SummaryRequestSuccess: {
            return {
                ...state,
                isSummarySectionLoading: false,
                summaryPagination: action.payload,
            };
        }
        case LUNMyPageActionTypes.SummaryRequestError: {
            return {
                ...state,
                isSummarySectionLoading: false,
            };
        }
        case LUNMyPageActionTypes.SummaryUpdateFilterProperty: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    summaryFilter: {
                        ...state.summaryFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                };
            } else {
                return {
                    ...state,
                    summaryFilter: {
                        ...state.summaryFilter,
                        [action.payload.key]: Array.isArray(state.summaryFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }
        case LUNMyPageActionTypes.MyPageCountersSetSummarySection: {
            return {
                ...state,
                selectedSummarySection: action.payload,
                summaryFilter: initialState.summaryFilter,
            };
        }
        case LUNMyPageActionTypes.AllDraftsRequest: {
            return {
                ...state,
                isAllDraftsSectionLoading: true,
            };
        }
        case LUNMyPageActionTypes.AllDraftsRequestSuccess: {
            return {
                ...state,
                isAllDraftsSectionLoading: false,
                allDraftsPagination: action.payload,
            };
        }
        case LUNMyPageActionTypes.AllDraftsRequestError: {
            return {
                ...state,
                isAllDraftsSectionLoading: false,
            };
        }
        case LUNMyPageActionTypes.AllDraftsUpdateFilterProperty: {
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    allDraftsFilter: {
                        ...state.allDraftsFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                };
            } else {
                return {
                    ...state,
                    allDraftsFilter: {
                        ...state.allDraftsFilter,
                        [action.payload.key]: Array.isArray(state.allDraftsFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                };
            }
        }

        case LUNMyPageActionTypes.DelegationsRequest: {
            return {
                ...state,
                isDelegationsSectionLoading: true,
            };
        }
        case LUNMyPageActionTypes.DelegationsRequestSuccess: {
            let items = action.payload.items.map((item) => ({
                ...item,
                selected: state.selectedItems.map((s) => s.id).indexOf(item.id) > -1,
            }));
            let pagination = Object.assign({}, action.payload, { items });
            return {
                ...state,
                isDelegationsSectionLoading: false,
                delegationsPagination: pagination,
            };
        }
        case LUNMyPageActionTypes.DelegationsRequestError: {
            return {
                ...state,
                isDelegationsSectionLoading: false,
            };
        }
        case LUNMyPageActionTypes.DelegationsUpdateFilterProperty: {
            let selectedItems = state.selectedItems;
            if (action.payload.key === 'type') {
                selectedItems = [];
            }
            if (action.payload.key === 'sortBy') {
                return {
                    ...state,
                    delegationsFilter: {
                        ...state.delegationsFilter,
                        sortBy: action.payload.value.active,
                        direction: action.payload.value.direction,
                        page: 0,
                    },
                    selectedItems,
                };
            } else {
                return {
                    ...state,
                    delegationsFilter: {
                        ...state.delegationsFilter,
                        [action.payload.key]: Array.isArray(state.delegationsFilter[action.payload.key])
                            ? [...action.payload.value]
                            : action.payload.value,
                    },
                    selectedItems,
                };
            }
        }
        case LUNMyPageActionTypes.DelegationsToggleSelected: {
            let selectedItems = Object.assign(new Array<DelegationsRow>(), state.selectedItems);
            if (action.payload.selected) {
                selectedItems.push(action.payload.item);
            } else {
                let index = selectedItems.map((s) => s.id).indexOf(action.payload.item.id);
                if (index > -1) {
                    selectedItems.splice(index, 1);
                }
            }
            return {
                ...state,
                selectedItems: selectedItems,
            };
        }
        case LUNMyPageActionTypes.DelegationsSelectedAll: {
            let selectedItems = Object.assign(new Array<DelegationsRow>(), state.selectedItems);
            if (action.payload.selected) {
                selectedItems = selectedItems
                    .concat(state.delegationsPagination.items)
                    .filter((value, index, self) => self.map((s) => s.id).indexOf(value.id) === index);
            } else {
                selectedItems = selectedItems.filter(
                    (s) => state.delegationsPagination.items.findIndex((f) => f.id === s.id) === -1
                );
            }
            return {
                ...state,
                selectedItems: selectedItems,
            };
        }
        case LUNMyPageActionTypes.DelegationsAssignmentRequest: {
            return {
                ...state,
                isDelegationsSectionLoading: true,
            };
        }
        case LUNMyPageActionTypes.DelegationsAssignmentRequestSuccess: {
            return {
                ...state,
                isDelegationsSectionLoading: false,
                selectedItems: [],
            };
        }
        case LUNMyPageActionTypes.DelegationsAssignmentRequestError: {
            return {
                ...state,
                isDelegationsSectionLoading: false,
            };
        }

        default: {
            return state;
        }
    }
}
