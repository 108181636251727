import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopupService } from 'src/app/services/shared/popup.service';
import { RoleService } from 'src/app/services/shared/role.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PopupSettings } from 'src/app/models/popup-settings';
import { Constants } from 'src/app/constants';
import { DeleteConfirmationPopupComponent } from '../../delete-confirmation-popup/delete-confirmation-popup.component';
import { CommentPopupComponent } from '../../comment-popup/comment-popup.component';
import { LUNComment } from 'src/app/store/lun/model';
import { LUNRemoveCommentRequest, LUNAddCommentRequest } from 'src/app/store/lun/actions';
import moment from 'moment';
import { ApplicationState } from 'src/app/store/model';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    selector: 'app-lun-comments-history',
    templateUrl: './lun-comments-history.component.html',
    styleUrls: ['./lun-comments-history.component.scss'],
})
export class LUNCommentsHistoryComponent implements OnInit {
    @Input() comments: LUNComment[] = [];
    @Input() lunId: number = 0;
    @Input() lunNumber: string = '';
    @Input() printMode = false;
    displayedColumns: string[] = ['comment', 'user', 'date', 'delete'];
    user: AccountInfo;
    isAdmin = false;
    isReadOnly = false;
    status$ = this.store.select((state) => state.lunState.form.status);

    constructor(
        private store: Store<ApplicationState>,
        private popupSvc: PopupService,
        private roleService: RoleService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon(
            'del-icon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/del-icon.svg')
        );
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isAdmin = this.roleService.isInRole('Admin');
        this.isReadOnly = this.roleService.isReadOnly();
    }

    removeComment(element: LUNComment) {
        this.popupSvc.openPopup(
            new PopupSettings(DeleteConfirmationPopupComponent, 400, 130, {
                action: (value: boolean) => {
                    if (value) {
                        this.store.dispatch(
                            new LUNRemoveCommentRequest({ comment: Object.assign({}, element, { isLoading: true }) })
                        );
                    }
                },
            })
        );
    }

    openCommentPopup(event: any) {
        this.popupSvc.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                action: (value: string) => {
                    let comment = new LUNComment();
                    comment.lunId = this.lunId;
                    comment.date = moment();
                    comment.description = value;
                    this.store.dispatch(
                        new LUNAddCommentRequest({
                            comment: comment,
                        })
                    );
                },
            })
        );

        event.stopPropagation();
    }
}
