<main class="main">
    <form [formGroup]="lunForm">
        <app-lun-workflow-status [lunForm]="lunForm" (printEvent)="printLUN()"></app-lun-workflow-status>
        <div [ngClass]="{ 'is-sticky': isSticky }">
            <app-lun-buttons
                [lunForm]="lunForm"
                (disableFormEvent)="disableForm()"
                (enableFormEvent)="enableForm()"
                (disableReviewAndApproveSectionEvent)="disableReviewAndApproveSection()"
            ></app-lun-buttons>
        </div>
        <app-lun-header [lunForm]="lunForm"></app-lun-header>
        <app-lun-subsystem-status *ngIf="lunForm.value.subsystems && lunForm.value.subsystems[0] && lunForm.value.subsystems[0].projectTeamName !== '3GI'"></app-lun-subsystem-status>
        <div #scrollTargetDescription></div>
        <app-lun-check-options [lunForm]="lunForm"></app-lun-check-options>
        <app-lun-comments [lunForm]="lunForm" (openJodit)="openJoditPopup($event)"
            (descriptionSet)="onEditorValueSet($event)">
        </app-lun-comments>
        <app-lun-attachments [lunForm]="lunForm" [isDisabled]="isLocked" [printMode]="printMode"></app-lun-attachments>
        <app-lun-workflow [lunForm]="lunForm" class="page-break"></app-lun-workflow>
        <app-lun-comments-history
            [comments]="comments"
            [lunId]="lunId"
            [lunNumber]="lunNumber$ | async"
            [printMode]="printMode"
        ></app-lun-comments-history>
        <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
    </form>
</main>
<div class="editor-jodit-container hidden-jodit">
    <textarea id="editor"></textarea>
</div>