import { Component, OnInit, ChangeDetectorRef, HostListener, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LUN, LUNComment, Subsystem } from '../../store/lun/model';
import { FormService } from 'src/app/services/shared/form.service';
import { BaseComponent } from 'src/app/components/base.component';
import { LUNFormValidators } from '../../validators/lun-form-validators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
    takeWhile,
    debounceTime,
    startWith,
    mergeMap,
    map,
    filter,
    distinctUntilChanged,
    take,
    withLatestFrom,
    finalize,
    catchError,
} from 'rxjs/operators';
import { Store, ActionsSubject } from '@ngrx/store';
import {
    GetLUNRequest,
    LUNUpdateProperty,
    LUNClear,
    LockLUNForm,
    UnLockLUNForm,
    LUNActionTypes,
    LUNUpdateInitialFormWithAttachments,
    LUNAutosaveRequest,
} from '../../store/lun/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { Subscription, interval, Subject, combineLatest, defer, of } from 'rxjs';
import { Constants } from 'src/app/constants';
import { JoditDescriptionType } from '../../store/jodit-description/model';
import { JoditDescriptionSetType, JoditDescriptionPreserveBackButton } from '../../store/jodit-description/actions';
import { UserDetail } from 'src/app/store/common.model';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { ApplicationState } from 'src/app/store/model';
import { Account } from 'msal';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from 'src/app/services/shared/role.service';
import { appConfig } from 'src/app/app.config';
import { LUNAttachmentType } from 'src/app/store/lun-attachments/model';
import { LUNService } from 'src/app/services/lun.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Dictionary } from 'typescript-collections';
import { LUNStatusType } from 'src/app/models/enums';
import { AccountInfo } from '@azure/msal-browser';

declare const Jodit: any;

@Component({
    selector: 'app-lun-form',
    templateUrl: './lun-form.component.html',
    styleUrls: ['./lun-form.component.scss'],
})
export class LUNFormComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('scrollTargetDescription') lunDescription: ElementRef;

    jodit: any;
    tokenRefreshSubs: Subscription;
    tokenRefreshInterval = Constants.TokenRefreshInterval;
    tokenRefreshInterval$ = interval(this.tokenRefreshInterval);
    autosaveInterval = Constants.AutosaveInterval;
    autosaveInterval$ = interval(this.autosaveInterval);
    joditDescriptionType: JoditDescriptionType = null;
    editorValueChanged = new Subject<{ key: string; value: string }>();
    isSticky = false;
    user: AccountInfo = null;
    isLocked = false;
    lunForm: UntypedFormGroup;
    initialForm = new LUN();
    lunId: number;
    isLoading: boolean = true;
    originator: UserDetail = null;
    comments: LUNComment[];
    controlDataReady = new Dictionary<string, boolean>();
    attachmentTypes = LUNAttachmentType;
    lun$ = this.store.select((state) => state.lunState.form);
    updatedProperties$ = this.store.select((state) => state.lunState.updatedProperties);
    joditDescriptionType$ = this.store.select((state) => state.joditDescriptionState.type);
    lunNumber$ = this.store.select((state) => state.lunState.form.lunNumber);
    originator$ = this.store.select((state) => state.lunState.form.originator);
    id$ = this.store.select((state) => state.lunState.form.id);
    isLocked$ = this.store.select((state) => state.lunState.isLocked);
    subsystems$ = this.store.select((state) => state.lunState.form.subsystems);
    isAutosaveInProgress$ = this.store.select((state) => state.lunState.isAutosaveInProgress);
    isReadOnly = false;
    printMode = false;
    status: LUNStatusType;
    statuses = LUNStatusType;
    status$ = this.store.select((state) => state.lunState.form.status);
    initiaFormVersion$ = this.store.select((state) => state.lunState.initiaFormVersion);

    lunLoader$ = this.store.select(
        (state) =>
            state.lunState.isLoading ||
            state.actionUsersState.isAreaSCElectricalDisciplineLeadsLoading ||
            state.actionUsersState.isAreaSCManagersLoading ||
            state.actionUsersState.isAreaSCSMPDisciplineLeadsLoading ||
            state.actionUsersState.isAreaSCIAndCDisciplineLeadsLoading
    );
    comments$ = this.store.select((state) => state.lunState.form.comments);

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private validators: LUNFormValidators,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private popupService: PopupService,
        private authService: MsalService,
        private actionsSubject$: ActionsSubject,
        private roleService: RoleService,
        private lunService: LUNService,
        private toastService: ToastService
    ) {
        super();
        this.lunForm = this.formService.createForm(new LUN(), this.validators);
        this.controlDataReady.setValue('description', false);
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isReadOnly = this.roleService.isReadOnly();

        combineLatest([this.status$, this.originator$])
            .pipe(
                takeWhile(() => this.isAlive),
                filter(([status, originator]) => !!status && !!originator && !!originator.email),
                distinctUntilChanged(
                    ([prevStatus, prevOriginator], [nextStatus, nextOriginator]) =>
                        prevStatus === nextStatus && prevOriginator.email === nextOriginator.email
                )
            )
            .subscribe(([status, originator]) => {
                this.status = status;
                if (
                    status === LUNStatusType.Draft &&
                    originator.email.toLowerCase() === this.user.username.toLowerCase()
                ) {
                    this.enableForm();
                } else {
                    this.disableForm();
                }
            });

        this.store
            .select((store) => store.lunState.isLoading)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => {
                this.isLoading = isLoading;
            });

        this.lun$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((lun) => !!lun.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
            .subscribe((lun) => {
                this.lunForm.patchValue(lun, { emitEvent: false });
                this.lunId = lun.id;
                this.setInitialForm();
            });

        this.autosaveInterval$
            .pipe(
                takeWhile(() => this.isAlive),
                withLatestFrom(this.status$, this.originator$, this.isAutosaveInProgress$, this.updatedProperties$),
                filter(
                    ([, status, originator, isAutosaveInProgress, updatedProperties]) =>
                        this.areUsersEqual(this.roleService.getAccount(), originator) &&
                        status === LUNStatusType.Draft &&
                        !isAutosaveInProgress &&
                        updatedProperties.length > 0 &&
                        this.lunForm.valid
                )
            )
            .subscribe(() => {
                if (!this.isLoading) {
                    this.store.dispatch(new LUNAutosaveRequest(this.lunForm.value));
                }
            });

        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;

            if (isLocked) {
                this.disableFormControls();
            } else {
                this.enableFormControls();
            }
        });

        this.store
            .select((state) => state.lunState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.lunForm.controls['areaSCElectricalDisciplineLead'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.lunForm.controls['areaSCSMPDisciplineLead'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.lunForm.controls['areaSCIAndCDisciplineLead'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.lunForm.controls['leadIsolationAuthoritySMP'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.lunForm.controls['leadIsolationAuthorityElectrical'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });

                    this.lunForm.controls['areaSCManager'].enable({
                        emitEvent: false,
                        onlySelf: true,
                    });
                }
            });

        this.editorValueChanged
            .pipe(
                takeWhile(() => this.isAlive),
                debounceTime(800)
            )
            .subscribe(({ key, value }) => {
                this.lunForm.controls[key].patchValue(value);
            });

        this.watchFormChanges();
        this.joditDescriptionType$.pipe(takeWhile(() => this.isAlive)).subscribe((type) => {
            this.joditDescriptionType = type;
        });

        this.setupJodit();
        this.comments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => (this.comments = comments));

        this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((params: ParamMap) => {
            const id = Number.parseInt(params.get('id'));
            this.store.dispatch(new GetLUNRequest(id));
            this.changeDetectorRef.markForCheck();
        });

        this.lunLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => (this.isLoading = isLoading));
        this.lunNumber$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((number) => this.lunForm.controls.lunNumber.setValue(number, { emitEvent: false }));

        this.actionsSubject$
            .pipe(
                takeWhile(() => this.isAlive),
                filter(
                    (action) =>
                        action.type === LUNActionTypes.LUNUpdateRequestSuccess ||
                        action.type === LUNActionTypes.LUNAutosaveSuccess
                )
            )
            .subscribe(() => this.setInitialForm());

        this.actionsSubject$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((action) => action.type === LUNActionTypes.LUNUpdateInitialFormWithAttachments)
            )
            .subscribe((action: LUNUpdateInitialFormWithAttachments) => {
                this.initialForm = {
                    ...this.initialForm,
                    [`${this.attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                };
                this.lunForm.controls[`${this.attachmentTypes[action.payload.type]}Attachments`].setValue(
                    action.payload.attachments.attachments,
                    { emitEvent: false }
                );
            });

        this.initiaFormVersion$
            .pipe(
                takeWhile(() => this.isAlive),
                distinctUntilChanged()
            )
            .subscribe((val) => {
                if (val > 0) {
                    this.setInitialForm();
                }
            });

        this.lunForm.markAllAsTouched();
    }

    disableFormControls() {
        this.lunForm.disable({ emitEvent: false });
        if (this.status === LUNStatusType.Draft) {
            this.lunForm.controls.originator.enable();
        }
    }

    enableFormControls() {
        this.lunForm.enable({ emitEvent: false });

        setTimeout(() => {
            if (this.status === LUNStatusType.Approved) {
                this.lunForm.controls['discipline'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }
            if (this.lunForm.controls.areaSCSMPDisciplineLeadReviewDate.value) {
                this.lunForm.controls['areaSCSMPDisciplineLeadCAI'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });

                this.lunForm.controls['areaSCSMPDisciplineLead'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }

            if (this.lunForm.controls.areaSCElectricalDisciplineLeadReviewDate.value) {
                this.lunForm.controls['areaSCElectricalDisciplineLeadCAI'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });

                this.lunForm.controls['areaSCElectricalDisciplineLead'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }

            if (this.lunForm.controls.areaSCIAndCDisciplineLeadReviewDate.value) {
                this.lunForm.controls['areaSCIAndCDisciplineLeadCAI'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });

                this.lunForm.controls['areaSCIAndCDisciplineLead'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }

            if (this.lunForm.controls.leadIsolationAuthoritySMPReviewDate.value) {
                this.lunForm.controls['leadIsolationAuthoritySMPCAI'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });

                this.lunForm.controls['leadIsolationAuthoritySMP'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }

            if (this.lunForm.controls.leadIsolationAuthorityElectricalReviewDate.value) {
                this.lunForm.controls['leadIsolationAuthorityElectricalCAI'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });

                this.lunForm.controls['leadIsolationAuthorityElectrical'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }

            if (this.lunForm.controls.areaSCManagerReviewDate.value) {
                this.lunForm.controls['areaSCManagerCAI'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });

                this.lunForm.controls['areaSCManager'].disable({
                    emitEvent: false,
                    onlySelf: true,
                });
            }

            if (this.status !== LUNStatusType.Draft) {
                this.lunForm.controls.originatorCAI.disable();
                this.lunForm.controls.originator.disable();
            }
        });
    }

    subsystemComparer = (a: Subsystem, b: Subsystem) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0);

    getUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.initialForm.id)) {
            return result;
        }
        for (const key of Object.keys(this.lunForm.controls).filter(
            (e) =>
                e !== 'NoMarkupDocuments' &&
                e !== 'SupportingInformationDocuments' &&
                e !== 'AFCDocuments' &&
                e !== 'Comments' &&
                e !== 'nteUsd' &&
                (e.indexOf('Date') < 0 || e === 'lunDate')
        )) {
            try {
                if (
                    (!this.isNotNullAndNotUndefined(this.initialForm[key]) || this.initialForm[key] === '') &&
                    (!this.isNotNullAndNotUndefined(this.lunForm.controls[key].value) ||
                        this.lunForm.controls[key].value === '')
                ) {
                    continue;
                }
                if (
                    (key === 'SupportingDocumentAttachments' || key === 'EstimateAttachments') &&
                    !this.compareArraysOfObjectsByProperty(
                        this.initialForm[key],
                        this.lunForm.controls[key].value,
                        'name'
                    )
                ) {
                    result.push(key);
                } else if (key === 'subsystems') {
                    const initialSubs = [...this.initialForm[key]];
                    const initial = JSON.stringify(initialSubs.sort(this.subsystemComparer));

                    const formSubs = [...this.lunForm.controls[key].value];
                    const changed = JSON.stringify(formSubs.sort(this.subsystemComparer));
                    if (initial !== changed) {
                        result.push(key);
                    }
                } else if (JSON.stringify(this.initialForm[key]) !== JSON.stringify(this.lunForm.value[key])  && !(key.toString().includes('IsolationAuthority'))) {
                    result.push(key);
                }
            } catch (e) {
                console.log('KEY ' + key);
            }
        }

        return result;
    }

    watchFormChanges() {
        for (const key of Object.keys(this.lunForm.controls)) {
            this.lunForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getUpdatedProperties();
                this.store.dispatch(
                    new LUNUpdateProperty({
                        key,
                        value,
                        updatedProperties,
                    })
                );
            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(new LUNClear());
    }

    onEditorValueSet(event: { controlName: string; value: string }) {
        this.lunForm.controls[event.controlName].patchValue(event.value, { emitEvent: false });
        this.controlDataReady.setValue(event.controlName, true);
        this.setInitialForm();
    }

    private setInitialForm() {
        if (this.isNotNullAndNotUndefined(this.lunForm.controls['id']?.value)) {
            for (const key of Object.keys(this.lunForm.controls)) {
                const dataReady = this.controlDataReady.getValue(key) || true;
                if (dataReady) {
                    this.initialForm[key] = JSON.parse(JSON.stringify(this.lunForm.controls[key].value));
                }
            }
        }
    }

    disableReviewAndApproveSection() {
        this.lunForm.controls.originator.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.routeDate.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.approveDate.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.approver.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.costEngineerReviewDate.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.costEngineer.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.contractsEngineerReviewDate.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.contractsEngineer.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.nbCosterSubmitDate.disable({ emitEvent: false, onlySelf: true });
        this.lunForm.controls.nbCoster.disable({ emitEvent: false, onlySelf: true });
    }

    enableForm() {
        this.store.dispatch(new UnLockLUNForm());
    }

    disableForm() {
        this.store.dispatch(new LockLUNForm());
    }

    private setupJodit() {
        this.jodit = new Jodit('#editor', {
            language: 'en',
            uploader: {
                url: `${appConfig.apiEndpoint}/image`,
                headers: {
                    Authorization: '',
                },
                prepareData: (formData: FormData) => {
                    formData.append('lunId', this.lunId.toString());
                },
                error: () => {
                    this.popupService.openPopup(
                        new PopupSettings(
                            InformationDialogComponent,
                            null,
                            null,
                            {
                                title: 'Not allowed image format',
                                text: 'Images are allowed only in jpg, jpeg, bmp and png format.',
                            },
                            1,
                            1
                        )
                    );
                },
            },
            buttons: `|,bold,strikethrough,underline,italic,|,
                superscript,subscript,|,ul,ol,|,outdent,indent,|,
                font,fontsize,brush,paragraph,|,image,table,link,|,
                align,undo,redo,\n,cut,hr,eraser,copyformat,|,symbol`,
            extraButtons: [
                {
                    name: 'FullWindow',
                    iconURL: 'assets/images/icons/close.svg',
                    exec: (editor) => {
                        editor.toggleFullSize();
                        document.getElementsByClassName('editor-jodit-container')[0].className += ' hidden-jodit';
                        this.scrollToElement();
                        this.store.dispatch(new JoditDescriptionSetType(null));
                        this.tokenRefreshSubs.unsubscribe();
                    },
                },
            ],
            events: {
                change: (newValue) => {
                    if (this.joditDescriptionType !== null) {
                        if (newValue.indexOf(';base64,') > -1 || newValue.indexOf('src="file:') > -1) {
                            if (this.joditDescriptionType === JoditDescriptionType.ContractorsEstimate) {
                                this.jodit.value = this.lunForm.controls.EstimateDetails.value
                                    ? this.lunForm.controls.EstimateDetails.value || ''
                                    : '';
                            } else if (this.joditDescriptionType === JoditDescriptionType.Description) {
                                this.jodit.value = this.lunForm.controls.NoticeInstruction.value
                                    ? this.lunForm.controls.NoticeInstruction.value || ''
                                    : '';
                            }
                            this.popupService.openPopup(
                                new PopupSettings(
                                    InformationDialogComponent,
                                    null,
                                    null,
                                    {
                                        title: 'Paste not allowed for the images',
                                        text: 'Please use Insert Image option from toolbar menu to insert an image.',
                                    },
                                    1,
                                    1
                                )
                            );
                        } else {
                            this.editorValueChanged.next({
                                key: this.joditDescriptionType.toString(),
                                value: newValue,
                            });
                        }
                    }
                },
            },
        });
    }

    openJoditPopup(event: { description: string; type: JoditDescriptionType }) {
        document.getElementsByClassName('editor-jodit-container')[0].className = document
            .getElementsByClassName('editor-jodit-container')[0]
            .className.replace('hidden-jodit', '');
        this.store.dispatch(new JoditDescriptionSetType(event.type));
        this.jodit.value = event.description || '';
        this.jodit.toggleFullSize(true);
        this.tokenRefreshSubs = this.tokenRefreshInterval$
            .pipe(
                takeWhile(() => this.isAlive),
                startWith(0),
                mergeMap(() =>
                    this.authService.acquireTokenSilent({ scopes: ['user.read'] }).pipe(
                        map((response) => {
                            this.jodit.uploader.options.headers = { Authorization: `Bearer ${response.accessToken}` };
                        })
                    )
                )
            )
            .subscribe();
    }

    @HostListener('window:popstate')
    onPopState() {
        if (this.joditDescriptionType !== null) {
            this.jodit.toggleFullSize();
            document.getElementsByClassName('editor-jodit-container')[0].className += ' hidden-jodit';
            this.tokenRefreshSubs.unsubscribe();
            this.scrollToElement();
            this.store.dispatch(new JoditDescriptionSetType(null));
            this.store.dispatch(new JoditDescriptionPreserveBackButton(true));
        }
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        this.isSticky = window.pageYOffset >= 250;
    }

    printLUN() {
        this.isLoading = true;
        this.lunService
            .generatePdf(this.lunId)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    this.isLoading = true;
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, 'LUN Proforma.pdf');
            });
    }

    private scrollToElement() {
        this.lunDescription.nativeElement.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
}
