<div class="popup">
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">Share LUN</span>
    </p>
    <div>
        <form [formGroup]="emailForm">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input
                    [mention]="users"
                    [mentionConfig]="mentionConfig"
                    matInput
                    formControlName="email"
                    placeholder="email@chevron.com / @username"
                    autocomplete="off"
                    required
                />
                <mat-error *ngIf="emailForm.controls['email'].invalid && emailForm.controls['email'].errors.required">
                    Email is required.
                </mat-error>
            </mat-form-field>
            <div class="row">
                <button type="button" class="lun-button lun-button-white margin-right20" (click)="onCancel()">
                    cancel
                </button>
                <button
                    type="button"
                    [disabled]="!emailForm.valid"
                    class="lun-button lun-button-light"
                    (click)="sendEmail()"
                >
                    send
                </button>
            </div>
            <app-loading-indicator *ngIf="loading"> </app-loading-indicator>
        </form>
    </div>
</div>
