import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { LUNService } from 'src/app/services/lun.service';
import { NightShiftService } from 'src/app/services/night-shift.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { AttachmentDTO } from 'src/app/store/lun-register/model';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-multiple-attachments',
    templateUrl: './multiple-attachments.component.html',
    styleUrls: ['./multiple-attachments.component.scss'],
})
export class MultipleAttachmentsComponent extends BaseComponent {
    displayedColumns: string[] = ['id', 'name', 'link'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    resultsLength = 0;
    pageSize = 10;
    sortBy = 'id';
    direction = 'asc';
    isLoading = false;
    dataSource = new MatTableDataSource();
    constructor(
        public dialogRef: MatDialogRef<MultipleAttachmentsComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private lunService: LUNService,
        private nightShiftService: NightShiftService,
        private toastService: ToastService
    ) {
        super();

        this.dataSource = new MatTableDataSource(data.multiAttach);
        this.resultsLength = data.multiAttach.length;
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
    }

    public onCancel(): void {
        this.dialogRef.close();
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'id':
                    return item[property].name;
                default:
                    return item[property];
            }
        };
    }
    download(attachment: AttachmentDTO, e) {
        e.stopPropagation();
        e.preventDefault();
        this.isLoading = true;
        (attachment.link.indexOf('lun/') > -1
            ? this.lunService.downloadAttachment(attachment.link)
            : this.nightShiftService.downloadAttachment(attachment.link)
        )
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }
}
