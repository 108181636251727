import { Moment } from 'moment';

export class UserDetail {
    id = null;
    cai = '';
    name = '';
    position = '';
    email = '';
    areaManagerSharedEmail = '';
}

export class UserWithRoleDetail extends UserDetail {
    role: AppRole;
}

export class Contractor {
    contractNo = '';
    contract = '';
    tcopm = '';
    mcPlusId = '';
    projectTeamName = '';
    masterSubsystemListKey = '';
    name = '';
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export enum AppRole {
    None = 0,
    Admin = 1,
    ReadOnly = 2,
    EditUser = 4,
}

export class CalendarColumn {
    rangeDates: Date[] = [];
    excludeBlanks = false;
    onlyBlanks = false;
}

export class CheckListColumn {
    items: string[] = [];
    excludeBlanks = false;
    onlyBlanks = false;
}

export class RangeColumn {
    ranges: number[] = [];
}

export class AreaDTO {
    id: number;
    code: string;
    name: string;
}
