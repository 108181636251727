<h2 mat-dialog-title class="title">manual email notification</h2>
<mat-dialog-content>
    <p>
        Your email distribution list is to long and was divided into {{ mailUrls.length }} chunks. Please click each
        link to show email popup.
    </p>
    <ul>
        <li *ngFor="let url of mailUrls; let i = index">
            <a href="{{ url }}">Email popup {{ i + 1 }}</a>
        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <ng-container>
        <button class="lun-button lun-button-white" [mat-dialog-close]="false">cancel</button>
    </ng-container>
</mat-dialog-actions>
