import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { BaseComponent } from '../base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/shared/toast.service';

@Component({
    selector: 'app-comment-popup',
    templateUrl: './comment-popup.component.html',
    styleUrls: ['./comment-popup.component.scss'],
})
export class CommentPopupComponent extends BaseComponent implements OnInit, OnDestroy {
    comment: string;
    action: (value: string) => void;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CommentPopupComponent>,
        private toastService: ToastService
    ) {
        super();

        this.comment = data.comment;
        this.action = data.action;
    }

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }

    onSubmit() {
        if (this.comment === undefined || this.comment === null || this.comment.trim().length === 0) {
            this.toastService.Info('description can not be empty.');
        } else {
            this.action(this.comment);
            this.dialogRef.close();
        }
    }
}
