import { ActionUsersActionTypes, ActionUsersAction } from './actions';
import { ActionUsersState } from './model';

const initialState: ActionUsersState = {
    areaSCElectricalDisciplineLeads: [],
    isAreaSCElectricalDisciplineLeadsLoading: false,
    areaSCSMPDisciplineLeads: [],
    isAreaSCSMPDisciplineLeadsLoading: false,
    areaSCIAndCDisciplineLeads: [],
    isAreaSCIAndCDisciplineLeadsLoading: false,
    areaSCManagers: [],
    isAreaSCManagersLoading: false,
    leadIsolationAuthoritiesSMP: [],
    leadIsolationAuthoritiesElectrical: [],
    isIsolationAuthoritiesSMPLoading: false,
    isIsolationAuthoritiesElectricalLoading: false
};

export function reducer(state: ActionUsersState = initialState, action: ActionUsersAction): ActionUsersState {
    switch (action.type) {
        case ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequest: {
            return {
                ...state,
                isAreaSCElectricalDisciplineLeadsLoading: true,
            };
        }
        case ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequestSuccess: {
            return {
                ...state,
                areaSCElectricalDisciplineLeads: action.payload,
                isAreaSCElectricalDisciplineLeadsLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequestError: {
            return {
                ...state,
                isAreaSCElectricalDisciplineLeadsLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequest: {
            return {
                ...state,
                isAreaSCSMPDisciplineLeadsLoading: true,
            };
        }
        case ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequestSuccess: {
            return {
                ...state,
                areaSCSMPDisciplineLeads: action.payload,
                isAreaSCSMPDisciplineLeadsLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequestError: {
            return {
                ...state,
                isAreaSCSMPDisciplineLeadsLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequest: {
            return {
                ...state,
                isAreaSCIAndCDisciplineLeadsLoading: true,
            };
        }
        case ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequestSuccess: {
            return {
                ...state,
                areaSCIAndCDisciplineLeads: action.payload,
                isAreaSCIAndCDisciplineLeadsLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequestError: {
            return {
                ...state,
                isAreaSCIAndCDisciplineLeadsLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCManagersRequest: {
            return {
                ...state,
                isAreaSCManagersLoading: true,
            };
        }
        case ActionUsersActionTypes.AreaSCManagersRequestSuccess: {
            return {
                ...state,
                areaSCManagers: action.payload,
                isAreaSCManagersLoading: false,
            };
        }
        case ActionUsersActionTypes.AreaSCManagersRequestError: {
            return {
                ...state,
                isAreaSCManagersLoading: false,
            };
        }
        case ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequest: {
            return {
                ...state,
                isIsolationAuthoritiesSMPLoading: true,
            };
        }
        case ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequestSuccess: {
            return {
                ...state,
                leadIsolationAuthoritiesSMP: action.payload,
                isIsolationAuthoritiesSMPLoading: false,
            };
        }
        case ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequestError: {
            return {
                ...state,
                isIsolationAuthoritiesSMPLoading: false,
            };
        }
        case ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequest: {
            return {
                ...state,
                isIsolationAuthoritiesElectricalLoading: true,
            };
        }
        case ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequestSuccess: {
            return {
                ...state,
                leadIsolationAuthoritiesElectrical: action.payload,
                isIsolationAuthoritiesElectricalLoading: false,
            };
        }
        case ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequestError: {
            return {
                ...state,
                isIsolationAuthoritiesElectricalLoading: false,
            };
        }
        case ActionUsersActionTypes.AddPowerUserToAreaSCElectricalDisciplineLeads: {
            return {
                ...state,
                areaSCElectricalDisciplineLeads: [action.payload, ...state.areaSCElectricalDisciplineLeads],
            };
        }
        case ActionUsersActionTypes.AddPowerUserToAreaSCSMPDisciplineLeads: {
            return {
                ...state,
                areaSCSMPDisciplineLeads: [action.payload, ...state.areaSCSMPDisciplineLeads],
            };
        }
        case ActionUsersActionTypes.AddPowerUserToAreaSCIAndCDisciplineLeads: {
            return {
                ...state,
                areaSCIAndCDisciplineLeads: [action.payload, ...state.areaSCIAndCDisciplineLeads],
            };
        }
        case ActionUsersActionTypes.AddPowerUserToAreaSCManagers: {
            return {
                ...state,
                areaSCManagers: [action.payload, ...state.areaSCManagers],
            };
        }
        default: {
            return state;
        }
    }
}
