import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupService } from '../../../services/lookup.service';
import { distinctUntilChanged, filter, take, takeWhile } from 'rxjs/operators';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { LUNLookupService } from 'src/app/services/lun-lookup.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { AreaDTO } from 'src/app/store/common.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { LUNSubsystemDetailsRequest } from 'src/app/store/lun/actions';
import { Observable } from 'rxjs';
import { SubsystemDetails } from 'src/app/store/lun/model';
import { FormService } from 'src/app/services/shared/form.service';
import { ProjectTeamsService } from 'src/app/services/project-teams.service';
@Component({
    selector: 'app-lun-header',
    templateUrl: './lun-header.component.html',
    styleUrls: ['./lun-header.component.scss'],
})
export class LUNFormHeaderComponent extends BaseComponent implements OnInit {
    @Input() lunForm: UntypedFormGroup;
    disciplines: string[] = [];
    areas: AreaDTO[] = [];
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    isSubsystemsLoading = false;
    isLocked = false;
    projectTeamNames: string[] = [];
    isLocked$ = this.store.select((state) => state.lunState.isLocked);
    mainForm$ = this.store.select((state) => state.lunState.form);
    subsystemDetails$: Observable<SubsystemDetails> = this.store.select((state) => state.lunState.form.subsystemDetails);
    subsystemDetailsForm: UntypedFormGroup;

    constructor(
        private lookupService: LookupService,
        private lunLookupService: LUNLookupService,
        private store: Store<ApplicationState>,
        private formService: FormService,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();
        this.subsystemDetailsForm = this.formService.createSimpleForm(new SubsystemDetails());
    }

    ngOnInit() {
        this.projectTeamNames = this.projectTeamsService.getTeams();
        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;
        });

        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((disciplines) => {
                this.disciplines = disciplines;
            });
        this.lunLookupService
            .getAreas()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((areas) => {
                this.areas = areas;
            });

        this.store
            .select((state) => state.lunState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.setSubsystemInput.emit(new SetInputEventArgs(false));
                }
            });
        if (!this.lunForm.controls.lunDate.value) {
            this.resetDate();
        }

        this.mainForm$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((form) => !!form.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id && prev.subsystems.filter(item => curr.subsystems.indexOf(item) < 0).length === 0)
            )
            .subscribe((form) => {
                if (form.id !== null) {
                    this.subsystemDetailsForm.patchValue({ ...new SubsystemDetails() })
                    this.store.dispatch(new LUNSubsystemDetailsRequest(form.subsystems[0]?.id));
                    this.subsystemDetails$
                        .pipe(
                            filter((subsystemDetails) => subsystemDetails !== null && subsystemDetails.id === form.subsystems[0]?.id),
                            take(1)
                        )
                        .subscribe((subsystemDetails) => {
                            this.subsystemDetailsForm.patchValue({ ...subsystemDetails });
                        });
                }
            });
    }

    resetDate() {
        this.lunForm.controls.lunDate.setValue(new Date());
    }

    dateChange(event: MatDatepickerInputEvent<Date>) {
        var date = moment(event.value).format("YYYY-MM-DD");
        this.lunForm.controls.lunDate.setValue(date);
    }

    ngAfterViewInit() {
        this.setSubsystemInput.emit(new SetInputEventArgs(true));
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.projectTeamNames);
    };

    displayMultipleSelected(values: any[], propertyName: string) {
        return values.map((x) => x[propertyName]).join(', ');
    }

    clearProperty(propertyName: string) {
        if (Array.isArray(this.lunForm.controls[propertyName].value)) {
            this.lunForm.controls[propertyName].setValue([]);
        } else {
            this.lunForm.controls[propertyName].setValue('');
        }
    }
}
