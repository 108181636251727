import {
    Component,
    ElementRef,
    ViewChild,
    Input,
    forwardRef,
    OnInit,
    EventEmitter,
    Output,
    ChangeDetectorRef,
} from '@angular/core';
import { Observable, timer, iif, of, Subject } from 'rxjs';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { startWith, debounce, tap, takeWhile, filter, switchMap, exhaustMap, scan } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { SetInputEventArgs } from '../../models/set-input';
import { ToastService } from '../../services/shared/toast.service';
import _ from 'lodash';

@Component({
    selector: 'app-multiple-items-selector',
    templateUrl: './multiple-items-selector.component.html',
    styleUrls: ['./multiple-items-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultipleItemsSelectorComponent),
            multi: true,
        },
    ],
})
export class MultipleItemsSelectorComponent extends BaseComponent implements ControlValueAccessor, OnInit {
    @Input() propertyToShow = 'id';
    @Input() placeholder = '';
    @Input() isAsync = false;
    @Input() canAddManually = false;
    @Input() invalid = false;
    @Input() isValueLoading = false;
    @Input() errorMessage = '';
    @Input() isTableAutoComplete = false;
    @Input() contractorClass = '';
    @Input() required: boolean;
    @Input() searchFunc: (id?: string, take?: number, page?: number) => Observable<any[]>;
    @Input() openSearchPopupCallback: () => void;
    @Input() setInput: EventEmitter<SetInputEventArgs>;
    @Input() displayedColumns = ['id'];
    @Input() setValue: any;
    @Input() filterForm: UntypedFormGroup;
    @Input() formSetter: string;
    @Input() oneItemMode = false;
    @Input() isDisabled = false;

    @Output() autocompleteClosed: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemRemoved: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('chipInput', { static: true }) chipInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

    itemsSelected: any[] = [];
    itemsAvailable: any[] = [];
    isLoading = false;
    placeholderText: string;
    noResultFound = false;
    dataSource: any[] = [];
    itemCtrl = new UntypedFormControl('');
    separatorKeysCodes: number[] = [ENTER, COMMA];
    nextPage$ = new Subject();
    previouslyEmittedItems = [];

    propagateChange = (_: any) => {};
    propagateTouched = (_: any) => {};

    constructor(private toastService: ToastService, private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    writeValue(value: any[]) {
        if (value) {
            this.dataSource.map((d) => {
                d = value.some((v) => this.compareItems(d, v))
                    ? { ...d, Selected: true }
                    : { ...d, Selected: undefined };
            });
            this.itemsSelected = [
                ...value.map((v) => {
                    return { ...v };
                }),
            ];
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.propagateTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
        if (isDisabled) {
            this.itemCtrl.disable({ emitEvent: false });
        } else {
            this.itemCtrl.enable({ emitEvent: false });
        }
    }

    ngOnInit(): void {
        if (this.setInput) {
            this.setInput.pipe(takeWhile(() => this.isAlive === true)).subscribe((args: SetInputEventArgs) => {
                if (args.itemList.length > 0) {
                    if (args.preserveInputValue) {
                        let result = _.unionBy(this.itemsSelected, args.itemList, this.propertyToShow);
                        this.itemsSelected = [
                            ...result.map((r) => {
                                return { ...r };
                            }),
                        ];
                        this.itemsSelected.forEach((x) => this.selected(x));
                        this.dataSource
                            .filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1)
                            .forEach((r) => (r.Selected = true));
                    } else {
                        this.itemsSelected = [
                            ...args.itemList.map((i) => {
                                return { ...i };
                            }),
                        ];
                        this.itemsSelected.forEach((x) => this.selected(x));
                        this.dataSource
                            .filter((r) => this.itemsSelected.findIndex((i) => this.compareItems(i, r)) !== -1)
                            .forEach((r) => (r.Selected = true));
                    }
                } else {
                    if (args.preserveInputValue) {
                        this.itemCtrl.setValue(this.itemCtrl.value);
                    } else {
                        this.itemCtrl.setValue(args.input);
                        this.chipInput.nativeElement.value = args.input;
                        this.itemsSelected = [];
                    }
                }
            });
        }
        if (this.setValue) {
            this.writeValue(this.setValue);
        }

        this.required = this.required !== undefined;
        this.placeholderText = this.required ? this.placeholder + ' *' : this.placeholder;
        if (this.searchFunc && !this.isAsync) {
            this.searchFunc()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe(
                    (data) => {
                        this.itemsAvailable = !this.isAsync
                            ? [
                                  ...data.map((d) => {
                                      return { ...d };
                                  }),
                              ]
                            : [];
                        this.dataSource = [
                            ...data.map((d) => {
                                if (this.itemsSelected.findIndex((i) => this.compareItems(i, d)) !== -1) {
                                    return { ...d, Selected: true };
                                }
                                return { ...d };
                            }),
                        ];
                        this.noResultFound = false;
                    },
                    () => {
                        this.toastService.Error(
                            'Error occurred while searching. Please contact Program Administrator.'
                        );
                    }
                );
        }

        this.itemCtrl.valueChanges
            .pipe(
                takeWhile(() => this.isAlive),
                tap(() => {
                    this.isLoading = this.isAsync;
                    this.changeDetectorRef.detectChanges();
                }),
                debounce(() => timer(this.isAsync ? 800 : 0)),
                startWith(''),
                filter((value) => typeof value === 'string' && this.searchFunc !== undefined),
                switchMap((value) => iif(() => this.isAsync, this.applyInfiniteScroll(value), of(this.filter(value))))
            )
            .subscribe((data) => {
                this.dataSource = [
                    ...data.map((d) => {
                        if (this.itemsSelected.findIndex((i) => this.compareItems(i, d)) !== -1) {
                            return { ...d, Selected: true };
                        }
                        return { ...d };
                    }),
                ];
                this.isLoading = false;
                this.noResultFound = data ? data.length === 0 : true;
                if (this.isAlive) {
                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    applyInfiniteScroll(value: string) {
        let currentPage = 0;
        return this.nextPage$.pipe(
            startWith(currentPage),
            exhaustMap(() => this.searchFunc(value, 10, currentPage)),
            takeWhile((newItems: any[]) => {
                if (newItems.length === 0 && currentPage === 0) {
                    this.isLoading = false;
                    this.noResultFound = true;
                    this.dataSource = [];
                }
                return newItems.length > 0;
            }),
            scan((allItems, newItems) => {
                if (currentPage === 0) {
                    allItems = [];
                }
                return allItems.concat(newItems);
            }, []),
            tap(() => currentPage++)
        );
    }

    remove = (item: string) => {
        const index = this.itemsSelected.indexOf(item);
        if (index >= 0) {
            this.itemsSelected[index].Selected = false;
            this.itemsSelected.splice(index, 1);
        }
        this.dataSource = this.dataSource.map((d) => {
            if (this.compareItems(d, item)) {
                return { ...d, Selected: false };
            }
            return { ...d };
        });

        if (this.filterForm) {
            this.filterForm.controls[this.formSetter].setValue(this.itemsSelected);
        }
        this.itemRemoved.emit(this.itemsSelected);
        this.previouslyEmittedItems = [...this.itemsSelected];
        this.changeDetectorRef.detectChanges();
    };

    selected = (item: any) => {
        if (!item.Selected && this.itemsSelected.findIndex((i) => this.compareItems(i, item)) === -1) {
            if (this.oneItemMode) {
                this.autocomplete.closePanel();
            }
            if (this.oneItemMode && this.itemsSelected.length === 1) {
                let previouslySelected = this.dataSource.find((r) => this.compareItems(r, this.itemsSelected[0]));
                if (previouslySelected) {
                    previouslySelected.Selected = false;
                }
                this.itemsSelected = [item];
            } else {
                this.itemsSelected.push(item);
            }

            item.Selected = true;
            this.dataSource.filter((r) => this.compareItems(r, item)).forEach((r) => (r.Selected = true));

            if (this.filterForm) {
                this.filterForm.controls[this.formSetter].setValue(this.itemsSelected);
            }
        }
        this.chipInput.nativeElement.blur();
        this.changeDetectorRef.detectChanges();
    };

    closed = () => {
        this.autocompleteClosed.emit(this.itemsSelected);
        this.chipInput.nativeElement.value = '';
        this.itemCtrl.setValue('');
    };

    getValue() {
        return this.itemsSelected
            .map((elem) => {
                return elem[this.propertyToShow];
            })
            .join(', ');
    }

    addManual(event: any, input: any) {
        if (this.canAddManually && event.keyCode === ENTER && input.value !== '') {
            if (this.itemsSelected.map((i) => i[this.propertyToShow]).indexOf(input.value) !== -1) {
                input.value = '';
                return;
            }
            const obj: any = {};
            obj[this.propertyToShow] = input.value;
            obj.Manual = true;
            this.itemsSelected.push(obj);
            this.propagateChange(this.itemsSelected);
            this.previouslyEmittedItems = [...this.itemsSelected];
            input.value = '';
        }
    }

    private filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return [
            ...this.itemsAvailable
                .filter((i) => i[this.propertyToShow].toLowerCase().indexOf(filterValue) === 0)
                .map((i) => {
                    return { ...i };
                }),
        ];
    }

    private compareItems(i1: any, i2: any) {
        return this.propertyToShow ? i1[this.propertyToShow] === i2[this.propertyToShow] : i1 === i2;
    }

    onScroll() {
        this.nextPage$.next(null);
    }
}
