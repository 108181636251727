import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { NewLUNComponent } from '../../components/new-lun/new-lun.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { takeWhile } from 'rxjs/operators';
import { LUNUpdateProperty } from 'src/app/store/lun/actions';
import { ApplicationState } from 'src/app/store/model';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { NightShiftUpdateProperty } from 'src/app/store/night-shift/actions';
import { NewNightShiftComponent } from '../new-night-shift/new-night-shift.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {
    updatedProperties$: Observable<string[]> = this.store.select((state) => state.lunState.updatedProperties);
    updatedProperties: string[] = [];
    showMyPage = false;
    showNewLUN = false;
    showNewNightShift = false;
    showLUNRegister = false;
    showNightShiftRegister = false;
    constructor(
        private popupService: PopupService,
        private store: Store<ApplicationState>,
        private roleService: RoleService
    ) {
        super();
        this.updatedProperties$.pipe(takeWhile(() => this.isAlive === true)).subscribe((updatedProperties: []) => {
            this.updatedProperties = updatedProperties;
        });
    }

    ngOnInit() {
        this.showMyPage = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.EditUser,
            Constants.applicableGroups.GIEditUser,
        ]);
        this.showNewLUN = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.EditUser,
            Constants.applicableGroups.GIEditUser,
        ]);
        this.showLUNRegister = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.EditUser,
            Constants.applicableGroups.GIEditUser,
            Constants.applicableGroups.ReadOnly,
            Constants.applicableGroups.AreaSCElectricalDisciplineLead,
            Constants.applicableGroups.AreaSCIAndCDisciplineLead,
            Constants.applicableGroups.AreaSCManager,
            Constants.applicableGroups.AreaSCSMPDisciplineLead,
        ]);
        this.showNewNightShift = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.EditUser,
            Constants.applicableGroups.GIEditUser,
        ]);
        this.showNightShiftRegister = this.roleService.isInAnyRole([
            Constants.applicableGroups.Admin,
            Constants.applicableGroups.EditUser,
            Constants.applicableGroups.GIEditUser,
            Constants.applicableGroups.ReadOnly,
            Constants.applicableGroups.AreaSCElectricalDisciplineLead,
            Constants.applicableGroups.AreaSCIAndCDisciplineLead,
            Constants.applicableGroups.AreaSCManager,
            Constants.applicableGroups.AreaSCSMPDisciplineLead,
        ]);
    }

    openNightShiftPopup() {
        if (this.updatedProperties.length > 0) {
            this.popupService
                .openPopup(
                    new ConfirmDialogPopupSettings({
                        title: 'Changes are not saved!',
                        text: "You haven't save your changes. Do you want to discard them?",
                        isDiscardChanges: true,
                    })
                )
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe((answer) => {
                    if (answer === true) {
                        this.store.dispatch(new LUNUpdateProperty({ key: null, value: null, updatedProperties: [] }));
                        this.store.dispatch(
                            new NightShiftUpdateProperty({ key: null, value: null, updatedProperties: [] })
                        );
                        this.popupService.openPopup(
                            new PopupSettings(NewNightShiftComponent, 447, 260, null, 447, 230, 447, 660)
                        );
                    }
                });
        } else {
            this.popupService.openPopup(new PopupSettings(NewNightShiftComponent, 447, 260, null, 447, 230, 447, 660));
        }
    }

    openLUNPopup() {
        if (this.updatedProperties.length > 0) {
            this.popupService
                .openPopup(
                    new ConfirmDialogPopupSettings({
                        title: 'Changes are not saved!',
                        text: "You haven't save your changes. Do you want to discard them?",
                        isDiscardChanges: true,
                    })
                )
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive === true))
                .subscribe((answer) => {
                    if (answer === true) {
                        this.store.dispatch(new LUNUpdateProperty({ key: null, value: null, updatedProperties: [] }));
                        this.store.dispatch(
                            new NightShiftUpdateProperty({ key: null, value: null, updatedProperties: [] })
                        );
                        this.popupService.openPopup(
                            new PopupSettings(NewLUNComponent, 447, 660, null, 447, 430, 447, 660)
                        );
                    }
                });
        } else {
            this.popupService.openPopup(new PopupSettings(NewLUNComponent, 447, 660, null, 447, 430, 447, 660));
        }
    }
}
