import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-comments-history',
    templateUrl: './comments-history.component.html',
    styleUrls: ['./comments-history.component.scss'],
})
export class CommentsHistoryComponent extends BaseComponent {
    displayedColumns: string[] = ['date', 'user', 'description'];
    @ViewChild(MatPaginator, {static:true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    resultsLength = 0;
    pageSize = 5;
    sortBy = 'date';
    direction = 'desc';
    dataSource = new MatTableDataSource();
    subsystemId: string;

    constructor(public dialogRef: MatDialogRef<CommentsHistoryComponent>, @Inject(MAT_DIALOG_DATA) data) {
        super();

        this.dataSource = new MatTableDataSource(data.commentHist.reverse());
        this.resultsLength = data.commentHist.length;
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
    }

    public onCancel(): void {
        this.dialogRef.close();
    }
    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'user':
                    return item[property].name;
                default:
                    return item[property];
            }
        };
    }
}
