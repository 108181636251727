import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeWhile, take, map, filter } from 'rxjs/operators';
import { JoditDescriptionPreserveBackButton } from '../store/jodit-description/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import { LUNFormComponent } from '../components/lun-form/lun-form.component';
import { ApplicationState } from 'src/app/store/model';

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateLUNGuard implements CanDeactivate<LUNFormComponent>, OnDestroy {
    updatedProperties$: Observable<string[]> = this.store.select(
        (state) => state.lunState.updatedProperties
    );
    updatedProperties: string[] = [];
    isDestroyed = false;
    preserverBackButton$ = this.store.select((state) => state.joditDescriptionState.preserverBackButton);
    preserverBackButton = false;

    constructor(private store: Store<ApplicationState>, private popupService: PopupService) {
        this.updatedProperties$.pipe(takeWhile(() => this.isDestroyed === false)).subscribe((updatedProperties: []) => {
            this.updatedProperties = updatedProperties;
        });
        this.preserverBackButton$
            .pipe(takeWhile(() => this.isDestroyed === false))
            .subscribe((value) => (this.preserverBackButton = value));
    }

    ngOnDestroy(): void {
        this.isDestroyed = true;
    }
    getBackButtonStatus() {
        this.preserverBackButton$.pipe(
            filter((res) => res),
            map(() => {})
        );
    }
    canDeactivate(
        component: LUNFormComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        return Observable.create((observer) => {
            combineLatest([this.preserverBackButton$, this.updatedProperties$])
                .pipe(take(1))
                .subscribe(([preserve, props]) => {
                    if (!observer.closed && preserve) {
                        this.store.dispatch(new JoditDescriptionPreserveBackButton(false));
                        history.pushState(null, null, location.href);
                        observer.next(false);
                        observer.complete();
                    }
                    if (!observer.closed) {
                        if (props.length > 0) {
                            const res = this.popupService
                                .openPopup(
                                    new ConfirmDialogPopupSettings({
                                        title: 'Changes are not saved!',
                                        text: "You haven't save your changes. Do you want to discard them?",
                                        isDiscardChanges: true,
                                    })
                                )
                                .afterClosed()
                                .pipe(takeWhile(() => this.isDestroyed === false))
                                .subscribe((ans) => {
                                    observer.next(ans);
                                    observer.complete();
                                });
                        } else {
                            observer.next(true);
                            observer.complete();
                        }
                    }
                });
        });
    }
}
