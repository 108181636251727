import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';

@Injectable({
    providedIn: 'root',
})
export class ManualUploadService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly manualUploadsEndpoint: string = '/admin';

    constructor(private http: HttpClient) {}
    
    getMasterSubsystemListDeleteRecords(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/deleterecords`, { params });
    }

    patchMasterSubsystemListDelta(subsystem: string, deltaState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/delta/${subsystem}`, [
            { op: 'replace', path: '/DeltaState', value: deltaState },
        ]);
    }

    patchMasterSubsystemListAllDelta(deltaState: boolean) {
        let params = new HttpParams();
        params = params.append('deltaState', `${deltaState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/delta`, null, { params });
    }

    patchMasterSubsystemListDeleteRecord(subsystem: string, deleteState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/delete/${subsystem}`, [
            { op: 'replace', path: '/DeleteState', value: deleteState },
        ]);
    }

    patchMasterSubsystemListDeleteAllRecords(deleteState: boolean) {
        let params = new HttpParams();
        params = params.append('deleteState', `${deleteState}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/deleterecords`, null, { params });
    }

    validateMasterSubsystemListDelta() {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/validate`);
    }

    getMasterSubsystemListDelta(page: number, take: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/msl/delta`, { params });
    }

    getSubsystemListDelta(page: number, take: number, type: string) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('type', type.toString());

        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/subsystemList/delta`, { params });
    }

    patchSubsystemListDelta(subsystem: string, deltaState: boolean) {
        return this.http.patch(`${this.rootUrl}${this.manualUploadsEndpoint}/subsystemList/delta/${subsystem}`, [
            { op: 'replace', path: '/DeltaState', value: deltaState },
        ]);
    }

    patchSubsystemListAllDelta(deltaState: boolean, type: string) {
        let params = new HttpParams();
        params = params.append('deltaState', `${deltaState}`);
        params = params.append('type', `${type}`);
        return this.http.post(`${this.rootUrl}${this.manualUploadsEndpoint}/subsystemList/delta`, null, { params });
    }

    validateSubsystemListDelta(type: string) {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/subsystemList/validate/${type}`);
    }

    downloadTemplate(fileName: string) {
        return this.http.get<BlobPart>(`${this.rootUrl}/admin/downloadtemplate/${fileName}`, {
            responseType: 'arraybuffer' as 'json',
        });
    }

    getValidateButtonState(type: string) {
        return this.http.get(`${this.rootUrl}${this.manualUploadsEndpoint}/${type}/validationState`);
    }
}
