import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import { takeWhile, take, filter, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { LUNDescriptionRequest } from 'src/app/store/lun/actions';
import { ApplicationState } from 'src/app/store/model';
import { JoditDescriptionType } from 'src/app/store/jodit-description/model';

@Component({
    selector: 'app-lun-comments',
    templateUrl: './lun-comments.component.html',
    styleUrls: ['./lun-comments.component.scss'],
})
export class LUNCommentsComponent extends BaseComponent implements OnInit {
    @Input() lunForm: UntypedFormGroup;
    @Output() openJodit = new EventEmitter<{
        description: string;
        type: JoditDescriptionType;
    }>();
    @Output() DescriptionSet = new EventEmitter<{ controlName: string; value: string }>();
    isDescriptionLoading = false;
    isDescriptionLoading$ = this.store.select((state) => state.lunState.isDescriptionLoading);
    lunId: number = null;
    isLocked$ = this.store.select((state) => state.lunState.isLocked);
    mainForm$ = this.store.select((state) => state.lunState.form);
    isMainFormLoaded$ = this.store.select((state) => state.lunState.isLoading);
    description$ = this.store.select((state) => state.lunState.form.description);
    isLocked = false;

    constructor(
        private store: Store<ApplicationState>
    ) {
        super();
    }

    ngOnInit() {
        this.isDescriptionLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isDescriptionLoading = isLoading;
        });

        this.mainForm$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((form) => !!form.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
            .subscribe((form) => {
                if (form.id !== null) {
                    this.lunId = form.id;
                    this.store.dispatch(new LUNDescriptionRequest(form.id));
                    this.description$
                        .pipe(
                            filter((description) => description !== null),
                            take(1)
                        )
                        .subscribe((description) =>
                            this.DescriptionSet.emit({ controlName: 'description', value: description })
                        );
                }
            });
        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;
        });
    }

    openJoditPopup(description: string) {
        this.openJodit.emit({ description, type: JoditDescriptionType.Description });
    }
}
