<div class="originator-section-container">
    <div [formGroup]="lunForm">
        <div class="col-header-container filter-container">
            <div class="container-multiselector">
                <mat-form-field class="container300 margin-right20">
                    <input matInput placeholder="Originator" readonly
                        [value]="lunForm.controls.originator.value.name" />
                </mat-form-field>
                <mat-form-field class="container580 margin-right20">
                    <input matInput placeholder="PERSON IN CHARGE (PIC) OF ENERGISATION"
                        formControlName="personInCharge" readonly />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <input matInput placeholder="Copy 1" formControlName="copy1" readonly />
                </mat-form-field>
                <mat-form-field class="container580 margin-right20">
                    <input matInput placeholder="WORKSITE PERFORMING AUTHORITY / PIC OF ENERGISATION" readonly />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="container300 margin-right20">
                    <input matInput placeholder="Copy 2" formControlName="copy2" readonly />
                </mat-form-field>
                <mat-form-field class="container580 margin-right20">
                    <input matInput placeholder="RETAINED BY PTW OFFICE" formControlName="retainedByPTW" readonly />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>