import { UserDetail } from '../common.model';
import { Action } from '@ngrx/store';

export enum ActionUsersActionTypes {
    AreaSCElectricalDisciplineLeadsRequest = '[LUN Action Users] AreaSCElectricalDisciplineLeads Request',
    AreaSCElectricalDisciplineLeadsRequestSuccess = '[LUN Action Users] AreaSCElectricalDisciplineLeads Success',
    AreaSCElectricalDisciplineLeadsRequestError = '[LUN Action Users] AreaSCElectricalDisciplineLeads Error',

    AreaSCSMPDisciplineLeadsRequest = '[LUN Action Users] AreaSCSMPDisciplineLeads Request',
    AreaSCSMPDisciplineLeadsRequestSuccess = '[LUN Action Users] AreaSCSMPDisciplineLeads Success',
    AreaSCSMPDisciplineLeadsRequestError = '[LUN Action Users] AreaSCSMPDisciplineLeads Error',

    AreaSCIAndCDisciplineLeadsRequest = '[LUN Action Users] AreaSCIAndCDisciplineLeads Request',
    AreaSCIAndCDisciplineLeadsRequestSuccess = '[LUN Action Users] AreaSCIAndCDisciplineLeads Success',
    AreaSCIAndCDisciplineLeadsRequestError = '[LUN Action Users] AreaSCIAndCDisciplineLeads Error',

    AreaSCManagersRequest = '[LUN Action Users] AreaSCManagers Request',
    AreaSCManagersRequestSuccess = '[LUN Action Users] AreaSCManagers Success',
    AreaSCManagersRequestError = '[LUN Action Users] AreaSCManagers Error',

    LeadIsolationAuthoritiesSMPRequest = '[LUN Action Users] LeadIsolationAuthoritiesSMP Request',
    LeadIsolationAuthoritiesSMPRequestSuccess = '[LUN Action Users] LeadIsolationAuthoritiesSMP Success',
    LeadIsolationAuthoritiesSMPRequestError = '[LUN Action Users] LeadIsolationAuthoritiesSMP Error',

    LeadIsolationAuthoritiesElectricalRequest = '[LUN Action Users] LeadIsolationAuthoritiesElectrical Request',
    LeadIsolationAuthoritiesElectricalRequestSuccess = '[LUN Action Users] LeadIsolationAuthoritiesElectrical Success',
    LeadIsolationAuthoritiesElectricalRequestError = '[LUN Action Users] LeadIsolationAuthoritiesElectrical Error',

    AddPowerUserToAreaSCElectricalDisciplineLeads = '[LUN Action Users] Add PowerUser to AreaSCElectricalDisciplineLeads',
    AddPowerUserToAreaSCSMPDisciplineLeads = '[LUN Action Users] Add PowerUser to AreaSCSMPDisciplineLeads',
    AddPowerUserToAreaSCIAndCDisciplineLeads = '[LUN Action Users] Add PowerUser to AreaSCIAndCDisciplineLeads',
    AddPowerUserToAreaSCManagers = '[LUN Action Users] Add PowerUser to AreaSCManagers',
}

export class AreaSCElectricalDisciplineLeadsRequest implements Action {
    readonly type = ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequest;

    constructor() {}
}

export class AreaSCElectricalDisciplineLeadsRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class AreaSCElectricalDisciplineLeadsRequestError implements Action {
    readonly type = ActionUsersActionTypes.AreaSCElectricalDisciplineLeadsRequestError;

    constructor(public payload: string) {}
}

export class AreaSCSMPDisciplineLeadsRequest implements Action {
    readonly type = ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequest;

    constructor() {}
}

export class AreaSCSMPDisciplineLeadsRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class AreaSCSMPDisciplineLeadsRequestError implements Action {
    readonly type = ActionUsersActionTypes.AreaSCSMPDisciplineLeadsRequestError;

    constructor(public payload: string) {}
}

export class AreaSCIAndCDisciplineLeadsRequest implements Action {
    readonly type = ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequest;

    constructor() {}
}

export class AreaSCIAndCDisciplineLeadsRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class AreaSCIAndCDisciplineLeadsRequestError implements Action {
    readonly type = ActionUsersActionTypes.AreaSCIAndCDisciplineLeadsRequestError;

    constructor(public payload: string) {}
}

export class AreaSCManagersRequest implements Action {
    readonly type = ActionUsersActionTypes.AreaSCManagersRequest;

    constructor() {}
}

export class AreaSCManagersRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.AreaSCManagersRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class AreaSCManagersRequestError implements Action {
    readonly type = ActionUsersActionTypes.AreaSCManagersRequestError;

    constructor(public payload: string) {}
}

export class LeadIsolationAuthoritiesSMPRequest implements Action {
    readonly type = ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequest;

    constructor() {}
}

export class LeadIsolationAuthoritiesSMPRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class LeadIsolationAuthoritiesSMPRequestError implements Action {
    readonly type = ActionUsersActionTypes.LeadIsolationAuthoritiesSMPRequestError;

    constructor(public payload: string) {}
}

export class LeadIsolationAuthoritiesElectricalRequest implements Action {
    readonly type = ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequest;

    constructor() {}
}

export class LeadIsolationAuthoritiesElectricalRequestSuccess implements Action {
    readonly type = ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequestSuccess;

    constructor(public payload: UserDetail[]) {}
}

export class LeadIsolationAuthoritiesElectricalRequestError implements Action {
    readonly type = ActionUsersActionTypes.LeadIsolationAuthoritiesElectricalRequestError;

    constructor(public payload: string) {}
}

export class AddPowerUserToAreaSCManagers implements Action {
    readonly type = ActionUsersActionTypes.AddPowerUserToAreaSCManagers;

    constructor(public payload: UserDetail) {}
}

export class AddPowerUserToAreaSCIAndCDisciplineLeads implements Action {
    readonly type = ActionUsersActionTypes.AddPowerUserToAreaSCIAndCDisciplineLeads;

    constructor(public payload: UserDetail) {}
}

export class AddPowerUserToAreaSCElectricalDisciplineLeads implements Action {
    readonly type = ActionUsersActionTypes.AddPowerUserToAreaSCElectricalDisciplineLeads;

    constructor(public payload: UserDetail) {}
}

export class AddPowerUserToAreaSCSMPDisciplineLeads implements Action {
    readonly type = ActionUsersActionTypes.AddPowerUserToAreaSCSMPDisciplineLeads;

    constructor(public payload: UserDetail) {}
}

export type ActionUsersAction =
    | AreaSCElectricalDisciplineLeadsRequest
    | AreaSCElectricalDisciplineLeadsRequestSuccess
    | AreaSCElectricalDisciplineLeadsRequestError
    | AreaSCSMPDisciplineLeadsRequest
    | AreaSCSMPDisciplineLeadsRequestSuccess
    | AreaSCSMPDisciplineLeadsRequestError
    | AreaSCIAndCDisciplineLeadsRequest
    | AreaSCIAndCDisciplineLeadsRequestSuccess
    | AreaSCIAndCDisciplineLeadsRequestError
    | AreaSCManagersRequest
    | AreaSCManagersRequestSuccess
    | AreaSCManagersRequestError
    | LeadIsolationAuthoritiesSMPRequest
    | LeadIsolationAuthoritiesSMPRequestSuccess
    | LeadIsolationAuthoritiesSMPRequestError
    | LeadIsolationAuthoritiesElectricalRequest
    | LeadIsolationAuthoritiesElectricalRequestSuccess
    | LeadIsolationAuthoritiesElectricalRequestError
    | AddPowerUserToAreaSCManagers
    | AddPowerUserToAreaSCSMPDisciplineLeads
    | AddPowerUserToAreaSCElectricalDisciplineLeads
    | AddPowerUserToAreaSCIAndCDisciplineLeads;
