import {
    Component,
    OnInit,
    ChangeDetectorRef,
    HostListener,
    OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NightShift, NightShiftComment } from '../../store/night-shift/model';
import { FormService } from 'src/app/services/shared/form.service';
import { BaseComponent } from 'src/app/components/base.component';
import { NightShiftFormValidators } from '../../validators/night-shift-form-validators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
    takeWhile,
    filter,
    distinctUntilChanged,
    withLatestFrom,
} from 'rxjs/operators';
import { Store, ActionsSubject } from '@ngrx/store';
import {
    GetNightShiftRequest,
    NightShiftUpdateProperty,
    NightShiftClear,
    LockNightShiftForm,
    UnLockNightShiftForm,
    NightShiftActionTypes,
    NightShiftUpdateInitialFormWithAttachments,
    NightShiftAutosaveRequest,
} from '../../store/night-shift/actions';
import { PopupService } from 'src/app/services/shared/popup.service';
import { interval, combineLatest } from 'rxjs';
import { Constants } from 'src/app/constants';
import { UserDetail } from 'src/app/store/common.model';
import { ApplicationState } from 'src/app/store/model';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from 'src/app/services/shared/role.service';
import { NightShiftAttachmentType } from 'src/app/store/night-shift/model';
import { NightShiftService } from 'src/app/services/night-shift.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { Dictionary } from 'typescript-collections';
import { NightShiftStatusType } from 'src/app/models/enums';
import { AccountInfo } from '@azure/msal-browser';

declare const Jodit: any;

@Component({
    selector: 'app-night-shift-form',
    templateUrl: './night-shift-form.component.html',
    styleUrls: ['./night-shift-form.component.scss'],
})
export class NightShiftFormComponent extends BaseComponent implements OnInit, OnDestroy {
    autosaveInterval = Constants.AutosaveInterval;
    autosaveInterval$ = interval(this.autosaveInterval);
    isSticky = false;
    user: AccountInfo = null;
    isLocked = false;
    nightShiftForm: UntypedFormGroup;
    initialForm = new NightShift();
    nightShiftId: number;
    isLoading: boolean = true;
    originator: UserDetail = null;
    comments: NightShiftComment[];
    controlDataReady = new Dictionary<string, boolean>();
    attachmentTypes = NightShiftAttachmentType;
    nightShift$ = this.store.select((state) => state.nightShiftState.form);
    updatedProperties$ = this.store.select((state) => state.nightShiftState.updatedProperties);
    nightShiftNumber$ = this.store.select((state) => state.nightShiftState.form.number);
    originator$ = this.store.select((state) => state.nightShiftState.form.originator);
    id$ = this.store.select((state) => state.nightShiftState.form.id);
    isLocked$ = this.store.select((state) => state.nightShiftState.isLocked);
    isAutosaveInProgress$ = this.store.select((state) => state.nightShiftState.isAutosaveInProgress);
    isReadOnly = false;
    printMode = false;
    status: NightShiftStatusType;
    statuses = NightShiftStatusType;
    status$ = this.store.select((state) => state.nightShiftState.form.status);

    nightShiftLoader$ = this.store.select(
        (state) =>
            state.nightShiftState.isLoading ||
            state.actionUsersState.isAreaSCElectricalDisciplineLeadsLoading ||
            state.actionUsersState.isAreaSCManagersLoading ||
            state.actionUsersState.isAreaSCSMPDisciplineLeadsLoading ||
            state.actionUsersState.isAreaSCIAndCDisciplineLeadsLoading
    );
    comments$ = this.store.select((state) => state.nightShiftState.form.comments);

    constructor(
        private store: Store<ApplicationState>,
        private formService: FormService,
        private validators: NightShiftFormValidators,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private popupService: PopupService,
        private authService: MsalService,
        private actionsSubject$: ActionsSubject,
        private roleService: RoleService,
        private nightShiftService: NightShiftService,
        private toastService: ToastService
    ) {
        super();
        this.nightShiftForm = this.formService.createForm(new NightShift(), this.validators);
    }

    ngOnInit() {
        this.user = this.roleService.getAccount();
        this.isReadOnly = this.roleService.isReadOnly();

        combineLatest([this.status$, this.originator$])
            .pipe(
                takeWhile(() => this.isAlive),
                filter(([status, originator]) => !!status && !!originator && !!originator.email),
                distinctUntilChanged(
                    ([prevStatus, prevOriginator], [nextStatus, nextOriginator]) =>
                        prevStatus === nextStatus && prevOriginator.email === nextOriginator.email
                )
            )
            .subscribe(([status, originator]) => {
                this.status = status;
                if (
                    status === NightShiftStatusType.Draft &&
                    originator.email.toLowerCase() === this.user.username.toLowerCase()
                ) {
                    this.enableForm();
                } else {
                    this.disableForm();
                }
            });

        this.store
            .select((store) => store.nightShiftState.isLoading)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => {
                this.isLoading = isLoading;
            });

        this.nightShift$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((nightShift) => !!nightShift.id),
                distinctUntilChanged((prev, curr) => prev.id === curr.id)
            )
            .subscribe((nightShift) => {
                this.nightShiftForm.patchValue(nightShift, { emitEvent: false });
                this.nightShiftId = nightShift.id;
                this.setInitialForm();
            });

        this.autosaveInterval$
            .pipe(
                takeWhile(() => this.isAlive),
                withLatestFrom(this.status$, this.originator$, this.isAutosaveInProgress$, this.updatedProperties$),
                filter(
                    ([, status, originator, isAutosaveInProgress, updatedProperties]) =>
                        this.areUsersEqual(this.roleService.getAccount(), originator) &&
                        status === NightShiftStatusType.Draft &&
                        !isAutosaveInProgress &&
                        updatedProperties.length > 0 &&
                        this.nightShiftForm.valid
                )
            )
            .subscribe(() => {
                if (!this.isLoading) {
                    this.store.dispatch(new NightShiftAutosaveRequest(this.nightShiftForm.value));
                }
            });

        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isLocked = isLocked;

            if (isLocked) {
                this.disableFormControls();
            } else {
                this.enableFormControls();
            }
        });

        // this.store
        //     .select((state) => state.nightShiftState.isCreated)
        //     .pipe(takeWhile(() => this.isAlive))
        //     .subscribe((isCreated) => {
        //         if (isCreated) {
        //             this.nightShiftForm.controls['areaSCElectricalDisciplineLead'].enable({
        //                 emitEvent: false,
        //                 onlySelf: true,
        //             });

        //             this.nightShiftForm.controls['areaSCSMPDisciplineLead'].enable({
        //                 emitEvent: false,
        //                 onlySelf: true,
        //             });

        //             this.nightShiftForm.controls['areaSCIAndCDisciplineLead'].enable({
        //                 emitEvent: false,
        //                 onlySelf: true,
        //             });

        //             this.nightShiftForm.controls['areaSCManager'].enable({
        //                 emitEvent: false,
        //                 onlySelf: true,
        //             });
        //         }
        //     });

        this.watchFormChanges();
        this.comments$.pipe(takeWhile(() => this.isAlive)).subscribe((comments) => (this.comments = comments));

        this.route.paramMap.pipe(takeWhile(() => this.isAlive)).subscribe((params: ParamMap) => {
            const id = Number.parseInt(params.get('id'));
            this.store.dispatch(new GetNightShiftRequest(id));
            this.changeDetectorRef.markForCheck();
        });

        this.nightShiftLoader$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isLoading) => (this.isLoading = isLoading));
        this.nightShiftNumber$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((number) => this.nightShiftForm.controls.number.setValue(number, { emitEvent: false }));

        this.actionsSubject$
            .pipe(
                takeWhile(() => this.isAlive),
                filter(
                    (action) =>
                        action.type === NightShiftActionTypes.NightShiftUpdateRequestSuccess ||
                        action.type === NightShiftActionTypes.NightShiftAutosaveSuccess
                )
            )
            .subscribe(() => this.setInitialForm());

        this.actionsSubject$
            .pipe(
                takeWhile(() => this.isAlive),
                filter((action) => action.type === NightShiftActionTypes.NightShiftUpdateInitialFormWithAttachments)
            )
            .subscribe((action: NightShiftUpdateInitialFormWithAttachments) => {
                this.initialForm = {
                    ...this.initialForm,
                    [`${this.attachmentTypes[action.payload.type]}Attachments`]: action.payload.attachments.attachments,
                };
                this.nightShiftForm.controls[`${this.attachmentTypes[action.payload.type]}Attachments`].setValue(
                    action.payload.attachments.attachments,
                    { emitEvent: false }
                );
            });

        this.nightShiftForm.markAllAsTouched();
    }

    disableFormControls() {
        this.nightShiftForm.disable({ emitEvent: false });
    }

    enableFormControls() {
        this.nightShiftForm.enable({ emitEvent: false });
        //this.nightShiftForm.controls.area.disable();
        // setTimeout(() => {
        //     if (this.nightShiftForm.controls.areaSCSMPDisciplineLeadReviewDate.value) {
        //         this.nightShiftForm.controls['areaSCSMPDisciplineLead'].disable({
        //             emitEvent: false,
        //             onlySelf: true,
        //         });
        //     }

        //     if (this.nightShiftForm.controls.areaSCElectricalDisciplineLeadReviewDate.value) {
        //         this.nightShiftForm.controls['areaSCElectricalDisciplineLead'].disable({
        //             emitEvent: false,
        //             onlySelf: true,
        //         });
        //     }

        //     if (this.nightShiftForm.controls.areaSCIAndCDisciplineLeadReviewDate.value) {
        //         this.nightShiftForm.controls['areaSCIAndCDisciplineLead'].disable({
        //             emitEvent: false,
        //             onlySelf: true,
        //         });
        //     }

        //     if (this.nightShiftForm.controls.areaSCManagerReviewDate.value) {
        //         this.nightShiftForm.controls['areaSCManager'].disable({
        //             emitEvent: false,
        //             onlySelf: true,
        //         });
        //     }
        // });
    }

    getUpdatedProperties(): string[] {
        const result = [];
        if (!this.isNotNullAndNotUndefined(this.initialForm.id)) {
            return result;
        }
        for (const key of Object.keys(this.nightShiftForm.controls).filter(
            (e) =>
                e !== 'NoMarkupDocuments' &&
                e !== 'SupportingInformationDocuments' &&
                e !== 'AFCDocuments' &&
                e !== 'Comments' &&
                e !== 'nteUsd' &&
                e.indexOf('raisedDate') < 0
        )) {
            if (
                (!this.isNotNullAndNotUndefined(this.initialForm[key]) || this.initialForm[key] === '') &&
                (!this.isNotNullAndNotUndefined(this.nightShiftForm.controls[key].value) ||
                    this.nightShiftForm.controls[key].value === '')
            ) {
                continue;
            }
            if (
                (key === 'SupportingDocumentAttachments' || key === 'EstimateAttachments') &&
                !this.compareArraysOfObjectsByProperty(
                    this.initialForm[key],
                    this.nightShiftForm.controls[key].value,
                    'name'
                )
            ) {
                result.push(key);
            } else if (
                JSON.stringify(this.initialForm[key]) !== JSON.stringify(this.nightShiftForm.controls[key].value)
            ) {
                result.push(key);
            }
        }

        return result;
    }
    watchFormChanges() {
        for (const key of Object.keys(this.nightShiftForm.controls)) {
            this.nightShiftForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                const updatedProperties = this.getUpdatedProperties();
                this.store.dispatch(
                    new NightShiftUpdateProperty({
                        key,
                        value,
                        updatedProperties,
                    })
                );
            });
        }
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.store.dispatch(new NightShiftClear());
    }

    onEditorValueSet(event: { controlName: string; value: string }) {
        this.nightShiftForm.controls[event.controlName].patchValue(event.value, { emitEvent: false });
        this.controlDataReady.setValue(event.controlName, true);
        this.setInitialForm();
    }

    private setInitialForm() {
        if (this.isNotNullAndNotUndefined(this.nightShiftForm.value.id)) {
            for (const key of Object.keys(this.nightShiftForm.controls)) {
                const dataReady = this.controlDataReady.getValue(key) || true;
                if (dataReady) {
                    this.initialForm[key] = JSON.parse(JSON.stringify(this.nightShiftForm.controls[key].value));
                }
            }
        }
    }

    disableReviewAndApproveSection() {
        // this.nightShiftForm.controls.originator.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.routeDate.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.approveDate.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.approver.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.costEngineerReviewDate.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.costEngineer.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.contractsEngineerReviewDate.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.contractsEngineer.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.nbCosterSubmitDate.disable({ emitEvent: false, onlySelf: true });
        // this.nightShiftForm.controls.nbCoster.disable({ emitEvent: false, onlySelf: true });
    }

    enableForm() {
        this.store.dispatch(new UnLockNightShiftForm());
    }

    disableForm() {
        this.store.dispatch(new LockNightShiftForm());
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        this.isSticky = window.pageYOffset >= 250;
    }
}
