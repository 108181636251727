<div class="lun-form-header-container">
    <div [formGroup]="nightShiftForm">
        <div class="col-header-container filter-container">
            <mat-form-field class="container360 margin-right20">
                <input matInput placeholder="Number" formControlName="number" readonly />
            </mat-form-field>
            <mat-form-field class="container115 margin-right20">
                <mat-label>Area</mat-label>
                <mat-select matNativeControl formControlName="area">
                    <mat-option *ngFor="let area of areas" [value]="area.id">
                        {{ area.code }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="container100 margin-right20">
                <input
                    matInput
                    placeholder="Raised Date"
                    readonly
                    [value]="nightShiftForm.controls.raisedDate.value | date: 'd/MMM/yy'"
                />
            </mat-form-field>
            <mat-form-field class="container250 margin-right20">
                <input
                    matInput
                    placeholder="Originator"
                    readonly
                    [value]="nightShiftForm.controls.originator.value.name"
                />
            </mat-form-field>
            <mat-form-field class="container250 margin-right20">
                <input matInput placeholder="Email" readonly [value]="nightShiftForm.controls.originator.value.email" />
            </mat-form-field>
            <mat-form-field class="container55">
                <input matInput placeholder="Location" readonly formControlName="location" />
            </mat-form-field>
            <div class="container-multiselector">
                <div class="container300-mh margin-right20">
                    <app-multiple-items-selector
                        class="container300"
                        [setInput]="setContractorInput"
                        formControlName="companyName"
                        [searchFunc]="getContractors"
                        [isAsync]="true"
                        [isTableAutoComplete]="true"
                        [displayedColumns]="contractorsAutocompleteDisplayedColumns"
                        (autocompleteClosed)="onContractorsClosed()"
                        (itemRemoved)="onContractorsClosed()"
                        [filterForm]="nightShiftForm"
                        [formSetter]="'companyName'"
                        [propertyToShow]="'contractNo'"
                        [placeholder]="'Company Name'"
                        [oneItemMode]="true"
                        required
                        [invalid]="
                            nightShiftForm.controls['companyName'].invalid &&
                            nightShiftForm.controls['companyName'].errors.required
                        "
                        [errorMessage]="'At least one company name need to be set'"
                    >
                    </app-multiple-items-selector>
                </div>
                <mat-form-field class="container250 margin-right20">
                    <mat-label>Subcontractr(s), if applicable</mat-label>
                    <input matInput autocomplete="off" formControlName="subContractor" />
                </mat-form-field>

                <mat-form-field class="container330 margin-right20">
                    <mat-label>Number of persons on board (POB)</mat-label>
                    <input matInput autocomplete="off" formControlName="numberOfPersons" />
                </mat-form-field>
            </div>
            <mat-form-field class="container-fit title">
                <mat-label
                    >Scope of work: A detailed description of the work scope is to be stated here which includes all
                    activities that will be undertaken.
                </mat-label>
                <textarea matInput formControlName="scope" cdkTextareaAutosize cdkAutosizeMinRows="5"></textarea>
            </mat-form-field>
            <mat-form-field class="container250 margin-right20">
                <mat-label>Proposed Duration of Night Shift</mat-label>
                <input matInput autocomplete="off" formControlName="proposedDurationText" />
            </mat-form-field>
            <mat-form-field class="margin-right20">
                <mat-label>From Date*</mat-label>
                <input
                    matInput
                    [matDatepicker]="nightShiftFromDate"
                    formControlName="proposedDurationFromDate"
                    (dateChange)="dateFromChange($event)"
                    [max]="maxDate"
                    autocomplete="off"
                />
                <mat-datepicker-toggle matSuffix [for]="nightShiftFromDate"></mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearFromDate()">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #nightShiftFromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="margin-right20">
                <mat-label>To Date</mat-label>
                <input
                    matInput
                    [matDatepicker]="nightShiftToDate"
                    formControlName="proposedDurationUntilDate"
                    (dateChange)="dateUntilChange($event)"
                    [min]="minDate"
                    autocomplete="off"
                />
                <mat-datepicker-toggle matSuffix [for]="nightShiftToDate"></mat-datepicker-toggle>
                <mat-datepicker-toggle matSuffix (click)="clearUntilDate()">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #nightShiftToDate></mat-datepicker>
            </mat-form-field>
            <div class="container250" style="display: inline-block;">
                {{ calculatedDurationMonths !== 0 ? calculatedDurationMonths + ' month(s)' : '' }}
                {{ calculatedDurationDays !== 0 ? calculatedDurationDays + ' day(s)' : '' }}
            </div>
            <span style="display: block;"
                >*Nightshift authorization form shall be approved no longer than one year.
            </span>
        </div>
    </div>
</div>
