import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from 'src/app/app.config';
import { NightShiftComment } from '../store/night-shift/model';

@Injectable({
    providedIn: 'root',
})
export class NightShiftCommentService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly commentEndpoint: string = '/nightshift';
    constructor(private http: HttpClient) {}

    addComment(id: number, description: string) {
        return this.http.post<NightShiftComment>(`${this.rootUrl}${this.commentEndpoint}/comment`, {
            NightShiftId: id,
            Description: description,
        });
    }

    getComments(id: number) {
        return this.http.get<NightShiftComment[]>(`${this.rootUrl}${this.commentEndpoint}/comments/${id}`);
    }
}
