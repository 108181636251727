import { Injectable } from '@angular/core';
import {
    MyPageCollection,
    MyPageFilter,
    MyPageLUNRow,
    MyPageCounters,
    LUNMyPageSections,
    DelegationFilter,
    DelegationsRow,
    DelegationType,
} from '../store/my-page/models';
import { HttpParams, HttpClient } from '@angular/common/http';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class MyPageService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly myPageEndpoint: string = '/lun/mypage';

    constructor(private http: HttpClient) {}

    filterLUNForSection(section: LUNMyPageSections, filter: MyPageFilter) {
        return this.http.post<MyPageCollection<MyPageLUNRow>>(
            `${this.rootUrl}${this.myPageEndpoint}/filter/${section}`,
            filter
        );
    }

    getAllDrafts(filter: MyPageFilter) {
        return this.http.post<MyPageCollection<MyPageLUNRow>>(
            `${this.rootUrl}${this.myPageEndpoint}/allDrafts`,
            filter
        );
    }

    getDelegations(filter: DelegationFilter) {
        return this.http.post<MyPageCollection<DelegationsRow>>(
            `${this.rootUrl}${this.myPageEndpoint}/delegations`,
            filter
        );
    }

    delegationAssignment(type: DelegationType, userId: number, selectedLUNIds: number[]) {
        let params = new HttpParams();
        params = params.append('type', type.toString());
        params = params.append('userId', userId.toString());
        return this.http.post(`${this.rootUrl}${this.myPageEndpoint}/delegationAssignment`, selectedLUNIds, {
            params,
        });
    }

    getMyPageCounters() {
        return this.http.get<MyPageCounters>(`${this.rootUrl}${this.myPageEndpoint}/counters`);
    }

    getTableFilterValues(columnName: string, search = '', take = -1, page = -1, section: number, filter: MyPageFilter) {
        let params = this.getParams(columnName, search, take, page, section);
        return this.http.post<string[]>(`${this.rootUrl}${this.myPageEndpoint}/tableFilterValues`, filter, { params });
    }

    getDelegationsFilterValues(columnName: string, search = '', take = -1, page = -1, filter: DelegationFilter) {
        let params = this.getParams(columnName, search, take, page, 0);
        return this.http.post<string[]>(`${this.rootUrl}${this.myPageEndpoint}/delegationsFilterValues`, filter, {
            params,
        });
    }

    private getParams(columnName: string, search: string, take: number, page: number, section: number) {
        let params = new HttpParams();
        params = params.append('columnName', columnName);
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        params = params.append('section', section.toString());
        return params;
    }
}
