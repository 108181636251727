import { InjectionToken } from '@angular/core';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

export const APP_INSIGHTS_CONFIG = new InjectionToken<ApplicationInsightsConfig>('appInsights.config');

interface AppConfig {
    production: boolean;
    apiEndpoint: string;
    clientId: string;
    tenant: string;
    redirectUri: string;
    instrumentationKey: string;
    sctoolAppUrl: string;
}

export interface ApplicationInsightsConfig {
    instrumentationKey: string;
}

enum AppUrl {
    local = 'https://localhost:44307',
    dev = 'https://fgplun-dev.azure.chevron.com',
    test = 'https://fgplun-test.azure.chevron.com',
    prod = 'https://fgplun.azure.chevron.com',
}

enum ApiUrl {
    local = 'https://localhost:44392',
    dev = 'https://fgplun-api-dev.azure.chevron.com',
    test = 'https://fgplun-api-test.azure.chevron.com',
    prod = 'https://fgplun-api.azure.chevron.com',
}

enum SCToolAppUrl {
    local = 'https://localhost:44303',
    dev = 'https://sctool-dev.azure.chevron.com',
    test = 'https://sctool-test.azure.chevron.com',
    prod = 'https://sctool.azure.chevron.com',
}

enum ClientId {
    test = '3cd5415b-d962-41e8-b6bd-c4841c714dc5',
    prod = '29057cf8-abe6-4828-92ec-85258702af48',
}

enum InstrumentationKey {
    local = '601336d9-f3a5-46c7-a507-47afd1b89bef',
    dev = '601336d9-f3a5-46c7-a507-47afd1b89bef',
    test = '',
    prod = '',
}

function getAppConfig(): AppConfig {
    const redirectUri = location.origin;
    const tenant = 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26';

    switch (location.origin) {
        case AppUrl.prod:
            return {
                apiEndpoint: ApiUrl.prod,
                clientId: ClientId.prod,
                redirectUri,
                production: true,
                tenant,
                instrumentationKey: InstrumentationKey.prod,
                sctoolAppUrl: SCToolAppUrl.prod,
            };
        case AppUrl.test:
            return {
                apiEndpoint: ApiUrl.test,
                clientId: ClientId.test,
                redirectUri,
                production: true,
                tenant,
                instrumentationKey: InstrumentationKey.test,
                sctoolAppUrl: SCToolAppUrl.test,
            };
        case AppUrl.dev:
            return {
                apiEndpoint: ApiUrl.dev,
                clientId: ClientId.test,
                redirectUri,
                production: true,
                tenant,
                instrumentationKey: InstrumentationKey.dev,
                sctoolAppUrl: SCToolAppUrl.dev,
            };
        case AppUrl.local:
        default:
            return {
                apiEndpoint: ApiUrl.local,
                clientId: ClientId.test,
                redirectUri,
                production: false,
                tenant,
                instrumentationKey: InstrumentationKey.local,
                sctoolAppUrl: SCToolAppUrl.local,
            };
    }
}

export const appConfig = getAppConfig();

export function MSALInscanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: appConfig.clientId,
            authority: 'https://login.microsoftonline.com/' + appConfig.tenant,
            redirectUri: appConfig.redirectUri,
            postLogoutRedirectUri: appConfig.redirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(appConfig.apiEndpoint, [`${appConfig.clientId}/User.Read`]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
        loginFailedRoute: '/notauthorized',
    };
}

export const appInsightsConfig: ApplicationInsightsConfig = {
    instrumentationKey: appConfig.instrumentationKey,
};
