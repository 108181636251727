import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { UserDetail } from 'src/app/store/common.model';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from 'src/app/components/base.component';
import { MatDialogRef } from '@angular/material/dialog';
import { LookupService } from 'src/app/services/lookup.service';
import { DelegationsRow, DelegationType } from 'src/app/store/my-page/models';
import { DelegationsAssignmentRequest } from 'src/app/store/my-page/actions';

@Component({
    selector: 'app-delegations-popup',
    templateUrl: './delegations-popup.component.html',
    styleUrls: ['./delegations-popup.component.scss'],
})
export class DelegationsPopupComponent extends BaseComponent implements OnInit {
    delegateForm: UntypedFormGroup;
    selectedItems$ = this.store.select((state) => state.lunMyPageState.selectedItems);
    type$ = this.store.select((state) => state.lunMyPageState.delegationsFilter.type);
    users: UserDetail[] = [];
    type: DelegationType;
    selectedItems: DelegationsRow[];
    autocompleteDisplayedColumns = ['name', 'email'];

    constructor(
        private dialogRef: MatDialogRef<DelegationsPopupComponent>,
        private lookupService: LookupService,
        private store: Store<ApplicationState>
    ) {
        super();
        this.delegateForm = new UntypedFormGroup({
            user: new UntypedFormControl('', Validators.required),
        });
    }

    ngOnInit(): void {
        this.type$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.type = data));
        this.selectedItems$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.selectedItems = data));
    }

    onConfirm() {
        this.store.dispatch(new DelegationsAssignmentRequest(this.delegateForm.value.user[0]));
        this.dialogRef.close();
    }

    onCancel() {
        this.dialogRef.close();
    }

    getUsers() {
        
    }
}
