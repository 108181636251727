import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Contractor, AppRole } from '../store/common.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, takeWhile } from 'rxjs/operators';
import { ApplicationState } from '../store/model';
import { Store } from '@ngrx/store';
import { LookupSave } from '../store/lookup/actions';
import { BaseComponent } from '../components/base.component';
import { LookupState } from '../store/lookup/model';
import { Tag } from '../store/lun/model';
import { DelegationType } from '../store/my-page/models';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class LookupService extends BaseComponent {
   
    private readonly rootUrl = appConfig.apiEndpoint;
    lookup$ = this.store.select((state) => state.lookupState);
    private readonly endpoint = '/lookup/';
    private lookup: LookupState;

    constructor(private http: HttpClient, private store: Store<ApplicationState>) {
        super();
        this.lookup$.pipe(takeWhile(() => this.isAlive)).subscribe((lookup) => {
            this.lookup = lookup;
        });
    }

    getDisciplines() {
        return this.lookupWithProjectTeamRequest('disciplines', [], '', -1, -1);
    }

    searchSubsystems(search: string, take: number, page: number, projectTeamName: string[] = []) {
        return this.lookupWithProjectTeamRequest('subsystems', projectTeamName, search, take, page);
    }

    searchUsers(search = '', page = -1, take = -1) {
        return this.lookupWithProjectTeamRequest('users', [], search, take, page);
    }

    searchContractors(
        search = '',
        take = -1,
        page = -1,
        projectTeamName: string[] = [],
        requestingPage = ''
    ): Observable<Contractor[]> {
        return this.lookupWithProjectTeamRequest(
            'contractors',
            projectTeamName,
            search,
            take,
            page,
            [],
            requestingPage
        );
    }

    searchUsersWithRoles(roles: AppRole[]) {
        return this.lookupWithProjectTeamRequest('usersWithRoles', [], '', -1, -1, roles);
    }

    canSendAutomatedEmails() {
        return this.http.get<boolean>(`${this.rootUrl}${this.endpoint}automaticemails`);
    }

    private getParams(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return params;
    }

    private lookupWithProjectTeamRequest(
        type: string,
        projectTeamNames: string[] = [],
        search: string = null,
        take: number = null,
        page: number = null,
        roles: AppRole[] = [],
        requestingPage = ''
    ) {
        if (this.lookup[type + roles + requestingPage]) {
            var result = this.lookup[type + roles + requestingPage];
            result = this.filterResults(result, type, projectTeamNames, search, take, page);
            return of(result);
        }

        var roleParam = roles.length ? `?roles=${roles}` : '';
        var requestingPageParam = requestingPage !== '' ? `?requestingPage=${requestingPage}` : '';

        return this.http.get<any[]>(`${this.rootUrl}${this.endpoint}${type}${roleParam}${requestingPageParam}`).pipe(
            map((value) => {
                this.store.dispatch(new LookupSave({ key: type + roles + requestingPage, value }));
                value = this.filterResults(value, type, projectTeamNames, search, take, page);
                return value;
            })
        );
    }

    private filterResults(
        result: any[],
        type: string,
        projectTeamNames: string[],
        search: string,
        take: number,
        page: number
    ) {
        var searchText = search !== null ? search.toLowerCase() : search;
        if (projectTeamNames !== null && projectTeamNames.length > 0) {
            result = result.filter((s) => projectTeamNames.indexOf(s.projectTeamName) > -1);
        }
        if (search !== null && search !== '') {
            if (type === 'users') {
                result = result.filter(
                    (s) =>
                        s.email.toLowerCase().indexOf(searchText) > -1 || s.name.toLowerCase().indexOf(searchText) > -1
                );
            } else if (type === 'cwps') {
                result = result.filter((s) => s.toLowerCase().indexOf(searchText) > -1);
            } else if (type === 'subsystems') {
                result = result.filter((s) => s.id.toLowerCase().indexOf(searchText) > -1);
            } else if (type === 'contractors') {
                result = result.filter((s: Contractor) => s.contractNo.toLowerCase().includes(searchText));
            } else {
                result = result.filter(
                    (s) =>
                        s.name.toLowerCase().indexOf(searchText) > -1 ||
                        s.description.toLowerCase().indexOf(searchText) > -1
                );
            }
        }
        if (take !== -1 && page !== -1) {
            result = result.slice(page * take, page * take + take);
        }

        return result;
    }
}
