import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UserDetail } from 'src/app/store/common.model';
import { Store } from '@ngrx/store';
import { LUNSaveAndUpdateStatusRequest, LUNUpdateRequest, LUNUpdateStatusRequest } from 'src/app/store/lun/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ApplicationState } from 'src/app/store/model';
import { InformationDialogComponent } from '../../information-dialog/information-dialog.component';
import { Account } from 'msal';
import { MsalService } from '@azure/msal-angular';
import { LUN } from 'src/app/store/lun/model';
import { LUNStatusChangeAction, LUNStatusType } from 'src/app/models/enums';
import { ConfirmDialogPopupSettings } from 'src/app/models/confirm-dialog-popup-settings';
import moment from 'moment';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    selector: 'app-lun-buttons',
    templateUrl: './lun-buttons.component.html',
    styleUrls: ['./lun-buttons.component.scss'],
})
export class LUNButtonsComponent extends BaseComponent implements OnInit {
    @Input() lunForm: UntypedFormGroup;
    @Output() disableFormEvent = new EventEmitter<void>();
    @Output() enableFormEvent = new EventEmitter<void>();
    @Output() disableReviewAndApproveSectionEvent = new EventEmitter<void>();

    isSaveButtonDisabled = false;
    isSaveButtonVisible = false;
    isEditButtonVisible = false;
    loaded = true;
    updatedProperties: string[] = [];
    isReadOnly = false;
    user: AccountInfo;
    originator: UserDetail;
    lunId: number;
    lun: LUN;
    canSendAutomatedEmails = false;
    isAdmin = false;
    isEditUser = false;
    isOriginator = false;
    isAutosaveInProgress = false;

    isLocked$ = this.store.select((state) => state.lunState.isLocked);
    isAutosaveInProgress$ = this.store.select((state) => state.lunState.isAutosaveInProgress);
    originator$ = this.store.select((state) => state.lunState.form.originator);
    updatedProperties$ = this.store.select((state) => state.lunState.updatedProperties);
    lun$ = this.store.select((state) => state.lunState.form);
    isLocked = false;
    isRevertButtonVisible = false;
    isSubmitButtonVisible = false;
    isSignOffButtonVisible = false;
    isApproveButtonVisible = false;
    isCancelButtonVisible = false;
    isIsolationSMPReviewButtonVisible = false;
    isIsolationElectricalReviewButtonVisible = false;
    cancelButtonText = '';
    isManager = false;
    isElectricalLead = false;
    isSMPLead = false;
    isIAndCLead = false;
    isIsolationSMPLead = false;
    isIsolationElectricalLead = false;
    isElectricalReviewCompleted = false;
    isManagerApprovalCompleted = false;
    isIAndCLeadReviewCompleted = false;
    isIsolationSMPLeadReviewCompleted = false;
    isIsolationElectricalLeadReviewCompleted = false;
    isSMPLeadReviewCompleted = false;
    status$ = this.store.select((state) => state.lunState.form.status);
    manager$ = this.store.select((state) => state.lunState.form.areaSCManager);
    managerReviewDate$ = this.store.select((state) => state.lunState.form.areaSCManagerReviewDate);
    electricalLead$ = this.store.select((state) => state.lunState.form.areaSCElectricalDisciplineLead);
    electricalLeadReviewDate$ = this.store.select(
        (state) => state.lunState.form.areaSCElectricalDisciplineLeadReviewDate
    );
    SMPLead$ = this.store.select((state) => state.lunState.form.areaSCSMPDisciplineLead);
    SMPLeadReviewDate$ = this.store.select((state) => state.lunState.form.areaSCSMPDisciplineLeadReviewDate);
    iAndCLead$ = this.store.select((state) => state.lunState.form.areaSCIAndCDisciplineLead);
    iAndCLeadReviewDate$ = this.store.select((state) => state.lunState.form.areaSCIAndCDisciplineLeadReviewDate);
    isolationLeadSMP$ = this.store.select((state) => state.lunState.form.leadIsolationAuthoritySMP);
    isolationLeadSMPReviewDate$ = this.store.select((state) => state.lunState.form.leadIsolationAuthoritySMPReviewDate);
    isolationLeadElectrical$ = this.store.select((state) => state.lunState.form.leadIsolationAuthorityElectrical);
    isolationLeadElectricalReviewDate$ = this.store.select(
        (state) => state.lunState.form.leadIsolationAuthorityElectricalReviewDate
    );

    managerCAI$ = this.store.select((state) => state.lunState.form.areaSCManagerCAI);
    electricalLeadCAI$ = this.store.select((state) => state.lunState.form.areaSCElectricalDisciplineLeadCAI);
    SMPLeadCAI$ = this.store.select((state) => state.lunState.form.areaSCSMPDisciplineLeadCAI);
    iAndCLeadCAI$ = this.store.select((state) => state.lunState.form.areaSCIAndCDisciplineLeadCAI);
    isolationLeadSMPCAI$ = this.store.select((state) => state.lunState.form.leadIsolationAuthoritySMPCAI);
    isolationLeadElectricalCAI$ = this.store.select((state) => state.lunState.form.leadIsolationAuthorityElectricalCAI);

    managerCAI: string;
    electricalLeadCAI: string;
    SMPLeadCAI: string;
    iAndCLeadCAI: string;
    isolationLeadSMPCAI: string;
    isolationLeadElectricalCAI: string;

    status: LUNStatusType;

    constructor(
        private store: Store<ApplicationState>,
        private roleService: RoleService,
        private popupService: PopupService,
        private authService: MsalService
    ) {
        super();
    }

    ngOnInit() {
        this.isReadOnly = this.roleService.isReadOnly();
        this.lun$.pipe(takeWhile(() => this.isAlive)).subscribe((lun) => {
            this.lunId = lun.id;
            this.lun = lun;
        });

        combineLatest(
            this.status$,
            this.manager$,
            this.managerReviewDate$,
            this.electricalLead$,
            this.electricalLeadReviewDate$,
            this.SMPLead$,
            this.SMPLeadReviewDate$,
            this.iAndCLead$,
            this.iAndCLeadReviewDate$,
            this.originator$,
            this.updatedProperties$,
            this.isLocked$,
            this.isAutosaveInProgress$,
            this.managerCAI$,
            this.electricalLeadCAI$,
            this.SMPLeadCAI$,
            this.iAndCLeadCAI$,
            this.isolationLeadSMP$,
            this.isolationLeadSMPReviewDate$,
            this.isolationLeadSMPCAI$,
            this.isolationLeadElectrical$,
            this.isolationLeadElectricalReviewDate$,
            this.isolationLeadElectricalCAI$
        )
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                ([
                    status,
                    manager,
                    managerReviewDate,
                    electricalLead,
                    electricalLeadReviewDate,
                    SMPLead,
                    SMPLeadReviewDate,
                    iAndCLead,
                    iAndCLeadReviewDate,
                    originator,
                    updatedProperties,
                    isLocked,
                    isAutosaveInProgress,
                    managerCAI,
                    electricalLeadCAI,
                    SMPLeadCAI,
                    iAndCLeadCAI,
                    isolationLeadSMP,
                    isolationLeadSMPReviewDate,
                    isolationLeadSMPCAI,
                    isolationLeadElectrical,
                    isolationLeadElectricalReviewDate,
                    isolationLeadElectricalCAI,
                ]) => {
                    this.user = this.roleService.getAccount();
                    this.originator = originator;
                    this.updatedProperties = updatedProperties;
                    this.isAdmin = this.roleService.isInRole(Constants.applicableGroups.Admin);
                    this.isEditUser = this.roleService.isInRole(Constants.applicableGroups.EditUser);
                    this.isOriginator = this.compareUsersWithAccount(this.user, originator);
                    this.isAutosaveInProgress = isAutosaveInProgress;
                    this.status = status;
                    this.cancelButtonText = status === LUNStatusType.Approved ? 'void LUN' : 'cancel LUN';

                    this.isManager = this.isAdmin || this.compareUsersWithAccount(this.user, manager);
                    this.isElectricalLead = this.isAdmin || this.compareUsersWithAccount(this.user, electricalLead);
                    this.isSMPLead = this.isAdmin || this.compareUsersWithAccount(this.user, SMPLead);
                    this.isIsolationSMPLead = this.isAdmin || this.compareUsersWithAccount(this.user, isolationLeadSMP);
                    this.isIsolationElectricalLead =
                        this.isAdmin || this.compareUsersWithAccount(this.user, isolationLeadElectrical);
                    this.isIAndCLead = this.isAdmin || this.compareUsersWithAccount(this.user, iAndCLead);

                    this.isElectricalReviewCompleted = electricalLeadReviewDate !== null;
                    this.isManagerApprovalCompleted = managerReviewDate !== null;
                    this.isIAndCLeadReviewCompleted = iAndCLeadReviewDate !== null;
                    this.isSMPLeadReviewCompleted = SMPLeadReviewDate !== null;
                    this.isIsolationSMPLeadReviewCompleted = isolationLeadSMPReviewDate !== null;
                    this.isIsolationElectricalLeadReviewCompleted = isolationLeadElectricalReviewDate !== null;

                    this.managerCAI = managerCAI;
                    this.electricalLeadCAI = electricalLeadCAI;
                    this.SMPLeadCAI = SMPLeadCAI;
                    this.iAndCLeadCAI = iAndCLeadCAI;
                    this.isolationLeadSMPCAI = isolationLeadSMPCAI;
                    this.isolationLeadElectricalCAI = isolationLeadElectricalCAI;

                    if (status !== LUNStatusType.Draft) {
                        this.setRequiredCAI();
                    }
                    this.showOrHideButtons(updatedProperties);
                }
            );

        this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
            this.isSaveButtonVisible = !isLocked;
            this.isLocked = isLocked;
            this.showOrHideButtons(this.updatedProperties);
        });
    }

    edit() {
        this.enableFormEvent.emit();
        if (this.status !== LUNStatusType.Draft) {
            this.showAutosaveDisabledPopup();
        }
    }

    submit() {
        this.popupService
            .openPopup(
                new ConfirmDialogPopupSettings({ title: 'Confirm action', text: 'Do you want to submit draft?' })
            )
            .afterClosed()
            .pipe(takeWhile(() => this.isAlive === true))
            .subscribe((answer) => {
                if (answer === true) {
                    this.confirmSubmitAction(LUNStatusChangeAction.Submit);
                }
            });
    }

    confirmSubmitAction(action: LUNStatusChangeAction) {
        this.loaded = true;
        if (this.isNotNullAndNotUndefined(this.updatedProperties) && this.updatedProperties.length > 0) {
            this.saveAndupdateStatusAction(action);
        } else {
            this.updateStatusAction(action);
        }
    }

    signOff() {
        var action = this.getCurrentSignOffAction();
        if (this.isReadyForSignOff(action)) {
            this.runUpdateStatusAction(action, 'Confirm action', 'Do you want to sign off?');
        } else {
            this.showRequiredActionPopup(
                'Cannot sign off',
                'LUN with a past LUN date or without the required CAIs cannot be signed off. Go to the edit mode and complete the form.'
            );
        }
    }

    isolationSMPReview() {
        if (this.isReadyForIsolationSMPReview()) {
            this.runUpdateStatusAction(
                LUNStatusChangeAction.LeadIsolationAuthoritySMPReview,
                'Confirm action',
                'Do you want to confirm isolation review'
            );
        } else {
            this.showRequiredActionPopup(
                'Cannot allow isolation review',
                'LUN with a past LUN date or without the required CAIs cannot be reviewed. Go to the edit mode and complete the form.'
            );
        }
    }

    isolationElectricalReview() {
        if (this.isReadyForIsolationElectricalReview()) {
            this.runUpdateStatusAction(
                LUNStatusChangeAction.LeadIsolationAuthorityElectricalReview,
                'Confirm action',
                'Do you want to confirm isolation review'
            );
        } else {
            this.showRequiredActionPopup(
                'Cannot allow isolation review',
                'LUN with a past LUN date or without the required CAIs cannot be reviewed. Go to the edit mode and complete the form.'
            );
        }
    }

    getCurrentSignOffAction() {
        return this.isIAndCLead && !this.isIAndCLeadReviewCompleted && this.isSigner(Constants.leads.iAndCLead)
            ? LUNStatusChangeAction.AreaSCIAndCDisciplineLeadReview
            : this.isElectricalLead &&
              !this.isElectricalReviewCompleted &&
              this.isSigner(Constants.leads.electricalLead)
            ? LUNStatusChangeAction.AreaSCElectricalDisciplineLeadReview
            : this.isSMPLead && !this.isSMPLeadReviewCompleted && this.isSigner(Constants.leads.smpLead)
            ? LUNStatusChangeAction.AreaSCSMPDisciplineLeadReview
            : null;
    }

    setRequiredCAI() {
        let action = this.getCurrentSignOffAction();
        switch (action) {
            case LUNStatusChangeAction.AreaSCIAndCDisciplineLeadReview:
                this.lunForm.controls.areaSCIAndCDisciplineLeadCAI.setValidators([Validators.required]);
                return;
            case LUNStatusChangeAction.AreaSCElectricalDisciplineLeadReview:
                this.lunForm.controls.areaSCElectricalDisciplineLeadCAI.setValidators([Validators.required]);
                return;
            case LUNStatusChangeAction.AreaSCSMPDisciplineLeadReview:
                this.lunForm.controls.areaSCSMPDisciplineLeadCAI.setValidators([Validators.required]);
                return;
        }
    }

    isReadyForSignOff(action: LUNStatusChangeAction) {
        let isCAIValid = true;
        let isLUNDateValid = moment().utc().diff(this.lun.lunDate, 'days') <= 0;
        switch (action) {
            case LUNStatusChangeAction.AreaSCIAndCDisciplineLeadReview:
                isCAIValid = this.iAndCLeadCAI !== '';
                break;
            case LUNStatusChangeAction.AreaSCElectricalDisciplineLeadReview:
                isCAIValid = this.electricalLeadCAI !== '';
                break;
            case LUNStatusChangeAction.AreaSCSMPDisciplineLeadReview:
                isCAIValid = this.SMPLeadCAI !== '';
                break;
        }
        return isCAIValid && this.status === LUNStatusType.Submitted && isLUNDateValid;
    }

    isReadyForIsolationSMPReview() {
        let isLUNDateValid = moment().utc().diff(this.lun.lunDate, 'days') <= 0;
        const isCAIValid =
            this.isolationLeadSMPCAI !== '' &&
            this.isolationLeadSMPCAI !== null &&
            this.isolationLeadSMPCAI !== undefined;
        return this.status === LUNStatusType.Reviewed && isCAIValid && isLUNDateValid;
    }

    isReadyForIsolationElectricalReview() {
        let isLUNDateValid = moment().utc().diff(this.lun.lunDate, 'days') <= 0;
        const isCAIValid =
            this.isolationLeadElectricalCAI !== '' &&
            this.isolationLeadElectricalCAI !== null &&
            this.isolationLeadElectricalCAI !== undefined;
        return this.status === LUNStatusType.Reviewed && isCAIValid && isLUNDateValid;
    }

    approve() {
        if (this.status === LUNStatusType.Reviewed && this.managerCAI !== '') {
            this.runUpdateStatusAction(
                LUNStatusChangeAction.AreaSCManagerApprove,
                'Confirm action',
                'Do you want to submit approval?'
            );
        } else {
            this.showRequiredActionPopup(
                'Cannot approve',
                'LUN without the required CAI cannot be approved. Go to the edit mode and complete the form.'
            );
        }
    }

    cancelOrVoid() {
        const action =
            this.status === LUNStatusType.Approved ? LUNStatusChangeAction.Void : LUNStatusChangeAction.Cancel;

        this.runUpdateStatusAction(
            action,
            'Confirm action',
            `Do you want to change this LUN's state to ${
                this.status === LUNStatusType.Approved ? 'void' : 'cancelled'
            }?`
        );
    }

    private saveAndupdateStatusAction(changeAction: LUNStatusChangeAction) {
        this.store.dispatch(
            new LUNSaveAndUpdateStatusRequest({
                form: this.lunForm.value,
                changeAction: changeAction,
            })
        );
        this.disableFormEvent.emit();
    }

    private updateStatusAction(changeAction: LUNStatusChangeAction) {
        this.store.dispatch(
            new LUNUpdateStatusRequest({
                id: this.lun.id,
                changeAction: changeAction,
            })
        );
        this.disableFormEvent.emit();
    }

    private updateStatusWithChangesCheck(
        updateStatusPopupSettings: { title: string; text: string },
        successAction: () => void,
        discardAction?: () => void
    ) {
        if (
            this.status !== LUNStatusType.Draft &&
            this.isNotNullAndNotUndefined(this.updatedProperties) === true &&
            this.updatedProperties.length > 0
        ) {
            this.popupService
                .openPopup(
                    new ConfirmDialogPopupSettings({
                        title: 'Changes are not saved!',
                        text: "You haven't save your changes. Do you want to discard them?",
                        isDiscardChanges: true,
                    })
                )
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((answer) => {
                    if (answer === true) {
                        this.popupService
                            .openPopup(new ConfirmDialogPopupSettings(updateStatusPopupSettings))
                            .afterClosed()
                            .pipe(takeWhile(() => this.isAlive === true))
                            .subscribe((ans) => {
                                if (ans === true) {
                                    successAction();
                                } else if (discardAction) {
                                    discardAction();
                                }
                            });
                    }
                });
        } else {
            this.popupService
                .openPopup(new ConfirmDialogPopupSettings(updateStatusPopupSettings))
                .afterClosed()
                .pipe(takeWhile(() => this.isAlive))
                .subscribe((answer) => {
                    if (answer === true) {
                        successAction();
                    } else if (discardAction) {
                        discardAction();
                    }
                });
        }
    }

    private runUpdateStatusAction(changeAction: LUNStatusChangeAction, title: string, text: string) {
        this.updateStatusWithChangesCheck({ title, text }, () => this.updateStatusAction(changeAction));
    }

    private showAutosaveDisabledPopup() {
        return this.popupService
            .openPopup(
                new PopupSettings(
                    InformationDialogComponent,
                    null,
                    null,
                    {
                        title: 'Autosave disabled',
                        text:
                            'Autosave function is not enabled in mode other than Draft. Please click on Save after making edits.',
                    },
                    1,
                    1
                )
            )
            .afterClosed();
    }

    private showRequiredActionPopup(title: string, text: string) {
        return this.popupService
            .openPopup(new PopupSettings(InformationDialogComponent, null, null, { title, text }, 1, 1))
            .afterClosed();
    }

    revertToDraft = () => {
        let text =
            this.isManager || this.isSMPLead || this.isElectricalLead || this.isIAndCLead
                ? `Are you sure you want to reject LUN back to the Originator? \n Consider adding comments (see section at the bottom of page) to provide reason for rejection.`
                : 'Do you want to revert to draft?';
        this.updateStatusWithChangesCheck({ title: 'Confirm action', text }, () => {
            this.disableFormEvent.emit();
            this.store.dispatch(
                new LUNUpdateStatusRequest({
                    id: this.lunForm.controls['id'].value,
                    changeAction: LUNStatusChangeAction.Draft,
                })
            );
        });
    };

    save() {
        this.lunForm.updateValueAndValidity();
        this.store.dispatch(new LUNUpdateRequest(this.lunForm.value));
        if (
            (this.isNotNullAndNotUndefined(this.status) && this.status !== LUNStatusType.Draft) ||
            (this.status === LUNStatusType.Draft && !this.isOriginator && (this.isAdmin || this.isEditUser))
        ) {
            this.disableFormEvent.emit();
        }
    }

    cancelEdit() {
        location.reload();
    }

    private showOrHideButtons(updatedProperties: string[]) {
        const isUserOriginatorOrAnApprover =
            this.isAdmin ||
            this.isOriginator ||
            this.isManager ||
            this.isElectricalLead ||
            this.isIAndCLead ||
            this.isIsolationSMPLead ||
            this.isIsolationElectricalLead ||
            this.isSMPLead;
        const isVoidOrCancelled = this.status === LUNStatusType.Cancelled || this.status === LUNStatusType.Void;

        this.isEditButtonVisible =
            (this.isAdmin ||
                ((this.status === LUNStatusType.Cancelled || this.status === LUNStatusType.Draft) && this.isEditUser) ||
                (this.status === LUNStatusType.Submitted &&
                    ((this.isElectricalLead && !this.isElectricalReviewCompleted) ||
                        (this.isSMPLead && !this.isSMPLeadReviewCompleted) ||
                        (this.isIAndCLead && !this.isIAndCLeadReviewCompleted))) ||
                (this.status === LUNStatusType.Reviewed &&
                    (this.isManager || this.isIsolationSMPLead || this.isIsolationElectricalLead))) &&
            this.isLocked;

        this.isSaveButtonVisible = !this.isLocked;
        this.isSaveButtonDisabled = updatedProperties.length === 0;

        this.isRevertButtonVisible =
            !isVoidOrCancelled &&
            (((this.isElectricalLead || this.isIAndCLead || this.isSMPLead) &&
                this.status === LUNStatusType.Submitted) ||
                (this.isManager && this.status === LUNStatusType.Reviewed) ||
                (this.isAdmin &&
                    this.isNotNullAndNotUndefined(this.status) &&
                    this.status !== LUNStatusType.Draft &&
                    this.status !== LUNStatusType.Approved));

        this.isSubmitButtonVisible = this.status === LUNStatusType.Draft && this.isOriginator;

        this.isSignOffButtonVisible =
            this.status === LUNStatusType.Submitted &&
            ((this.isElectricalLead && !this.isElectricalReviewCompleted && this.electricalLeadCAI !== null) ||
                (this.isSMPLead && !this.isSMPLeadReviewCompleted && this.SMPLeadCAI !== null) ||
                (this.isIAndCLead && !this.isIAndCLeadReviewCompleted && this.iAndCLeadCAI !== null));

        this.isIsolationSMPReviewButtonVisible =
            this.status === LUNStatusType.Reviewed &&
            !this.isManagerApprovalCompleted &&
            !!this.lun.leadIsolationAuthoritySMPCAI &&
            !this.isIsolationSMPLeadReviewCompleted &&
            this.isIsolationSMPLead;

        this.isIsolationElectricalReviewButtonVisible =
            this.status === LUNStatusType.Reviewed &&
            !this.isManagerApprovalCompleted &&
            !!this.lun.leadIsolationAuthorityElectricalCAI &&
            !this.isIsolationElectricalLeadReviewCompleted &&
            this.isIsolationElectricalLead;

        const requiredIsolationAuthoritySigners = this.lun.requiredIsolationAuthoritySigners?.length ?? 0;
        const signedIsolationAuthorities = [
            this.isIsolationSMPLeadReviewCompleted,
            this.isIsolationElectricalLeadReviewCompleted,
        ].filter(Boolean).length;
        const isIsolationDisciplineCompleted =
            this.lun.discipline === Constants.disciplines.AllDisciplines
                ? requiredIsolationAuthoritySigners === signedIsolationAuthorities
                : this.lun.discipline === Constants.disciplines.Mechanical ||
                  this.lun.discipline === Constants.disciplines.Piping
                ? this.isIsolationSMPLeadReviewCompleted
                : this.isIsolationElectricalLeadReviewCompleted;

        this.isApproveButtonVisible =
            this.status === LUNStatusType.Reviewed &&
            this.isManager &&
            !this.isManagerApprovalCompleted &&
            //isIsolationDisciplineCompleted &&
            this.managerCAI !== null;

        const isNotFinallyApproved = this.status !== LUNStatusType.Approved;

        this.isCancelButtonVisible =
            !isVoidOrCancelled && (this.isAdmin || (isUserOriginatorOrAnApprover && isNotFinallyApproved));
    }

    private compareUsersWithAccount(account: AccountInfo, userDetail: UserDetail) {
        return (
            this.isNotNullAndNotUndefined(account) &&
            this.isNotNullAndNotUndefined(userDetail) &&
            account.username.toLowerCase() === userDetail.email.toLowerCase()
        );
    }

    private isSigner(signer: string): boolean {
        return this.lunForm.controls.requiredSigners.value.indexOf(signer) > -1;
    }
}
