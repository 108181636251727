import { TemplateRef } from '@angular/core';
import { BasePopupSettings } from './base-popup-settings';
import { ComponentType } from '@angular/cdk/overlay/index';

export class PopupSettings<T> extends BasePopupSettings {
    content: ComponentType<any> | TemplateRef<any>;
    width: number;
    height: number;
    data: T;

    constructor(
        content: any,
        width: number,
        height: number,
        data: T = null,
        minWidth: number = null,
        minHeight: number = null,
        maxWidth: number = null,
        maxHeight: number = null,
        disableClose: boolean = false,
        panelClass: string = ''
    ) {
        if (minWidth && minHeight) {
            super(
                content,
                {
                    minHeight: minHeight + 'px',
                    minWidth: minWidth + 'px',
                    maxHeight: maxHeight + 'px',
                    maxWidth: maxWidth + 'px',
                    disableClose: disableClose,
                    autoFocus: false,
                    panelClass
                },
                data
            );
        } else {
            super(
                content,
                {
                    height: height + 'px',
                    width: width + 'px',
                    minHeight: minWidth + 'px',
                    minWidth: minHeight + 'px',
                    disableClose: disableClose,
                    autoFocus: false,
                    panelClass
                },
                data
            );
        }
        this.content = content;
        this.height = height;
        this.width = width;
        this.data = data;
    }
}
