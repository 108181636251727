import { Action } from '@ngrx/store';

export enum ManualUploadActionTypes {
    ManualUploadSetActivatedRoute = '[Manual Upload] Set Activated Route',
    ManualUploadFilesUploadRequest = '[Manual Upload] Files Upload Request',
    ManualUploadFilesUploadSuccess = '[Manual Upload] Files Upload Success',
    ManualUploadFilesUploadError = '[Manual Upload] Files Upload Error',
}

export class ManualUploadSetActivatedRoute implements Action {
    readonly type = ManualUploadActionTypes.ManualUploadSetActivatedRoute;

    constructor(public payload: string) {}
}

export class ManualUploadFilesUploadRequest implements Action {
    readonly type = ManualUploadActionTypes.ManualUploadFilesUploadRequest;

    constructor(public payload: FormData) {}
}

export class ManualUploadFilesUploadSuccess implements Action {
    readonly type = ManualUploadActionTypes.ManualUploadFilesUploadSuccess;
}

export class ManualUploadFilesUploadError implements Action {
    readonly type = ManualUploadActionTypes.ManualUploadFilesUploadError;

    constructor(public payload: string) {}
}

export type ManualUploadActions =
    | ManualUploadSetActivatedRoute
    | ManualUploadFilesUploadRequest
    | ManualUploadFilesUploadSuccess
    | ManualUploadFilesUploadError;
