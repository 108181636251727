import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/services/shared/form.service';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LUNPopupValidators } from '../../validators/lun-popup-validators';
import { takeWhile, take } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/shared/toast.service';
import { LUNLookupService } from '../../services/lun-lookup.service';
import { forkJoin, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { LUNInitialFields } from '../../store/lun/model';
import { AddLUNRequest } from '../../store/lun/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { LookupService } from '../../services/lookup.service';
import { ApplicationState } from 'src/app/store/model';
import _ from 'lodash';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { RoleService } from 'src/app/services/shared/role.service';
import { Constants } from 'src/app/constants';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ProjectTeamsService } from 'src/app/services/project-teams.service';

@Component({
    selector: 'app-new-lun',
    templateUrl: './new-lun.component.html',
    styleUrls: ['./new-lun.component.scss'],
})
export class NewLUNComponent extends BaseComponent implements OnInit, OnDestroy {
    lunForm: UntypedFormGroup;
    loading: boolean;
    disciplines: string[];
    setSubsystemInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    projectTeamNames: string[] = [];
    constructor(
        private formSvc: FormService,
        private dialogRef: MatDialogRef<NewLUNComponent>,
        private toastService: ToastService,
        private lunPopupValidators: LUNPopupValidators,
        private lunLookupService: LUNLookupService,
        private lookupService: LookupService,
        private store: Store<ApplicationState>,
        private popupService: PopupService,
        private roleService: RoleService,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();

        this.lunForm = this.formSvc.createForm(new LUNInitialFields(), this.lunPopupValidators);
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    ngOnInit() {
        this.loading = true;
        this.lookupService
            .getDisciplines()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (disciplines) => {
                    this.loading = false;
                    this.disciplines = disciplines;
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while fetching disciplines. Please contact Program Administrator.'
                    );
                }
            );

        this.projectTeamNames = this.projectTeamsService.getTeams();
    }

    getSubsystems = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchSubsystems(search, take, page, this.projectTeamNames);
    };

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        this.store.dispatch(
            new AddLUNRequest({
                lun: this.lunForm.value,
            })
        );
        this.store
            .select((state) => state.lunState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.loading = false;
                    this.dialogRef.close();
                }
            });
    }
}
