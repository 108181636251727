import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base.component';
import { HeaderChecklistFilterComponent } from 'src/app/components/filter/header-checklist-filter/header-checklist-filter.component';
import { EmailDistribution } from 'src/app/models/configuration';
import { HeaderCheckListFilter } from 'src/app/models/filter-settings';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { ToastService } from 'src/app/services/shared/toast.service';
import { CheckListColumn, OrderDirection } from 'src/app/store/common.model';

@Component({
    selector: 'app-email-distribution',
    templateUrl: './email-distribution.component.html',
    styleUrls: ['./email-distribution.component.scss'],
})
export class EmailDistributionComponent extends BaseComponent implements OnInit {
    isLoading = false;
    users: EmailDistribution[] = [];
    displayedColumns = ['name', 'surname', 'email', 'areaManagerSharedEmail', 'actions'];
    resultsLength = 0;
    pageSize = 25;
    sortBy = 'surname';
    direction = 'asc';

    columnNames: CheckListColumn = null;
    columnSurnames: CheckListColumn = null;
    columnEmails: CheckListColumn = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    constructor(
        private configurationService: ConfigurationService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private toastService: ToastService,
        private popupService: PopupService
    ) {
        super();
        this.iconRegistry.addSvgIcon(
            'edit',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
        );
    }

    ngOnInit() {
        this.loadData();
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.pageSize = pageEvent.pageSize;
        }

        this.loadData();
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        var pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    enterEditMode(config: EmailDistribution) {
        config.isInEditMode = !config.isInEditMode;
        config.areaManagerSharedEmailPrev = config.areaManagerSharedEmail;
    }

    cancelEdit(config: EmailDistribution) {
        config.isInEditMode = !config.isInEditMode;
        config.areaManagerSharedEmail = config.areaManagerSharedEmailPrev;
    }

    save(config: EmailDistribution) {
        config.isInEditMode = !config.isInEditMode;

        this.isLoading = true;
        this.configurationService
            .updateEmailDistributionData(config)
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occured while updating Approval Notification DL. Please contact Program Administrator'
                    );
                    return of(error);
                })
            )
            .subscribe(() =>
                this.toastService.Success(
                    `Approval Notification DL has been successfully updated for the user ${config.email}`
                )
            );
    }

    getNames = (search = '', take = 10, page = 0) =>
        this.configurationService.getNamesForEmailDistributionFilter(search, page, take, {
            columnNames: this.columnNames,
            columnSurnames: this.columnSurnames,
            columnEmails: this.columnEmails,
        });

    getSurnames = (search = '', take = 10, page = 0) =>
        this.configurationService.getSurnamesForEmailDistributionFilter(search, page, take, {
            columnNames: this.columnNames,
            columnSurnames: this.columnSurnames,
            columnEmails: this.columnEmails,
        });

    getEmails = (search = '', take = 10, page = 0) =>
        this.configurationService.getEmailsForEmailDistributionFilter(search, page, take, {
            columnNames: this.columnNames,
            columnSurnames: this.columnSurnames,
            columnEmails: this.columnEmails,
        });

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        displayBlanksRadio: boolean = false,
        isSortingOff: boolean = false
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: isSortingOff,
                showCounts,
                showInputSearch: showInputSearch,
                displayBlanksRadio: displayBlanksRadio,
                onlyBlanks: selected ? selected.onlyBlanks : false,
                excludeBlanks: selected ? selected.excludeBlanks : false,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this[propertyName] = value;
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.loadData();
                },
                resetColumnAction: () => {
                    this.sortBy = 'surname';
                    this.direction = 'asc';
                },
            })
        );
    }

    private loadData() {
        this.isLoading = true;
        this.configurationService
            .getEmailDistributionData({
                page: this.paginator.pageIndex,
                take: this.pageSize,
                sortBy: this.sortBy,
                direction: this.direction,
                columnNames: this.columnNames,
                columnSurnames: this.columnSurnames,
                columnEmails: this.columnEmails,
            })
            .pipe(
                take(1),
                finalize(() => (this.isLoading = false))
            )
            .subscribe((data: { totalCount: number; items: EmailDistribution[] }) => {
                this.users = data.items;
                this.resultsLength = data.totalCount;
            });
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        this.sortBy = isAscendingSort || isDescendingSort ? columnName : this.sortBy;
        this.direction = isAscendingSort ? 'asc' : isDescendingSort ? 'desc' : this.direction;
    }
}
