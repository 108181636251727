import {
    SubsystemListState,
    SubsystemListDeltaPagination,
    SubsystemListDeltaFilter,
    SubsystemListDeltaDTO,
} from './model';
import { SubsystemListActions, SubsystemListActionTypes } from './actions';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { ImportStatuses } from 'src/app/models/import-statuses';
import * as moment from 'moment';

const initialState: SubsystemListState = {
    uploadLogData: [],
    isUploadLogLoading: false,
    deltaData: new SubsystemListDeltaPagination(),
    isDeltaDataLoading: false,
    deltaFilter: new SubsystemListDeltaFilter(),
    uploadLogStartDate: null,
    uploadLogStatus: '',
    is3GPImportInProgress: false,
    is3GIImportInProgress: false,
    isLoading: false,
    isValidateButtonEnabled: false,
};

export function reducer(state = initialState, action: SubsystemListActions) {
    switch (action.type) {
        case SubsystemListActionTypes.SubsystemListUploadLogRequest: {
            return {
                ...state,
                isUploadLogLoading: true,
            };
        }
        case SubsystemListActionTypes.SubsystemListUploadLogSuccess: {
            return {
                ...state,
                uploadLogData: action.payload,
                isUploadLogLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListUploadLogError: {
            return {
                ...state,
                isUploadLogLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case SubsystemListActionTypes.SubsystemListDeltaSuccess: {
            return {
                ...state,
                deltaData: action.payload,
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListSetStatusAndStartDate: {
            return {
                ...state,
                uploadLogStatus: action.payload.status,
                uploadLogStartDate: action.payload.startDate,
            };
        }
        case SubsystemListActionTypes.SubsystemListDeltaFilterPropertyUpdate: {
            state.deltaFilter[action.payload.key] = action.payload.value;
            return {
                ...state,
            };
        }
        case SubsystemListActionTypes.SubsystemListPatchDeltaRequest: {
            var itemsObject = Object.assign(new Array<SubsystemListDeltaDTO>(), state.deltaData.items);
            const item = Object.assign(
                {},
                itemsObject.find((d) => d.subsystem === action.payload.subsystem)
            );
            item.deltaState = action.payload.deltaState;
            const index = itemsObject.findIndex((i) => i.subsystem === action.payload.subsystem);
            itemsObject[index] = item;
            return {
                ...state,
                isDeltaDataLoading: true,
                deltaData: {
                    items: itemsObject,
                },
            };
        }
        case SubsystemListActionTypes.SubsystemListPatchDeltaSuccess: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListPatchDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListPatchAllDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
            };
        }
        case SubsystemListActionTypes.SubsystemListPatchAllDeltaSuccess: {
            var itemsObject = Object.assign(new Array<SubsystemListDeltaDTO>(), state.deltaData.items);
            itemsObject.forEach((i) => {
                const item = Object.assign({}, i);
                item.deltaState = action.payload.deltaState;
                const index = itemsObject.findIndex((i) => i.subsystem === item.subsystem);
                itemsObject[index] = item;
            });
            return {
                ...state,
                isDeltaDataLoading: false,
                deltaData: {
                    items: itemsObject,
                },
            };
        }
        case SubsystemListActionTypes.SubsystemListPatchAllDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListValidateDeltaRequest: {
            return {
                ...state,
                isDeltaDataLoading: true,
                isValidateButtonEnabled: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListValidateDeltaSuccess: {
            return {
                ...state,
                deltaData: new SubsystemListDeltaPagination(),
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListValidateDeltaError: {
            return {
                ...state,
                isDeltaDataLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListFileUploaded: {
            if (action.payload === 'SubsystemList-3GP') {
                return {
                    ...state,
                    is3GPImportInProgress: true,
                    deltaData: new SubsystemListDeltaPagination(),
                };
            } else {
                return {
                    ...state,
                    is3GIImportInProgress: true,
                    deltaData: new SubsystemListDeltaPagination(),
                };
            }
        }
        case SubsystemListActionTypes.SubsystemListClearInProgressSpinner: {
            if (action.payload === 'SubsystemList-3GP') {
                return {
                    ...state,
                    is3GPImportInProgress: false,
                };
            } else {
                return {
                    ...state,
                    is3GIImportInProgress: false,
                };
            }
        }
        case SubsystemListActionTypes.SubsystemListTemplateFileRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SubsystemListActionTypes.SubsystemListTemplateFileSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListTemplateFileError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListValidateButtonStateSuccess: {
            return {
                ...state,
                isValidateButtonEnabled: action.payload,
            };
        }
        case SubsystemListActionTypes.SubsystemListDownloadOutputDataRequest: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SubsystemListActionTypes.SubsystemListDownloadOutputDataSuccess: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListDownloadOutputDataError: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SubsystemListActionTypes.SubsystemListAddStartedLog: {
            let updateLog = new UpdateDataLog();
            updateLog.status = ImportStatuses.Started;
            updateLog.type = action.payload;
            updateLog.startDate = moment();
            var logs = [{ ...updateLog }, ...state.uploadLogData];
            return {
                ...state,
                uploadLogData: logs,
            };
        }
        default:
            return state;
    }
}
