import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-asc-desc-order',
    templateUrl: './asc-desc-order.component.html',
    styleUrls: ['./asc-desc-order.component.scss'],
})
export class AscDescOrderComponent implements OnInit {
    @Output() isAscendingSortChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isDescendingSortChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() isAscendingSort: boolean;
    @Input() isDescendingSort: boolean;

    isAscendingSortToggle: boolean = false;
    isDescendingSortToggle: boolean = false;

    constructor() {}

    ngOnInit() {
        this.isAscendingSortToggle = this.isAscendingSort;
        this.isDescendingSortToggle = this.isDescendingSort;
    }

    onAscendingSort() {
        this.isAscendingSortToggle = !this.isAscendingSortToggle;
        this.isDescendingSortToggle = false;

        this.emitToggles();
    }

    onDescendingSort() {
        this.isAscendingSortToggle = false;
        this.isDescendingSortToggle = !this.isDescendingSortToggle;

        this.emitToggles();
    }

    emitToggles() {
        this.isAscendingSortChange.emit(this.isAscendingSortToggle);
        this.isDescendingSortChange.emit(this.isDescendingSortToggle);
    }
}
