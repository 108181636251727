import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LUNRegisterFilter } from '../store/lun-register/model';
import { appConfig } from '../app.config';
import { Area } from '../store/common/model';
import { NightShiftRegisterFilter } from '../store/night-shift-register/model';

@Injectable({
    providedIn: 'root',
})
export class LUNLookupService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly lunLookupEndpoint: string = '/lookup';

    constructor(private http: HttpClient) {}

    getStatuses(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/statuses/lunregister`, filter, {
            params,
        });
    }

    getNumbers(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/numbers/lunregister`, filter, {
            params,
        });
    }

    getAreas() {
        return this.http.get<Area[]>(`${this.rootUrl}${this.lunLookupEndpoint}/areas`);
    }

    getNightShiftStatuses(search = '', take = -1, page = -1, filter: NightShiftRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/statuses/nightshiftregister`, filter, {
            params,
        });
    }

    getNightShiftNumbers(search = '', take = -1, page = -1, filter: NightShiftRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/numbers/nightshiftregister`, filter, {
            params,
        });
    }

    getNightShiftAreas(search = '', take = -1, page = -1, filter: NightShiftRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/areas/nightshiftregister`, filter, {
            params,
        });
    }

    getSubsystems(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/subsystems/lunregister`, filter, {
            params,
        });
    }

    getDisciplines(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/disciplines/lunregister`, filter, {
            params,
        });
    }

    getOriginators(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/originators/lunregister`, filter, {
            params,
        });
    }

    getReviewers(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/reviewers/LUNregister`, filter, {
            params,
        });
    }

    getApprovers(search = '', take = -1, page = -1, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/approvers/LUNregister`, filter, {
            params,
        });
    }

    searchComments(search: string, take: number, page: number, filter: LUNRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.lunLookupEndpoint}/comments/lunregister`, filter, { params });
    }

    getNightShiftOriginators(search = '', take = -1, page = -1, filter: NightShiftRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post<string[]>(`${this.rootUrl}${this.lunLookupEndpoint}/originators/nightshiftregister`, filter, {
            params,
        });
    }

    searchNightShiftComments(search: string, take: number, page: number, filter: NightShiftRegisterFilter) {
        let params = this.getParams(search, take, page);
        return this.http.post(`${this.rootUrl}${this.lunLookupEndpoint}/comments/nightshiftregister`, filter, { params });
    }

    private getParams(search: string, take: number, page: number) {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('take', take.toString());
        params = params.append('search', search);
        return params;
    }
}
