<main class="main">
    <a class="navigator" [routerLink]="['/admin']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back to admin
    </a>
    <div class="title-container">
        <div class="title">
            configuration
        </div>
    </div>

    <nav mat-tab-nav-bar>
        <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
        >
            {{ link.label }}
        </a>
    </nav>

    <div class="main-container">
        <router-outlet></router-outlet>
    </div>
</main>
