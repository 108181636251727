import { UserDetail } from 'src/app/store/common.model';
import { Moment } from 'moment';
import { Attachment } from '../lun-attachments/model';
import { LUNStatusType } from 'src/app/models/enums';

export class LUNInitialFields {
    subsystems: Subsystem[] = [];
    discipline = '';
}

export class LUN {
    id: number = null;
    lunNumber = '';
    area: string = '';
    subsystems: Subsystem[] = [];
    raisedDate: Moment = null;
    discipline = '';
    originator: UserDetail = new UserDetail();
    description: string = null;
    lunDate: Moment = null;
    history: LUNHistory[] = [];
    comments: LUNComment[] = [];
    subsystemMCStatusDetails: LUNSubsystemMCStatusDetails[] = [];
    SupportingDocumentAttachments: Attachment[] = [];
    OldSupportingDocumentAttachments: Attachment[] = [];
    mcdaC2Number: string = '';
    mcC2Number: string = '';
    ptwNumber: string = '';
    iheiccNumber: string = '';
    isolationPermitNumber: string = '';
    subsystemDetails: SubsystemDetails = null;
    personInCharge: string = '';
    copy1: string = '';
    copy2: string = '';
    retainedByPTW = '';
    mcCertificateSigned: boolean = false;
    qvDsCompleted: boolean = false;
    markedupDrawingsAttached: boolean = false;
    areaSCElectricalDisciplineLeadReviewDate: Moment = null;
    areaSCSMPDisciplineLeadReviewDate: Moment = null;
    areaSCIAndCDisciplineLeadReviewDate: Moment = null;
    areaSCManagerReviewDate: Moment = null;
    leadIsolationAuthoritySMPReviewDate: Moment = null;
    leadIsolationAuthorityElectricalReviewDate: Moment = null;
    routeDate: Moment = null;
    areaSCElectricalDisciplineLead: UserDetail = new UserDetail();
    areaSCSMPDisciplineLead: UserDetail = new UserDetail();
    areaSCIAndCDisciplineLead: UserDetail = new UserDetail();
    leadIsolationAuthoritySMP: UserDetail = new UserDetail();    
    leadIsolationAuthorityElectrical: UserDetail = new UserDetail();
    areaSCManager: UserDetail = new UserDetail();
    areaSCElectricalDisciplineLeadCAI: string = '';
    areaSCSMPDisciplineLeadCAI: string = '';
    areaSCIAndCDisciplineLeadCAI: string = '';
    areaSCManagerCAI: string = '';
    leadIsolationAuthoritySMPCAI: string = '';
    leadIsolationAuthorityElectricalCAI: string = '';
    originatorCAI: string = '';
    status: LUNStatusType = LUNStatusType.Draft;
    wasAreaSCElectricalDisciplineLeadAdmin: boolean = false;
    wasAreaSCSMPDisciplineLeadAdmin: boolean = false;
    wasAreaSCIAndCDisciplineLeadAdmin: boolean = false;
    wasAreaSCManagerAdmin: boolean = false;
    wasLeadIsolationAuthoritySMPAdmin: boolean = false;
    wasLeadIsolationAuthorityElectricalAdmin: boolean = false;
    requiredSigners: string[] =  null;
    requiredIsolationAuthoritySigners: string[] =  null;
    hazardDescription: string = '';
}

export interface LUNState {
    readonly form: LUN;
    readonly updatedProperties: string[];
    readonly isLoading: boolean;
    readonly isHistoryLoading: boolean;
    readonly isCreated: boolean;
    readonly isEstimateDetailsLoading: boolean;
    readonly isDescriptionLoading: boolean;
    readonly isLocked: boolean;
    readonly isAutosaveInProgress: boolean;
    readonly isSubsystemMCStatusLoading: boolean;
    initiaFormVersion: number;
}
export interface LUNHistory {
    lunId: number;
    user: UserDetail;
    date: Date;
    action: string;
    prevModelJson: string;
    newModelJson: string;
}
export class CWP {
    id = '';
}

export class Subsystem {
    id = '';
    projectTeamName = '';
}

export class Tag {
    tagNo = '';
    tagType = '';
}

export class LUNComment {
    id: number = 0;
    description: string = '';
    userId: number = 0;
    user: UserDetail = null;
    date: Moment = null;
    lunId: number = 0;
    isLoading: boolean = true;
}

export class ExchangeRate {
    currency = '';
    value: number;
    prevValue: number;
    isInEditMode: boolean;
    default: number;
}

export class ExchangeRateEdit extends ExchangeRate {
    prevValue: number;
    isInEditMode: boolean;
}

export class ShareEmail {
    recipient: string;
    id: number;
    number: string;
}

export class LUNSubsystemMCStatusDetails {
    subsystem: string = '';
    mcActual: Date = null;
    dacActual: Date = null;
    apli: number = null;
    aqvd: number = null;
    npw: number = null;
    ex: number = null;
}

export class SubsystemDetails {
    subsystemName: string = '';
    system: string = '';
    id: string = '';
}
