import { Injectable } from '@angular/core';
import { UserDetail } from '../store/common.model';
import { HttpParams, HttpClient } from '@angular/common/http';
import { appConfig } from '../app.config';

@Injectable({
    providedIn: 'root',
})
export class ActionUsersService {
    private readonly rootUrl: string = appConfig.apiEndpoint;
    private readonly actionUserUrl: string = '/lun/actionusers';

    constructor(private http: HttpClient) {}

    getReviewers(type: string, discipline?: string) {
        let params = new HttpParams();
        params = params.append('type', type);
        if(discipline) {
            
        params = params.append('discipline', discipline);
        }
        return this.http.get<UserDetail[]>(`${this.rootUrl}${this.actionUserUrl}/reviewers`, {
            params: params,
        });
    }
}
