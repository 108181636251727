import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { Moment } from 'moment';

export interface LUNMyPageState {
    readonly counters: MyPageCounters;
    readonly isCountersSectionLoading: boolean;

    readonly myPendingActionsPagination: MyPageCollection<MyPageLUNRow>;
    readonly isMyPendingActionsSectonLoading: boolean;
    readonly myPendingActionsFilter: MyPageFilter;

    readonly summaryPagination: MyPageCollection<MyPageLUNRow>;
    readonly summaryFilter: MyPageFilter;
    readonly isSummarySectionLoading: boolean;
    readonly selectedSummarySection: LUNMyPageSections;

    readonly allDraftsPagination: MyPageCollection<MyPageLUNRow>;
    readonly isAllDraftsSectionLoading: boolean;
    readonly allDraftsFilter: MyPageFilter;

    readonly delegationsPagination: MyPageCollection<DelegationsRow>;
    readonly isDelegationsSectionLoading: boolean;
    readonly delegationsFilter: DelegationFilter;
    readonly selectedItems: DelegationsRow[];
}

export enum DelegationType {
    Originator = 1,
    ContractsEngineer = 2,
    CostEngineer = 3,
    Approver = 4,
}

export class DelegationsRow {
    type: DelegationType;
    id: number;
    lunNumber: string;
    revision: string;
    subject: string;
    originator: string;
    approver: string;
    costEngineer: string;
    contractsEngineer: string;
    discipline: string;
    raisedDate: Moment;
    contractNo: string;
}

export class MyPageLUNRow {
    id: number;
    lunNumber: string;
    revision: string;
    subject: string;
    originator: string;
    discipline: string;
    raisedDate: Moment;
    routedDate: Moment;
}

export class MyPageCollection<T> {
    totalCount = 0;
    items: T[] = [];
}

export class MyPageFilter {
    sortBy: string;
    direction: OrderDirection = OrderDirection.Desc;
    page = 0;
    take = 10;
    columnNumber: CheckListColumn = null;
    columnRevision: CheckListColumn = null;
    columnDiscipline: CheckListColumn = null;
    columnSubject: CheckListColumn = null;
    columnStatus: CheckListColumn = null;
    columnOriginator: CheckListColumn = null;
    columncostEngineer: CheckListColumn = null;
    columnapprover: CheckListColumn = null;
    columncontractsEngineer: CheckListColumn = null;
    columnRaisedDate: CalendarColumn = null;
    columnapproveDate: CalendarColumn = null;
    columncostEngineerReviewDate: CalendarColumn = null;
    columnContractNo: CheckListColumn = null;
   
    constructor(sortBy: string) {
        this.sortBy = sortBy;
    }
}

export class DelegationFilter extends MyPageFilter {
    type: DelegationType = DelegationType.Originator;
}

export class MyPageCounters {
    totalCount = 0;
    pendingActionCount = 0;
    draftCount = 0;
    submittedCount = 0;
    approvedCount = 0;
    reviewedCount = 0;
}

export enum LUNMyPageSections {
    Total = 0,
    Pending = 1,
    Draft = 2,
    Submitted = 3,
    Reviewed = 4,
    Approved = 5,
    MyDraft = 6,
}
