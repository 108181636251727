<main class="main">
    <form [formGroup]="nightShiftForm">
        <app-night-shift-workflow-status
            [nightShiftForm]="nightShiftForm"
        ></app-night-shift-workflow-status>
        <div [ngClass]="{ 'is-sticky': isSticky }">
            <app-night-shift-buttons
                [nightShiftForm]="nightShiftForm"
                (disableFormEvent)="disableForm()"
                (enableFormEvent)="enableForm()"
                (disableReviewAndApproveSectionEvent)="disableReviewAndApproveSection()"
            ></app-night-shift-buttons>
        </div>
        <app-night-shift-header [nightShiftForm]="nightShiftForm"></app-night-shift-header>
        <app-night-shift-attachments
            [nightShiftForm]="nightShiftForm"
            [isDisabled]="isLocked"
            [printMode]="printMode"
        ></app-night-shift-attachments>
        <app-night-shift-comments-history
            [comments]="comments"
            [nightShiftId]="nightShiftId"
            [nightShiftNumber]="nightShiftNumber$ | async"
            [printMode]="printMode"
        ></app-night-shift-comments-history>
        <app-loading-indicator *ngIf="isLoading"> </app-loading-indicator>
    </form>
</main>
