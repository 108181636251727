import { AreaDTO, Contractor, UserDetail } from 'src/app/store/common.model';
import { Moment } from 'moment';
import { NightShiftStatusType } from 'src/app/models/enums';

export class NightShiftInitialFields {
    location: string = '';
}

export class NightShift {
    id: number = null;
    area: string = '';
    location: string = '';
    companyName: Contractor[] = [];
    proposedDurationText: string = '';
    subContractor: string = '';
    raisedDate: Moment = null;
    originator: UserDetail = new UserDetail();
    proposedDurationFromDate: Moment = null;
    proposedDurationUntilDate: Moment = null;
    history: NightShiftHistory[] = [];
    comments: NightShiftComment[] = [];
    SupportingDocumentAttachments: Attachment[] = [];
    OldSupportingDocumentAttachments: Attachment[] = [];
    status: NightShiftStatusType = NightShiftStatusType.Draft;
    number: string = '';
    numberOfPersons: string = '';
    scope: string = '';
}

export interface NightShiftState {
    readonly form: NightShift;
    readonly updatedProperties: string[];
    readonly isLoading: boolean;
    readonly isHistoryLoading: boolean;
    readonly isCreated: boolean;
    readonly isDescriptionLoading: boolean;
    readonly isLocked: boolean;
    readonly isAutosaveInProgress: boolean;
}
export interface NightShiftHistory {
    nightShiftId: number;
    user: UserDetail;
    date: Date;
    action: string;
    prevModelJson: string;
    newModelJson: string;
}

export class NightShiftComment {
    id: number = 0;
    description: string = '';
    userId: number = 0;
    user: UserDetail = null;
    date: Moment = null;
    nightShiftId: number = 0;
    isLoading: boolean = true;
}

export enum NightShiftAttachmentType {
    SupportingDocument = 1,
    Estimate = 2,
}

export class NightShiftAttachments {
    nightShiftId: number;
    attachments: Attachment[];
}

export class Attachment {
    id: number;
    name = '';
    link = '';
    documentNo = '';
    loading: boolean;
    attachmentType: NightShiftAttachmentType = null;
    nightShiftId: number;
    file: File;
    isValid: boolean;
    isBeingProcessed: boolean;
}
