<div class="lun-form-header-container">
    <div [formGroup]="lunForm">
        <div class="col-header-container filter-container">
            <mat-form-field class="container400 margin-right20">
                <input matInput placeholder="LUN Number" formControlName="lunNumber" readonly />
            </mat-form-field>
            <mat-form-field class="container400 margin-right20">
                <input matInput placeholder="MC-DAC2 No." formControlName="mcdaC2Number" />
            </mat-form-field>
            <mat-form-field class="container400 margin-right20">
                <input matInput placeholder="MCC2 No." formControlName="mcC2Number" />
            </mat-form-field>
            <mat-form-field class="container190 margin-right20">
                <input matInput placeholder="PTW No." formControlName="ptwNumber" />
            </mat-form-field>
            <mat-form-field class="container190 margin-right20">
                <input matInput placeholder="IHE/ICC No." formControlName="iheiccNumber" />
            </mat-form-field>
            <mat-form-field class="container190 margin-right20">
                <input matInput placeholder="Isolation Permit No." formControlName="isolationPermitNumber" />
            </mat-form-field>

            <mat-form-field class="container190 margin-right20">
                <mat-label>Area</mat-label>
                <mat-select matNativeControl formControlName="area">
                    <mat-option *ngFor="let area of areas" [value]="area.id">
                        {{ area.code }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="container190 margin-right20">
                <input
                    matInput
                    placeholder="Raised Date"
                    readonly
                    [value]="lunForm.controls.raisedDate.value | date: 'd/MMM/yy'"
                />
            </mat-form-field>
            <mat-form-field class="container190">
                <mat-label>Discipline</mat-label>
                <mat-select matNativeControl formControlName="discipline">
                    <mat-option *ngFor="let disc of disciplines" [value]="disc">
                        {{ disc }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="container-multiselector">
                <mat-form-field class="container610 margin-right20">
                    <input matInput placeholder="Hazard Description" formControlName="hazardDescription" />
                </mat-form-field>
                <app-multiple-items-selector
                    class="container610"
                    formControlName="subsystems"
                    [searchFunc]="getSubsystems"
                    [isValueLoading]="isSubsystemsLoading"
                    [setInput]="setSubsystemInput"
                    [isAsync]="true"
                    [filterForm]="lunForm"
                    [formSetter]="'subsystems'"
                    [placeholder]="'Subsystem'"
                    [isDisabled]="isLocked"
                    required
                    [invalid]="lunForm.controls['subsystems'].invalid && lunForm.controls['subsystems'].errors.required"
                    [errorMessage]="'At least one subsystem need to be set'"
                >
                </app-multiple-items-selector>
            </div>
            <div>
                <mat-form-field class="container610 margin-right20">
                    <input
                        matInput
                        placeholder="Sub-System Description"
                        readonly
                        [value]="subsystemDetailsForm.controls['subsystemName']?.value"
                    />
                </mat-form-field>

                <mat-form-field class="container250 margin-right20">
                    <input
                        matInput
                        placeholder="System"
                        readonly
                        [value]="subsystemDetailsForm.controls['system']?.value"
                    />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>LUN Date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="lunDate"
                        formControlName="lunDate"
                        (dateChange)="dateChange($event)"
                    />
                    <mat-datepicker-toggle matSuffix [for]="lunDate"></mat-datepicker-toggle>
                    <mat-datepicker #lunDate></mat-datepicker>
                </mat-form-field>
            </div>

            <span>
                System boundaries are clearly defined in the attached marked up drawings and supplementary
                documentation.
                This Livening Up Notice (LUN) herby gives notice that the system / sub-system defined above and
                supplementary
                documentation have been danded over to Systems Completion for commissioning, referenced by teh MC-DAC2 /
                MCC2, and
                that onec energized, the hazards associated with the system / sub-system, as defined, will change. With
                effect from the
                Time on the Date above, no work may be carried out on these system(s), unless covered by an approved
                Permit to Work.
            </span>
        </div>
    </div>
</div>
