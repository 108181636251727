import { Component, OnInit } from '@angular/core';
import { LUNSubsysteMCStatusRequest } from 'src/app/store/lun/actions';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { BaseComponent } from 'src/app/components/base.component';
import { takeWhile, take, filter, distinctUntilChanged } from 'rxjs/operators';
import { LUNSubsystemMCStatusDetails } from 'src/app/store/lun/model';
import { FormService } from 'src/app/services/shared/form.service';
import { Observable } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-lun-subsystem-status',
  templateUrl: './lun-subsystem-status.component.html',
  styleUrls: ['./lun-subsystem-status.component.scss']
})
export class LunSubsystemStatusComponent extends BaseComponent implements OnInit {

  isSubsystemMCStatusLoading = false;
  isSubsystemMCStatusLoading$ = this.store.select((state) => state.lunState.isSubsystemMCStatusLoading);
  isLocked$ = this.store.select((state) => state.lunState.isLocked);
  mainForm$ = this.store.select((state) => state.lunState.form);
  subsystemMCStatusDetails$: Observable<LUNSubsystemMCStatusDetails[]> = this.store.select((state) => state.lunState.form.subsystemMCStatusDetails);
  isLocked = false;
  subsystemMCSForms: UntypedFormGroup[];

  constructor(private store: Store<ApplicationState>,
              private formService: FormService) {
    super();
    this.subsystemMCSForms = [this.formService.createSimpleForm(new LUNSubsystemMCStatusDetails())];
  }

  ngOnInit(): void {
    this.isSubsystemMCStatusLoading$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
      this.isSubsystemMCStatusLoading = isLoading;
    });

    this.mainForm$
      .pipe(
        takeWhile(() => this.isAlive),
        filter((form) => !!form.id),
        distinctUntilChanged((prev, curr) => 
          prev.id === curr.id 
          && prev.discipline === curr.discipline 
          && prev.subsystems.filter(item => curr.subsystems.indexOf(item) < 0).length === 0)
      )
      .subscribe((form) => {
        if (form.id !== null) {
          this.subsystemMCSForms.forEach(form => form.reset());
          this.subsystemMCSForms = [];

          const subsystems = form.subsystems.map(s => s.id);
          this.store.dispatch(new LUNSubsysteMCStatusRequest(subsystems));
          
        }
      });

    this.subsystemMCStatusDetails$
      .pipe(
        filter((subsystemMCStatusDetails) => subsystemMCStatusDetails !== null),
        takeWhile(() => this.isAlive)
      )
      .subscribe((subsystemMCStatusDetails) => {
        subsystemMCStatusDetails.forEach((status, idx) => {
          this.subsystemMCSForms[idx] = this.formService.createSimpleForm(new LUNSubsystemMCStatusDetails());
          this.subsystemMCSForms[idx].patchValue({...status});
        })
      });

    this.isLocked$.pipe(takeWhile(() => this.isAlive)).subscribe((isLocked) => {
      this.isLocked = isLocked;
    });
  }

  goToSCToolDetailedStatus(scope: string, subsystem: string, category: string, status: string) {
    status = status ? `&status=${status}` : '';
    let url = `${appConfig.sctoolAppUrl}/detailedstatus?scope=${scope}&subsystem=${subsystem}&category=${category}&zone=0${status}&lockFilters=true`;
    window.open(url, '_blank');
  }

  goToSCToolChangeManagement(changeType: string, subsystem: string) {
    let url = `${appConfig.sctoolAppUrl}/changemanagement?subsystem=${subsystem}&changeType=${changeType}&lockFilters=true`;
    window.open(url, '_blank');
  }

  trackForm(index, form) {
    return form ? form.controls?.subsystem?.value : undefined;
  }

}
