import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormService } from 'src/app/services/shared/form.service';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/store/model';
import { SummaryUpdateFilterProperty, SummaryRequest } from 'src/app/store/my-page/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { MyPageLUNRow, LUNMyPageSections, MyPageFilter } from 'src/app/store/my-page/models';
import { Router } from '@angular/router';
import { take, takeWhile } from 'rxjs/operators';
import { CalendarColumn, CheckListColumn, OrderDirection } from 'src/app/store/common.model';
import { PopupSettings } from 'src/app/models/popup-settings';
import { HeaderCheckListFilter, HeaderDateFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from '../../filter/header-checklist-filter/header-checklist-filter.component';
import { HeaderDateFilterComponent } from '../../filter/header-date-filter/header-date-filter.component';
import { PopupService } from 'src/app/services/shared/popup.service';
import { MyPageService } from 'src/app/services/my-page.service';

@Component({
    selector: 'app-lun-summary',
    templateUrl: './lun-summary.component.html',
    styleUrls: ['./lun-summary.component.scss'],
})
export class LUNSummaryComponent extends BaseComponent implements OnInit {
    summaryForm: UntypedFormGroup;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    pageSize = 10;
    sortBy = 'lunNumber';
    direction: OrderDirection = OrderDirection.Desc;
    displayedColumns: string[] = [
        'lunNumber',
        'revision',
        'discipline',
        'subject',
        'status',
        'originator',
        'raisedDate',
        'approveDate',
        'wr.contractNo',
    ];

    columnNumber$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnNumber);
    columnNumber: CheckListColumn = null;
    columnRevision$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnRevision);
    columnRevision: CheckListColumn = null;
    columnDiscipline$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnDiscipline);
    columnDiscipline: CheckListColumn = null;
    columnSubject$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnSubject);
    columnSubject: CheckListColumn = null;
    columnStatus$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnStatus);
    columnStatus: CheckListColumn = null;
    columnOriginator$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnOriginator);
    columnOriginator: CheckListColumn = null;
    columnRaisedDate$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnRaisedDate);
    columnRaisedDate: CalendarColumn = null;
    columnapproveDate$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnapproveDate);
    columnapproveDate: CalendarColumn = null;
    columncostEngineerReviewDate$ = this.store.select(
        (state) => state.lunMyPageState.summaryFilter.columncostEngineerReviewDate
    );
    columncostEngineerReviewDate: CalendarColumn = null;
    columnContractNo$ = this.store.select((state) => state.lunMyPageState.summaryFilter.columnContractNo);
    columnContractNo: CheckListColumn = null;

    selectedSummarySection$ = this.store.select((store) => store.lunMyPageState.selectedSummarySection);
    selectedSummarySection: LUNMyPageSections = null;
    selectedSectionName = LUNMyPageSections[LUNMyPageSections.Total];

    isLoading$ = this.store.select((store) => store.lunMyPageState.isSummarySectionLoading);
    summaryPagination$ = this.store.select((store) => store.lunMyPageState.summaryPagination);
    filter$ = this.store.select((store) => store.lunMyPageState.summaryFilter);

    constructor(
        private formService: FormService,
        private store: Store<ApplicationState>,
        private ngZone: NgZone,
        private router: Router,
        private popupService: PopupService,
        private myPageService: MyPageService
    ) {
        super();
        this.summaryForm = this.formService.createSimpleForm(new MyPageLUNRow());
    }

    ngOnInit() {
        this.filter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.paginator.pageIndex = filter.page;
            this.paginator.pageSize = filter.take;
            this.pageSize = filter.take;
            this.direction = filter.direction;
            this.sortBy = filter.sortBy;
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new SummaryUpdateFilterProperty({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;

            this.store.dispatch(new SummaryRequest());
        });

        this.selectedSummarySection$.pipe(takeWhile(() => this.isAlive)).subscribe((section) => {
            this.selectedSummarySection = section;
            this.selectedSectionName = LUNMyPageSections[section];
        });

        this.columnNumber$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNumber = data));
        this.columnRevision$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRevision = data));
        this.columnDiscipline$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnDiscipline = data));
        this.columnSubject$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnSubject = data));
        this.columnStatus$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatus = data));
        this.columnOriginator$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnOriginator = data));
        this.columnRaisedDate$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnRaisedDate = data));
        this.columnapproveDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnapproveDate = data));
        this.columncostEngineerReviewDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columncostEngineerReviewDate = data));
        this.columnContractNo$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnContractNo = data));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new SummaryUpdateFilterProperty({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new SummaryUpdateFilterProperty({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new SummaryRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }

        const pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    openDetailsPage(id: number) {
        this.ngZone.run(() => this.router.navigate([`details/${id}`]));
    }

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        displayBlanksRadio: boolean = false
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: false,
                showCounts,
                showInputSearch: showInputSearch,
                displayBlanksRadio: displayBlanksRadio,
                onlyBlanks: selected ? selected.onlyBlanks : false,
                excludeBlanks: selected ? selected.excludeBlanks : false,
                column: columnName,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;

                    this.store.dispatch(
                        new SummaryUpdateFilterProperty({
                            key: propertyName,
                            value: value,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new SummaryUpdateFilterProperty({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, 345, 625, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                action: (data) => {
                    this.store.dispatch(
                        new SummaryUpdateFilterProperty({
                            key: propertyName,
                            value: data.calendarColumn,
                        })
                    );

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new SummaryUpdateFilterProperty({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new SummaryUpdateFilterProperty({
                    key: 'sortBy',
                    value: { active: 'lunNumber', direction: OrderDirection.Desc },
                })
            );
        }
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new SummaryUpdateFilterProperty({
                key: 'page',
                value: 0,
            })
        );

        this.store.dispatch(new SummaryRequest());
    }

    private getLatestFilterData(): MyPageFilter {
        let filter: MyPageFilter;
        this.store
            .pipe(select((x) => x.lunMyPageState, take(1)))
            .subscribe((data) => (filter = data.summaryFilter));
        return filter;
    }

    getTableFilterValues = (search = '', take = 10, page = 0, column?: string) =>
        this.myPageService.getTableFilterValues(
            column,
            search,
            take,
            page,
            this.selectedSummarySection,
            this.getLatestFilterData()
        );
}
