import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
    LUNActionTypes,
    GetLUNRequest,
    GetLUNRequestSuccess,
    GetLUNRequestError,
    AddLUNRequestSuccess,
    AddLUNRequest,
    AddLUNRequestError,
    LUNUpdateRequest,
    LUNUpdateRequestError,
    LUNUpdateRequestSuccess,
    LUNDescriptionRequest,
    LUNDescriptionRequestError,
    LUNDescriptionRequestSuccess,
    LUNSubsysteMCStatusRequest,
    LUNSubsysteMCStatusRequestSuccess,
    LUNSubsysteMCStatusRequestError,
    LUNRemoveCommentRequest,
    LUNRemoveCommentSuccess,
    LUNRemoveCommentError,
    LUNAddCommentRequest,
    LUNAddCommentSuccess,
    LUNAddCommentError,
    LUNHistoryRequest,
    LUNHistoryRequestSuccess,
    LUNHistoryRequestError,
    LUNAttachmentsRequest,
    LUNAttachmentsSuccess,
    LUNAttachmentsError,
    LUNUpdateInitialFormWithAttachments,
    LUNDownloadAttachmentRequest,
    LUNDownloadAttachmentSuccess,
    LUNDownloadAttachmentError,
    LUNAutosaveError,
    LUNAutosaveRequest,
    LUNAutosaveSuccess,
    LUNSubsystemDetailsRequest,
    LUNSubsystemDetailsRequestSuccess,
    LUNSubsystemDetailsRequestError,
    LUNUpdateStatusRequest,
    LUNUpdateStatusRequestSuccess,
    LUNUpdateStatusRequestError,
    LUNSaveAndUpdateStatusRequest,
} from './actions';
import { mergeMap, catchError, map, tap, withLatestFrom, switchMap, concatMap } from 'rxjs/operators';
import { LUNService } from '../../services/lun.service';
import { ToastService } from '../../services/shared/toast.service';
import { iif, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LUN } from './model';
import { EmailService } from '../../services/email.service';
import { ApplicationState } from '../model';
import { HttpErrorResponse } from '@angular/common/http';
import _ from 'lodash';
import { Attachment, LUNAttachmentType } from '../lun-attachments/model';
import { saveAs } from 'file-saver';
import { LookupService } from 'src/app/services/lookup.service';
import { LUNStatusChangeAction, LUNStatusType } from 'src/app/models/enums';
import { Constants } from 'src/app/constants';
import { PopupService } from 'src/app/services/shared/popup.service';
import { PopupSettings } from 'src/app/models/popup-settings';
import { ManualEmailDialogComponent } from 'src/app/components/admin-section/configuration/email-distribution/manual-email-popup/manual-email-popup.component';
import { UserDetail } from '../common.model';

@Injectable()
export class LUNEffects {
    constructor(
        private actions$: Actions,
        private lunService: LUNService,
        private toastService: ToastService,
        private router: Router,
        private store: Store<ApplicationState>,
        private emailService: EmailService,
        private lookupService: LookupService,
        private popupService: PopupService
    ) {}

    
    getLUN$ = createEffect(() => this.actions$.pipe(
        ofType<GetLUNRequest>(LUNActionTypes.GetLUNRequest),
        mergeMap(({ payload }) =>
            this.lunService.getLUNById(payload).pipe(
                map((lundata) => {
                    return new GetLUNRequestSuccess(lundata);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while getting LUN with id:${payload}. Please contact Program Administrator.`
                    );
                    return of(new GetLUNRequestError(error));
                })
            )
        )
    ));

    
    addLUN$ = createEffect(() => this.actions$.pipe(
        ofType<AddLUNRequest>(LUNActionTypes.AddLUNRequest),
        mergeMap(({ payload }) =>
            this.lunService.createLUN(payload.lun).pipe(
                map((lunId) => {
                    return new AddLUNRequestSuccess({
                        lunId: +lunId,
                        lun: payload.lun,
                    });
                }),
                catchError((error) => {
                    this.toastService.Error('Error occurred while adding LUN. Please contact Program Administrator.');
                    return of(new AddLUNRequestError(error));
                })
            )
        )
    ));

    
    addLUNSuccess = createEffect(() => this.actions$.pipe(
        ofType<AddLUNRequestSuccess>(LUNActionTypes.AddLUNRequestSuccess),
        tap(({ payload }) => {
            this.router.navigate(['details', payload.lunId]);
        })
    ), { dispatch: false });

    
    autosaveLUN$ = createEffect(() => this.actions$.pipe(
        ofType<LUNAutosaveRequest>(LUNActionTypes.LUNAutosaveRequest),
        withLatestFrom(
            this.store.select((store) => store.lunState.updatedProperties),
            this.store.select((store) => store.lunState.form)
        ),
        mergeMap(([action, updatedProperties, lun]) => {
            this.toastService.Info('LUN autosave in progress.');
            return this.uploadAttachments(lun).pipe(
                concatMap((uploadedAttachments: any) =>
                    this.lunUpdate(lun, updatedProperties, uploadedAttachments?.attachments).pipe(
                        map((result) => {
                            return new LUNAutosaveSuccess(result.payload);
                        }),
                        catchError(([error]) => {
                            this.toastService.Error('LUN autosave failed.');
                            return of(new LUNAutosaveError(error));
                        })
                    )
                )
            );
        })
    ));

    
    updateLUN$ = createEffect(() => this.actions$.pipe(
        ofType<LUNUpdateRequest>(LUNActionTypes.LUNUpdateRequest),
        withLatestFrom(
            this.store.select((store) => store.lunState.updatedProperties),
            this.store.select((store) => store.lunState.form)
        ),
        mergeMap(([action, updatedProperties, lun]) => {
            return this.uploadAttachments(lun).pipe(
                concatMap((uploadedAttachments: any) =>
                    this.lunUpdate(lun, updatedProperties, uploadedAttachments?.attachments).pipe(
                        catchError(([error]) => {
                            this.toastService.Error('LUN update failed.');
                            return of(new LUNUpdateRequestError({ error: error[0], unlockForm: error[1] }));
                        })
                    )
                )
            );
        })
    ));

    
    loadScopeOfWorkDescription = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNDescriptionRequest),
        mergeMap((action: LUNDescriptionRequest) =>
            this.lunService.getDescription(action.payload).pipe(
                map((description) => new LUNDescriptionRequestSuccess(description)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading LUN scope of work description. Please contact Program Administrator.'
                    );
                    return of(new LUNDescriptionRequestError(error));
                })
            )
        )
    ));

    
    loadSubsystemMCStatusDetails = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNSubsysteMCStatusRequest),
        mergeMap((action: LUNSubsysteMCStatusRequest) =>
            this.lunService.getSubsystemMCStatusDetails(action.subsystems).pipe(
                map((payload) => new LUNSubsysteMCStatusRequestSuccess(payload)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading LUN subsystem status. Please contact Program Administrator.'
                    );
                    return of(new LUNSubsysteMCStatusRequestError(error));
                })
            )
        )
    ));

    
    loadSubsystemDetails = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNSubsystemDetailsRequest),
        mergeMap((action: LUNSubsystemDetailsRequest) =>
            this.lunService.getSubsystemDetails(action.subsystem).pipe(
                map((payload) => new LUNSubsystemDetailsRequestSuccess(payload)),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading LUN subsystem details. Please contact Program Administrator.'
                    );
                    return of(new LUNSubsystemDetailsRequestError(error));
                })
            )
        )
    ));

    
    removeLUNComment$ = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNRemoveCommentRequest),
        mergeMap((action: LUNRemoveCommentRequest) =>
            this.lunService.removeComment(action.payload.comment).pipe(
                map(() => new LUNRemoveCommentSuccess(action.payload)),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 403) {
                        this.toastService.Error('A comment can be deleted only by the author.');
                    } else {
                        this.toastService.Error(
                            'Error occurred while removing LUN comment. Please contact Program Administrator.'
                        );
                    }
                    return of(new LUNRemoveCommentError(error));
                })
            )
        )
    ));

    
    addLUNComment$ = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNAddCommentRequest),
        mergeMap((action: LUNAddCommentRequest) =>
            this.lunService.addComment(action.payload.comment).pipe(
                map((comment) => new LUNAddCommentSuccess({ comment: comment })),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while adding LUN comment. Please contact Program Administrator.'
                    );
                    return of(new LUNAddCommentError(error));
                })
            )
        )
    ));

    
    loadHistory$ = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNHistoryRequest),
        mergeMap((action: LUNHistoryRequest) =>
            this.lunService.loadHistory(action.payload).pipe(
                map((history) => {
                    return new LUNHistoryRequestSuccess(history);
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while loading LUN history. Please contact Program Administrator.'
                    );
                    return of(new LUNHistoryRequestError(error));
                })
            )
        )
    ));

    
    getAttachments$ = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNAttachmentsRequest),
        mergeMap((action: LUNAttachmentsRequest) =>
            this.lunService.getLUNAttachmentsRequest(action.payload).pipe(
                switchMap((uploadedAttachments: any) => {
                    return [
                        new LUNUpdateInitialFormWithAttachments({
                            attachments: uploadedAttachments,
                            type: action.payload.type,
                        }),
                        new LUNAttachmentsSuccess({
                            attachments: uploadedAttachments,
                            type: action.payload.type,
                        }),
                    ];
                }),
                catchError((error) => {
                    this.toastService.Error(
                        'Error occurred while displaying attachment. Please contact Program Administrator.'
                    );
                    return of(new LUNAttachmentsError(error));
                })
            )
        )
    ));

    
    downloadLUNAttachmentRequest = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNDownloadAttachmentRequest),
        mergeMap((action: LUNDownloadAttachmentRequest) =>
            this.lunService.downloadLUNAttachmentRequest(action.payload.attachment).pipe(
                map(
                    (attachmentBinaries) =>
                        new LUNDownloadAttachmentSuccess({
                            content: attachmentBinaries,
                            fileName: action.payload.attachment.name,
                            type: action.payload.type,
                        })
                ),
                catchError((error) => {
                    this.toastService.Error(
                        `Error occurred while downloading attachment ${action.payload.attachment.name}. Please contact Program Administrator.`
                    );
                    return of(
                        new LUNDownloadAttachmentError({
                            attachment: action.payload.attachment,
                            type: action.payload.type,
                        })
                    );
                })
            )
        )
    ));

    
    saveAndUpdateStatus$ = createEffect(() => this.actions$.pipe(
        ofType<LUNSaveAndUpdateStatusRequest>(LUNActionTypes.LUNSaveAndUpdateStatusRequest),
        withLatestFrom(
            this.store.select((store) => store.lunState.updatedProperties),
            this.store.select((store) => store.lunState.form)
        ),
        mergeMap(([action, updatedProperties, lun]) => {
            return this.uploadAttachments(lun).pipe(
                concatMap((uploadedAttachments: any) =>
                    this.lunUpdate(lun, updatedProperties, uploadedAttachments?.attachments).pipe(
                        ofType<LUNUpdateRequestSuccess>(LUNActionTypes.LUNUpdateRequestSuccess),
                        switchMap((successAction: LUNUpdateRequestSuccess) => {
                            return [
                                successAction,
                                new LUNUpdateStatusRequest({
                                    id: action.payload.form.id,
                                    changeAction: action.payload.changeAction,
                                }),
                            ];
                        }),
                        catchError(([error]) => {
                            return of(new LUNUpdateRequestError({ error: error[0], unlockForm: error[1] }));
                        })
                    )
                )
            );
        })
    ));

    
    updateStatus$ = createEffect(() => this.actions$.pipe(
        ofType(LUNActionTypes.LUNUpdateStatusRequest),
        mergeMap((action: LUNUpdateStatusRequest) => {
            return this.lunService.updateLUNStatus(action.payload.id, action.payload.changeAction).pipe(
                map((lun) => {
                    this.lookupService.canSendAutomatedEmails().subscribe((canSend) => {
                        var signers: string[] = [];
                        if (lun.areaSCElectricalDisciplineLead !== null) {
                            signers.push(lun.areaSCElectricalDisciplineLead.email);
                        }
                        if (lun.areaSCSMPDisciplineLead !== null) {
                            signers.push(lun.areaSCSMPDisciplineLead.email);
                        }
                        if (lun.areaSCIAndCDisciplineLead !== null) {
                            signers.push(lun.areaSCIAndCDisciplineLead.email);
                        }
                        if (lun.leadIsolationAuthoritySMP !== null) {
                            signers.push(lun.leadIsolationAuthoritySMP.email);
                        }
                        if (lun.leadIsolationAuthorityElectrical !== null) {
                            signers.push(lun.leadIsolationAuthorityElectrical.email);
                        }

                        if (!canSend) {
                            switch (lun.status) {
                                case LUNStatusType.Submitted: {
                                    if (action.payload.changeAction === LUNStatusChangeAction.Submit) {
                                        this.sendEmailOnSubmit(lun, signers);
                                    }
                                    break;
                                }
                                case LUNStatusType.Reviewed: {
                                    if (
                                        action.payload.changeAction ===
                                            LUNStatusChangeAction.AreaSCElectricalDisciplineLeadReview ||
                                        action.payload.changeAction ===
                                            LUNStatusChangeAction.AreaSCSMPDisciplineLeadReview ||
                                        action.payload.changeAction ===
                                            LUNStatusChangeAction.AreaSCIAndCDisciplineLeadReview ||
                                        action.payload.changeAction ===
                                            LUNStatusChangeAction.LeadIsolationAuthoritySMPReview ||
                                        action.payload.changeAction ===
                                            LUNStatusChangeAction.LeadIsolationAuthorityElectricalReview
                                    ) {
                                        this.sendEmailOnReviewed(lun);
                                    }
                                    break;
                                }
                                case LUNStatusType.Approved: {
                                    if (action.payload.changeAction === LUNStatusChangeAction.AreaSCManagerApprove) {
                                        this.sendEmailOnApproved(lun, signers);
                                    }
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                    });
                    this.toastService.Success(`LUN was successfully updated to status: ${LUNStatusType[lun.status]}`);
                    return new LUNUpdateStatusRequestSuccess(lun);
                }),
                catchError((response: HttpErrorResponse) => {
                    if (response.status === 400) {
                        this.toastService.Error(response.error);
                        return of(new LUNUpdateStatusRequestError({ unlockForm: true }));
                    } else {
                        this.toastService.Error(
                            'Error occurred while updating LUN status. Please save LUN manually, refresh page and retry updating status.'
                        );
                        return of(new LUNUpdateStatusRequestError({ unlockForm: false }));
                    }
                })
            );
        })
    ));

    
    downloadLUNAttachmentRequestSuccess = createEffect(() => this.actions$.pipe(
        ofType<LUNDownloadAttachmentSuccess>(LUNActionTypes.LUNDownloadAttachmentSuccess),
        map((action) => {
            const blob = new Blob([action.payload.content], {
                type: 'application/octet-stream',
            });

            saveAs(blob, action.payload.fileName);
        })
    ), { dispatch: false });

    private uploadAttachments(lun: LUN) {
        return iif(
            () => lun.SupportingDocumentAttachments.some((attachment) => !!attachment.file),
            this.lunService.uploadLUNAttachmentsRequest(
                lun.id,
                _.concat(lun.SupportingDocumentAttachments.filter((attachment) => !!attachment.file))
            ),
            of(null)
        );
    }

    private lunUpdate(form: LUN, updatedProperties: string[], uploadedAttachments: Attachment[] = []) {
        return this.lunService.updateLUN(form, updatedProperties).pipe(
            mergeMap((result: LUN) => {
                const payload = {
                    ...result,
                    SupportingDocumentAttachments: [
                        ...form.SupportingDocumentAttachments.map((supportingAttachment) => {
                            const attachment = uploadedAttachments.find(
                                (uploadedAttachment) =>
                                    uploadedAttachment.lunAttachmentType === LUNAttachmentType.SupportingDocument &&
                                    uploadedAttachment.name === supportingAttachment.name
                            );
                            return {
                                ...supportingAttachment,
                                link: attachment ? attachment.link : supportingAttachment.link,
                                lunId: attachment ? attachment.lunId : supportingAttachment.lunId,
                                file: null,
                            };
                        }),
                    ],
                };
                payload.OldSupportingDocumentAttachments = payload.SupportingDocumentAttachments;
                this.toastService.Success('LUN successfully saved.');
                return of(new LUNUpdateRequestSuccess(payload));
            }),
            catchError((response: HttpErrorResponse) => {
                if (response.status === 400) {
                    this.toastService.Error(response.error);
                } else {
                    this.toastService.Error(
                        'Error occurred while updating LUN. Please save LUN manually and retry updating fields.'
                    );
                }
                return throwError([response.error, response.status === 400]);
            })
        );
    }

    private getRecipientsChunk(recipients: string[]) {
        const maxUrlLength = 1500;
        let sum = 0;
        return _.takeWhile(recipients, (value) => {
            sum += value.length;
            return maxUrlLength > sum;
        });
    }

    private getMailUrl(lun: LUN, recipients: string[], isSubmittedForApproval: boolean) {
        const originatorMail = this.isNotNullAndNotUndefined(lun.originator) ? lun.originator.email : '';
        const recipient = recipients.filter((r) => !!r).join(';') + ';';
        var options = null;
        if (isSubmittedForApproval) {
            options = {
                cc: originatorMail,
                subject: 'LUN SUBMITTED: ' + lun.lunNumber,
                body:
                    '3GP SC Livening Up Notice \nThe following LUN is submitted for approval, please follow the link and to review and provide your approval \n' +
                    window.location.href,
            };
        } else {
            options = {
                subject: 'LUN APPROVED: ' + lun.lunNumber,
                body:
                    '3GP SC Livening Up Notice \nThe following LUN is completed, please follow the link to review\n' +
                    window.location.href,
            };
        }

        return this.emailService.getMailToURI(recipient, options);
    }

    private sendEmail(lun: LUN, recipients: string[], isSubmittedForApproval = true) {
        let recipientsChunk = [];
        const mailUrls = [];
        while (recipients.length !== 0) {
            recipientsChunk = this.getRecipientsChunk(recipients);
            mailUrls.push(this.getMailUrl(lun, recipientsChunk, isSubmittedForApproval));
            recipients = recipients.slice(recipientsChunk.length);
        }

        if (mailUrls.length == 1) {
            const mailto = document.createElement('a');
            mailto.style.visibility = 'hidden';
            mailto.style.position = 'absolute';
            mailto.href = mailUrls[0];
            document.body.appendChild(mailto);
            mailto.click();
            mailto.remove();
        } else {
            this.popupService.openPopup(new PopupSettings(ManualEmailDialogComponent, 600, 340, { mailUrls }));
        }
    }

    sendEmailOnSubmit(lun: LUN, signers: string[]) {
        switch (lun.discipline) {
            case Constants.disciplines.Electrical: {
                this.sendEmail(lun, [lun.areaSCElectricalDisciplineLead.email]);
                break;
            }
            case Constants.disciplines.Instrument: {
                this.sendEmail(lun, [lun.areaSCIAndCDisciplineLead.email]);
                break;
            }
            case Constants.disciplines.Telecom: {
                this.sendEmail(lun, [lun.areaSCIAndCDisciplineLead.email]);
                break;
            }
            case Constants.disciplines.Mechanical: {
                this.sendEmail(lun, [lun.areaSCSMPDisciplineLead.email]);
                break;
            }
            case Constants.disciplines.Piping: {
                this.sendEmail(lun, [lun.areaSCSMPDisciplineLead.email]);
                break;
            }
            case Constants.disciplines.AllDisciplines: {
                this.sendEmail(lun, signers);
                break;
            }
            default:
                break;
        }
    }

    sendEmailOnReviewed(lun: LUN) {
        if (lun.leadIsolationAuthoritySMPReviewDate == null) {
            this.sendEmail(lun, getIsolationLeadEmail(lun.leadIsolationAuthoritySMP));
        } else if (lun.leadIsolationAuthorityElectricalReviewDate == null) {
            this.sendEmail(lun, getIsolationLeadEmail(lun.leadIsolationAuthorityElectrical));
        } else if (
            lun.leadIsolationAuthoritySMPReviewDate != null &&
            lun.leadIsolationAuthorityElectricalReviewDate != null
        ) {
            this.sendEmail(lun, [lun.areaSCManager.email]);
        }
    }

    sendEmailOnApproved(lun: LUN, signers: string[]) {
        signers.push(lun.originator.email);
        const approvalDl = lun.areaSCManager.areaManagerSharedEmail.split('\n');
        signers.push(...approvalDl);
        this.sendEmail(lun, _.uniq(signers), false);
    }

    private isNotNullAndNotUndefined(item: any): boolean {
        return item !== null && item !== undefined;
    }
}

const getIsolationLeadEmail = (user: UserDetail) => {
    if (user.areaManagerSharedEmail) {
        return user.areaManagerSharedEmail.split('\n');
    }

    return [user?.email];
};
