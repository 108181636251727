<div class="popup">
    <div class="icon-remove" (click)="onCancel()"></div>
    <p mat-dialog-title class="title">
        <span class="font-gotham-narrow-bold">Attachments</span>
    </p>
    <mat-dialog-content>
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            [matSortActive]="sortBy"
            matSortDisableClear
            [matSortDirection]="direction"
        >
            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>id</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.id }}
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="link">
                <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button
                        [title]="element.name"
                        type="button"
                        class="download-uploaded-attachment"
                        (click)="download(element, $event)"
                    ></button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-dialog-content>
    <mat-paginator [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]"></mat-paginator>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
</div>
