<div class="attachments-container">
    <div class="position">
        <div class="title">Attachments</div>

        <div [formGroup]="nightShiftForm">
            <app-night-shift-attachment-sia
                formControlName="SupportingDocumentAttachments"
                [headerText]="supportingDocsHeader"
                [nightShiftForm]="nightShiftForm"
                [isDisabled]="isDisabled"
                [printMode]="printMode"
            >
            </app-night-shift-attachment-sia>
        </div>
    </div>
</div>
