import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormService } from 'src/app/services/shared/form.service';
import { UntypedFormGroup } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NightShiftInitialFields } from '../../store/night-shift/model';
import { AddNightShiftRequest } from '../../store/night-shift/actions';
import { BaseComponent } from 'src/app/components/base.component';
import { ApplicationState } from 'src/app/store/model';
import _ from 'lodash';
import { ProjectTeamsService } from 'src/app/services/project-teams.service';
import { AreaDTO } from 'src/app/store/common.model';
import { Constants } from 'src/app/constants';
import { RoleService } from 'src/app/services/shared/role.service';

@Component({
    selector: 'app-new-night-shift',
    templateUrl: './new-night-shift.component.html',
    styleUrls: ['./new-night-shift.component.scss'],
})
export class NewNightShiftComponent extends BaseComponent implements OnInit, OnDestroy {
    nightShiftForm: UntypedFormGroup;
    loading: boolean;
    areas: AreaDTO[] = [];

    constructor(
        private formSvc: FormService,
        private dialogRef: MatDialogRef<NewNightShiftComponent>,
        private store: Store<ApplicationState>,
        private roleService: RoleService
    ) {
        super();

        this.nightShiftForm = this.formSvc.createSimpleForm(new NightShiftInitialFields());
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    ngOnInit() {}

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        this.loading = true;
        if (
            this.roleService.isInRole(Constants.applicableGroups.EditUser) ||
            this.roleService.isInRole(Constants.applicableGroups.Admin)
        ) {
            this.nightShiftForm.value.location = '3GP';
        } else if (this.roleService.isInRole(Constants.applicableGroups.GIEditUser)) {
            this.nightShiftForm.value.location = '3GI';
        }
        this.store.dispatch(
            new AddNightShiftRequest({
                nightShift: this.nightShiftForm.value,
            })
        );
        this.store
            .select((state) => state.nightShiftState.isCreated)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isCreated) => {
                if (isCreated) {
                    this.loading = false;
                    this.dialogRef.close();
                }
            });
    }
}
