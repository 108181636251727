import { NgModule } from '@angular/core';
import { StoreModule as RxjsStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LUNEffects } from './lun/effects';
import { LUNRegisterEffects } from './lun-register/effects';
import { NightShiftRegisterEffects } from './night-shift-register/effects';
import { LUNMyPageEffects } from './my-page/effects';
import { ManualUploadEffects } from './manual-upload/effects';
import { NightShiftEffects } from './night-shift/effects';
import { SubsystemListEffects } from './manual-upload/subsystem-list/effects';
import { MasterSubsystemListEffects } from './manual-upload/master-subsystem-list/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ActionUsersEffects } from './action-users/effects';
import * as lun from './lun/reducer';
import * as jodit from './jodit-description/reducer';
import * as lunRegister from './lun-register/reducer';
import * as nightShiftRegister from './night-shift-register/reducer';
import * as lunMyPageState from './my-page/reducers';
import * as lookupState from './lookup/reducer';
import { reducer as manualUploadReducer } from './manual-upload/reducer';
import { reducer as subsystemListReducer } from './manual-upload/subsystem-list/reducer';
import { reducer as actionUsersReducer } from './action-users/reducer';
import { reducer as masterSubsystemListReducer } from './manual-upload/master-subsystem-list/reducer';
import { reducer as nightShiftReducer } from './night-shift/reducer';
@NgModule({
    declarations: [],
    imports: [
        RxjsStoreModule.forRoot({
            lunState: lun.reducer,
            joditDescriptionState: jodit.reducer,
            lunRegisterState: lunRegister.reducer,
            lunMyPageState: lunMyPageState.reducer,
            lookupState: lookupState.reducer,
            manualUploadState: manualUploadReducer,
            subsystemListState: subsystemListReducer,
            actionUsersState: actionUsersReducer,
            masterSubsystemListState: masterSubsystemListReducer,
            nightShiftState: nightShiftReducer,
            nightShiftRegisterState: nightShiftRegister.reducer,
        }),
        EffectsModule.forRoot([
            LUNEffects,
            LUNRegisterEffects,
            LUNMyPageEffects,
            ManualUploadEffects,
            SubsystemListEffects,
            ActionUsersEffects,
            MasterSubsystemListEffects,
            NightShiftEffects,
            NightShiftRegisterEffects,
        ]),
        StoreDevtoolsModule.instrument({ maxAge: 0 }),
    ],
})
export class StoreModule {}
