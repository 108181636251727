import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
    static readonly applicableGroups = {
        ReadOnly: 'ReadOnly',
        EditUser: 'EditUser',
        GIEditUser: '3GI-EditUser',
        Admin: 'Admin',
        AreaSCElectricalDisciplineLead: 'AreaSCElectricalDisciplineLead',
        AreaSCSMPDisciplineLead: 'AreaSCSMPDisciplineLead',
        AreaSCIAndCDisciplineLead: 'AreaSCIAndCDisciplineLead',
        AreaSCManager: 'AreaSCManager',
        IsolationSMPLead: 'IsolationSMPLead',
        IsolationElectricalLead: 'IsolationElectricalLead'
    };

    static readonly changeTypes = ['SubsystemList'];
    static readonly logRefreshInterval = 45 * 1000;
    static readonly delayAfterUploadInterval = 7 * 1000;
    static readonly popupWidth: number = 500;
    static readonly popupHeight: number = 300;
    static readonly TokenRefreshInterval = 1800000;
    static readonly AutosaveInterval = 1800000;

    static get LUNDefaults() {
        return {
            defaultSortField: 'raisedDate',
        };
    }

    static readonly disciplines = {
        AllDisciplines: 'All Disciplines / Multi Discipline',
        Electrical: 'Electrical',
        Instrument: 'Instrument',
        Telecom: 'Telecom',
        Mechanical: 'Mechanical',
        Piping: 'Piping',
    }

    static readonly leads = {
        electricalLead: 'Area SC Electrical Discipline Lead',
        iAndCLead: 'Area SC I&C Discipline Lead',
        smpLead: 'Area SC SMP Discipline Lead',
        isolationLeadSMP: 'Lead Isolation Authority SMP',
        isolationLeadElectrical: 'Lead Isolation Authority Electrical'
    }
}
