import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter, NgZone } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/shared/form.service';
import { Store, select } from '@ngrx/store';
import { takeWhile, map, take, finalize, catchError } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import {
    NightShiftRegisterFilterRequest,
    NightShiftRegisterFilterPropertyUpdate,
    NightShiftRegisterFilterReset,
    NightShiftRegisterExportToExcelRequest,
    NightShiftRegisterToggleColumnShowSetting,
    NightShiftRegisterAddCommentRequest,
} from 'src/app/store/night-shift-register/actions';
import { ActivatedRoute, Router } from '@angular/router';
import {
    NightShiftRegisterFilter,
    NightShiftRegisterDTO,
    ShowColumnSettings,
    AttachmentDTO,
} from 'src/app/store/night-shift-register/model';
import { LookupService } from '../../services/lookup.service';
import { SetInputEventArgs } from 'src/app/models/set-input';
import { ToastService } from 'src/app/services/shared/toast.service';
import { BaseComponent } from 'src/app/components/base.component';
import { OrderDirection, CheckListColumn, RangeColumn, CalendarColumn } from 'src/app/store/common.model';
import { of } from 'rxjs';
import { RoleService } from 'src/app/services/shared/role.service';
import { ApplicationState } from 'src/app/store/model';
import { LUNLookupService } from 'src/app/services/lun-lookup.service';
import { HeaderCheckListFilter, HeaderDateFilter, HeaderRangeFilter } from 'src/app/models/filter-settings';
import { HeaderChecklistFilterComponent } from '../filter/header-checklist-filter/header-checklist-filter.component';
import { PopupSettings } from 'src/app/models/popup-settings';
import { PopupService } from 'src/app/services/shared/popup.service';
import { HeaderRangeFilterComponent } from '../filter/header-range-filter/header-range-filter.component';
import { HeaderDateFilterComponent } from '../filter/header-date-filter/header-date-filter.component';
import { CommentPopupComponent } from '../comment-popup/comment-popup.component';
import { Constants } from 'src/app/constants';
import { CommentService } from 'src/app/services/comment.service';
import { CommentsHistoryComponent } from '../comments-history/comments-history.component';
import { NightShiftService } from 'src/app/services/night-shift.service';
import { Dictionary } from 'typescript-collections';
import { NightShiftStatusType } from 'src/app/models/enums';
import { ProjectTeamsService } from 'src/app/services/project-teams.service';
import { NightShiftCommentService } from 'src/app/services/night-shift-comment.service';
import { MultipleAttachmentsComponent } from '../multiple-attachments/multiple-attachments.component';

@Component({
    selector: 'app-night-shift-register',
    templateUrl: './night-shift-register.component.html',
    styleUrls: ['./night-shift-register.component.scss'],
})
export class NightShiftRegisterComponent extends BaseComponent implements OnInit {
    filterForm: UntypedFormGroup;
    isLoading = false;
    resultsLength = 0;

    todayDate = new Date();
    data: NightShiftRegisterDTO[] = [];
    registerGridData$ = this.store.select((state) => state.nightShiftRegisterState.dataPagination);
    registerGridLoader$ = this.store.select((state) => state.nightShiftRegisterState.isLoading);
    registerFilter$ = this.store.select((state) => state.nightShiftRegisterState.filter);
    setOriginatorInput: EventEmitter<SetInputEventArgs> = new EventEmitter();
    sortBy = 'number';
    pageSize;
    direction: OrderDirection = OrderDirection.Desc;
    areas: string[] = [];
    readonly isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);
    statuses = NightShiftStatusType;

    uploadedAttachmentsActionInProgress = new Dictionary<string, boolean>();
    showColumnSettings$ = this.store.select((state) => state.nightShiftRegisterState.showColumnSettings);
    showColumnSettings: ShowColumnSettings;

    displayedColumns: string[] = [
        'number',
        'originator',
        'raisedDate',
        'startDate',
        'endDate',
        'attachments',
        'latestComment',
        'commenthist',
    ];

    isReadOnly = false;
    autocompleteDisplayedColumns = ['name', 'description'];

    columnNumbers$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnNumbers);
    columnNumbers: CheckListColumn = null;
    columnAreas$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnAreas);
    columnAreas: CheckListColumn = null;
    columnOriginators$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnOriginators);
    columnOriginators: CheckListColumn = null;
    columnRaisedDate$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnRaisedDate);
    columnRaisedDate: CalendarColumn = null;
    columnStartDate$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnStartDate);
    columnStartDate: CalendarColumn = null;
    columnEndDate$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnEndDate);
    columnEndDate: CalendarColumn = null;
    columnComments$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnComments);
    columnComments: CheckListColumn = null;
    columnStatuses$ = this.store.select((state) => state.nightShiftRegisterState.filter.columnStatuses);
    columnStatuses: CheckListColumn = null;
    projectTeamNames: string[] = [];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private formSvc: FormService,
        private store: Store<ApplicationState>,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private lookupService: LookupService,
        private toastService: ToastService,
        private ngZone: NgZone,
        private roleService: RoleService,
        private nightShiftLookupService: LUNLookupService,
        private popupService: PopupService,
        private commentService: NightShiftCommentService,
        private nightShiftService: NightShiftService,
        private route: ActivatedRoute,
        private projectTeamsService: ProjectTeamsService
    ) {
        super();

        this.filterForm = this.formSvc.createSimpleForm(new NightShiftRegisterFilter());
    }

    watchFormChanges() {
        for (const key of Object.keys(this.filterForm.controls)) {
            this.filterForm.controls[key].valueChanges.pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
                this.store.dispatch(new NightShiftRegisterFilterPropertyUpdate({ key, value }));
                this.paginator.pageIndex = 0;
            });
        }
    }

    openHistoryPopup(element: NightShiftRegisterDTO) {
        if (element.latestComment === null) {
            return;
        } else {
            this.isLoading = true;
            this.commentService.getComments(element.id).subscribe((data) => {
                this.isLoading = false;
                this.popupService.openPopup(
                    new PopupSettings(CommentsHistoryComponent, null, null, { commentHist: data }, 200, 200)
                );
            });
        }
    }
    openAttachmentsPopup(element: NightShiftRegisterDTO) {
        if (element.attachments === null) {
            return;
        } else {
            this.popupService.openPopup(
                new PopupSettings(
                    MultipleAttachmentsComponent,
                    null,
                    null,
                    { multiAttach: element.attachments },
                    200,
                    200
                )
            );
        }
    }

    download(attachment: AttachmentDTO, e) {
        e.stopPropagation();
        e.preventDefault();
        this.uploadedAttachmentsActionInProgress.setValue(attachment.link, true);
        this.nightShiftService
            .downloadAttachment(attachment.link)
            .pipe(
                take(1),
                finalize(() => this.uploadedAttachmentsActionInProgress.setValue(attachment.link, false)),
                catchError(() => {
                    this.toastService.Error(
                        'Error has occurred while downloading attachment. Please contact Program Administrator.'
                    );
                    return of(null);
                })
            )
            .subscribe((file) => {
                const blob = new Blob([file], {
                    type: 'application/octet-stream',
                });
                saveAs(blob, attachment.name);
            });
    }

    getComments = (search = '', take = 30, page = 0) =>
        this.nightShiftLookupService.searchNightShiftComments(search, take, page, this.getLatestFilterData());

    ngOnInit() {
        this.projectTeamNames = this.projectTeamsService.getTeams();
        this.paginator.pageIndex = 0;
        this.isReadOnly = this.roleService.isReadOnly();
        this.registerFilter$.pipe(takeWhile(() => this.isAlive)).subscribe((filter) => {
            this.filterForm.patchValue(filter, { emitEvent: false });
            this.sortBy = filter.sortBy;
            this.direction = filter.direction;
            this.pageSize = filter.take;
            this.paginator.pageIndex = filter.page;
        });

        this.nightShiftLookupService
            .getAreas()
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(
                (areas) => {
                    this.areas = areas.map((s) => s.name);
                },
                () => {
                    this.toastService.Error(
                        'Error occurred while fetching areas. Please contact Program Administrator.'
                    );
                }
            );

        this.registerGridData$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => {
            this.data = data.items;
            this.resultsLength = data.totalCount;
        });

        this.registerGridLoader$.pipe(takeWhile(() => this.isAlive)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            // hack for the Angular bug:
            // https://stackoverflow.com/questions/39741293/why-is-ngonchanges-not-firing-when-a-boolean-value-changed-in-angularjs-2
            this.changeDetectorRef.detectChanges();
        });

        this.sort.sortChange.pipe(takeWhile(() => this.isAlive)).subscribe((sortChange: Sort) => {
            this.store.dispatch(
                new NightShiftRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: sortChange,
                })
            );
            this.paginator.pageIndex = 0;
            this.store.dispatch(new NightShiftRegisterFilterRequest());
        });

        this.columnAreas$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnAreas = data));
        this.columnNumbers$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnNumbers = data));
        this.columnOriginators$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => (this.columnOriginators = data));
        this.columnRaisedDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((raiseDate) => (this.columnRaisedDate = raiseDate));
        this.columnStartDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((columnStartDate) => (this.columnStartDate = columnStartDate));
        this.columnEndDate$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((columnEndDate) => (this.columnEndDate = columnEndDate));
        this.columnComments$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnComments = data));
        this.columnStatuses$.pipe(takeWhile(() => this.isAlive)).subscribe((data) => (this.columnStatuses = data));
        this.showColumnSettings$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((showColumnSettings) => (this.showColumnSettings = showColumnSettings));

        this.watchFormChanges();

        this.store.dispatch(new NightShiftRegisterFilterRequest());
    }

    openDetailsPage(id: number): void {
        this.ngZone.run(() => this.router.navigate([`nightshift/details/${id}`]));
    }

    onPaginatorChange(pageEvent: PageEvent) {
        if (this.pageSize !== pageEvent.pageSize) {
            this.store.dispatch(
                new NightShiftRegisterFilterPropertyUpdate({
                    key: 'take',
                    value: pageEvent.pageSize,
                })
            );
            this.filterForm.controls.take.setValue(pageEvent.pageSize);
            this.pageSize = pageEvent.pageSize;
        } else {
            this.store.dispatch(
                new NightShiftRegisterFilterPropertyUpdate({
                    key: 'page',
                    value: pageEvent.pageIndex,
                })
            );
        }
        this.store.dispatch(new NightShiftRegisterFilterRequest());
    }

    getOriginators = (search = '', take = 10, page = 0) => {
        return this.lookupService.searchUsers(search, page, take);
    };

    resetFilters() {
        this.store.dispatch(new NightShiftRegisterFilterReset());
        this.setOriginatorInput.emit(new SetInputEventArgs(false, ''));
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;
    }

    exportToExcel() {
        this.store.dispatch(new NightShiftRegisterExportToExcelRequest());
    }

    search() {
        this.paginator.pageIndex = 0;
        this.store.dispatch(
            new NightShiftRegisterFilterPropertyUpdate({
                key: 'page',
                value: 0,
            })
        );
        this.store.dispatch(new NightShiftRegisterFilterRequest());
    }

    onPageChange(newPage: number) {
        if (newPage < 1) {
            newPage = 1;
        } else if (newPage > this.paginator.getNumberOfPages()) {
            newPage = this.paginator.getNumberOfPages();
        }
        var pageEvt = new PageEvent();
        pageEvt.pageIndex = newPage - 1;
        pageEvt.pageSize = this.pageSize;
        this.paginator.pageIndex = pageEvt.pageIndex;
        this.onPaginatorChange(pageEvt);
    }

    private getLatestFilterData(): NightShiftRegisterFilter {
        let filter: NightShiftRegisterFilter;
        this.store.pipe(select((x) => x.nightShiftRegisterState, take(1))).subscribe((data) => (filter = data.filter));
        return filter;
    }

    getStatuses = (search = '', take = 10, page = 0) =>
        this.nightShiftLookupService.getNightShiftStatuses(search, take, page, this.getLatestFilterData());

    getNumbers = (search = '', take = 10, page = 0) =>
        this.nightShiftLookupService.getNightShiftNumbers(search, take, page, this.getLatestFilterData());

    getAreas = (search = '', take = 10, page = 0) =>
        this.nightShiftLookupService.getNightShiftAreas(search, take, page, this.getLatestFilterData());

    searchOriginators = (search = '', take = 10, page = 0) =>
        this.nightShiftLookupService.getNightShiftOriginators(search, take, page, this.getLatestFilterData());

    openHeaderCheckListFilter(
        columnName: string,
        searchFunc: any,
        propertyName: string,
        placeholder: string,
        selected: CheckListColumn,
        showCounts: boolean = false,
        showInputSearch: boolean = true,
        displayBlanksRadio: boolean = false,
        isSortingOff: boolean = false
    ) {
        this.popupService.openPopup(
            new PopupSettings<HeaderCheckListFilter>(HeaderChecklistFilterComponent, 400, 590, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                placeHolder: placeholder,
                searchFunc: searchFunc,
                selectedItems: selected ? [...selected.items] : [],
                isSortingOff: isSortingOff,
                showCounts,
                showInputSearch: showInputSearch,
                displayBlanksRadio: displayBlanksRadio,
                onlyBlanks: selected ? selected.onlyBlanks : false,
                excludeBlanks: selected ? selected.excludeBlanks : false,
                action: (data) => {
                    let value = new CheckListColumn();
                    value.items = data.selectedItems.length > 0 ? data.selectedItems : [];
                    value.excludeBlanks = data.excludeBlanks;
                    value.onlyBlanks = data.onlyBlanks;
                    this.filterForm.controls[propertyName].setValue(value);

                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);

                    this.search();
                },
                resetColumnAction: () => {
                    this.store.dispatch(
                        new NightShiftRegisterFilterPropertyUpdate({
                            key: propertyName,
                            value: null,
                        })
                    );
                },
            })
        );
    }

    openHeaderRangeFilter(columnName: string, propertyName: string, placeholder: string, rangeColumn: RangeColumn) {
        const ranges = rangeColumn === null ? [] : rangeColumn.ranges;
        this.popupService.openPopup(
            new PopupSettings<HeaderRangeFilter>(HeaderRangeFilterComponent, 425, 280, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                rangeColumn: { ranges },
                placeHolder: placeholder,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.rangeColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            })
        );
    }

    openHeaderDateFilter(
        columnName: string,
        propertyName: string,
        placeholder: string,
        calendarColumn: CalendarColumn
    ) {
        const excludeBlanks = calendarColumn === null ? false : calendarColumn.excludeBlanks;
        const onlyBlanks = calendarColumn === null ? false : calendarColumn.onlyBlanks;
        const rangeDates = calendarColumn === null ? [] : calendarColumn.rangeDates;
        this.popupService.openPopup(
            new PopupSettings<HeaderDateFilter>(HeaderDateFilterComponent, 345, 625, {
                isAscendingSort: this.sortBy === columnName && this.direction === OrderDirection.Asc,
                isDescendingSort: this.sortBy === columnName && this.direction === OrderDirection.Desc,
                calendarColumn: { rangeDates, excludeBlanks, onlyBlanks },
                placeHolder: placeholder,
                action: (data) => {
                    this.filterForm.controls[propertyName].setValue(data.calendarColumn);
                    this.sortUpdate(data.isAscendingSort, data.isDescendingSort, columnName);
                    this.search();
                },
            })
        );
    }

    private sortUpdate(isAscendingSort: boolean, isDescendingSort: boolean, columnName: string) {
        if (isAscendingSort || isDescendingSort) {
            const direction: OrderDirection = isAscendingSort ? OrderDirection.Asc : OrderDirection.Desc;
            this.store.dispatch(
                new NightShiftRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: columnName, direction: direction },
                })
            );
        } else {
            this.store.dispatch(
                new NightShiftRegisterFilterPropertyUpdate({
                    key: 'sortBy',
                    value: { active: 'number', direction: OrderDirection.Desc },
                })
            );
        }
    }

    public toggleShowSetting(columnName: string) {
        this.store.dispatch(new NightShiftRegisterToggleColumnShowSetting(columnName));
    }

    openCommentPopup(element: NightShiftRegisterDTO, event: any) {
        const comment = element.latestComment || '';
        if (this.isReadOnly) return;

        this.popupService.openPopup(
            new PopupSettings(CommentPopupComponent, Constants.popupWidth, Constants.popupHeight, {
                comment,
                action: (value: string) => {
                    this.store.dispatch(
                        new NightShiftRegisterAddCommentRequest({
                            id: element.id,
                            description: value,
                        })
                    );
                },
            })
        );

        event.stopPropagation();
    }
}
