import { Action } from '@ngrx/store';
import { UpdateDataLog } from 'src/app/models/update-data-log';
import { SubsystemListDeltaPagination } from './model';
import { Moment } from 'moment';

export enum SubsystemListActionTypes {
    SubsystemListUploadLogRequest = '[Subsystem List] Upload Log Request',
    SubsystemListUploadLogSuccess = '[Subsystem List] Upload Log Success',
    SubsystemListUploadLogError = '[Subsystem List] Upload Log Error',
    SubsystemListDeltaRequest = '[Subsystem List] Delta Request',
    SubsystemListDeltaSuccess = '[Subsystem List] Delta Success',
    SubsystemListDeltaError = '[Subsystem List] Delta Error',
    SubsystemListSetStatusAndStartDate = '[Subsystem List] Set Status and Start Date',
    SubsystemListDeltaFilterPropertyUpdate = '[Subsystem List] Delta Filter Property Update',
    SubsystemListPatchDeltaRequest = '[Subsystem List] Patch Delta Request',
    SubsystemListPatchDeltaSuccess = '[Subsystem List] Patch Delta Success',
    SubsystemListPatchDeltaError = '[Subsystem List] Patch Delta Error',
    SubsystemListPatchAllDeltaRequest = '[Subsystem List] Patch All Delta Request',
    SubsystemListPatchAllDeltaSuccess = '[Subsystem List] Patch All Delta Success',
    SubsystemListPatchAllDeltaError = '[Subsystem List] Patch All Delta Error',
    SubsystemListValidateDeltaRequest = '[Subsystem List] Validate Delta Request',
    SubsystemListValidateDeltaSuccess = '[Subsystem List] Validate Delta Success',
    SubsystemListValidateDeltaError = '[Subsystem List] Validate Delta Error',
    SubsystemListFileUploaded = '[Subsystem List] File Uploaded',
    SubsystemListClearInProgressSpinner = '[Subsystem List] Clear In Progress Spinner',
    SubsystemListTemplateFileRequest = '[Subsystem List] Template File Request',
    SubsystemListTemplateFileSuccess = '[Subsystem List] Template File Success',
    SubsystemListTemplateFileError = '[Subsystem List] Template File Error',
    SubsystemListValidateButtonStateRequest = '[Subsystem List] Validate Button State Request',
    SubsystemListValidateButtonStateSuccess = '[Subsystem List] Validate Button State Success',
    SubsystemListValidateButtonStateError = '[Subsystem List] Validate Button State Error',
    SubsystemListDownloadOutputDataRequest = '[Subsystem List] Download Output Data Request',
    SubsystemListDownloadOutputDataSuccess = '[Subsystem List] Download Output Data Success',
    SubsystemListDownloadOutputDataError = '[Subsystem List] Download Output Data Error',
    SubsystemListAddStartedLog = '[SubsystemList] Add Started Log',
}

export class SubsystemListAddStartedLog implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListAddStartedLog;

    constructor(public payload: string) {}
}

export class SubsystemListUploadLogRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListUploadLogRequest;

    constructor(public payload: string) {}
}

export class SubsystemListUploadLogSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListUploadLogSuccess;

    constructor(public payload: UpdateDataLog[], public subType: string) {}
}

export class SubsystemListUploadLogError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListUploadLogError;

    constructor(public payload: string) {}
}

export class SubsystemListDeltaRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDeltaRequest;

    constructor(public payload: string) {}
}

export class SubsystemListDeltaSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDeltaSuccess;

    constructor(public payload: SubsystemListDeltaPagination, public subType: string) {}
}

export class SubsystemListDeltaError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDeltaError;

    constructor(public payload: string) {}
}

export class SubsystemListSetStatusAndStartDate implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListSetStatusAndStartDate;

    constructor(public payload: { status: string; startDate: Moment, type: string }) {}
}

export class SubsystemListDeltaFilterPropertyUpdate implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDeltaFilterPropertyUpdate;

    constructor(public payload: { key: string; value: any }) {}
}

export class SubsystemListPatchDeltaRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListPatchDeltaRequest;

    constructor(public payload: { subsystem: string; deltaState: boolean }) {}
}

export class SubsystemListPatchAllDeltaRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListPatchAllDeltaRequest;

    constructor(public payload: { deltaState: boolean, type: string }) {}
}

export class SubsystemListPatchDeltaSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListPatchDeltaSuccess;
}

export class SubsystemListPatchAllDeltaSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListPatchAllDeltaSuccess;

    constructor(public payload: { deltaState: boolean }) {}
}

export class SubsystemListPatchDeltaError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListPatchDeltaError;

    constructor(public payload: string) {}
}

export class SubsystemListPatchAllDeltaError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListPatchAllDeltaError;

    constructor(public payload: string) {}
}

export class SubsystemListValidateDeltaRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListValidateDeltaRequest;

    constructor(public payload: string) {}
}

export class SubsystemListValidateDeltaSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListValidateDeltaSuccess;
}

export class SubsystemListValidateDeltaError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListValidateDeltaError;

    constructor(public payload: string) {}
}

export class SubsystemListFileUploaded implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListFileUploaded;

    constructor(public payload: string) {}
}

export class SubsystemListClearInProgressSpinner implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListClearInProgressSpinner;

    constructor(public payload: string) {}
}

export class SubsystemListTemplateFileRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListTemplateFileRequest;

    constructor(public payload: string) {}
}

export class SubsystemListTemplateFileSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListTemplateFileSuccess;

    constructor(public payload: BlobPart) {}
}

export class SubsystemListTemplateFileError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListTemplateFileError;

    constructor(public payload: string) {}
}

export class SubsystemListValidateButtonStateRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListValidateButtonStateRequest;
}

export class SubsystemListValidateButtonStateSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListValidateButtonStateSuccess;

    constructor(public payload: boolean) {}
}

export class SubsystemListValidateButtonStateError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListValidateButtonStateError;

    constructor(public payload: string) {}
}

export class SubsystemListDownloadOutputDataRequest implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDownloadOutputDataRequest;

    constructor(public payload: string) {}
}

export class SubsystemListDownloadOutputDataSuccess implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDownloadOutputDataSuccess;

    constructor(public payload: { content: BlobPart; fileName: string }) {}
}

export class SubsystemListDownloadOutputDataError implements Action {
    readonly type = SubsystemListActionTypes.SubsystemListDownloadOutputDataError;

    constructor(public payload: string) {}
}

export type SubsystemListActions =
    | SubsystemListUploadLogRequest
    | SubsystemListUploadLogSuccess
    | SubsystemListUploadLogError
    | SubsystemListDeltaRequest
    | SubsystemListDeltaSuccess
    | SubsystemListDeltaError
    | SubsystemListSetStatusAndStartDate
    | SubsystemListDeltaFilterPropertyUpdate
    | SubsystemListPatchDeltaRequest
    | SubsystemListPatchDeltaSuccess
    | SubsystemListPatchDeltaError
    | SubsystemListPatchAllDeltaRequest
    | SubsystemListPatchAllDeltaSuccess
    | SubsystemListPatchAllDeltaError
    | SubsystemListValidateDeltaRequest
    | SubsystemListValidateDeltaSuccess
    | SubsystemListValidateDeltaError
    | SubsystemListFileUploaded
    | SubsystemListClearInProgressSpinner
    | SubsystemListTemplateFileRequest
    | SubsystemListTemplateFileSuccess
    | SubsystemListTemplateFileError
    | SubsystemListValidateButtonStateRequest
    | SubsystemListValidateButtonStateSuccess
    | SubsystemListValidateButtonStateError
    | SubsystemListDownloadOutputDataRequest
    | SubsystemListDownloadOutputDataSuccess
    | SubsystemListDownloadOutputDataError
    | SubsystemListAddStartedLog;
